import axios from 'axios';
import api from '../config/api';
import CryptoJS from 'crypto-js';

class PromotionCtrl {
    checkSpecialCode = (params) => {
        return new Promise(async (resolve, reject) => {
            try {
                const { code } = params;
                const encrypted = CryptoJS.AES.encrypt(code, api.PRIVATE_KEY_PROMOTION);
                const encode = encodeURIComponent(encrypted.toString());
                const url = api.PROMOTION_API + '/checkCodeSpecial?serviceKey=' + api.SERVICE_KEY_PROMOTION + '&secretKey=' + encode + '&action=check';
                const result = await axios.get(url)
                resolve({ result, encode })
            } catch (error) {
                reject(error);
            }
        })
    }

    checkCodeIssue = (params) => {
        return new Promise(async (resolve, reject) => {
            try {
                const { issueCode } = params;
                const encrypted = CryptoJS.AES.encrypt(issueCode, api.PRIVATE_KEY_PROMOTION);
                const encode = encodeURIComponent(encrypted.toString());
                const url = api.PROMOTION_API + '/checkCodeIssue?serviceKey=' + api.SERVICE_KEY_PROMOTION + '&secretKey=' + encode + '&action=check';
                const result = await axios.get(url)
                resolve({ result, encode })
            } catch (error) {
                reject(error);
            }
        })
    }
}




export default PromotionCtrl;