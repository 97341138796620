import firebase  from 'firebase';

const firebaseConfig = {
  apiKey: "AIzaSyC5X4Ag9u5-Af34-_PiU5hRApmaaDHZx0w",
  authDomain: "chat-manager-9e733.firebaseapp.com",
  databaseURL: "https://chat-manager-9e733.firebaseio.com",
  projectId: "chat-manager-9e733",
  storageBucket: "chat-manager-9e733.appspot.com",
  messagingSenderId: "396774669515",
  appId: "1:396774669515:web:b597f6605c3a80a48e6f4e",
  measurementId: "G-6H2KGYZ8TY"
};

const firebaseConfigTest = {
  apiKey: "AIzaSyBcxEWO1qyJ_HeBh3mX6xVe1gpdeF9fg8U",
  authDomain: "chat-manager-test.firebaseapp.com",
  databaseURL: "https://chat-manager-test.firebaseio.com",
  projectId: "chat-manager-test",
  storageBucket: "chat-manager-test.appspot.com",
  messagingSenderId: "913334893234",
  appId: "1:913334893234:web:b1b72f5831d8fe09b267ab"
}

firebase.initializeApp(process.env.NODE_ENV === "development" ? firebaseConfigTest : firebaseConfig)

const db = firebase.firestore();

const timeServer = firebase.firestore.FieldValue.serverTimestamp()


  export {
    firebaseConfig,
    db,
    timeServer
  }