import React from 'react';
import Modal from '../../Modal';
import IconAddAddress from '../../../assets/Icons/Add_1.png';
import FromAddress from './FromAddress';
import ListAddressUser from './ListAddressUser';
import { connect } from 'react-redux';
import * as actions_address from '../../../redux/actions/address';
import googleMapCtrl from '../../../controllers/googleMapCtrl';
import addressUsersCtrl from '../../../controllers/addressUsersCtrl';
import IconNotification from '../../../assets/Icons/IconAlert.svg';
import * as actions_notification from '../../../redux/actions/notification';
import * as actions_createOrder from '../../../redux/actions/createOrder';
const api_googleMapCtrl = new googleMapCtrl();
const api_addressUsersCtrl = new addressUsersCtrl();


class ModalAddress extends React.Component {
    state = {
        status: "list",
        title: "เลือกที่อยู่อื่น",
        textButton: "เพิ่มและใช้ที่อยู่นี้",
        value: {
            address: "",
            addressExtra: "",
            subdistrict: null,
            district: null,
            province: null,
            location: [],
            zipcode: ""
        },
        listAddressCustomer: [],
        select: {
            address: null,
            addressDefault: null
        }
    }


    baseState = {
        ...this.state
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.modalAddress.isOpen && !nextProps.modalAddress.isOpen) {
            this.setState({
                ...this.baseState
            });
        }

        if (nextProps.listAddressCustomer !== this.props.listAddressCustomer) {
            const addressDefault = nextProps.listAddressCustomer.find(item => item.no === 1);
            this.setState({
                listAddressCustomer: nextProps.listAddressCustomer,
                select: {
                    ...this.state.select,
                    addressDefault
                }
            });
        }

        if (nextProps.modalAddress.data.addressUser !== this.props.modalAddress.data.addressUser) {
            this.setState({
                select: {
                    ...this.state.select,
                    address: nextProps.modalAddress.data.addressUser,
                }
            });
        }

    }


    addNewAddress = () => {
        this.setState({
            status: 'add',
            title: "เพิ่มที่อยู่ใหม่",
            textButton: "เพิ่มและใช้ที่อยู่นี้"
        });
    }

    updateAddress = () => {
        this.setState({
            status: 'update',
            title: "แก้ไขที่อยู่",
            textButton: "แก้ไขและใช้ที่อยู่นี้"
        });
    };

    backToListAddress = () => {
        this.setState({
            listAddressCustomer: [...this.props.listAddressCustomer],
            select: {
                ...this.state.select
            },
            status: "list",
            title: "เลือกที่อยู่อื่น",
            textButton: "เพิ่มและใช้ที่อยู่นี้",
            value: {
                ...this.baseState.value
            }
        });
    }


    handleSelectProvince = async (selected) => {
        await this.props.getListDistricts({
            provinceId: selected.provinceId
        });
        this.setState({
            value: {
                ...this.state.value,
                province: selected,
                district: null,
                subdistrict: null
            }
        });
    }
    handleSelectDistricts = (selected) => {
        this.props.getListSubDistricts({
            districtId: selected.districtId
        });
        this.setState({
            value: {
                ...this.state.value,
                district: selected,
                subdistrict: null
            }
        });

    }
    handleSelectSubdistrict = (selected) => {
        this.setState({
            value: {
                ...this.state.value,
                subdistrict: selected,
                zipcode: selected.zipcode
            }
        });
    }

    handleChangeAddress_AddressExtra = (evt) => {
        this.setState({
            value: {
                ...this.state.value,
                [evt.target.name]: evt.target.value
            }
        });
    }



    handleClickSearchLatLongByAddress = async () => {
        try {
            const {
                address,
                subdistrict,
                district,
                province,
                zipcode
            } = this.state.value;

            let concatAddress = ''

            if (address !== "") {
                concatAddress += address
            }
            if (subdistrict !== null) {
                concatAddress += " " + subdistrict.subdistrictName
            }
            if (district !== null) {
                concatAddress += " " + district.districtName
            }
            if (province !== null) {
                concatAddress += " " + province.provinceName
            }
            if (zipcode !== "") {
                concatAddress += " " + zipcode
            }

            const { data } = await api_googleMapCtrl.getLatLngByAddress({ address: concatAddress });
            if (data.results.length > 0) {
                const location = data.results[0].geometry.location;
                this.setState({
                    value: {
                        ...this.state.value,
                        location: [location.lat, location.lng]
                    }
                });
            } else {
                this.openNotificationAlert('ค้นหาที่อยู่ไม่พบ')
            }
        } catch (error) {
            console.log(error);
        }
    }

    openNotificationAlert = (message) => {
        const data = {
            message: <div><img src={IconNotification} alt="IconNotification"></img><span className="ml-3">{message}</span></div>,
            level: 'error',
            autoDismiss: 0,
            position: "tr"
        }
        this.props.openNotificationAlert(data);
    }

    handleGetLatLongInMap = ({ lat, lng }) => {
        this.setState({
            value: {
                ...this.state.value,
                location: [lat, lng]
            }
        });
    }

    handleChangeZipcode = (evt) => {
        this.setState({
            value: {
                ...this.state.value,
                zipcode: evt.target.value
            }
        });
    }


    handleClickCreateAddress = async () => {
        try {
            const isValiDateFrom = await this.validateFrom();
            if (isValiDateFrom) {
                const {
                    address,
                    addressExtra,
                    subdistrict,
                    district,
                    province,
                    location,
                    zipcode
                } = this.state.value;
                const { userCustomer, listAddressCustomer } = this.props.createOrder;


                const dataCreateAddress = {
                    address: address,
                    addressExtra: addressExtra,
                    subdistrict: subdistrict.subdistrictName,
                    district: district.districtName,
                    province: province.provinceName,
                    location: [location[0].toString(), location[1].toString()],
                    name: userCustomer.displayName,
                    mobile: userCustomer.mobile,
                    userId: userCustomer.id,
                    zipcode: zipcode.toString(),
                    // no: listAddressCustomer.length + 1
                };



                const { data } = await api_addressUsersCtrl.createAddressUser(dataCreateAddress);
                if (data) {
                    await this.props.getListAddressUser();
                    this.setState({
                        status: "list",
                        title: "เลือกที่อยู่อื่น",
                        textButton: "เพิ่มและใช้ที่อยู่นี้",
                    });
                }

            }
        } catch (error) {
            console.log(error);
        }

    }

    validateFrom = async () => {
        let isSusscess = true;
        const reg = new RegExp('^[0-9]+$');
        const {
            address,
            subdistrict,
            district,
            province,
            zipcode,
            location
        } = this.state.value;
        if (address === "") {
            await this.openNotificationAlert('กรุณากรอกบ้านเลขที่,ซอย,ถนน');
            isSusscess = false;
        }
        if (subdistrict == null) {
            await this.openNotificationAlert('กรุณาเลือกแขวง');
            isSusscess = false;
        }
        if (district == null) {
            await this.openNotificationAlert('กรุณาเลือกเขต');
            isSusscess = false;
        }
        if (province == null) {
            await this.openNotificationAlert('กรุณาเลือกจังหวัด');
            isSusscess = false;
        }
        if (zipcode.toString().trim().length !== 5 || !reg.test(zipcode)) {
            await this.openNotificationAlert('กรุณากรอกรหัสไปรษณีย์ใหถูกต้อง');
            isSusscess = false;
        }
        if (location[0] == null || location[1] == null) {
            await this.openNotificationAlert('กรุณาเลือก Lat Long');
            isSusscess = false;
        }
        return isSusscess;
    }



    handleClickSelectAddressUser = (selected) => {
        this.setState({
            select: {
                ...this.state.select,
                address: selected
            }
        })
    }


    handleClickSetDefaultAddress = (selected) => {
        this.setState({
            select: {
                ...this.state.select,
                addressDefault: selected
            }
        });
    }



    handleClickDeleteAddress = (selected, idx) => {
        this.props.deleteAddressUser({
            address: selected,
            idx
        });
    }

    handleClickConfirm = async (idx) => {
       await this.props.setLoaddingConfirmSelectAddressUser();
        const data = {
            select: this.state.select,
            idx,
            success: () => {
                this.props.closeModal()
            }
        }
       await this.props.confirmSetAddressUser(data);
       await this.props.closeModal();
    }


    render() {
        const { closeModal, isLoadingConfirmSelectAddressUser } = this.props;
        const { isOpen, data } = this.props.modalAddress;
        const { idx } = data;

        return (
            <Modal
                isOpen={isOpen}
                closeModal={closeModal}
                size="lg"
                title={this.state.title}
            >
                <div className="container">
                    {this.state.status === "list" ?
                        <>
                            <div className="row">
                                <div className="d-flex align-istems-center cursor-pointer" onClick={this.addNewAddress}>
                                    <img src={IconAddAddress} alt="IconAddPromoCode" className="size-icon-20px" />
                                    <span className="font-primary-16px-text ml-2">
                                        เพิ่มที่อยู่ใหม่
                              </span>
                                </div>
                            </div>
                            <ListAddressUser
                                list={this.state.listAddressCustomer}
                                addressId={this.state.select && this.state.select.address && this.state.select.address.id}
                                addressDefaultId={this.state.select && this.state.select.addressDefault && this.state.select.addressDefault.id}
                                clickSelect={this.handleClickSelectAddressUser}
                                clickSetDefault={this.handleClickSetDefaultAddress}
                                clickDelete={(selected) => this.handleClickDeleteAddress(selected, idx)}
                                showAddressDefault={true}
                            />
                            <div className="row mt-10per">
                                <button
                                    type="button"
                                    className={`btn w-100  ${(this.state.listAddressCustomer.length === 0 || isLoadingConfirmSelectAddressUser) ? "btn-darkgrey" : "btn-primary"}`}
                                    onClick={(this.state.listAddressCustomer.length === 0 || isLoadingConfirmSelectAddressUser) ? null : () => this.handleClickConfirm(idx)}

                                >
                                    ยืนยัน
                                    {
                                        isLoadingConfirmSelectAddressUser ?
                                            <div className="ml-3 spinner-border text-light size-icon-20px" role="status">
                                                <span className="sr-only">Loading...</span>
                                            </div>
                                            :
                                            null
                                    }
                                </button>
                            </div>
                        </>
                        : <>
                            <FromAddress
                                handleSelectProvince={this.handleSelectProvince}
                                handleSelectDistricts={this.handleSelectDistricts}
                                handleSelectSubdistrict={this.handleSelectSubdistrict}
                                handleChangeAddress_AddressExtra={(evt) => this.handleChangeAddress_AddressExtra(evt)}
                                handleClickSearchLatLongByAddress={() => this.handleClickSearchLatLongByAddress(idx)}
                                getAddress={(location) => this.handleGetLatLongInMap(location, idx)}
                                value={
                                    {
                                        address: this.state.value.address,
                                        addressExtra: this.state.valueaddressExtra,
                                        location: {
                                            lat: this.state.value.location[0],
                                            lng: this.state.value.location[1]
                                        },
                                        province: this.state.value.province,
                                        district: this.state.value.district,
                                        subdistrict: this.state.value.subdistrict,
                                        zipcode: this.state.value.zipcode
                                    }

                                }
                                handleChangeZipcode={(evt) => this.handleChangeZipcode(evt)}
                                isGlobalAddress={true}
                            />
                            <div className="row">
                                <span className="font-primary-16px-text cursor-pointer" onClick={this.backToListAddress}>
                                    กลับไปเลือกที่อยู่อื่น
                              </span>
                            </div>
                            <div className="row mt-10per button-confirm">
                                <button
                                    type="button"
                                    className="btn w-100 btn-primary"
                                    onClick={this.handleClickCreateAddress}
                                >{this.state.textButton}</button>
                            </div>
                        </>


                    }
                </div>
            </Modal>
        )
    }
}

const mapStateToProps = state => ({
    listLogistic: state.logistics.listLogistic,
    createOrder: state.createOrder,
    listAddressCustomer: state.createOrder.listAddressCustomer,
    isLoadingConfirmSelectAddressUser: state.createOrder.isLoadingConfirmSelectAddressUser
});

const mapDispatchToProps = dispatch => ({
    getListDistricts: ({ provinceId }) => dispatch(actions_address.getListDistricts({ provinceId })),
    getListSubDistricts: ({ districtId }) => dispatch(actions_address.getListSubDistricts({ districtId })),
    openNotificationAlert: (payload) => dispatch(actions_notification.openNotificationAlert(payload)),
    getListAddressUser: () => dispatch(actions_createOrder.getListAddressUser()),
    confirmSetAddressUser: ({ select, idx, success }) => dispatch(actions_createOrder.confirmSetAddressUser({ select, idx, success })),
    deleteAddressUser: ({ address, idx }) => dispatch(actions_createOrder.deleteAddressUser({ address, idx })),
    setLoaddingConfirmSelectAddressUser: () => dispatch(actions_createOrder.setLoaddingConfirmSelectAddressUser())
});


export default connect(mapStateToProps, mapDispatchToProps)(ModalAddress);