import { Type } from "../actions/address";

const INITIAL_STATE = {
    listProvinces: [],
    listDistricts: [],
    listSubDistricts: []
};

export default function address(state = INITIAL_STATE, actions) {
    const { type, payload } = actions;
    switch (type) {
        case (Type.GET_LIST_PROVINCES_SUCCESS): {
            return {
                ...state,
                listProvinces: payload.listProvinces
            }
        }
        case (Type.GET_LIST_DISTRICTS_SUCCESS): {
            return {
                ...state,
                listDistricts: payload.listDistricts
            }
        }
        case (Type.GET_LIST_SUBDISTRICTS_SUCCESS): {
            return {
                ...state,
                listSubDistricts: payload.listSubDistricts
            }
        }
        case (Type.CLEAR_LIST_DISTRICTS_AND_SUBDISTRICTS): {
            return {
                ...state,
                listDistricts: [],
                listSubDistricts: []
            }
        }
        default:
            return {
                ...state
            }
    }
}