import React, { Component } from 'react'
import SelectComponent from '../components/Select'
import CheckboxActiveSqure from '../assets/Icons/CheckboxActiveSqure.png'
import CheckboxInActiveSqure from '../assets/Icons/CheckboxInActiveSqure.png'
// import {
//     getListMenu
// } from '../redux/actions/extraData'
import { connect } from 'react-redux'
import extraDataCtrl from '../controllers/extraDataCtrl'
import MenuCtrl from '../controllers/menuCtrl'
import { saveListMenu } from '../redux/actions/extraData'
import api from '../config/api'
import CardMenu from '../components/pages/ExtraData/CardMenu'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faCircleNotch } from "@fortawesome/free-solid-svg-icons";
import { openLoading, closeLoading } from '../redux/actions/loading'
import Bracket from '../assets/Icons/bracket.png'
import * as actions_extraData from '../redux/actions/extraData'
const api_extradata = new extraDataCtrl()

const sortBy = [
    {
        label: 'ตัวอักษร',
        value: 'name'
    }, {
        label: 'อัพเดทล่าสุด',
        value: 'updateAt'
    }
]


class FullExtraData extends Component {
    constructor(props) {
        super(props)
        this.state = {
            listMenu: [],
            listShopOptions: [],
            listMenuOptions: [],
            shopSearchSelected: "",
            menuSearchSelected: "",
            sortBySelected: { label: 'ตัวอักษร', value: 'name' }
        }
    }

    async componentDidMount() {
        this.props.openLoading()
        const listMenu = await api_extradata.getListMenu({ sortBy: "name" })
        this.setListMenu(listMenu) // set menu to redux
        await this.getListMenuOptions()
        await this.getListShop()
        this.setState({
            listMenu
        })
        this.props.closeLoading()
    }

    setListMenu = (listMenu) => {
        const arr = []
        listMenu && listMenu.map(item => item.data.map(data => {
            arr.push(data)
        }))
        this.props.saveListMenu(arr)
    }


    getListShop = async () => {
        const listShopOptions = []
        const listShop = await api_extradata.getListShop()
        listShopOptions.push({
            label: 'ร้านค้าทั้งหมด',
            value: ''
        })
        listShop && listShop.map((item) => {
            listShopOptions.push({
                label: item.name,
                value: item
            })
        })
        this.setState({
            listShopOptions
        })
    }

    getListMenuOptions = async () => {
        const listMenuOptions = []
        const listMenu = await api_extradata.getListMenuOptions()
        listMenuOptions.push({
            label: 'เมนูทั้งหมด',
            value: ''
        })
        listMenu && listMenu.map((item) => {
            listMenuOptions.push({
                label: item.name.th || item.name.en,
                value: item
            })
        })
        this.setState({
            listMenuOptions,
        })
    }

    onChangeListShop = async (value) => {
        this.props.openLoading()
        this.setState({
            shopSearchSelected: value
        })
        // data search param //
        const params = {
            // menu: this.state.menuSearchSelected && this.state.menuSearchSelected.value && this.state.menuSearchSelected.value.name.th,
            menu: "",
            shop: value.value.name,
            sortBy: this.state.sortBySelected.value
        }
        // data search param //

        /// add menu only shopselected///
        const getListMenu = await api_extradata.getListMenuOptions(value.value.id)
        const listMenu = [{
            label: "เมนูทั้งหมด",
            value: ""
        }]
        getListMenu.map(item => {
            listMenu.push({
                label: item.name.th || item.name.en,
                value: item
            })
        })
        /// add menu only shopselected///

        this.setState({
            listMenu: await api_extradata.getListMenu(params),
            listMenuOptions: listMenu,
            menuSearchSelected: ''
        })
        this.setListMenu(await api_extradata.getListMenu(params))
        this.props.clearProductSelected()
        this.props.closeLoading()

    }

    onChangeListMenu = async (value) => {
        // this.props.openLoading()
        const { shopSearchSelected } = this.state
        this.setState({
            menuSearchSelected: value
        })
        // const result = await api_extradata.getListMenu({menu:"",shop: value.value.name})
        const params = {
            menu: value.value.name ? value.value.name.th || value.value.name.en : "",
            shop: shopSearchSelected && shopSearchSelected.value && shopSearchSelected.value.name,
            sortBy: this.state.sortBySelected.value
        }

        this.setState({
            listMenu: await api_extradata.getListMenu(params)
        })
        this.setListMenu(await api_extradata.getListMenu(params))
        this.props.clearProductSelected()
        // this.props.closeLoading()
    }

    onSelectAllProduct = () => {
        this.state.listMenu.map((item, idx) => {
            const product = []
            item.data.map((data) => {
                const product = {
                    productId: data.dataProduct[0]._id,
                    menuName: data.name.th || data.name.en
                }
            })
        })
        this.props.addProductAllRequire(this.state.listMenu)
    }

    onChangeSortBy = async (e) => {
        const params = {
            menu: this.state.menuSearchSelected.label == "เมนูทั้งหมด" ? "" : this.state.menuSearchSelected.label,
            shop: this.state.shopSearchSelected.label == "ร้านค้าทั้งหมด" ? "" : this.state.shopSearchSelected.label,
            sortBy: e.value
        }

        const listMenu = await api_extradata.getListMenu(params)
        this.setState({
            sortBySelected: e,
            listMenu
        })
    }

    render() {
        
        return (
            <div>
                <div className="col-xl-12" id="extra-data">
                    <div className="search-component">
                        <div className="d-flex justify-content-between align-items-center flex-response p-1">
                            <h4>ข้อมูลเพิ่มเติม</h4>
                            <label className="d-inline-flex sort align-items-center">
                                <span className="p-0 align-self-center sort-text">จัดเรียงตาม</span>
                                <SelectComponent
                                    placeholder={sortBy[0].label}
                                    options={sortBy}
                                    value={this.state.sortBySelected}
                                    onChange={this.onChangeSortBy}
                                />
                            </label>
                        </div>

                        <div className="d-flex search-item flex-response justify-content-between">
                            <span className="full-width p-1">
                                <SelectComponent
                                    placeholder={[<FontAwesomeIcon icon={faSearch} className="mr-2" />, 'ค้นหาร้านค้า']}
                                    options={this.state.listShopOptions}
                                    onChange={this.onChangeListShop}
                                    value={this.state.shopSearchSelected}
                                /></span>
                            <span className="full-width align-self-end p-1">
                                <SelectComponent
                                    placeholder={[<FontAwesomeIcon icon={faSearch} className="mr-2" />, 'ค้นหาเมนู']}
                                    options={this.state.listMenuOptions}
                                    onChange={this.onChangeListMenu}
                                    value={this.state.menuSearchSelected}
                                /></span>
                        </div>

                        <div className="d-flex justify-content-end align-items-center mt-3 flex-response p-1">
                            <label onClick={this.onSelectAllProduct} className="cursor-pointer">
                                <img
                                    src={this.props.listMenu.length == this.props.listProductPDF.length && this.props.listMenu.length != 0 ? CheckboxActiveSqure : CheckboxInActiveSqure} alt="" className="icon-check" />
                                <span>เลือกทั้งหมด</span></label>
                            <button className="btn btn-download ml-4" disabled={this.props.listProductPDF.length == 0} onClick={() => this.props.getDownloadPDF()}> {this.props.isLoadingPDF ? <FontAwesomeIcon icon={faCircleNotch} spin /> : ""}  ดาวน์โหลด</button>
                        </div>
                    </div>


                    <div className="shop" id="create-order">
                        {this.state.listMenu && this.state.listMenu.length == 0 ?
                            <div className="mt-4">ไม่มีข้อมูล</div> :
                            this.state.listMenu && this.state.listMenu.map((shop) => (
                                <div key={shop.dataShop[0]._id} className="mb-4">
                                    <img src={`${api.THUMP}/image/${shop.dataShop[0].cover.fileName}`} className="img-icon mr-2" alt="..." />
                                    <label className="header-menu-name"> {shop.dataShop[0].name} </label>
                                    <div className="row">
                                        {shop.data.map((menu) =>
                                            <CardMenu
                                                key={menu._id}
                                                detailMenu={menu}
                                                mainData={shop}
                                                url={this.props.match.url}
                                            // modal={this.state.modalExtraData}
                                            // openModal={this.openModalExtraData}
                                            // closeModal={this.closeModalExtraData}
                                            />
                                        )}
                                    </div>
                                </div>
                            ))
                        }

                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    listMenu: state.extraData.listMenu,
    listProductPDF: state.extraData.listProductPDF,
    isLoadingPDF: state.extraData.isLoadingPDF
})

const mapDispatchToProps = (dispatch) => ({
    // getListMenu: (payload) => dispatch(getListMenu(payload)),
    saveListMenu: (payload) => dispatch(saveListMenu(payload)),
    openLoading: () => dispatch(openLoading()),
    closeLoading: () => dispatch(closeLoading()),
    addProductPDFRequire: (payload) => dispatch(actions_extraData.addProductPDFRequire(payload)),
    addProductAllRequire: (payload) => dispatch(actions_extraData.addProductAllRequire(payload)),
    clearProductSelected: () => dispatch(actions_extraData.clearProductSelected()),
    getDownloadPDF: (payload) => dispatch(actions_extraData.getDownloadPDF(payload))
})

export default connect(mapStateToProps, mapDispatchToProps)(FullExtraData)