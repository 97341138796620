import axios from 'axios';
import api from '../config/api';
import { getAccessToken } from '../utils/utils';
// import store from '../redux/store';
import history from '../history';
import Cookies from 'js-cookie';
// import { openModalAlert } from '../redux/actions/modal';

// const axios_saranros = axios.create({
//     baseURL: api.SARANROS_API,
//     headers: {
//         'Authorization': getAccessToken() || "",
//         'channel': 'cms'
//     }
// });

const axios_saranros = axios.create();


axios_saranros.interceptors.request.use(function (config) {
    const access_token = getAccessToken();
    config.baseURL = api.SARANROS_API;
    config.headers.channel = "cms";
    config.headers.Authorization = access_token || '';
    config.headers.platform = "cms";
    return config;
}, function (err) {
    return Promise.reject(err);
});


// Add a response interceptor
axios_saranros.interceptors.response.use(function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
}, function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    switch (error.response && error.response.status) {

        //--- Case client error
        case 401:  //  un autrorized case
            Cookies.remove('access_token');
            Cookies.remove('user_id');
            Cookies.remove('userType');
            Cookies.remove('userName');
            history.push("/login");
            // alert("You are not authorized to view this page.")
            // showErrorModal('You are not authorized to view this page');
            break;

        // case 422:  // Unprocessable Entity)
        //     alert("Can not save data.")
        //     // showErrorModal('Can not save data');
        //     break;

        //--- Case sever  error --
        case 504: // gateway timeout
            // alert("Gateway timeout please try agin later.");
            // showErrorModal('Gateway timeout please try agin later.')
            break;
        default:


    }// end swicth
    return Promise.reject(error);
});


// function showErrorModal(msg) {
//     store.dispatch(openModalAlert(
//         {
//             title: '',
//             content: msg,
//             textbutton: "Close",
//             status: "success"
//         }
//     ));
// }


export default axios_saranros;