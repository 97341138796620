import { Type } from '../actions/createOrder';


const structureCreateOrder = {
    search: {
        menu: "",
        date: "",
        time: "",
        shop: "",
    },
    shopSelect: {},
    listMenuSelect: {
        // "ProductID": {
        //     quantity: 1,
        //     stock: null,
        //     menu: {}
        // }
    },
    createUser: {
        name: '',
        mobile: ''
    },
    checkShippingCosts: {
        deliveryAt: [{
            location: [],
            addressId: '',
            subdistrict: null,
            district: null,
            province: null,
            zipcode: "",
            address: "",
            addressExtra: "",
            name: "",
            mobile: "",
            typeCar: "MOTORCYCLE",
            dateDelivery: "",
            productType: "",
            invoiceId: "",
            isBox: false,
            isIndividually: true
        }],
        shopId: "",
        userId: "",
        methodPayment: "cash",
        deliveryNote: ""
    },
    updateInvoiceById: {
        message: "",
        priceSpecialDiscount: null
    },
    deliveryOption: "",
    specialDisCount: null,
    listTimeDelivery: [],
    isBox: false,
    isIndividually: true,
    listDateDelivery: [],
    contactShop: {},
    addressUser: {},
    mainData: null,
    shippingCost: {
        deliveryPrice: 0,
        discountPrice: 0,
    },
    priceSpecial: '',
    isDefaultContactCustomer: true,
    listStockByDateTime: null,
    listDistricts: [],
    listSubDistricts: [],
    errors: {
        specialDiscount: {
            shippingDiscount: false,
            foodDiscount: false,
            note: false,
            code: false
        },
        calculateShipingcost: {
            outOfServiceArea: false
        }
    },
    isAddressOnChange: false
}

const INITIAL_STATE = {
    orderShop: [
        {
            ...structureCreateOrder
        }
    ],
    activeTab: 0,
    userChat: {},
    userCustomer: {},
    listAddressCustomer: [],
    isLoadingCreateOrder: false,
    isLoadingConfirmSelectAddressUser: false,
    sendMsg: true
};

export default function createOrder(state = INITIAL_STATE, actions) {
    const { type, payload } = actions;
    switch (type) {
        case (Type.SET_DEFAULT_ADDRESS): {
            const this_orderShop = { ...state.orderShop[0] };
            return {
                ...state,
                orderShop: [
                    ...state.orderShop.slice(0, 0),
                    {
                        ...this_orderShop,
                        createUser: {
                            // name: payload.userCustomer.displayName,
                            // mobile: payload.userCustomer.contactMobile,
                            name: payload.userChat.name || '',
                            mobile: payload.userChat.mobile || ''
                        },
                        checkShippingCosts: {
                            ...this_orderShop.checkShippingCosts,
                            deliveryAt: [{
                                ...this_orderShop.checkShippingCosts.deliveryAt[0],
                                addressId: (payload.addressUser && payload.addressUser.id) || '',
                                subdistrict: payload.listAddressUserOption.list_subDistricts == null ? null : {
                                    ...payload.listAddressUserOption.list_subDistricts,
                                    label: payload.listAddressUserOption.list_subDistricts.subdistrictName,
                                    value: payload.listAddressUserOption.list_subDistricts.subdistrictId
                                },
                                district: payload.listAddressUserOption.list_districts == null ? null : {
                                    ...payload.listAddressUserOption.list_districts,
                                    label: payload.listAddressUserOption.list_districts.districtName,
                                    value: payload.listAddressUserOption.list_districts.districtId
                                },
                                province: payload.listAddressUserOption.list_province == null ? null : {
                                    ...payload.listAddressUserOption.list_province,
                                    label: payload.listAddressUserOption.list_province.provinceName,
                                    value: payload.listAddressUserOption.list_province.provinceId
                                },
                                zipcode: (payload.addressUser && payload.addressUser.zipcode) || '',
                                address: (payload.addressUser && payload.addressUser.address) || '',
                                addressExtra: (payload.addressUser && payload.addressUser.addressExtra) || '',
                                location: (payload.addressUser && payload.addressUser.location && payload.addressUser.location.lat && payload.addressUser.location.lng && [parseFloat(payload.addressUser.location.lat), parseFloat(payload.addressUser.location.lng)]) || []
                            }]
                        },
                        addressUser: payload.addressUser || {},
                        listDistricts: payload.listDistricts,
                        listSubDistricts: payload.listSubDistricts,
                        isAddressOnChange: payload.addressUser == null ? true : false
                    },
                    ...state.orderShop.slice(0 + 1)
                ]
            }
        }
        case (Type.ADD_TAB_SUCCESS): {
            const orderShopDefault = { ...state.orderShop[0] };
            return {
                ...state,
                orderShop: [
                    ...state.orderShop,
                    {
                        ...structureCreateOrder,
                        // shippingCost: orderShopDefault.shippingCost,
                        // deliveryOption: orderShopDefault.deliveryOption,
                        isBox: orderShopDefault.isBox,
                        createUser: { ...orderShopDefault.createUser },
                        checkShippingCosts: {
                            ...orderShopDefault.checkShippingCosts,
                            deliveryAt: [{
                                ...orderShopDefault.checkShippingCosts.deliveryAt[0],
                                dateDelivery: '',
                                
                            }],
                            deliveryNote: ''
                        },
                        addressUser: orderShopDefault.addressUser,
                        listDistricts: orderShopDefault.listDistricts,
                        listSubDistricts: orderShopDefault.listSubDistricts,
                        isAddressOnChange: orderShopDefault.isAddressOnChange
                    }
                ]
            }
        }
        case (Type.REMOVE_TAB): {
            return {
                ...state,
                activeTab: payload.idx === 0 ? payload.idx : payload.idx - 1,
                orderShop: [
                    ...state.orderShop.filter(item => item !== state.orderShop[payload.idx]) // remove item from idx.
                ]
            }
        }
        case (Type.CLEAR_DATA_ORDER_DEFAULT): {
            return {
                ...state,
                orderShop: [
                    ...state.orderShop.slice(0, payload.idx),
                    {
                        ...structureCreateOrder
                    },
                    ...state.orderShop.slice(payload.idx + 1)
                ]
            }
        }
        case (Type.SET_ACTIVE_TAB_SUCCESS): {
            return {
                ...state,
                activeTab: payload.idx
            }
        }
        case (Type.SET_ADDRESS_AND_ADDRESSEXTRA): {
            const this_orderShop = { ...state.orderShop[payload.idx] };
            return {
                ...state,
                orderShop: [
                    ...state.orderShop.slice(0, payload.idx),
                    {
                        ...this_orderShop,
                        checkShippingCosts: {
                            ...this_orderShop.checkShippingCosts,
                            deliveryAt: [{
                                ...this_orderShop.checkShippingCosts.deliveryAt[0],
                                [payload.name]: payload.value
                            }]
                        },
                        isAddressOnChange: payload.name === "addressExtra" ? this_orderShop.isAddressOnChange : true
                    },
                    ...state.orderShop.slice(payload.idx + 1)
                ],

            }
        }
        case (Type.SET_NAME_AND_MOBILE): {
            const this_orderShop = { ...state.orderShop[payload.idx] };
            return {
                ...state,
                orderShop: [
                    ...state.orderShop.slice(0, payload.idx),
                    {
                        ...this_orderShop,
                        createUser: {
                            ...this_orderShop.createUser,
                            [payload.name]: payload.value
                        }
                    },
                    ...state.orderShop.slice(payload.idx + 1)
                ]
            }
        }
        case (Type.SET_DELIVERY_OPTION_SUCCESS): {
            const this_orderShop = { ...state.orderShop[payload.idx] };
            return {
                ...state,
                orderShop: [
                    ...state.orderShop.slice(0, payload.idx),
                    {
                        ...this_orderShop,
                        deliveryOption: payload.deliveryOption
                    },
                    ...state.orderShop.slice(payload.idx + 1)
                ]
            }
        }
        case (Type.ADD_MENU_TO_CART_SUCCESS): {
            const this_orderShop = { ...state.orderShop[payload.idx] };
            return {
                ...state,
                orderShop: [
                    ...state.orderShop.slice(0, payload.idx),
                    {
                        ...this_orderShop,
                        listMenuSelect: {
                            ...this_orderShop.listMenuSelect,
                            [payload.detailMenu.dataProduct[0]._id]: {
                                quantity: 1,
                                // stock: payload.detailMenu.stock,
                                product: payload.detailMenu,
                                error: {
                                    statusCreate: false,
                                    messageError: ''
                                }
                            }
                        },
                        mainData: payload.mainData,
                        contactShop: {
                            ...payload.mainData.dataContact.find(item => item.no === 1)
                        }
                    },
                    ...state.orderShop.slice(payload.idx + 1)
                ]
            }
        }

        case (Type.REMOVE_MENU_FROM_CART_SUCCESS): {
            const this_orderShop = { ...state.orderShop[payload.idx] };
            return {
                ...state,
                orderShop: [
                    ...state.orderShop.slice(0, payload.idx),
                    {
                        ...this_orderShop,
                        listMenuSelect: {
                            ...payload.listMenuSelect
                        }
                    },
                    ...state.orderShop.slice(payload.idx + 1)
                ]
            }
        }
        case (Type.GET_LAT_LNG_BY_ADDRESS_SUCCESS): {
            const this_orderShop = { ...state.orderShop[payload.idx] };
            return {
                ...state,
                orderShop: [
                    ...state.orderShop.slice(0, payload.idx),
                    {
                        ...this_orderShop,
                        checkShippingCosts: {
                            ...this_orderShop.checkShippingCosts,
                            deliveryAt: [{
                                ...this_orderShop.checkShippingCosts.deliveryAt[0],
                                location: [payload.location.lat, payload.location.lng]
                            }]
                        },
                        isAddressOnChange: false
                    },
                    ...state.orderShop.slice(payload.idx + 1)
                ],

            }
        }
        case (Type.SET_LAT_LNG_SUCCESS): {
            const this_orderShop = { ...state.orderShop[payload.idx] };
            return {
                ...state,
                orderShop: [
                    ...state.orderShop.slice(0, payload.idx),
                    {
                        ...this_orderShop,
                        checkShippingCosts: {
                            ...this_orderShop.checkShippingCosts,
                            deliveryAt: [{
                                ...this_orderShop.checkShippingCosts.deliveryAt[0],
                                location: [payload.lat, payload.lng]
                            }]
                        }
                    },
                    ...state.orderShop.slice(payload.idx + 1)
                ]
            }
        }
        case (Type.SET_IS_BOX): {
            const this_orderShop = { ...state.orderShop[payload.idx] };
            return {
                ...state,
                orderShop: [
                    ...state.orderShop.slice(0, payload.idx),
                    {
                        ...this_orderShop,
                        checkShippingCosts : {
                            ...this_orderShop.checkShippingCosts,
                            deliveryAt : [{
                                ...this_orderShop.checkShippingCosts.deliveryAt[0],
                                isBox: !this_orderShop.isBox
                            }],
                        },
                        isBox: !this_orderShop.isBox
                    },
                    ...state.orderShop.slice(payload.idx + 1)
                ]
            }
        }

        case (Type.SET_IS_INDIVIDUALLY): {
            const this_orderShop = { ...state.orderShop[payload.idx] };
            return {
                ...state,
                orderShop: [
                    ...state.orderShop.slice(0, payload.idx),
                    {
                        ...this_orderShop,
                        isIndividually: !this_orderShop.isIndividually
                    },
                    ...state.orderShop.slice(payload.idx + 1)
                ]
            }
        }

        case (Type.SET_CONTACT_SHOP_SUCCESS): {
            const this_orderShop = { ...state.orderShop[payload.idx] };
            return {
                ...state,
                orderShop: [
                    ...state.orderShop.slice(0, payload.idx),
                    {
                        ...this_orderShop,
                        contactShop: payload.contactShop
                    },
                    ...state.orderShop.slice(payload.idx + 1)
                ]
            }
        }
        case (Type.ADD_SPECIAL_DISCOUNT): {
            const this_orderShop = { ...state.orderShop[payload.idx] };
            return {
                ...state,
                orderShop: [
                    ...state.orderShop.slice(0, payload.idx),
                    {
                        ...this_orderShop,
                        updateInvoiceById: {
                            ...this_orderShop.updateInvoiceById,
                            priceSpecialDiscount: { "shippingDiscount": 0, "foodDiscount": 0, "note": "", "code": "", "encode": "" }
                        }
                    },
                    ...state.orderShop.slice(payload.idx + 1)
                ]
            }
        }
        case (Type.REMOVE_SPECIAL_DISCOUNT): {
            const this_orderShop = { ...state.orderShop[payload.idx] };
            return {
                ...state,
                orderShop: [
                    ...state.orderShop.slice(0, payload.idx),
                    {
                        ...this_orderShop,
                        updateInvoiceById: {
                            ...this_orderShop.updateInvoiceById,
                            priceSpecialDiscount: null
                        },
                        errors: {
                            ...this_orderShop.errors,
                            specialDiscount: {
                                ...structureCreateOrder.errors.specialDiscount
                            }
                        }
                    },
                    ...state.orderShop.slice(payload.idx + 1)
                ]
            }
        }

        case (Type.REMOVE_DATA_WHEN_NONE_SHOP): {
            const this_orderShop = { ...state.orderShop[payload.idx] };

            if (state.orderShop.length === 1) {
                return {
                    ...state,
                    activeTab: 0,
                    orderShop: [
                        ...state.orderShop.slice(0, payload.idx),
                        {
                            ...this_orderShop,
                            search: structureCreateOrder.search,
                            mainData: null,
                            contactShop: {},
                            listStockByDateTime: null,
                            listDateDelivery: [],
                            checkShippingCosts: {
                                deliveryAt: [{
                                    ...this_orderShop.checkShippingCosts.deliveryAt[0],
                                    dateDelivery: ""
                                }],
                                deliveryNote: ""
                            }
                        },
                        ...state.orderShop.slice(payload.idx + 1)
                    ]
                }
            } else if (state.orderShop.length > 1) {
                return {
                    ...state,
                    activeTab: payload.idx === 0 ? payload.idx : payload.idx - 1,
                    orderShop: [
                        ...state.orderShop.slice(0, payload.idx),
                        ...state.orderShop.slice(payload.idx + 1)
                    ]
                }
            } else {
                return {
                    ...state
                }
            }

        }

        case (Type.SET_PRICE_SPECIAL_DISCOUNT): {
            const this_orderShop = { ...state.orderShop[payload.idx] };
            return {
                ...state,
                orderShop: [
                    ...state.orderShop.slice(0, payload.idx),
                    {
                        ...this_orderShop,
                        updateInvoiceById: {
                            ...this_orderShop.updateInvoiceById,
                            priceSpecialDiscount:
                            {
                                ...this_orderShop.updateInvoiceById.priceSpecialDiscount,
                                [payload.name]: payload.value
                            }
                        }
                    },
                    ...state.orderShop.slice(payload.idx + 1)
                ]
            }
        }
        case (Type.CHECK_SPECIAL_CODE_SUCCCESS): {
            const this_orderShop = { ...state.orderShop[payload.idx] };
            return {
                ...state,
                orderShop: [
                    ...state.orderShop.slice(0, payload.idx),
                    {
                        ...this_orderShop,
                        updateInvoiceById: {
                            ...this_orderShop.updateInvoiceById,
                            priceSpecialDiscount: {
                                ...this_orderShop.updateInvoiceById.priceSpecialDiscount,
                                encode: payload.encode
                            }
                        },
                        errors: {
                            ...this_orderShop.errors,
                            specialDiscount: {
                                ...this_orderShop.errors.specialDiscount,
                                code: payload.status
                            }
                        }
                    },
                    ...state.orderShop.slice(payload.idx + 1)
                ]
            }
        }
        case (Type.SET_SHIPING_COST): {
            const this_orderShop = { ...state.orderShop[payload.idx] };
            return {
                ...state,
                orderShop: [
                    ...state.orderShop.slice(0, payload.idx),
                    {
                        ...this_orderShop,
                        shippingCost: {
                            deliveryPrice: payload.shippingCost[0].deliveryPrice - payload.shippingCost[0].discountPrice,
                            discountPrice: payload.shippingCost[0].discountPrice
                        }
                    },
                    ...state.orderShop.slice(payload.idx + 1)
                ]
            }
        }
        //search input
        case (Type.ADD_MENU_OF_SEARCH): {
            const this_orderShop = { ...state.orderShop[payload.idx] };
            return {
                ...state,
                orderShop: [
                    ...state.orderShop.slice(0, payload.idx),
                    {
                        ...this_orderShop,
                        search: {
                            ...this_orderShop.search,
                            menu: payload.search.menu,
                        }
                    },
                    ...state.orderShop.slice(payload.idx + 1)
                ]
            }
        }

        case (Type.ADD_SHOP_OF_SEARCH): {
            const this_orderShop = { ...state.orderShop[payload.idx] };
            return {
                ...state,
                orderShop: [
                    ...state.orderShop.slice(0, payload.idx),
                    {
                        ...this_orderShop,
                        search: {
                            ...this_orderShop.search,
                            shop: payload.search.shop,
                        }
                    },
                    ...state.orderShop.slice(payload.idx + 1)
                ]
            }
        }


        case (Type.CHECK_LIST_DATE_TIME_DEFAULT): {
            const this_orderShop = { ...state.orderShop[payload.idx] };
            return {
                ...state,
                orderShop: [
                    ...state.orderShop.slice(0, payload.idx),
                    {
                        ...this_orderShop,
                        listStockByDateTime: {
                            ...payload.data,
                            id: payload.id
                        },
                        listMenuSelect: {
                            ...this_orderShop.listMenuSelect,
                            [payload.id]: {

                                ...this_orderShop.listMenuSelect[payload.id],
                                listStockByDateTime: {
                                    ...payload.data,
                                    id: payload.id
                                }
                            }
                        }
                    },
                    ...state.orderShop.slice(payload.idx + 1)
                ]
            }
        }

        case (Type.CHECK_LIST_DATE_TIME_SUCCESS): {
            const this_orderShop = { ...state.orderShop[payload.idx] };

            return {
                ...state,
                orderShop: [
                    ...state.orderShop.slice(0, payload.idx),
                    {
                        ...this_orderShop,
                        listMenuSelect: {
                            ...this_orderShop.listMenuSelect,
                            [payload.id]: {
                                ...this_orderShop.listMenuSelect[payload.id],
                                listStockByDateTime: {
                                    ...payload.data,
                                    id: payload.id
                                }
                            }
                        }
                    },
                    ...state.orderShop.slice(payload.idx + 1)
                ]
            }
        }


        case (Type.SET_ERROR_ADDRESS_OUT_OF_AREA): {
            const this_orderShop = { ...state.orderShop[payload.idx] };
            return {
                ...state,
                orderShop: [
                    ...state.orderShop.slice(0, payload.idx),
                    {
                        ...this_orderShop,
                        errors: {
                            ...this_orderShop.errors,
                            calculateShipingcost: {
                                ...this_orderShop.errors.calculateShipingcost,
                                outOfServiceArea: payload.status,
                            }
                        }
                    },
                    ...state.orderShop.slice(payload.idx + 1)
                ]
            }
        }
        case (Type.SET_IS_CONTACT_CUSTOMER_DEFAULT): {
            const this_orderShop = { ...state.orderShop[payload.idx] };
            const orderShopDefault = { ...state.orderShop[0] }

            const isDefaultChange = !this_orderShop.isDefaultContactCustomer;
            // let data_change = {}
            // if (isDefaultChange === false) {
            //     data_change = {
            //         shippingCost: this_orderShop.shippingCost,
            //         deliveryOption: this_orderShop.deliveryOption,
            //         isBox: this_orderShop.isBox,
            //         createUser: { ...this_orderShop.createUser },
            //         checkShippingCosts: this_orderShop.checkShippingCosts,
            //     }
            // } else {
            //     data_change = {
            //         shippingCost: orderShopDefault.shippingCost,
            //         deliveryOption: orderShopDefault.deliveryOption,
            //         isBox: orderShopDefault.isBox,
            //         createUser: { ...orderShopDefault.createUser },
            //         checkShippingCosts: orderShopDefault.checkShippingCosts,
            //     }
            // }

            return {
                ...state,
                orderShop: [
                    ...state.orderShop.slice(0, payload.idx),
                    {
                        ...this_orderShop,
                        // shippingCost: orderShopDefault.shippingCost,
                        deliveryOption: orderShopDefault.deliveryOption,
                        isBox: orderShopDefault.isBox,
                        createUser: { ...orderShopDefault.createUser },
                        checkShippingCosts: {
                            deliveryAt: [{
                                ...orderShopDefault.checkShippingCosts.deliveryAt[0],
                                dateDelivery: this_orderShop.checkShippingCosts.deliveryAt[0].dateDelivery
                            }]
                        },
                        isDefaultContactCustomer: isDefaultChange
                    },
                    ...state.orderShop.slice(payload.idx + 1)
                ]
            }
        }

        case (Type.SET_IS_CONTACT_CUSTOMER_NOT_DEFAULT): {
            const this_orderShop = { ...state.orderShop[payload.idx] };
            const orderShopDefault = { ...state.orderShop[0] }
            const isDefaultChange = false;
            // let data_change = {}
            // if (isDefaultChange === false) {
            //     data_change = {
            //         shippingCost: this_orderShop.shippingCost,
            //         deliveryOption: this_orderShop.deliveryOption,
            //         isBox: this_orderShop.isBox,
            //         createUser: { ...this_orderShop.createUser },
            //         checkShippingCosts: this_orderShop.checkShippingCosts,
            //     }
            // } else {
            //     data_change = {
            //         shippingCost: orderShopDefault.shippingCost,
            //         deliveryOption: orderShopDefault.deliveryOption,
            //         isBox: orderShopDefault.isBox,
            //         createUser: { ...orderShopDefault.createUser },
            //         checkShippingCosts: orderShopDefault.checkShippingCosts,
            //     }
            // }
            
            return {
                ...state,
                orderShop: [
                    ...state.orderShop.slice(0, payload.idx),
                    {
                        ...this_orderShop,
                        shippingCost: structureCreateOrder.shippingCost,
                        deliveryOption: structureCreateOrder.deliveryOption,
                        isBox: structureCreateOrder.isBox,
                        // createUser: { ...structureCreateOrder.createUser },
                        checkShippingCosts: {
                            deliveryAt: [{
                                ...structureCreateOrder.checkShippingCosts.deliveryAt[0],
                                dateDelivery: this_orderShop.checkShippingCosts.deliveryAt[0].dateDelivery
                            }],
                            deliveryNote: ""
                        },
                        isDefaultContactCustomer: isDefaultChange,
                        isAddressOnChange: true
                    },
                    ...state.orderShop.slice(payload.idx + 1)
                ]
            }
        }

        //// SET STOCK QUANTITY

        case (Type.ON_ADD_QUANTITY_MENU_SUCCESS): {
            const this_orderShop = { ...state.orderShop[payload.idx] };
            return {
                ...state,
                orderShop: [
                    ...state.orderShop.slice(0, payload.idx),
                    {
                        ...this_orderShop,
                        listMenuSelect: {
                            ...this_orderShop.listMenuSelect,
                            [payload.id]: {
                                ...this_orderShop.listMenuSelect[payload.id],
                                quantity: state.orderShop[payload.idx].listMenuSelect[payload.id].quantity + 1
                            }
                        }
                    },
                    ...state.orderShop.slice(payload.idx + 1)
                ]
            }
        }


        case (Type.ON_REDUCE_QUANTITY_MENU_SUCCESS): {
            const this_orderShop = { ...state.orderShop[payload.idx] };
            return {
                ...state,
                orderShop: [
                    ...state.orderShop.slice(0, payload.idx),
                    {
                        ...this_orderShop,
                        listMenuSelect: {
                            ...this_orderShop.listMenuSelect,
                            [payload.id]: {
                                ...this_orderShop.listMenuSelect[payload.id],
                                quantity: state.orderShop[payload.idx].listMenuSelect[payload.id].quantity - 1
                            }
                        }
                    },
                    ...state.orderShop.slice(payload.idx + 1)
                ]
            }
        }
        //// SET STOCK QUANTITY

        case (Type.SET_TIME_DELIVERY_SUCCESS): {
            const this_orderShop = { ...state.orderShop[payload.idx] };
            return {
                ...state,
                orderShop: [
                    ...state.orderShop.slice(0, payload.idx),
                    {
                        ...this_orderShop,
                        listDateDelivery: {
                            dateDelivery: payload.date
                        }
                    },
                    ...state.orderShop.slice(payload.idx + 1)
                ]
            }
        }
        case (Type.SET_DATA_NEW_USER_SUCCESS): {
            const this_orderShop = { ...state.orderShop[0] };
            return {
                ...state,
                userChat: payload.userChat,
                userCustomer: payload.userCustomer,
                orderShop: [
                    ...state.orderShop.slice(0, 0),
                    {
                        ...this_orderShop,
                        createUser: {
                            // name: payload.userCustomer.displayName,
                            // mobile: payload.userCustomer.contactMobile,
                            name: payload.userChat.name || '',
                            mobile: payload.userChat.mobile || ''
                        },
                        checkShippingCosts: {
                            ...this_orderShop.checkShippingCosts,
                            deliveryAt: [{
                                ...this_orderShop.checkShippingCosts.deliveryAt[0],
                                addressId: (payload.addressUser && payload.addressUser.id) || '',
                                subdistrict: payload.listAddressUserOption.list_subDistricts == null ? null : {
                                    ...payload.listAddressUserOption.list_subDistricts,
                                    label: payload.listAddressUserOption.list_subDistricts.subdistrictName,
                                    value: payload.listAddressUserOption.list_subDistricts.subdistrictId
                                },
                                district: payload.listAddressUserOption.list_districts == null ? null : {
                                    ...payload.listAddressUserOption.list_districts,
                                    label: payload.listAddressUserOption.list_districts.districtName,
                                    value: payload.listAddressUserOption.list_districts.districtId
                                },
                                province: payload.listAddressUserOption.list_province == null ? null : {
                                    ...payload.listAddressUserOption.list_province,
                                    label: payload.listAddressUserOption.list_province.provinceName,
                                    value: payload.listAddressUserOption.list_province.provinceId
                                },
                                zipcode: (payload.addressUser && payload.addressUser.zipcode) || '',
                                address: (payload.addressUser && payload.addressUser.address) || '',
                                addressExtra: (payload.addressUser && payload.addressUser.addressExtra) || '',
                                location: (payload.addressUser && payload.addressUser.location && payload.addressUser.location.lat && payload.addressUser.location.lng && [parseFloat(payload.addressUser.location.lat), parseFloat(payload.addressUser.location.lng)]) || []
                            }]
                        },
                        addressUser: payload.addressUser || {},
                        listDistricts: payload.listDistricts,
                        listSubDistricts: payload.listSubDistricts,
                        isAddressOnChange: payload.addressUser == null ? true : false
                    },
                    ...state.orderShop.slice(0 + 1)
                ]
            }
        }

        case (Type.SET_TIME_DELIVERY_AT_SUCCESS): {
            const this_orderShop = { ...state.orderShop[payload.idx] };
            return {
                ...state,
                orderShop: [
                    ...state.orderShop.slice(0, payload.idx),
                    {
                        ...this_orderShop,
                        checkShippingCosts: {
                            ...this_orderShop.checkShippingCosts,
                            deliveryAt: [{
                                ...this_orderShop.checkShippingCosts.deliveryAt[0],
                                dateDelivery: payload.date
                            }]
                        }
                    },
                    ...state.orderShop.slice(payload.idx + 1)
                ]
            }
        }

        case (Type.SET_MENU_CREATE_ORDER): {
            const this_orderShop = { ...state.orderShop[payload.idx] };
            return {
                ...state,
                orderShop: [
                    ...state.orderShop.slice(0, payload.idx),
                    {
                        ...this_orderShop,
                        listMenuSelect: {
                            ...payload.listMenuSelect
                        }
                    },
                    ...state.orderShop.slice(payload.idx + 1)
                ]
            }
        }


        case (Type.CONFIRM_SEND_DATA_SUCCESS): {
            const this_orderDefault = { ...state.orderShop[0] };
            const { userCustomer, userChat } = state;
            return {
                ...state,
                orderShop: [
                    {
                        ...structureCreateOrder,
                        createUser: {
                            name: userChat.name || '',
                            mobile: userChat.mobile || ''
                        },
                        checkShippingCosts: {
                            ...structureCreateOrder.checkShippingCosts,
                            deliveryAt: [{
                                ...structureCreateOrder.checkShippingCosts.deliveryAt[0],
                                province: this_orderDefault.checkShippingCosts.deliveryAt[0].province,
                                district: this_orderDefault.checkShippingCosts.deliveryAt[0].district,
                                subdistrict: this_orderDefault.checkShippingCosts.deliveryAt[0].subdistrict,
                                zipcode: this_orderDefault.checkShippingCosts.deliveryAt[0].zipcode,
                                address: this_orderDefault.checkShippingCosts.deliveryAt[0].address,
                                addressExtra: this_orderDefault.checkShippingCosts.deliveryAt[0].addressExtra,
                                location: this_orderDefault.checkShippingCosts.deliveryAt[0].location
                            }]
                        },
                        listDistricts: this_orderDefault.listDistricts,
                        listSubDistricts: this_orderDefault.listSubDistricts,
                        addressUser: payload.addressUser == null ? this_orderDefault.addressUser : payload.addressUser
                    }
                ],
                activeTab: 0,
            }
        }

        case (Type.CHECK_MENU_DATE_DELIVERY_SUCCESS): {
            return {
                ...state,
                listMenu: payload.data
            }
        }

        case (Type.REMOVE_DELIVERY_AT_DATE_DELIVERY): {
            const this_orderShop = { ...state.orderShop[payload.idx] };

            return {
                ...state,
                orderShop: [
                    ...state.orderShop.slice(0, payload.idx),
                    {
                        ...this_orderShop,
                        search: {
                            ...this_orderShop.search,
                            date: "",
                            time: ""
                        },
                        checkShippingCosts: {
                            ...this_orderShop.checkShippingCosts,
                            deliveryAt: [{
                                ...this_orderShop.checkShippingCosts.deliveryAt[0],
                                dateDelivery: ""
                            }]
                        }
                    },
                    ...state.orderShop.slice(payload.idx + 1)
                ]
            }
        }


        case (Type.SELECT_LIST_OPTION_ADDRESS_USER_SUCCESS): {
            const this_orderShop = { ...state.orderShop[payload.idx] };
            return {
                ...state,
                orderShop: [
                    ...state.orderShop.slice(0, payload.idx),
                    {
                        ...this_orderShop,
                        checkShippingCosts: {
                            ...this_orderShop.checkShippingCosts,
                            deliveryAt: [{
                                ...this_orderShop.checkShippingCosts.deliveryAt[0],
                                province: payload.name === "province" ? payload.address : this_orderShop.checkShippingCosts.deliveryAt[0].province,
                                district: payload.name === "province" ? null : (payload.name === "district" ? payload.address : this_orderShop.checkShippingCosts.deliveryAt[0].district),
                                subdistrict: payload.name === "province" || payload.name === "district" ? null : (payload.name === "subdistrict" ? payload.address : this_orderShop.checkShippingCosts.deliveryAt[0].subdistrict),
                                zipcode: payload.name === "subdistrict" ? payload.address.zipcode : this_orderShop.checkShippingCosts.deliveryAt[0].zipcode
                            }]
                        },
                        listDistricts: payload.listDistricts == null ? this_orderShop.listDistricts : payload.listDistricts,
                        listSubDistricts: payload.listSubDistricts == null ? this_orderShop.listSubDistricts : payload.listSubDistricts,
                        isAddressOnChange: true
                    },
                    ...state.orderShop.slice(payload.idx + 1)
                ],
            }
        }


        case (Type.SET_ZIPCODE): {
            const this_orderShop = { ...state.orderShop[payload.idx] };
            return {
                ...state,
                orderShop: [
                    ...state.orderShop.slice(0, payload.idx),
                    {
                        ...this_orderShop,
                        checkShippingCosts: {
                            ...this_orderShop.checkShippingCosts,
                            deliveryAt: [{
                                ...this_orderShop.checkShippingCosts.deliveryAt[0],
                                zipcode: payload.zipcode
                            }]
                        },
                        isAddressOnChange: true
                    },
                    ...state.orderShop.slice(payload.idx + 1)
                ],

            }
        }


        case (Type.GET_LIST_ADDRESS_USER_SUCCESS): {
            return {
                ...state,
                listAddressCustomer: payload.listAddressCustomer
            }
        }

        case (Type.REPLACE_LIST_STOCK_BY_DATE_TIME): {
            const this_orderShop = { ...state.orderShop[payload.idx] };
            return {
                ...state,
                orderShop: [
                    ...state.orderShop.slice(0, payload.idx),
                    {
                        ...this_orderShop,
                        listStockByDateTime: {
                            ...payload.data
                        }
                    },
                    ...state.orderShop.slice(payload.idx + 1)
                ]
            }
        }

        case (Type.SET_QUANTITY_DEFAULT): {
            const this_orderShop = { ...state.orderShop[payload.idx] };
            return {
                ...state,
                orderShop: [
                    ...state.orderShop.slice(0, payload.idx),
                    {
                        ...this_orderShop,
                        listMenuSelect: {
                            ...this_orderShop.listMenuSelect,
                            [payload.id]: {
                                ...this_orderShop.listMenuSelect[payload.id],
                                quantity: payload.quantity
                            }
                        }
                    },
                    ...state.orderShop.slice(payload.idx + 1)
                ]
            }
        }
        case (Type.SET_SEARCH_DATA): {
            const this_orderShop = { ...state.orderShop[payload.idx] };
            return {
                ...state,
                orderShop: [
                    ...state.orderShop.slice(0, payload.idx),
                    {
                        ...this_orderShop,
                        search: {
                            ...this_orderShop.search,
                            ...payload.data
                            // date: payload.date,
                            // time: payload.time,
                            // menu: payload.menu,
                            // shop: payload.shop
                        }
                    },
                    ...state.orderShop.slice(payload.idx + 1)
                ]
            }
        }


        case (Type.SET_ADDRESS_USER_SUCCESS): {
            const this_orderShop = { ...state.orderShop[payload.idx] };
            return {
                ...state,
                orderShop: [
                    ...state.orderShop.slice(0, payload.idx),
                    {
                        ...this_orderShop,
                        checkShippingCosts: {
                            ...this_orderShop.checkShippingCosts,
                            deliveryAt: [{
                                ...this_orderShop.checkShippingCosts.deliveryAt[0],
                                addressId: (payload.addressUser && payload.addressUser.id) || '',
                                subdistrict: {
                                    ...payload.listAddressUserOption.list_subDistricts,
                                    label: payload.listAddressUserOption.list_subDistricts.subdistrictName,
                                    value: payload.listAddressUserOption.list_subDistricts.subdistrictId
                                },
                                district: {
                                    ...payload.listAddressUserOption.list_districts,
                                    label: payload.listAddressUserOption.list_districts.districtName,
                                    value: payload.listAddressUserOption.list_districts.districtId
                                },
                                province: {
                                    ...payload.listAddressUserOption.list_province,
                                    label: payload.listAddressUserOption.list_province.provinceName,
                                    value: payload.listAddressUserOption.list_province.provinceId
                                },
                                zipcode: (payload.addressUser && payload.addressUser.zipcode) || '',
                                address: (payload.addressUser && payload.addressUser.address) || '',
                                addressExtra: (payload.addressUser && payload.addressUser.addressExtra) || '',
                                location: (payload.addressUser.location.lat && payload.addressUser.location.lng && [parseFloat(payload.addressUser.location.lat), parseFloat(payload.addressUser.location.lng)]) || []
                            }]
                        },
                        addressUser: payload.addressUser || {}
                    },
                    ...state.orderShop.slice(payload.idx + 1)
                ]
            }
        }

        //// check dateDelivery menu
        case (Type.NO_DATE_DELIVERY): {
            const this_orderShop = { ...state.orderShop[payload.idx] };
            return {
                ...state,
                orderShop: [
                    ...state.orderShop.slice(0, payload.idx),
                    {
                        ...this_orderShop,
                        errors: {
                            ...this_orderShop.errors,
                            deliveryDate: {
                                status: payload.status
                            }
                        },
                        ...state.orderShop.slice(payload.idx + 1)
                    }
                ]
            }
        }

        case (Type.SET_LOADDING_CREATE_ORDER): {
            return {
                ...state,
                isLoadingCreateOrder: !state.isLoadingCreateOrder
            }
        }

        case (Type.CLEAR_SEARCH_MENU): {
            const this_orderShop = { ...state.orderShop[payload.idx] };
            return {
                ...state,
                orderShop: [
                    ...state.orderShop.slice(0, payload.idx),
                    {
                        ...this_orderShop,
                        search: {
                            ...this_orderShop.search,
                            menu: "",
                            // shop: ""
                        }
                    },
                    ...state.orderShop.slice(payload.idx + 1)
                ]
            }
        }

        case (Type.SET_LOADDING_CONFIRM_SELECT_ADDRESS_USER): {
            return {
                ...state,
                isLoadingConfirmSelectAddressUser: !state.isLoadingConfirmSelectAddressUser
            }
        }

        case (Type.COPY_DEFAULT_ADDRESS): {
            const this_orderShop = { ...state.orderShop[payload.idx] };
            return {
                ...state,
                orderShop: [
                    ...state.orderShop.slice(0, payload.idx),
                    {
                        ...this_orderShop,
                        checkShippingCosts: {
                            ...this_orderShop.checkShippingCosts,
                            deliveryAt: [{
                                ...this_orderShop.checkShippingCosts.deliveryAt[0],
                                province: payload.province,
                                district: payload.district,
                                subdistrict: payload.subdistrict,
                                zipcode: payload.zipcode,
                                address: payload.address,
                                addressExtra: payload.addressExtra,
                                location: payload.location
                            }]
                        },
                        // priceSpecial: '',
                        isAddressOnChange: payload.isAddressOnChange,
                        // listDistricts: payload.listDistricts,
                        // listSubDistricts: payload.listSubDistricts,
                        // deliveryOption: payload.deliveryOption,
                        // listLogistic: payload.listLogistic,
                        errors: {
                            ...this_orderShop.errors,
                            calculateShipingcost: {
                                ...this_orderShop.errors.calculateShipingcost,
                                outOfServiceArea: payload.outOfServiceArea
                            }
                        }
                    },
                    ...state.orderShop.slice(payload.idx + 1)
                ]
            }
        }
        case (Type.SET_IS_BOX_DEFAULT): {
            const this_orderShop = { ...state.orderShop[payload.idx] };
            return {
                ...state,
                orderShop: [
                    ...state.orderShop.slice(0, payload.idx),
                    {
                        ...this_orderShop,
                        isBox: payload.isBox
                    },
                    ...state.orderShop.slice(payload.idx + 1)
                ]
            }
        }
        case (Type.SET_IS_INDIVIDUALLY_DEFAULT): {
            const this_orderShop = { ...state.orderShop[payload.idx] };
            return {
                ...state,
                orderShop: [
                    ...state.orderShop.slice(0, payload.idx),
                    {
                        ...this_orderShop,
                        isIndividually: payload.isIndividually
                    },
                    ...state.orderShop.slice(payload.idx + 1)
                ]
            }
        }
        case (Type.SET_PRICE_KERRY): {
            const this_orderShop = { ...state.orderShop[payload.idx] };
            return {
                ...state,
                orderShop: [
                    ...state.orderShop.slice(0, payload.idx),
                    {
                        ...this_orderShop,
                        shippingCost: {
                            ...this_orderShop.shippingCost,
                            kerryPrice: payload.kerryPrice
                        }
                    },
                    ...state.orderShop.slice(payload.idx + 1)
                ]
            }
        }

        case (Type.SET_MESSAGE) : {
            const this_orderShop = { ...state.orderShop[payload.idx] };
            return {
                ...state,
                orderShop: [
                    ...state.orderShop.slice(0, payload.idx),
                    {
                        ...this_orderShop,
                        updateInvoiceById : {
                            ...this_orderShop.updateInvoiceById,
                            message : payload.message,
                        }
                    },
                    ...state.orderShop.slice(payload.idx + 1)
                ]
            }
        } 
        
        case(Type.SET_SEND_MESSAGE) : {
            return {
                ...state,
                sendMsg : !state.sendMsg
            }
        }

        case (Type.SET_DELIVERY_NOTE): {
            const this_orderShop = { ...state.orderShop[payload.idx] };
            return {
                ...state,
                orderShop: [
                    ...state.orderShop.slice(0, payload.idx),
                    {
                        ...this_orderShop,
                        checkShippingCosts: {
                            ...this_orderShop.checkShippingCosts,
                            deliveryNote: payload.deliveryNote
                        }
                    },
                    ...state.orderShop.slice(payload.idx + 1)
                ]
            }
        }

        case (Type.SET_LIST_LOGISTIC_SUCCESS): {
            const this_orderShop = { ...state.orderShop[payload.idx] };
            return {
                ...state,
                orderShop: [
                    ...state.orderShop.slice(0, payload.idx),
                    {
                        ...this_orderShop,
                        listLogistic: payload.listLogistic
                    },
                    ...state.orderShop.slice(payload.idx + 1)
                ]
            }
        }

        case (Type.SET_PRICE_SPECIAL): {
            const this_orderShop = { ...state.orderShop[payload.idx] };
            return {
                ...state,
                orderShop: [
                    ...state.orderShop.slice(0, payload.idx),
                    {
                        ...this_orderShop,
                        priceSpecial: payload.priceSpecial
                    },
                    ...state.orderShop.slice(payload.idx + 1)
                ]
            }
        }
        

        default:
            return {
                ...state
            }
    }
}

