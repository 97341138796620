import React, { useState } from 'react';
import moment from 'moment'
import IconBin from '../../../assets/Icons/bin.png'
import IconCheckBoxInActive from '../../../assets/Icons/checkBoxInActive.png';
import IconChecBoxActiveBackWhite from '../../../assets/Icons/checBoxActiveBackWhite.png';
import IconCheckBoxActive from '../../../assets/Icons/checkBoxActive.png';
import IconAddPromoCode from '../../../assets/Icons/Add_1.png';
import checkBoxActiveSquare from '../../../assets/Icons/checkBoxActiveSquare.png';
import checkBoxIsActiveSquare from '../../../assets/Icons/checkBoxIsActiveSquare.svg';
// import MapComponent from '../../Map';
import { connect } from 'react-redux';
import ModalAddress from './ModalAddress';
import * as actions_createOrder from '../../../redux/actions/createOrder';
import * as actions_menus from '../../../redux/actions/menus';
import * as actions_address from '../../../redux/actions/address';
import * as actions_loading from '../../../redux/actions/loading';
import IconDeleteProduct from '../../../assets/Icons/Icon_close1.svg';
import IconUpAmountProduct from '../../../assets/Icons/Add_1.png';
import IconDownAmountProduct from '../../../assets/Icons/Remove.svg';
import IconCodeSuccess from '../../../assets/Icons/IconSuccess.svg';
import IconCodeError from '../../../assets/Icons/IconError.svg';
import { changeFormatDate } from '../../../utils/utils';
import * as utils from "../../../utils/utils";
// import SelectOption from '../../../components/Select';
import FromAddress from '../../../components/pages/CreateOrder/FromAddress';
import { Tooltip, UncontrolledTooltip } from 'reactstrap';
import Delete1 from '../../../assets/Icons/delete1.png';
import { skootar, lalamove, kerry, cpiu } from '../../../config/typeDelivery';


class ContentTabUser extends React.Component {
    state = {
        modalAddress: {
            isOpen: false,
            title: '',
            data: {
                idx: null,
                addressUser: null
            }
        },
        isOnBlurCode: false,
        isOpenToolTip: false,
        specialPrice: null,
        deliveryOption: this.props.item.deliveryOption,
        listLogistic: []
    }
    baseState = { ...this.state }

    componentDidUpdate() {
        this.scollPos(this.props.createOrder.activeTab)
    }

    componentWillReceiveProps(nextProps) {
        if (this.state.deliveryOption != nextProps.item.deliveryOption) {
            this.setState({
                deliveryOption: nextProps.item.deliveryOption
            })
        }
        if (this.state.listLogistic != nextProps.createOrder.orderShop[nextProps.idx].listLogistic) {
            this.setState({
                listLogistic: nextProps.createOrder.orderShop[nextProps.idx].listLogistic || []
            })
        }
    }


    openModalAddress = async (idx, addressUser) => {
        await this.props.clearListDistrictAndSubDistricts();
        await this.props.getListAddressUser();
        this.setState({
            modalAddress: {
                isOpen: true,
                data: {
                    idx, addressUser
                }
            }
        });
    }

    closeModalAddress = () => {
        this.setState({
            modalAddress: {
                ...this.baseState.modalAddress
            }
        })
    }
    /// tab ///
    removeTab = (idx) => {
        this.props.removeTab(idx);
        if (this.props.createOrder.activeTab == 0) {
            this.props.getListMenu(this.props.createOrder.orderShop[this.props.createOrder.activeTab + 1].search)
        } else {
            this.props.getListMenu(this.props.createOrder.orderShop[this.props.createOrder.activeTab - 1].search)
        }
    }
    /// tab ///

    /// address ///
    handleChangeAddress_AddressExtra = (evt, idx) => {
        const data = {
            idx,
            name: evt.target.name,
            value: evt.target.value,
        }
        this.props.handleChangeAddress_AddressExtra(data);
    }

    handleClickSearchLatLongByAddress = (address, idx) => {
        this.props.getLatLngByAddress({ address, idx });
    }

    handleGetLatLongInMap = ({ lat, lng }, idx) => {
        this.props.setLatLng({ lat, lng, idx });
    }

    handleSetContactShop = (contactShop, idx) => {
        this.props.handleSetContactShop({ contactShop, idx })
    }

    /// address ///

    /// contact ///
    handleChangeName_Mobile = (evt, idx) => {
        const data = {
            idx,
            name: evt.target.name,
            value: evt.target.value,
        }
        this.props.handleChangeName_Mobile(data);

        // for (let i in this.props.createOrder.orderShop) {
        // if (this.props.createOrder.activeTab == 0) {
        if (evt.target.name == "name") {
            if (this.props.createOrder.orderShop[idx].createUser.name != evt.target.value) {
                const data = {
                    idx: parseInt(idx),
                    name: evt.target.name,
                    value: evt.target.value,
                }
                this.props.handleChangeName_Mobile(data);
            }
        }
        if (evt.target.name == "mobile") {
            if (this.props.createOrder.orderShop[idx].createUser.mobile != evt.target.value) {
                const data = {
                    idx: parseInt(idx),
                    name: evt.target.name,
                    value: evt.target.value,
                }
                this.props.handleChangeName_Mobile(data);
            }
        }
        // }
        // }
    }
    /// contact ///

    /// delivery ///
    handleSetDeliveryOption = (itemLogistic, idx) => {
        this.props.handleSetDeliveryOption({ itemLogistic, idx });

        // if (itemLogistic.logistic.code == "SPECIAL") {
        //     // this.props.setPriceSpecial(this.state.specialPrice)
        //     const shippingCost = [{
        //         deliveryPrice: this.props.createOrder.orderShop[idx].priceSpecial,
        //         discountPrice: 0
        //     }]
        //     this.props.setDeliveryPriceKerry({ shippingCost, idx })
        // }

    }
    handleSetIsBox = (idx) => {
        this.props.handleSetIsBox({ idx });
    }

    handleSetIsIndividually = (idx) => {
        this.props.handleSetIsIndividually({ idx });
    };

    handleSetDeliveryPriceKerry = (event, idx) => {
        this.setState({
            specialPrice: event.target.value
        })
        this.props.setPriceSpecial(event.target.value, idx)
        // this.props.setPriceSpecial(this.state.specialPrice)
        if (this.props.createOrder.orderShop[this.props.createOrder.activeTab].deliveryOption == "SPECIAL") {
            const shippingCost = [{
                deliveryPrice: event.target.value,
                discountPrice: 0
            }]
            this.props.setDeliveryPriceKerry({ shippingCost, idx })
        }


        // if (this.props.createOrder.orderShop[idx].deliveryOption === "SPECIAL") {
        // this.props.createOrder.orderShop.map((item, index) => {
        // this.props.setDeliveryPriceKerry({ shippingCost, idx })
        // })
        // }
    }
    /// delivery ///


    handleClickAddSpecialDisCount = (idx) => {
        this.props.handleClickAddSpecialDisCount({ idx });
    };

    handleClickRemoveSpecialDisCount = (idx) => {
        this.props.handleClickRemoveSpecialDisCount({ idx });
    }

    handleChangPriceSpecilaDiscount = (evt, idx) => {
        const listMenuSelect = this.props.createOrder.orderShop[idx].listMenuSelect
        // const priceMenu = this.sumPriceFood(listMenuSelect)

        if (evt.target.name === "code") {
            this.setState({
                isOnBlurCode: false
            });
        }
        const data = {
            idx,
            name: evt.target.name,
            value: evt.target.name === "foodDiscount" ? this.checkPriceFoodSpecialCode(evt.target.value, idx) : evt.target.name === "shippingDiscount" ? this.checkPriceDeliverySpecialCode(evt.target.value, idx) : evt.target.value
        }
        this.props.handleChangPriceSpecilaDiscount(data);
    }

    checkPriceFoodSpecialCode = (evt, idx) => {
        const listMenuSelect = this.props.createOrder.orderShop[idx].listMenuSelect
        const priceMenu = this.sumPriceFood(listMenuSelect)
        if (evt <= priceMenu && evt >= 0) {
            return evt
        } else if (evt <= 0) {
            return 0
        } else {
            return priceMenu
        }
    }

    checkPriceDeliverySpecialCode = (evt, idx) => {
        const { deliveryPrice } = this.props.createOrder.orderShop[idx].shippingCost
        if (evt <= deliveryPrice && evt >= 0) {
            return evt
        } else if (evt <= 0) {
            return 0
        } else {
            return deliveryPrice
        }
    }


    checkSpecialCode = (code, idx) => {
        this.setState({
            isOnBlurCode: true
        });
        if (code != null && code !== "") {
            this.props.checkSpecialCode({ code, idx });
        }
    }


    setIsDefaultContactCustomer = async (idx, address) => {

        if (this.props.createOrder.orderShop[idx].isDefaultContactCustomer) {
            this.props.setIsNotDefaultContactCustomer({ idx });
        } else {
            await this.props.getLatLngByAddress({ address: this.props.createOrder.orderShop[0].checkShippingCosts.deliveryAt[0], idx })
            this.props.setIsDefaultContactCustomer({ idx });
        }
    }

    onSetQuantity = (id, idx, date, userId, type) => {
        this.props.onSetQuantityMenu({ id, idx, date, userId, type })
    }

    removeMenuOnList = async (detailMenu, idx) => {
        await this.props.removeMenuOnList({ detailMenu, idx })
    }




    changeFormatTime = (time) => {
        const timeDelivery = moment(time).format('HH:mm')
        return timeDelivery
    }

    setTimeDeliveryAt = async (date, idx) => {
        const menu = this.props.createOrder.orderShop[idx].search.menu
        this.props.setTimeDeliveryAt({ date, idx })
        const setDateFormat = date && moment(date).format("YYYY-MM-DD") || ""
        const setTimeFormat = date && moment(date).format("HH:mm") || ""

        const search = this.props.createOrder.orderShop[idx].checkShippingCosts.deliveryAt[0].dateDelivery

        if (date != search) {
            const body = {
                payload: {
                    menu,
                    time: setTimeFormat || "",
                    date: setDateFormat || "",
                },
            }
            await this.props.setSearch({ data: body.payload, idx })
        } else {
            const body = {
                payload: {
                    menu,
                    time: "",
                    date: "",
                },
            }
            await this.props.setSearch({ data: body.payload, idx })
        }
        await this.props.onSearch(this.props.createOrder.orderShop[idx].search)
        // if (date === search) {
        //     const body = {
        //         payload: {
        //             menu,
        //             time: "",
        //             date: ""
        //         }
        //     }
        //     this.props.onSearch(body.payload)
        //     return
        // }
        // this.props.onSearch(body.payload)
    }


    sumPriceFood = (listMenuSelects) => {
        const total = Object.keys(listMenuSelects).reduce(function (previous, key) { // for loop sum total price
            const result = parseFloat(previous + (listMenuSelects[key].quantity * (listMenuSelects[key].product && listMenuSelects[key].product.dataProduct[0].priceNet)));
            if (isNaN(result)) {
                return 0
            }
            return result
        }, 0);
        return total
    };

    showPriceFoodDiscount = (listMenuSelects) => {
        const { priceSpecialDiscount } = this.props.createOrder.orderShop[this.props.idx].updateInvoiceById
        let foodDiscount = priceSpecialDiscount != null && priceSpecialDiscount.foodDiscount ? parseFloat(priceSpecialDiscount.foodDiscount) : 0
        const result = this.sumPriceFood(listMenuSelects)
        if (priceSpecialDiscount != null) {
            return result - foodDiscount
        }
        return result
    }


    sumTotalAll = (listMenuSelect, item, orderShop) => {
        const { deliveryOption, shippingCost, isDefaultContactCustomer } = item;

        const total = Object.keys(listMenuSelect).reduce(function (previous, key) { // for loop sum total price
            const result = parseFloat(previous + (listMenuSelect[key].quantity * (listMenuSelect[key].product && listMenuSelect[key].product.dataProduct[0].priceNet)));
            if (isNaN(result)) {
                return 0
            }
            return result
        }, 0);

        // if (isDefaultContactCustomer) {
        if (item && item.shippingCost) {
            return total + item.shippingCost.deliveryPrice
        }
        // }
    }
    //         const order_default = orderShop[0];
    //         if (item.deliveryOption === lalamove.code || item.deliveryOption === skootar.code || item.deliveryOption === kerry.code) {
    //             return total + item.shippingCost.deliveryPrice
    //         } else {
    //             return total;
    //         }

    //     } else {

    //         if ((deliveryOption === lalamove.code || deliveryOption === skootar.code) || item.deliveryOption === kerry.code) {
    //             return total + shippingCost.deliveryPrice
    //         } else {
    //             return total;
    //         }
    //     }
    // }

    showPriceDiscountAll = (listMenuSelect, item, orderShop) => {
        const { priceSpecialDiscount } = this.props.createOrder.orderShop[this.props.idx].updateInvoiceById
        const resultPrice = this.sumTotalAll(listMenuSelect, item, orderShop)

        if (priceSpecialDiscount != null) {
            const  {foodDiscount, shippingDiscount} = priceSpecialDiscount && priceSpecialDiscount
            const priceFoodDiscount = foodDiscount.toString().trim().length > 0 ? parseFloat(foodDiscount) : 0
            const priceShippingDiscount = shippingDiscount.toString().trim().length > 0 ? parseFloat(shippingDiscount) : 0
            const priceDiscount = resultPrice - priceFoodDiscount - priceShippingDiscount
            return priceDiscount
        }
            return resultPrice

    }

    showPriceDelivery = (item, orderShop) => {
        const { deliveryOption, shippingCost, isDefaultContactCustomer } = item;
        const { priceSpecialDiscount } = this.props.createOrder.orderShop[this.props.idx].updateInvoiceById
        // if (isDefaultContactCustomer) {
        if (priceSpecialDiscount != null && priceSpecialDiscount && priceSpecialDiscount.shippingDiscount) {
            return item && item.shippingCost && item.shippingCost.deliveryPrice - parseInt(priceSpecialDiscount.shippingDiscount)
        }
        return item && item.shippingCost && item.shippingCost.deliveryPrice;
        // } else 
    }
    //         // const order_default = orderShop[0];
    //         if (item.deliveryOption === lalamove.code || item.deliveryOption === skootar.code || item.deliveryOption === kerry.code) {
    //             return item.shippingCost.deliveryPrice;
    //         } else {
    //             return 0
    //         }
    //     } else {
    //         if ((deliveryOption === lalamove.code || deliveryOption === skootar.code) || item.deliveryOption === kerry.code) {
    //             return shippingCost.deliveryPrice
    //         } else {
    //             return 0;
    //         }
    //     }
    // };



    handleSelectProvince = (selected, idx) => {
        // this.props.getListDistricts({
        //     provinceId: selected.provinceId
        // });

        const data = {
            address: selected,
            name: 'province',
            idx
        }
        this.props.setAddressUser(data);
    }
    handleSelectDistricts = (selected, idx) => {
        // this.props.getListSubDistricts({
        //     districtId: selected.districtId
        // });

        const data = {
            address: selected,
            name: 'district',
            idx
        }
        this.props.setAddressUser(data);

    }

    handleSelectSubdistrict = (selected, idx) => {
        const data = {
            address: selected,
            name: 'subdistrict',
            idx
        }
        this.props.setAddressUser(data);
    };

    handleChangeZipcode = (evt, idx) => {
        const data = {
            zipcode: evt.target.value,
            idx
        }
        this.props.setZipcode(data);
    }

    toggleToolTip = () => {
        this.setState(prevState => {
            return {
                isOpenToolTip: !prevState.isOpenToolTip
            }
        })
    }

    scollPos = (idx) => {
        if (Object.keys(this.props.createOrder.orderShop[idx].listMenuSelect).length >= 1 && this.props.createOrder.orderShop[idx].checkShippingCosts.deliveryAt[0].dateDelivery != "" && this.props.createOrder.orderShop[idx].checkShippingCosts.deliveryAt[0].dateDelivery != "undefined") {
            this.props.createOrder.orderShop[idx].listStockByDateTime && this.props.createOrder.orderShop[idx].listStockByDateTime.date.map((item) => {
                const dateDelivery = moment(this.props.createOrder.orderShop[idx].checkShippingCosts.deliveryAt[0].dateDelivery).format('DD-MM-YYYY')
                const dateMenu = moment(item).format('DD-MM-YYYY')
                if (dateDelivery == dateMenu) {
                    const div = document.getElementById("date") && document.getElementById("date").offsetTop;
                    document.getElementById("scrollDate").scrollTop = div - 50
                }
            })
        } else {

        }


    }

    handleChangeMessage = (evt, idx) => {
        this.props.handleChangeMessage({ message: evt.target.value, idx })
    }

    onSetDeliveryNote = (evt, idx) => {
        const payload = {
            deliveryNote: evt.target.value,
            idx: idx
        }
        this.props.setDeliveryNote(payload)

    }


    render() {
        const { idx, item, countItemAll, createOrder, listLogistic } = this.props;
        const { orderShop, userCustomer } = createOrder;
        const {
            checkShippingCosts,
            createUser,
            deliveryOption,
            mainData,
            contactShop,
            updateInvoiceById,
            shippingCost,
            isDefaultContactCustomer,
            errors,
            listMenuSelect,
            listDateDelivery,
            addressUser,
            listDistricts,
            listSubDistricts,
            isAddressOnChange
        } = item;

        return (
            <div className="container">

                <div className="row justify-content-between">
                    <label className="font-primary-16px-text">รอบที่ {idx + 1}</label>
                    {countItemAll > 1 ?
                        <div>
                            {/* <Tooltip placement="top" target={"cardMenu" + countItemAll} toggle={this.toggleToolTip} isOpen={this.state.isOpenToolTip}>
                                ลบรอบส่งนี้
                              </Tooltip> */}
                            <UncontrolledTooltip placement="top" target={"cardMenu" + idx}>
                                ลบรอบส่งนี้
                                </UncontrolledTooltip>
                            <img src={IconBin} alt="IconDlete" className="size-icon-20px cursor-pointer"
                                onClick={() => this.removeTab(idx)}
                                id={"cardMenu" + idx}
                                onMouseOver={e => (e.currentTarget.src = Delete1)}
                                onMouseOut={e => (e.currentTarget.src = IconBin)}
                            />
                        </div>
                        : ""
                    }

                </div>


                <div className="row" >
                    <label className="font-bold-size-20px">
                        {createOrder.orderShop[createOrder.activeTab].mainData && createOrder.orderShop[createOrder.activeTab].mainData.dataShop[0].name}
                    </label>
                </div>

                <div className="row line-hr mt-2" />
                <div className="row justify-content-center mt-4">
                    <label className="font-bold-size-16px">วันเวลาจัดส่งที่ลูกค้าเลือก</label>
                </div>

                <div className="scroll-date-view row justify-content-center col" id="scrollDate">
                    {listDateDelivery && listDateDelivery.dateDelivery && listDateDelivery.dateDelivery.map((item, index) =>
                        <div className="mt-2" key={index} id={checkShippingCosts.deliveryAt[0].dateDelivery === item ? "date" : ""}>
                            <div className={checkShippingCosts.deliveryAt[0].dateDelivery === item ? "box-select-date-active" : "box-select-date-inactive"} onClick={() => this.setTimeDeliveryAt(item, idx)} key={item} >
                                <div className={checkShippingCosts.deliveryAt[0].dateDelivery === item ? "row font-color-white" : "row font-color-primary"}>
                                    <div className="col-md-6">
                                        <div className="d-flex align-items-center">
                                            <img className="size-icon-20px" src={checkShippingCosts.deliveryAt[0].dateDelivery === item ? IconChecBoxActiveBackWhite : IconCheckBoxInActive} alt="IconCheckBoxInActive" />
                                            <span className="font-box-select-date ml-4" >
                                                {changeFormatDate(item) || ""}
                                            </span>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-sm-6">
                                        <span className="font-box-select-date">
                                            รอบ
                                </span  >
                                        <span className="font-box-select-date ml-4">
                                            {this.changeFormatTime(item) || ""} น.
                                </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>




                <div className="row mt-15">
                    <label className="font-bold-size-16px">เมนูที่สั่ง</label>
                </div>


                {Object.keys(listMenuSelect && listMenuSelect).map((key, idx) => (
                    <div className="row mt-2" key={[key]} >
                        <div className="box-menu-selecct" >
                            <div className="detail">
                                <div className="menu">
                                    <span className="font-size-20px word-break-word">
                                        {item.listMenuSelect[key].product && (item.listMenuSelect[key].product.name.th || item.listMenuSelect[key].product.name.en)}
                                    </span>
                                </div>
                                <div className="item-right">
                                    <span className="name">
                                        {utils.numberFormatDecimalTwoPoint(item.listMenuSelect[key].product && item.listMenuSelect[key].product.dataProduct[0].priceNet)}.- / ชุด
                                    </span>
                                    <div className="products">
                                        <img src={IconDownAmountProduct} alt="IconClose" className="size-icon-20px mr-3 cursor-pointer"
                                            onClick={() => this.onSetQuantity(item.listMenuSelect[key].product && item.listMenuSelect[key].product.dataProduct[0]._id, createOrder.activeTab, checkShippingCosts.deliveryAt && checkShippingCosts.deliveryAt[0].dateDelivery, userCustomer.id, "reduce")} />
                                        <span> {item.listMenuSelect[key].quantity} </span>
                                        <img src={IconUpAmountProduct} alt="IconClose" className="size-icon-20px ml-3 cursor-pointer" onClick={() => this.onSetQuantity(item.listMenuSelect[key].product && item.listMenuSelect[key].product.dataProduct[0]._id, createOrder.activeTab, checkShippingCosts.deliveryAt && checkShippingCosts.deliveryAt[0].dateDelivery, userCustomer.id, "add")} />
                                    </div>
                                    <div className="icon-delete-product">
                                        <img src={IconDeleteProduct} alt="IconClose" className="size-icon-20px cursor-pointer" onClick={() => this.removeMenuOnList(item.listMenuSelect[key].product, createOrder.activeTab)} />
                                    </div>
                                </div>
                            </div>

                            <span className="font-color-error">{
                                item.listMenuSelect[key].error && (!item.listMenuSelect[key].error.statusCreate || item.listMenuSelect[key].error.isErrorUpdateInvoice) ? <span className="font-size-16px">{utils.showMessageError(item.listMenuSelect[key].error.messageError)} </span> : ''
                            }</span>

                            <span className="font-stock-menu">
                                {item.listMenuSelect[key].listStockByDateTime && item.listMenuSelect[key].listStockByDateTime.isPreorder && item.listMenuSelect[key].listStockByDateTime.stock.isLimit ?
                                    `เหลืออีก ${item.listMenuSelect[key].listStockByDateTime && item.listMenuSelect[key].listStockByDateTime.stock.stock} ชุด` : ""
                                }

                            </span>
                        </div>
                    </div>

                ))}
                <div className="row mt-3 mb-1">
                    <span className="font-bold-size-16px">
                        ที่อยู่ร้าน
                            </span>
                </div>

                {
                    mainData && mainData.dataContact.map((itemContact, idxContact) =>
                        <div className="row justify-content-between mt-3 cursor-pointer" key={idxContact}>
                            {itemContact.isActive ?
                                <div className="d-flex align-items-start"
                                    onClick={() => this.handleSetContactShop(itemContact, idx)}
                                >
                                    <img
                                        src={contactShop._id === itemContact._id ? IconCheckBoxActive : IconCheckBoxInActive}
                                        alt="IconCheckBoxActive"
                                        className="size-icon-20px cursor-pointer mt-05"
                                    />
                                    <span className="font-size-16px ml-3">
                                        {itemContact.address}
                                    </span>
                                </div> : ""}
                        </div>

                    )
                }
                <div className="row justify-content-center mt-4">
                    <label className="font-bold-size-20px">
                        ข้อมูลในการจัดส่ง
                    </label>
                </div>
                {idx !== 0 ?
                    <div className="row">
                        <img
                            src={isDefaultContactCustomer ? checkBoxActiveSquare : checkBoxIsActiveSquare}
                            alt="checkBoxActiveSquare"
                            className="size-icon-20px cursor-pointer"
                            onClick={() => this.setIsDefaultContactCustomer(idx)}
                        />
                        <span className="font-size-16px ml-2 cursor-pointer"
                            onClick={() => this.setIsDefaultContactCustomer(idx)}
                        >
                            ใช้ข้อมูลการจัดส่งเดียวกันกับรอบส่งที่ 1
                             </span>
                    </div>
                    : ""}
                {idx === 0 || !isDefaultContactCustomer ?

                    <>
                        <div className="row justify-content-between mt-3">
                            <span className="font-bold-size-16px">
                                ที่อยู่ในการจัดส่ง
                    </span>
                            <span className="font-primary-16px-text cursor-pointer" onClick={() => this.openModalAddress(idx, addressUser)}>
                                เลือกที่อยู่อื่น
                    </span>
                        </div>

                        <FromAddress
                            handleChangeAddress_AddressExtra={(evt) => this.handleChangeAddress_AddressExtra(evt, idx)}
                            handleClickSearchLatLongByAddress={() => this.handleClickSearchLatLongByAddress(checkShippingCosts.deliveryAt[0], idx)}
                            getAddress={(location) => this.handleGetLatLongInMap(location, idx)}
                            value={
                                {
                                    address: checkShippingCosts && checkShippingCosts.deliveryAt[0].address,
                                    addressExtra: checkShippingCosts && checkShippingCosts.deliveryAt[0].addressExtra,
                                    location: {
                                        lat: checkShippingCosts && checkShippingCosts.deliveryAt[0].location[0],
                                        lng: checkShippingCosts && checkShippingCosts.deliveryAt[0].location[1]
                                    },
                                    province: checkShippingCosts && checkShippingCosts.deliveryAt[0].province,
                                    district: checkShippingCosts && checkShippingCosts.deliveryAt[0].district,
                                    subdistrict: checkShippingCosts && checkShippingCosts.deliveryAt[0].subdistrict,
                                    zipcode: checkShippingCosts && checkShippingCosts.deliveryAt[0].zipcode
                                }

                            }
                            handleSelectProvince={(selected) => this.handleSelectProvince(selected, idx)}
                            handleSelectDistricts={(selected) => this.handleSelectDistricts(selected, idx)}
                            handleSelectSubdistrict={(selected) => this.handleSelectSubdistrict(selected, idx)}
                            handleChangeZipcode={(evt) => this.handleChangeZipcode(evt, idx)}
                            isGlobalAddress={false}
                            listDistricts={listDistricts}
                            listSubDistricts={listSubDistricts}
                            isAddressOnChange={createOrder.orderShop[createOrder.activeTab].isAddressOnChange}
                            handleGetLatLongInMap={this.handleGetLatLongInMap}
                            sendLatLng="ContentTabUser"
                        /> 


                        {/* <div className="row mt-2">
                            <SelectOption
                                placeholder="เลือกจังหวัด"
                            />
                        </div>
                        <div className="row mt-2">
                            <SelectOption
                                placeholder="เลือกเขต"
                            />
                        </div>
                        <div className="row mt-2">
                            <SelectOption
                                placeholder="เลือกแขวง"
                            />
                        </div>
                        <div className="row mt-4">
                            <div className="form-group w-100">
                                <input
                                    name="zipcode"
                                    type="text"
                                    className="form-control input-background-color"
                                    placeholder="รหัสไปรษณีย์"
                                // onChange={(evt) => this.handleChangeName_Mobile(evt, idx)}
                                // value={createUser.name}
                                />
                            </div>
                        </div>
                        <div className="row mt-2">
                            <div className="form-group w-100">
                                <textarea
                                    name="address"
                                    onChange={(evt) => this.handleChangeAddress_AddressExtra(evt, idx)}
                                    value={checkShippingCosts.deliveryAt[0].address}
                                    className="form-control input-background-color"
                                    rows="3"
                                    placeholder="พิมพ์ที่อยู่"

                                ></textarea>
                            </div>
                        </div>
                        <div className="row">
                            <button type="button"
                                className="btn btn-primary w-100 color-primary btn-primary"
                                onClick={() => this.handleClickSearchLatLongByAddress(checkShippingCosts.deliveryAt[0].address, idx)}>ค้นหาที่อยู่</button>
                        </div>
                        <div className="mt-3">
                            <MapComponent
                                location={{
                                    lat: checkShippingCosts.deliveryAt[0].location[0],
                                    lng: checkShippingCosts.deliveryAt[0].location[1]
                                }}
                                getAddress={(location) => this.handleGetLatLongInMap(location, idx)}
                            />
                        </div> */}



                        {/* <div className="row mt-2">
                            <div className="form-group w-100">
                                <textarea
                                    className="form-control input-background-color"
                                    name="addressExtra"
                                    value={checkShippingCosts.deliveryAt[0].addressExtra}
                                    onChange={(evt) => this.handleChangeAddress_AddressExtra(evt, idx)}
                                    rows="3"
                                    placeholder="รายละเอียดที่อยู่เพิ่มเติม"></textarea>
                            </div>
                        </div> */}
                    </>
                    : null
                }




                {createOrder.orderShop[createOrder.activeTab].isAddressOnChange ? "" :
                    <div>
                        <div className="row mt-2">
                            <label className="font-bold-size-16px">
                                รูปแบบการจัดส่ง
                 </label>
                        </div>


                        {
                            this.state.listLogistic && this.state.listLogistic.map((itemLogistic, idxLogistic) =>
                                <React.Fragment key={idxLogistic}>
                                    <div className="row justify-content-between">
                                        <div className="d-flex align-items-center">

                                            <img
                                                onClick={() => this.handleSetDeliveryOption(itemLogistic, idx)}
                                                src={itemLogistic && itemLogistic.logistic && itemLogistic.logistic.code == this.state.deliveryOption ? IconCheckBoxActive : IconCheckBoxInActive}
                                                alt="IconCheckBoxActive"
                                                className="size-icon-20px cursor-pointer"
                                            />
                                            <span className="font-size-16px ml-3">
                                                {itemLogistic.logistic && itemLogistic.logistic.label}
                                            </span>
                                        </div>
                                        <span>
                                            {itemLogistic && itemLogistic.logistic && itemLogistic.logistic.code == "SPECIAL" ?
                                                <div className="mt-1">
                                                    <input
                                                        style={{ width: "7rem" }}
                                                        type="number"
                                                        className="form-control input-background-color input-no-spinner"
                                                        onChange={(event) => this.handleSetDeliveryPriceKerry(event, idx)}
                                                        placeholder="กรอกราคา"
                                                        value={createOrder.orderShop[createOrder.activeTab].priceSpecial || ""}
                                                    />
                                                </div> : itemLogistic.logistic && itemLogistic.deliveryPrice + ".-"
                                            }
                                        </span>
                                    </div>
                                    {itemLogistic && itemLogistic.logistic && itemLogistic.logistic.code == "SPECIAL" ?
                                        <input
                                            type="text"
                                            className="form-control input-background-color input-no-spinner mt-2"
                                            onChange={(evt) => this.onSetDeliveryNote(evt, idx)}
                                            placeholder="ระบุขนส่ง"
                                            value={createOrder.orderShop[createOrder.activeTab].checkShippingCosts.deliveryNote || ""}
                                        /> : ""
                                    }




                                    {/* ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// */}
                                    {/* <div className="row justify-content-between">
                                        <div className="d-flex align-items-center">
                                            <img
                                                onClick={() => this.handleSetDeliveryOption(itemLogistic.code, idx)}
                                                src={deliveryOption === itemLogistic.code ? IconCheckBoxActive : IconCheckBoxInActive}
                                                alt="IconCheckBoxActive"
                                                className="size-icon-20px cursor-pointer"
                                            />
                                            <span className="font-size-16px ml-3">
                                                {itemLogistic.code === skootar.code ?
                                                    "แมสเซ็นเจอร์"
                                                    : (itemLogistic.code === kerry.code ?
                                                        kerry.title : itemLogistic.code === lalamove.code ? lalamove.title
                                                            : itemLogistic.code === cpiu.code ? "รับของด้วยตนเอง" : ""
                                                    )}
                                            </span>
                                        </div>
                                        <span>
                                            {((itemLogistic.code === lalamove.code && deliveryOption === lalamove.code) || (itemLogistic.code === skootar.code && deliveryOption === skootar.code)) ?
                                                `${utils.numberFormatDecimalTwoPoint(shippingCost.deliveryPrice)}.-`
                                                : (itemLogistic.code === "" ?
                                                    "80.-" : itemLogistic.code === kerry.code && deliveryOption === kerry.code ?


                                                        <input
                                                            style={{ width: "7rem" }}
                                                            type="number"
                                                            className="form-control input-background-color input-no-spinner"
                                                            onChange={(event) => this.handleSetDeliveryPriceKerry(event, idx)}
                                                            // value={`${utils.numberFormatDecimalTwoPoint(shippingCost.deliveryPrice)}.-`}
                                                            placeholder="กรอกราคา"
                                                            // value={this.props.createOrder.orderShop[this.props.createOrder.activeTab].shippingCost.deliveryPrice == 0 ? "" : this.props.createOrder.orderShop[this.props.createOrder.activeTab].shippingCost.deliveryPrice}
                                                        />
                                                        
                                                        : itemLogistic.code === "CPIU" ? "0.-" : "0.-"
                                                )}
                                        </span>
                                    </div> */}

                                    {
                                        (itemLogistic.code === lalamove.code && deliveryOption === lalamove.code) || (itemLogistic.code === skootar.code && deliveryOption === skootar.code) ?
                                            <>
                                                {/* <div className="row mt-1">
                                                    <div className="col">
                                                        <div className="ml-21px">
                                                            <img
                                                                src={item.isBox ? checkBoxActiveSquare : checkBoxIsActiveSquare}
                                                                alt="checkBoxActiveSquare"
                                                                className="size-icon-20px cursor-pointer"
                                                                onClick={() => this.handleSetIsBox(idx)}
                                                            />
                                                            <span className="font-size-16px ml-2 cursor-pointer"
                                                                onClick={() => this.handleSetIsBox(idx)}
                                                            >
                                                                มีกล่องในการจัดส่ง
                                                            </span>
                                                        </div>

                                                    </div>
                                                </div> */}
                                                <div className="row mt-1">
                                                    <div className="col">
                                                        <div className="ml-21px">
                                                            <img
                                                                src={item.isIndividually ? checkBoxActiveSquare : checkBoxIsActiveSquare}
                                                                alt="checkBoxActiveSquare"
                                                                className="size-icon-20px cursor-pointer"
                                                                onClick={() => this.handleSetIsIndividually(idx)}
                                                            />
                                                            <span className="font-size-16px ml-2 cursor-pointer"
                                                                onClick={() => this.handleSetIsIndividually(idx)}
                                                            >
                                                                ส่งที่เดียวเท่านั้น
                                                            </span>
                                                        </div>

                                                    </div>
                                                </div>
                                            </>
                                            : null
                                    }
                                </React.Fragment>
                            )
                        }
                    </div>
                }

                <div className="row mt-4">
                    <div className="form-group w-100">
                        <label className="font-bold-size-16px">
                            ชื่อผู้รับ
                             </label>
                        <input
                            name="name"
                            type="text"
                            className="form-control input-background-color"
                            onChange={(evt) => this.handleChangeName_Mobile(evt, idx)}
                            value={createUser && createUser.name}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="form-group w-100">
                        <label className="font-bold-size-16px">
                            เบอร์โทรผู้รับ
                                </label>
                        <input
                            name="mobile"
                            type="text"
                            className="form-control input-background-color"
                            onChange={(evt) => this.handleChangeName_Mobile(evt, idx)}
                            value={createUser && createUser.mobile}
                        />
                    </div>
                </div>

                <div className="row">
                    <div className="form-group w-100">
                        <textarea
                            className="form-control input-background-color"
                            name="message"
                            value={updateInvoiceById && updateInvoiceById.message}
                            onChange={(evt) => this.handleChangeMessage(evt, idx)}
                            rows="3"
                            placeholder="ข้อความเพิ่มเติม"></textarea>
                    </div>
                </div>


                {createOrder.orderShop[idx].isDefaultContactCustomer && createOrder.orderShop[0].isAddressOnChange ? "" : <div>
                    {updateInvoiceById && !updateInvoiceById.priceSpecialDiscount ?
                        <div className="row mt-2">
                            <div className="d-flex align-items-center cursor-pointer" onClick={() => this.handleClickAddSpecialDisCount(idx)}>
                                <img src={IconAddPromoCode} alt="IconAddPromoCode" className="size-icon-20px" />
                                <span className="font-primary-16px-text ml-3">
                                    เพิ่มโค้ดส่วนลดฉุกเฉิน
                                 </span>
                            </div>
                        </div>
                        : null}

                    {updateInvoiceById && updateInvoiceById.priceSpecialDiscount
                        ? <div className="box-discount row">
                            <div className="mt-3 w-100">
                                <div className="icon-delete-product">
                                    <img src={IconDeleteProduct}
                                        alt="IconClose"
                                        className="size-icon-20px cursor-pointer"
                                        onClick={() => this.handleClickRemoveSpecialDisCount(idx)}
                                    />
                                </div>
                                <div className="col">
                                    <div className="form-group">
                                        <div className="d-flex justify-content-between">
                                            <label className="font-size-16px">รหัสยืนยันตัวตน</label>
                                            {
                                                updateInvoiceById.priceSpecialDiscount.code.trim().length !== 0 && this.state.isOnBlurCode && errors.specialDiscount.code ?
                                                    <label className="font-size-16px font-color-error">ไม่ถูกต้อง</label>
                                                    : null
                                            }
                                        </div>
                                        {
                                            updateInvoiceById.priceSpecialDiscount.code.trim().length !== 0 && this.state.isOnBlurCode ? (errors.specialDiscount.code
                                                ?
                                                <img src={IconCodeError}
                                                    alt="IconClose"
                                                    className="size-icon-20px icon-status-code"
                                                />
                                                : <img src={IconCodeSuccess}
                                                    alt="IconClose"
                                                    className="size-icon-20px icon-status-code"
                                                />) : null
                                        }

                                        <input type="text"
                                            name="code"
                                            className={`form-control ${updateInvoiceById.priceSpecialDiscount.code.trim().length !== 0 && errors.specialDiscount.code ? "border-color-error" : ""}`}
                                            value={updateInvoiceById.priceSpecialDiscount.code}
                                            onBlur={() => this.checkSpecialCode(updateInvoiceById.priceSpecialDiscount.code, idx)}
                                            onChange={(evt) => this.handleChangPriceSpecilaDiscount(evt, idx)}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label className="font-size-16px">ส่วนลดค่าอาหาร</label>
                                        <input
                                            type="number"
                                            name="foodDiscount"
                                            className="form-control"
                                            value={updateInvoiceById.priceSpecialDiscount.foodDiscount}
                                            onChange={(evt) => this.handleChangPriceSpecilaDiscount(evt, idx)}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label className="font-size-16px">ส่วนลดค่าขนส่ง</label>
                                        <input
                                            type="number"
                                            name="shippingDiscount"
                                            className="form-control"
                                            value={updateInvoiceById.priceSpecialDiscount.shippingDiscount}
                                            onChange={(evt) => this.handleChangPriceSpecilaDiscount(evt, idx)}
                                            disabled={this.props.createOrder.orderShop[idx].deliveryOption === "CPIU" ? true : false}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label className="font-size-16px">รายละเอียด</label>
                                        <textarea
                                            className="form-control input-background-color"
                                            name="note"
                                            rows="3"
                                            value={updateInvoiceById.priceSpecialDiscount.note}
                                            onChange={(evt) => this.handleChangPriceSpecilaDiscount(evt, idx)}
                                        ></textarea>
                                    </div>
                                </div>
                            </div>
                        </div> : ""}

                    <div className="row mt-3">
                        <div className="box-summary-order">
                            <div className="box-header">
                                <label className="font-bold-primary-size-20px">
                                    สรุปราคาของรอบส่งนี้
                            </label>
                            </div>
                            <div className="box-body mt-2">
                                {Object.keys(listMenuSelect).map((key) => (

                                    <div className="row" key={key}>
                                        <span className="font-size-16px width-33per word-break-word">
                                            {listMenuSelect[key].product && (listMenuSelect[key].product.name.th || listMenuSelect[key].product.name.en)}
                                        </span>
                                        <span className="font-size-16px width-33per text-center">
                                            x{listMenuSelect[key].quantity}</span>
                                        <span className="font-size-16px width-33per text-right">
                                            {utils.numberFormatDecimalTwoPoint(listMenuSelect[key].product && listMenuSelect[key].product.dataProduct[0].priceNet * listMenuSelect[key].quantity)}.-</span>
                                    </div>
                                ))}

                                <div className="row line-hr mt-2" />
                                <div className="row justify-content-between mt-2">
                                    <span className="font-size-16px">
                                        ค่าอาหาร
                                </span>
                                    <span className="font-size-16px">
                                        {utils.numberFormatDecimalTwoPoint(this.showPriceFoodDiscount(listMenuSelect && listMenuSelect))}.-
                                </span>
                                </div>
                                <div className="row justify-content-between">
                                    <span className="font-size-16px">
                                        ค่าจัดส่ง
                                </span>
                                    <span className="font-size-16px">
                                        {utils.numberFormatDecimalTwoPoint(this.showPriceDelivery(item, orderShop))}.-
                                </span>
                                </div>

                                <div className="row line-hr mt-2" />
                                <div className="row justify-content-between">
                                    <span className="font-bold-size-20px">
                                        รวม
                                </span>
                                    <span className="font-bold-primary-size-20px">
                                        {utils.numberFormatDecimalTwoPoint(this.showPriceDiscountAll(listMenuSelect, item, orderShop))}.-
                                </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>}


                <ModalAddress
                    modalAddress={this.state.modalAddress}
                    closeModal={this.closeModalAddress}
                />


            </div>
        )
    }
}

const mapStateToProps = state => ({
    listLogistic: state.logistics.listLogistic,
    createOrder: state.createOrder
});

const mapDisPatchToProps = dispatch => ({
    removeTab: (idx) => dispatch(actions_createOrder.removeTab(idx)),
    handleChangeAddress_AddressExtra: ({ idx, name, value }) => dispatch(actions_createOrder.handleChangeAddress_AddressExtra({ idx, name, value })),
    handleChangeName_Mobile: ({ idx, name, value }) => dispatch(actions_createOrder.handleChangeName_Mobile({ idx, name, value })),
    handleSetDeliveryOption: ({ itemLogistic, idx }) => dispatch(actions_createOrder.handleSetDeliveryOption({ itemLogistic, idx })),
    getLatLngByAddress: ({ address, idx }) => dispatch(actions_createOrder.getLatLngByAddress({ address, idx })),
    setLatLng: ({ lat, lng, idx }) => dispatch(actions_createOrder.setLatLng({ lat, lng, idx })),
    handleSetIsBox: ({ idx }) => dispatch(actions_createOrder.handleSetIsBox({ idx })),
    handleSetIsIndividually: ({ idx }) => dispatch(actions_createOrder.handleSetIsIndividually({ idx })),
    handleSetContactShop: ({ contactShop, idx }) => dispatch(actions_createOrder.handleSetContactShop({ contactShop, idx })),
    handleClickAddSpecialDisCount: ({ idx }) => dispatch(actions_createOrder.handleClickAddSpecialDisCount({ idx })),
    handleChangPriceSpecilaDiscount: ({ idx, name, value }) => dispatch(actions_createOrder.handleChangPriceSpecilaDiscount({ idx, name, value })),
    checkSpecialCode: ({ code, idx }) => dispatch(actions_createOrder.checkSpecialCode({ code, idx })),
    handleClickRemoveSpecialDisCount: ({ idx }) => dispatch(actions_createOrder.handleClickRemoveSpecialDisCount({ idx })),
    setIsDefaultContactCustomer: ({ idx }) => dispatch(actions_createOrder.setIsDefaultContactCustomer({ idx })),
    onSetQuantityMenu: ({ id, idx, date, userId, type }) => dispatch(actions_createOrder.onSetQuantityMenu({ id, idx, date, userId, type })),
    removeMenuOnList: ({ detailMenu, idx }) => dispatch(actions_createOrder.removeMenuOnList({ detailMenu, idx })),
    setTimeDeliveryAt: ({ date, idx }) => dispatch(actions_createOrder.setTimeDeliveryAt({ date, idx })),
    getListDistricts: ({ provinceId }) => dispatch(actions_address.getListDistricts({ provinceId })),
    getListSubDistricts: ({ districtId }) => dispatch(actions_address.getListSubDistricts({ districtId })),
    setAddressUser: ({ address, name, idx }) => dispatch(actions_createOrder.setAddressUser({ address, name, idx })),
    setZipcode: ({ zipcode, idx }) => dispatch(actions_createOrder.setZipcode({ zipcode, idx })),
    getListAddressUser: () => dispatch(actions_createOrder.getListAddressUser()),
    setSearch: ({ data, idx }) => dispatch(actions_createOrder.setSearch({ data, idx })),
    onSearch: ({ menu, date, time, shop }) => dispatch(actions_menus.onSearch({ menu, date, time, shop })),
    clearListDistrictAndSubDistricts: () => dispatch(actions_address.clearListDistrictAndSubDistricts()),
    setIsNotDefaultContactCustomer: ({ idx }) => dispatch(actions_createOrder.setIsNotDefaultContactCustomer({ idx })),
    getListMenu: ({ menu, date, time, shop }) => dispatch(actions_menus.getListMenu({ menu, date, time, shop })),
    openLoading: () => dispatch(actions_loading.openLoading()),
    closeLoading: () => dispatch(actions_loading.closeLoading()),
    setDeliveryPriceKerry: ({ shippingCost, idx }) => dispatch(actions_createOrder.setDeliveryPriceKerry({ shippingCost, idx })),
    handleChangeMessage: ({ message, idx }) => dispatch(actions_createOrder.handleChangeMessage({ message, idx })),
    setDeliveryNote: (payload) => dispatch(actions_createOrder.setDeliveryNote(payload)),
    setPriceSpecial: (price, idx) => dispatch(actions_createOrder.setPriceSpecial(price, idx))
});

export default connect(mapStateToProps, mapDisPatchToProps)(ContentTabUser);