import React from 'react';
import { Map, GoogleApiWrapper, Marker } from 'google-maps-react';
import api from '../../config/api'

class GoogleMap extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            lat: this.props.lat,
            lng: this.props.lng,
        }

        this.mapStyles = {
            width: '100%',
            height: '100%',
            zIndex: '100',
            position: 'relative'
        };
    }

    componentDidMount() {
        this.setState({
            lat: this.props.lat,
            lng: this.props.lng
        })
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            lat: nextProps.lat,
            lng: nextProps.lng,
        })
    }

    onClickMap = (mapProps, map, clickEvent) => {
        map.panTo(clickEvent.latLng)
        this.setState({
            lat: clickEvent.latLng.lat(),
            lng: clickEvent.latLng.lng()
        })
    }


    render() {

        return (
            <Map
                google={this.props.google}
                zoom={17}
                style={this.mapStyles}
                initialCenter={{ lat: this.props.lat, lng: this.props.lng }}
                // center={{ lat: this.props.lat, lng: this.props.lng }}
                onClick={this.props.show ? this.onClickMap : ""}
                draggable={this.props.show}
                scrollwheel={this.props.show}
                fullscreenControl={this.props.show}
                streetViewControl={false}
                mapTypeControl={false}
                >
                <Marker position={{ lat: this.state.lat, lng: this.state.lng }}/>
            </Map>
        )
    }
}

export default GoogleApiWrapper({
    apiKey: api.KEY_GOOGLE_MAP
})(GoogleMap);