import React, { useState } from 'react';
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import classnames from 'classnames';
import SuccessPayment from './SuceessPayment';
import WaitPayment from './WaitPayment';

const TabHistory = (props) => {
    const [activeTab, setActiveTab] = useState('wait_payment');

    const toggle = tab => {
        if (activeTab !== tab) setActiveTab(tab);
    }

    return (
      <div>
        <Nav tabs>
          <NavItem>
            <NavLink
              style={{
                color: activeTab === 'wait_payment' ? '#E1453C' : '#828282',
              }}
              className={classnames(
                { active: activeTab === 'wait_payment' },
                { 'tabinactive-history': activeTab !== 'wait_payment' },
              )}
              onClick={() => {
                toggle('wait_payment')
              }}
            >
              รอชำระเงิน
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              style={{
                color: activeTab === 'success_payment' ? '#E1453C' : '#828282',
              }}
              className={classnames(
                { active: activeTab === 'success_payment' },
                { 'tabinactive-history': activeTab !== 'success_payment' },
              )}
              onClick={() => {
                toggle('success_payment')
              }}
            >
              ชำระเงินแล้ว
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent activeTab={activeTab}>
          <TabPane tabId='wait_payment'>
            <WaitPayment activeTab={activeTab} {...props} />
          </TabPane>
          <TabPane tabId='success_payment'>
            <SuccessPayment activeTab={activeTab} {...props} />
          </TabPane>
        </TabContent>
      </div>
    )
}

export default TabHistory;