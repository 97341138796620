
export const Type = {
    GET_LIST_MENU_SEARCH: "GET_LIST_MENU_SEARCH",
    ADD_PRODUCT_PDF_REQUIRE: "ADD_PRODUCT_PDF_REQUIRE",
    ADD_PRODUCT_PDF: "ADD_PRODUCT_PDF",
    ADD_PRODUCT_ALL_REQUIRE: "ADD_PRODUCT_ALL_REQUIRE",
    ADD_PRODUCT_ALL: "ADD_PRODUCT_ALL_REQUIRE",
    CLEAR_PRODUCT_SELECTED: "CLEAR_PRODUCT_SELECTED",
    DOWNLOAD_PDF_REQUIRE : "DOWNLOAD_PDF_REQUIRE",
    DOWNLOAD_PDF: "DOWNLOAD_PDF",
    CLOSE_LOADING_BUTTON: "CLOSE_LOADING_BUTTON",
    OPEN_LOADING_BUTTON: "OPEN_LOADING_BUTTON"
}

export const saveListMenu = (payload) => ({
    type : Type.GET_LIST_MENU_SEARCH,
    payload
})

export const addProductPDFRequire = (payload) => ({
    type: Type.ADD_PRODUCT_PDF_REQUIRE,
    payload
})

export const addProductAllRequire = (payload) => ({
    type: Type.ADD_PRODUCT_ALL_REQUIRE,
    payload
})

export const clearProductSelected = (payload) => ({
    type: Type.CLEAR_PRODUCT_SELECTED,
    payload
})

export const getDownloadPDF = (payload) => ({
    type: Type.DOWNLOAD_PDF_REQUIRE,
    payload
})