import axios_saranros from '../lib/axios-saranros';
import api from '../config/api';

class PurchasesCtrl {

    requestPayUrl = async (params) => {
        try {
            const path = `${api.SARANROS_API}/api/Purchases/v3/requestPayUrl`
            const body = {
                userId: params.userId,
                invoiceId: params.invoiceId,
                methodPayment: params.methodPayment
            }

            const reqPayUrl = await axios_saranros.post(path, body)
            return reqPayUrl.data

        } catch (error) {
            return error
        }
    }

    confirmPayment = async (params) => {
        try {
            const path = `${api.SARANROS_API}/api/Purchases/v2/confirmPayment`
            const body = {
                purchaseId: params.purchaseId,
                userId: params.userId,
                transPayment: params.transPayment,
                priceNet: parseFloat(params.priceNet),
                note: params.note,
                media:
                    [{
                        deleted: false,
                        path: params.pathImg,
                        type: "image/png",
                        fileName: params.fileName,
                        originalFileName: params.originalFileName
                    }]
            }

            const confirm = await axios_saranros.patch(path, body)
            return confirm.data

        } catch (error) {
            return error
        }
    }

    getListPurchaseWaitApprove = async (params) => {
        return new Promise(async (resolve, reject) => {
            try {
                const { pagination, search } = params;
                const filter = {
                    include: [{
                        relation: 'invoice',
                    }],
                    where: {
                        "status": "confirm",
                        transid: { regexp: search.transid },
                    },
                    limit: pagination.pageSize,
                    skip: pagination.skip,
                    order: "transPayment ASC"
                };


                const where = {
                    "status": "confirm",
                    transid: { regexp: search.transid }
                };

                const url_count = "api/Purchases/count?where=" + JSON.stringify(where);
                const url = "api/Purchases?filter=" + JSON.stringify(filter);

                const result = await axios_saranros.get(url);
                const result_count = await axios_saranros.get(url_count);

                resolve({
                    list: result.data,
                    count: result_count.data.count
                });
            } catch (error) {
                reject(error);
            }
        });
    }


    getPurchaseById = (params) => {
        return new Promise(async (resolve, reject) => {
            try {
                const filter = {
                    include: [{
                        relation: 'invoice',
                        scope: {
                            include: [{
                                relation: "order",
                                scope: {
                                    where: {
                                        or: [{ orderStatus: "reserve" }, { orderStatus: "wait" }, { orderStatus: "finish" }]
                                    },
                                    include: [{
                                        relation: "product",
                                        scope: {
                                            include: ["menu"]
                                        }
                                    }]
                                }
                            }]
                        }
                    },
                    {
                        relation: "purchaseNote"
                    }
                    ]
                };
                const url = "api/Purchases/" + params.purchaseId + "?filter=" + JSON.stringify(filter);
                const result = await axios_saranros.get(url);
                resolve(result)
            } catch (error) {
                reject(error);
            }
        });
    }

    ListPurchasesByUserId = async (userId, status) => {
        try {
            const path = `${api.SARANROS_API}/api/Purchases?filter={"where":{"status": "${status}","userId":"${userId}"},"order":"transid ASC","include":{"relation":"invoice", "scope" :  {"include": { "relation": "order","scope":{"where": {"or": [{"orderStatus":"reserve"},{"orderStatus":"wait"},{"orderStatus":"finish"}]},"include": {"relation" : "product", "scope":  {"include": {"relation": "menu"}}}}}}}}`
            const listPurchases = await axios_saranros.get(path)
            return listPurchases.data

        } catch (error) {
            return error
        }
    }


    confirmPaymentInApprove = (params) => {
        return new Promise(async (resolve, reject) => {
            try {
                const url = "api/Purchases/v2/confirmPayment";
                const result = axios_saranros.patch(url, { ...params })
                resolve(result);
            } catch (error) {
                reject(error);
            }
        });
    }



    approvePayment = (params) => {
        return new Promise(async (resolve, reject) => {
            try {
                const url = "api/Purchases/v2/approvePayment";
                const result = axios_saranros.patch(url, { ...params })
                resolve(result);
            } catch (error) {
                reject(error);
            }
        });
    };




}




export default PurchasesCtrl;