export const skootar = {
    title: 'Skootar',
    code: 'SKOOTAR'
};

export const lalamove = {
    title: 'Lalamove',
    code: 'LLM'
};

export const kerry = {
    title: 'Kerry',
    code: 'KERRY'
};

export const cpiu = {
    title: 'cpiu',
    code: 'CPIU'
};