export const Type = {
    ADD_TAB_REQUIRE: "ADD_TAB_REQUIRE",
    ADD_TAB_SUCCESS: "ADD_TAB_SUCCESS",
    REMOVE_TAB: "REMOVE_TAB",
    SET_ACTIVE_TAB_REQUIRE: "SET_ACTIVE_TAB_REQUIRE",
    SET_ACTIVE_TAB_SUCCESS: "SET_ACTIVE_TAB_SUCCESS",
    SET_ADDRESS_AND_ADDRESSEXTRA: "SET_ADDRESS_AND_ADDRESSEXTRA",
    SET_NAME_AND_MOBILE: "SET_NAME_AND_MOBILE",
    SET_DELIVERY_OPTION_REQUEST: "SET_DELIVERY_OPTION_REQUEST",
    SET_DELIVERY_OPTION_SUCCESS: "SET_DELIVERY_OPTION_SUCCESS",
    ADD_MENU_TO_CART_SUCCESS: "ADD_MENU_TO_CART_SUCCESS",
    ADD_MENU_TO_CART_REQUIRE: "ADD_MENU_TO_CART_REQUIRE",
    REMOVE_MENU_FROM_CART_REQUIRE: "REMOVE_MENU_FROM_CART_REQUIRE",
    REMOVE_MENU_FROM_CART_SUCCESS: "REMOVE_MENU_FROM_CART_SUCCESS",
    GET_LAT_LNG_BY_ADDRESS_REQUIRE: "GET_LAT_LNG_BY_ADDRESS_REQUIRE",
    GET_LAT_LNG_BY_ADDRESS_SUCCESS: "GET_LAT_LNG_BY_ADDRESS_SUCCESS",
    SET_LAT_LNG_REQUIRE: "SET_LAT_LNG_REQUIRE",
    SET_LAT_LNG_SUCCESS: "SET_LAT_LNG_SUCCESS",
    SET_IS_BOX: "SET_IS_BOX",
    SET_CONTACT_SHOP_REQUIRE: "SET_CONTACT_SHOP_REQUIRE",
    SET_CONTACT_SHOP_SUCCESS: "SET_CONTACT_SHOP_SUCCESS",
    ADD_SPECIAL_DISCOUNT: "ADD_SPECIAL_DISCOUNT",
    REMOVE_SPECIAL_DISCOUNT: "REMOVE_SPECIAL_DISCOUNT",
    REMOVE_DATA_WHEN_NONE_SHOP: "REMOVE_DATA_WHEN_NONE_SHOP",
    SET_PRICE_SPECIAL_DISCOUNT: "SET_PRICE_SPECIAL_DISCOUNT",
    CHECK_SPECIAL_CODE_REQUIRE: "CHECK_SPECIAL_CODE_REQUIRE",
    CHECK_SPECIAL_CODE_SUCCCESS: "CHECK_SPECIAL_CODE_SUCCCESS",
    SET_SHIPING_COST: "SET_SHIPING_COST",
    ADD_MENU_OF_SEARCH: "ADD_MENU_OF_SEARCH",
    ADD_SHOP_OF_SEARCH: "ADD_SHOP_OF_SEARCH",
    SET_ERROR_ADDRESS_OUT_OF_AREA: "SET_ERROR_ADDRESS_OUT_OF_AREA",
    SET_IS_CONTACT_CUSTOMER_DEFAULT: "SET_IS_CONTACT_CUSTOMER_DEFAULT",
    SET_IS_CONTACT_CUSTOMER_NOT_DEFAULT: " SET_IS_CONTACT_CUSTOMER_NOT_DEFAULT",
    CHECK_LIST_DATE_TIME_REQUIRE: "CHECK_LIST_DATE_TIME_REQUIRE",
    CHECK_LIST_DATE_TIME_SUCCESS: "CHECK_LIST_DATE_TIME_SUCCESS",
    CHECK_LIST_DATE_TIME_DEFAULT: "CHECK_LIST_DATE_TIME_DEFAULT",
    ON_SET_QUANTITY_MENU: "ON_SET_QUANTITY_MENU",
    ON_ADD_QUANTITY_MENU_SUCCESS: "ON_ADD_QUANTITY_MENU_SUCCESS",
    ON_REDUCE_QUANTITY_MENU_SUCCESS: "ON_REDUCE_QUANTITY_MENU_SUCCESS",
    REMOVE_MENU_ON_LIST: "REMOVE_MENU_ON_LIST",
    SET_TIME_DELIVERY: "SET_TIME_DELIVERY",
    SET_TIME_DELIVERY_SUCCESS: "SET_TIME_DELIVERY_SUCCESS",
    CONFIRM_SEND_DATA_REQUIRE: "CONFIRM_SEND_DATA_REQUIRE",
    CONFIRM_SEND_DATA_SUCCESS: "CONFIRM_SEND_DATA_SUCCESS",
    SET_DATA_NEW_USER_REQUIRE: "SET_DATA_NEW_USER_REQUIRE",
    SET_DATA_NEW_USER_SUCCESS: "SET_DATA_NEW_USER_SUCCESS",
    SET_MENU_CREATE_ORDER: "SET_MENU_CREATE_ORDER",
    SET_TIME_DELIVERY_AT: "SET_TIME_DELIVERY_AT",
    SET_TIME_DELIVERY_AT_SUCCESS: "SET_TIME_DELIVERY_AT_SUCCESS",
    DELETE_ORDER_MULTI_REQIURE: "DELETE_ORDER_MULTI_REQIURE",
    CLEAR_DATA_ORDER_DEFAULT: "CLEAR_DATA_ORDER_DEFAULT",
    CHECK_MENU_DATE_DELIVERY: "CHECK_MENU_DATE_DELIVERY",
    CHECK_MENU_DATE_DELIVERY_SUCCESS: "CHECK_MENU_DATE_DELIVERY_SUCCESS",
    REMOVE_DELIVERY_AT_DATE_DELIVERY: "REMOVE_DELIVERY_AT_DATE_DELIVERY",
    REPLACE_LIST_STOCK_BY_DATE_TIME: "REPLACE_LIST_STOCK_BY_DATE_TIME",
    SET_ZIPCODE: "SET_ZIPCODE",
    GET_LIST_ADDRESS_USER_REQUES: "GET_LIST_ADDRESS_USER_REQUES",
    GET_LIST_ADDRESS_USER_SUCCESS: "GET_LIST_ADDRESS_USER_SUCCESS",
    SET_QUANTITY_DEFAULT: "SET_QUANTITY_DEFAULT",
    SET_SEARCH_DATA: "SET_SEARCH_DATA",
    SET_ADDRESS_USER_REQUEST: "SET_ADDRESS_USER_REQUEST",
    SET_ADDRESS_USER_SUCCESS: "SET_ADDRESS_USER_SUCCESS",
    DELETE_ADDRESS_USER_REQUEST: "DELETE_ADDRESS_USER_REQUEST",
    DELETE_ADDRESS_USER_SUCCESS: "DELETE_ADDRESS_USER_SUCCESS",
    NO_DATE_DELIVERY: "NO_DATE_DELIVERY",
    SELECT_LIST_OPTION_ADDRESS_USER_REQUEST: "SELECT_LIST_OPTION_ADDRESS_USER_REQUEST",
    SELECT_LIST_OPTION_ADDRESS_USER_SUCCESS: "SELECT_LIST_OPTION_ADDRESS_USER_SUCCESS",
    SET_LOADDING_CREATE_ORDER: "SET_LOADDING_CREATE_ORDER",
    CLEAR_SEARCH_MENU: "CLEAR_SEARCH_MENU",
    SET_LOADDING_CONFIRM_SELECT_ADDRESS_USER: "SET_LOADDING_CONFIRM_SELECT_ADDRESS_USER",
    COPY_DEFAULT_ADDRESS: "COPY_DEFAULT_ADDRESS",
    SET_IS_BOX_DEFAULT: "SET_IS_BOX_DEFAULT",
    SET_IS_INDIVIDUALLY: "SET_IS_INDIVIDUALLY",
    SET_IS_INDIVIDUALLY_DEFAULT: "SET_IS_INDIVIDUALLY_DEFAULT",
    SET_MESSAGE: "SET_MESSAGE",
    SET_SEND_MESSAGE: "SET_SEND_MESSAGE",
    SELECT_SEARCH_SHOP: "SELECT_SEARCH_SHOP",
    SELECT_SEARCH_MENU: "SELECT_SEARCH_MENU",
    SET_DELIVERY_NOTE: "SET_DELIVERY_NOTE",
    SET_LIST_LOGISTIC_SUCCESS: "SET_LIST_LOGISTIC_SUCCESS",
    SET_PRICE_SPECIAL: "SET_PRICE_SPECIAL",
    SET_DEFAULT_DATA: "SET_DEFAULT_DATA",
    SET_SEND_LOCATION_REQUIRE: "SET_SEND_LOCATION_REQUIRE",
    SET_SEND_LOCATION_SUCCESS: "SET_SEND_LOCATION_SUCCESS"
};


/// tab ///
export const addTab = () => ({
    type: Type.ADD_TAB_REQUIRE
});

export const removeTab = (idx) => ({
    type: Type.REMOVE_TAB,
    payload: { idx }
});

export const setActiveTab = (idx) => ({
    type: Type.SET_ACTIVE_TAB_REQUIRE,
    payload: { idx }
})
/// tab ///


/// address ///
export const handleChangeAddress_AddressExtra = (payload) => ({
    type: Type.SET_ADDRESS_AND_ADDRESSEXTRA,
    payload
});

export const getLatLngByAddress = (payload) => ({
    type: Type.GET_LAT_LNG_BY_ADDRESS_REQUIRE,
    payload
});

export const setLatLng = (payload) => ({
    type: Type.SET_LAT_LNG_REQUIRE,
    payload
})

/// address ///

/// contact ///
export const handleChangeName_Mobile = (payload) => ({
    type: Type.SET_NAME_AND_MOBILE,
    payload
});
/// contact ///


/// delivery ///
export const handleSetDeliveryOption = (payload) => ({
    type: Type.SET_DELIVERY_OPTION_REQUEST,
    payload
});

export const handleSetIsBox = (payload) => ({
    
    type: Type.SET_IS_BOX,
    payload
});

export const handleSetIsIndividually = (payload) => ({
    type: Type.SET_IS_INDIVIDUALLY,
    payload
});
/// delivery ///


////cart////
export const addMenuToCart = (payload) => ({
    type: Type.ADD_MENU_TO_CART_REQUIRE,
    payload
});

export const removeMenuFromCart = (payload) => ({
    type: Type.REMOVE_MENU_FROM_CART_REQUIRE,
    payload
})
////cart////

export const handleSetContactShop = (payload) => ({
    type: Type.SET_CONTACT_SHOP_REQUIRE,
    payload
});

export const handleClickAddSpecialDisCount = (payload) => ({
    type: Type.ADD_SPECIAL_DISCOUNT,
    payload
});

export const handleClickRemoveSpecialDisCount = (payload) => ({
    type: Type.REMOVE_SPECIAL_DISCOUNT,
    payload
});

export const removeDataWhenNoneShop = (payload) => ({
    type: Type.REMOVE_DATA_WHEN_NONE_SHOP,
    payload
});

export const handleChangPriceSpecilaDiscount = (payload) => ({
    type: Type.SET_PRICE_SPECIAL_DISCOUNT,
    payload
});

export const checkSpecialCode = (payload) => ({
    type: Type.CHECK_SPECIAL_CODE_REQUIRE,
    payload
});


export const addMenuOfSearch = (payload) => ({
    type: Type.ADD_MENU_OF_SEARCH,
    payload
})

export const addShopOfSearch = (payload) => ({
    type: Type.ADD_SHOP_OF_SEARCH,
    payload
})


export const setIsDefaultContactCustomer = (payload) => ({
    type: Type.SET_IS_CONTACT_CUSTOMER_DEFAULT,
    payload
});

export const setIsNotDefaultContactCustomer = (payload) => ({
    type: Type.SET_IS_CONTACT_CUSTOMER_NOT_DEFAULT,
    payload
});

export const checkListDateTime = (payload) => ({
    type: Type.CHECK_LIST_DATE_TIME_REQUIRE,
    payload
});

export const onSetQuantityMenu = (payload) => ({
    type: Type.ON_SET_QUANTITY_MENU,
    payload
})

export const removeMenuOnList = (payload) => ({
    type: Type.REMOVE_MENU_ON_LIST,
    payload
})

export const setTimeDeliveryAt = (payload) => ({
    type: Type.SET_TIME_DELIVERY_AT,
    payload
})
export const handleClickConfirmSendData = (payload) => ({
    type: Type.CONFIRM_SEND_DATA_REQUIRE,
    payload
});

export const setDataNewUser = (payload) => ({
    type: Type.SET_DATA_NEW_USER_REQUIRE,
    payload
});

export const checkMenuDateDelivery = (payload) => ({
    type: Type.CHECK_MENU_DATE_DELIVERY,
    payload
});

export const setAddressUser = (payload) => ({
    type: Type.SELECT_LIST_OPTION_ADDRESS_USER_REQUEST,
    payload
});

export const setZipcode = (payload) => ({
    type: Type.SET_ZIPCODE,
    payload
});

export const getListAddressUser = () => ({
    type: Type.GET_LIST_ADDRESS_USER_REQUES
});

export const setSearch = (payload) => ({
    type: Type.SET_SEARCH_DATA,
    payload
});


export const confirmSetAddressUser = (payload) => ({
    type: Type.SET_ADDRESS_USER_REQUEST,
    payload
});

export const deleteAddressUser = (payload) => ({
    type: Type.DELETE_ADDRESS_USER_REQUEST,
    payload
});

export const setLoaddingCreateOrrder = () => ({
    type: Type.SET_LOADDING_CREATE_ORDER
});

export const onClearSearch = (payload) => ({
    type: Type.CLEAR_SEARCH_MENU,
    payload
});

export const setLoaddingConfirmSelectAddressUser = () => ({
    type: Type.SET_LOADDING_CONFIRM_SELECT_ADDRESS_USER
});

export const setDeliveryPriceKerry = (payload) => ({
    type: Type.SET_SHIPING_COST,
    payload
})

export const handleChangeMessage = (payload) => ({
    type: Type.SET_MESSAGE,
    payload
})

export const handleClickSendMsg = () => ({
    type: Type.SET_SEND_MESSAGE,
})

export const handleSelectSearchShop = (payload) => ({
    type: Type.SELECT_SEARCH_SHOP,
    payload
})

export const handleSelectSearchMenu = (payload) => ({
    type: Type.SELECT_SEARCH_MENU,
    payload
})

export const setDeliveryNote = (payload) => ({
    type: Type.SET_DELIVERY_NOTE,
    payload
})

export const setPriceSpecial = (price,idx) => ({
    type: Type.SET_PRICE_SPECIAL,
    payload : {
        priceSpecial : price,
        idx
    }
})


export const setSendLocation = (data) => ({
    type: Type.SET_SEND_LOCATION_REQUIRE,
    payload: {
        data
    }
})

export const setSendLocationSuccess = () => ({
    type: Type.SET_SEND_LOCATION_SUCCESS,
})