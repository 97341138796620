import React, { Component } from 'react';
import { db } from '../../config/firebase'
import { InputGroup, InputGroupAddon, Button, Input, FormGroup } from 'reactstrap';
import '../../styles/chat/listUserChat.scss'
import ModalCustomer from './ModalCustomer'
import socketIOClient from 'socket.io-client'
import axios from 'axios'
import api from '../../config/api'
import { changeFormatTime, changeFormatDate } from '../../utils/utils'



class ListUserChat extends Component {
    constructor(props) {
        super(props)
        this.state = {
            endpoint: api.SOCKET_API,
            listUserData: [],
            msgSearch: "",
            isShowModalCreate: false,
            filterType: "all",
            defaultListUserData: [],
        }

        this.unlistAll = undefined
        this.unlistSearch = undefined
    }

    componentDidMount() {
        this.list()
    }

    keyPressed = (e) => {
        if (e.key === "Enter") {
            this.searchUserByName()
        }
    }

    list = async () => {
        const { endpoint } = this.state
        const user = await axios({
            method: "GET",
            url: `${endpoint}/user`
        })

        const userList = user.data

        this.props.setDataUser(userList)

        this.setState({ listUserData: userList, defaultListUserData: userList })

        this.onSocketUser()
    }

    onSocketUser = () => {
        try {
            const { endpoint } = this.state

            const socketUser = socketIOClient(endpoint)

            this.unlistAll = socketUser.on('user-list', async (list) => {
                let dataUser = list

                if(this.state.filterType !== 'all'){
                    dataUser = []
                    await Promise.all(
                        await list.map(user => {
                            if(user.platform == this.state.filterType){
                                dataUser.push(user)
                            }
                        })
                    )
                }

                this.props.setDataUser(dataUser)
                this.setState({ listUserData: dataUser, defaultListUserData: list })
            })
        } catch (error) {
            console.log('errorOnSocketUSER', error)
        }

    }

    onChangeFilterUserPlatform = async(platform) => {
        this.setState({ filterType: platform })

        let dataUser = this.state.defaultListUserData

        if(platform !== 'all'){
            dataUser = []
            await Promise.all(
                await this.state.defaultListUserData.map(user => {
                    if(user.platform == platform){
                        dataUser.push(user)
                    }
                })
            )
        }

        this.props.setDataUser(dataUser)
        this.props.disableChat()
        this.setState({ listUserData: dataUser })
    }


    onClickToChat = async (doc) => {
         this.props.setPlatform(doc.platform)
         this.props.setIdChat(doc.userId)
         this.props.setReadChat(doc.read)
         this.props.setPin(doc.pin)
         this.props.setAnnounce(doc.announce)
         this.props.setAnnounceMsg(doc.announceMsg)

         axios({
            method: "PATCH",
            url: `${this.state.endpoint}/user`,
            data: {
                userId: doc.userId,
                read: true,
            }
        })
    }

    searchUserByName = async () => {
        this.setState({
            filterType: "all"
        })
        this.props.disableChat()
        if (this.state.msgSearch == 0) {
            // this.unlistSearch.disconnect()
            this.list()
        } else {
            const { endpoint } = this.state
            this.unlistAll.disconnect()

            const userSearch = await axios({
                method: "GET",
                url: `${endpoint}/user/search/${this.state.msgSearch}`
            })

            this.setState({
                listUserData: userSearch.data
            })
            this.props.setDataUser(userSearch.data)
        }
    }

    showModalCreate = () => {
        this.setState({
            isShowModalCreate: true
        })
    }

    closeModalCreate = () => {
        this.setState({
            isShowModalCreate: false
        })
    }


    render() {
        return (
          <div className='wrap-customer'>
            <div className='haeder-user-chat'>
              <div className='haeder-cus'>
                <h2 className='text-left haeder'>Seller</h2>
                {/* <button className="btn add-cus" onClick={this.showModalCreate}><i className="icon-add" />เพิ่มลูกค้าจากโทรศัพท์</button> */}
              </div>

              <div className='haeder-search'>
                <InputGroup>
                  <InputGroupAddon addonType='prepend'>
                    <button onClick={this.searchUserByName} className='btn'>
                      <i className='icon-search' />
                    </button>
                  </InputGroupAddon>
                  <Input
                    value={this.state.msgSearch}
                    onChange={(e) =>
                      this.setState({ msgSearch: e.target.value })
                    }
                    placeholder='ค้นหา ชื่อ, เบอร์โทร'
                    onKeyPress={this.keyPressed}
                  />
                  <InputGroupAddon addonType='prepend'>
                    {this.state.msgSearch.length > 0 ? (
                      <button className='btn btn-close'>
                        <i
                          className='icon-close'
                          onClick={() => this.setState({ msgSearch: '' })}
                        />
                      </button>
                    ) : null}
                  </InputGroupAddon>
                </InputGroup>
              </div>

              {/* <div className="check-social">
                        <FormGroup>
                            <Input id="all"
                                className="radio-custom-modal"
                                type="radio"
                                name="social"
                                checked={this.state.filterType == "all"}
                                onChange={() => this.onChangeFilterUserPlatform('all')}
                            />
                            <label htmlFor={"all"} className="radio-custom-modal-label">
                                <span>ทั้งหมด</span>
                            </label>
                        </FormGroup>
                      
                        <FormGroup>
                            <Input id="line"
                                className="radio-custom-modal"
                                type="radio"
                                name="social"
                                checked={this.state.filterType == "line"}
                                onChange={() => this.onChangeFilterUserPlatform('line')}
                            />
                            <label htmlFor={"line"} className="radio-custom-modal-label">
                                <span>LINE</span>
                            </label>
                        </FormGroup>

                        <FormGroup>
                            <Input id="facebook"
                                className="radio-custom-modal"
                                type="radio"
                                name="social"
                                checked={this.state.filterType == "facebook"}
                                onChange={() => this.onChangeFilterUserPlatform('facebook')}
                            />
                            <label htmlFor={"facebook"} className="radio-custom-modal-label">
                                <span>facebook</span>
                            </label>
                        </FormGroup>

                          <FormGroup>
                            <Input id="mobile"
                                className="radio-custom-modal"
                                type="radio"
                                name="social"
                                checked={this.state.filterType == "mobile"}
                                onChange={() => this.onChangeFilterUserPlatform('mobile')}
                            />
                            <label htmlFor={"mobile"} className="radio-custom-modal-label">
                                <span>เบอร์โทร</span>
                            </label>
                        </FormGroup>
                    </div> */}
            </div>

            <div className='wrap-box-user'>
              {this.state.listUserData.map((cust, index) => (
                <div
                  key={cust._id}
                  className={
                    cust.read
                      ? this.props.idSelected == cust.userId
                        ? ' box-customer active-box'
                        : 'box-customer'
                      : this.props.idSelected == cust.userId
                      ? 'box-customer-noti active-box'
                      : 'box-customer-noti'
                  }
                  onClick={() => this.onClickToChat(cust)}
                >
                  <div className='right-item'>
                    <div className='box-img'>
                      <img
                        src={
                          cust.urlImg ||
                          'https://thumb.izcene.com/saranros/image/ab8fd127-0b30-43f7-b8e3-0af4aee56f56.png'
                        }
                      />
                      <div
                        className={
                          cust.platform == 'line'
                            ? 'social line'
                            : cust.platform == 'facebook'
                            ? 'social facebook'
                            : 'social phone'
                        }
                      >
                        <i
                          className={
                            cust.platform == 'line'
                              ? 'icon-line'
                              : cust.platform == 'facebook'
                              ? 'icon-facebook'
                              : 'icon-phone'
                          }
                        />
                      </div>
                    </div>
                    <div className='detail'>
                      <label>{cust.name || cust.nickName}</label>
                      <label>{cust.mobile || 'ยังไม่บันทึกเบอร์'}</label>
                      <label className='msg'>
                        {cust.lastText ? cust.lastText : ''}
                      </label>
                    </div>
                  </div>
                  <div className='left-item'>
                    <div className='left-item-bottom'>
                      <div className='text-time'>
                        {changeFormatDate(cust.updateAt)}
                      </div>
                      <div className='text-time'>
                        {changeFormatTime(cust.updateAt)}
                      </div>
                    </div>
                    <div className='left-item-top'>
                      {cust.pin ? (
                        <div className='follow-up'>
                          <i className='icon-pin' />
                          ปักหมุด
                        </div>
                      ) : null}
                      <div className={cust.read ? '' : 'noti-alert'} />
                    </div>
                  </div>
                </div>
              ))}
              <div className='cover-bar' />
            </div>
            <div></div>

            {/* <ModalCustomer
                    showModalCreate={this.state.isShowModalCreate}
                    closeModalCreate={this.closeModalCreate}
                    title="เพิ่มลูกค้าจากโทรศัพท์"
                /> */}
          </div>
        )
    }
}

export default ListUserChat