import React from 'react';
import { loginRequest } from '../redux/actions/users'
import { connect } from 'react-redux';
import CardMenu from "../components/pages/CreateOrder/CardMenu";
import TabUser from "../components/pages/CreateOrder/TabUser";
import { getListMenu, onSearch } from '../redux/actions/menus';
import * as actions_createOrder from '../redux/actions/createOrder';
import { getUserFromCode } from '../controllers/chatCtrl'
import moment from 'moment'
import * as actions_loading from '../redux/actions/loading';
import * as actions_users from '../redux/actions/users';
import history from '../history';
import Close from '../assets/Icons/Close.png'
import SelectComponent from '../components/Select'
import * as actions_Notification from '../redux/actions/notification';
import shopCtrl from '../controllers/shopCtrl'
import menuCtrl from '../controllers/menuCtrl'
const api_shopCtrl = new shopCtrl()
const api_menuCtrl = new menuCtrl()

class App extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            listMenu: [],
            optionListShop: [{
                label: 'ร้านค้าทั้งหมด',
                value: ''
            }],
            valueListShop: '',
            optionListMenu: [{
                label: 'เมนูทั้งหมด',
                value: ''
            }],
            valueListMenu: '',
            modalExtraData: {
                isOpen: false,
                data: null
            }
        }
    }

    async componentDidMount() {
        try {
            const url = new URL(window.location.href);
            const code = url.searchParams.get("idCode");
            this.props.getCode({ code })
            if (code) {
                this.props.openLoading();
                const user = await getUserFromCode(code);
                const awaitSetDataNewUser = await this.props.setDataNewUser({ user });
                this.props.getListMenu({ menu: "", shop: "", date: "", time: "" })
                this.props.closeLoading();
            } else {
                history.push('/');
            }

        } catch (error) {
            const res_error = error.response || null
            if (res_error && res_error.status === 404 && res_error.data === "NOT FOUND USER FROM CODE") {
                history.push('/');
                this.props.closeLoading();
                const notification = {
                    message: 'กำลังมีผู้สร้างออเดอร์นี้อยู่',
                    level: 'error',
                    autoDismiss: 0,
                    position: "tr"
                }
                this.props.openNotificationAlertComponent(notification);
            }
        }
        this.props.openLoading()
        ////set select option search
        this.setOptionListShop()
        this.setOptionListMenu()
        ///////////
        this.props.closeLoading()
    }

    async componentWillReceiveProps(nextProps) {
       
        const shopName = nextProps.createOrder.orderShop[nextProps.createOrder.activeTab] && nextProps.createOrder.orderShop[nextProps.createOrder.activeTab].search.shop
        
        if (nextProps.menus.listMenu !== this.state.listMenu) {
            this.setState({
                listMenu: nextProps.menus.listMenu
            })
        }
        if (nextProps.createOrder.orderShop.length > 1) {
              //get value shop
            const getValueShop = await this.state.optionListShop.filter((item) => item.label == shopName)
            const getMenu = await api_menuCtrl.getOptionMenuList(getValueShop && getValueShop[0] && getValueShop[0].value.id)
            
            const newMenu = [{
                label: 'เมนูทั้งหมด',
                value: ''
            }]
            getMenu.data.map((item,idx) => {
                newMenu.push({
                    label : item.name.th,
                    value: item
                })
            })         
            
            this.setState({
                valueListShop: [{
                    label: nextProps.createOrder.orderShop[nextProps.createOrder.activeTab].search.shop || 'ค้นหาร้านค้า',
                    value: ''
                }],
                valueListMenu: [{
                    label: nextProps.createOrder.orderShop[nextProps.createOrder.activeTab].search.menu || 'ค้นหาเมนู',
                    value: ''
                }],
                optionListMenu : newMenu
            })
        }
    }



    onSubmitSearch = async (e, tab) => {
        const search = this.props.createOrder.orderShop[tab].checkShippingCosts.deliveryAt[0].dateDelivery
        const setDateFormat = search && moment(search).format("YYYY-MM-DD") || ""
        const setTimeFormat = search && moment(search).format("HH:mm") || ""
        const body = {
            payload: {
                menu: e.target.value,
                time: setTimeFormat || "",
                date: setDateFormat || "",
            },
        }
        if (e.key === 'Enter') {
            e.preventDefault()
            await this.props.setSearch({ data: body.payload, idx: tab })
            this.props.openLoading();
            await this.props.onSearch(this.props.createOrder.orderShop[tab].search)
            this.props.closeLoading();
        } else if (e.key !== "Enter") {
            return ""
        }
    }

    onChangeSearch = async (e) => {
        const body = {
            payload: {
                menu: e.target.value,
            }
        }
        await this.props.addMenuOfSearch({ search: body.payload, idx: this.props.activeTab })
    }

    onClearSearch = (idx) => {
        const search = {
            ...this.props.createOrder.orderShop[idx].search,
            menu: '',
            // shop: ''
        }
        this.props.onClearSearch({ idx })
        this.props.getListMenu(search)
    }

    setOptionListShop = async () => {
        // const {optionListShop} = this.state

        try {
            const result = await api_shopCtrl.getShoplist()
            if (result.status === 200) {
                result.data.map((item) => {
                    this.state.optionListShop.push({
                        label: item.name,
                        value: item
                    })
                })
            }
        } catch (err) {
            console.log(err)
        }
    }

    setOptionListMenu = async () => {
        try {
            const { data } = await api_menuCtrl.getOptionMenuList()
            data.map((item) => {
                this.state.optionListMenu.push({
                    label: item.name && item.name.th,
                    value: item
                })
            })
        } catch (error) {
        }
    }

    onChangeListShop = async (valueListShop) => {
        this.props.openLoading();
        this.setState({
            // ...this.state,
            valueListShop,
            // valueListMenu: this.state.optionListMenu[0]
        })
        
        

        const payload = {
            shop: valueListShop.value && valueListShop.value.name,
            idx: this.props.activeTab
        }

        ///saga doing
        this.props.handleSelectSearchShop(payload)
        ///saga doing
        // this.props.addShopOfSearch({ search: payload, idx: this.props.activeTab })

        ////set data to listMenu
        const { data } = await api_menuCtrl.getOptionMenuList(valueListShop && valueListShop.value.id)

        const newMenu = [{
            label: 'ร้านค้าทั้งหมด',
            value: ''
        }]

        data.map((item) => {
            newMenu.push({
                label: item.name && item.name.th,
                value: item
            })
        })

        this.setState({
            optionListMenu: newMenu
        })
        ////set data to listMenu
        this.onClearSearch(this.props.activeTab)
        this.props.closeLoading();
    }

    onChangeListMenu = (valueListMenu) => {
        this.props.openLoading();
        
        this.setState({
            // ...this.state,
            valueListMenu,
        })

        const payload = {
            menu: valueListMenu.value.name && valueListMenu.value.name.th,
            idx: this.props.activeTab
        }
        this.props.addMenuOfSearch({ search: payload, idx: this.props.activeTab })
        this.props.handleSelectSearchMenu(payload)
        this.props.closeLoading();
    }

    setValueOption = (value) => {
        return [{ label: value, value }]
    }




    render() {
        const { createOrder } = this.props;
        
        return (
            <div className="container" id="create-order">
                <div className="row">
                    <div className="col-lg-6 col-md-6">
                        <div className="header-shop">
                            <label className="topic">
                                สร้างออเดอร์ของคุณ
                        </label>
                            <label className="name">
                                {createOrder.userChat.name || ''}
                            </label>
                        </div>
                        <form>
                            {/* <div className="input-group pb-15">
                                <input type="text" className="form-control input-search" aria-label="Username" aria-describedby="basic-addon1"
                                    onChange={(e) => this.onChangeSearch(e, this.props.createOrder.activeTab)}
                                    onKeyPress={(e, tab) => this.onSubmitSearch(e, this.props.createOrder.activeTab)}
                                    value={this.props.createOrder.orderShop[this.props.createOrder.activeTab].search.menu}
                                    placeholder="ค้นหาเมนู"
                                />
                                <div className="search-inside-input" onClick={() => this.onClearSearch(createOrder.activeTab)}><img src={Close} alt="Close" className="close-icon-search cursor-pointer" /></div>
                            </div> */}


                            <div className="row mb-4">
                                <div className="col-6">
                                    <SelectComponent
                                        placeholder="ค้นหาร้านค้า"
                                        options={this.state.optionListShop}
                                        onChange={this.onChangeListShop}
                                        value={this.state.valueListShop}
                                    // disabled={Object.keys(this.props.createOrder.orderShop[this.props.activeTab].listMenuSelect).length >= 1}
                                    />
                                </div>
                                <div className="col-6">
                                    <SelectComponent
                                        placeholder="ค้นหาเมนู"
                                        options={this.state.optionListMenu}
                                        onChange={this.onChangeListMenu}
                                        value={this.state.valueListMenu}
                                    />
                                </div>
                            </div>

                            {/* <div className="d-flex justify-content-between">
                            <button>ค้นหา</button>
                            </div> */}

                        </form>

                        <div id="group-shop" className="component-shop">
                            {this.state.listMenu && this.state.listMenu.length === 0 ? <div>ไม่พบเมนูที่ค้นหา</div> :
                                this.state.listMenu && this.state.listMenu.map((item) =>
                                    <div key={item.dataShop[0]._id} className="shop">
                                        <label className="header-menu-name"> {item.dataShop[0].name} </label>
                                        <div className="row">
                                            {item.data.map((menu) =>
                                                <CardMenu
                                                    key={menu._id}
                                                    detailMenu={menu}
                                                    mainData={item}
                                                    modal={this.state.modalExtraData}
                                                    openModal={this.openModalExtraData}
                                                    closeModal={this.closeModalExtraData}
                                                />
                                            )}

                                        </div>
                                    </div>
                                )}
                            <div className="cover-shop" />
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                        <TabUser />
                    </div>
                </div>
            </div>
        )
    }
}



const mapDispatchToProps = dispatch => ({
    loginRequest: ({ username, password }) => dispatch(loginRequest({ username, password })),
    getListMenu: ({ menu, shop, date, time }) => dispatch(getListMenu({ menu, shop, date, time })),
    onSearch: ({ menu, date, time, shop }) => dispatch(onSearch({ menu, date, time, shop })),
    addMenuOfSearch: ({ search, idx }) => dispatch(actions_createOrder.addMenuOfSearch({ search, idx })),
    addShopOfSearch: ({ search, idx }) => dispatch(actions_createOrder.addShopOfSearch({ search, idx })),
    setDataNewUser: ({ user }) => dispatch(actions_createOrder.setDataNewUser({ user })),
    setSearch: ({ data, idx }) => dispatch(actions_createOrder.setSearch({ data, idx })),
    openLoading: () => dispatch(actions_loading.openLoading()),
    onClearSearch: ({ idx }) => dispatch(actions_createOrder.onClearSearch({ idx })),
    getCode: ({ code }) => dispatch(actions_users.getCode({ code })),
    closeLoading: () => dispatch(actions_loading.closeLoading()),
    openNotificationAlertComponent: (payload) => dispatch(actions_Notification.openNotificationAlertComponent(payload)),
    handleSelectSearchShop: (payload) => dispatch(actions_createOrder.handleSelectSearchShop(payload)),
    handleSelectSearchMenu: (payload) => dispatch(actions_createOrder.handleSelectSearchMenu(payload))
})

const mapStateToProps = state => ({
    menus: state.menus,
    createOrder: state.createOrder,
    activeTab: state.createOrder.activeTab
})

export default connect(mapStateToProps, mapDispatchToProps)(App);

