/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
import * as Reactdom from 'react-dom';
import * as loadimage from 'blueimp-load-image';
class ImageViewer extends React.Component {

    static defaultProps = {
        imageUrl: null
    }

    constructor(props) {
        super(props)
        this.imageCanvas = null

    }

    componentWillReceiveProps(nextProps) {
        if(nextProps.imageUrl != this.props.imageUrl) {
            loadimage(nextProps.imageUrl, (img) => {
                img.className = 'fit-to-parent'; // css class: { max-width: 100%; max-height: 100%; }
                if (Reactdom.findDOMNode(this.imageCanvas) != null) {
                    Reactdom.findDOMNode(this.imageCanvas).appendChild(img);
                    const item = Reactdom.findDOMNode(this.imageCanvas).childNodes[0]
                    Reactdom.findDOMNode(this.imageCanvas).replaceChild(img,item)
                }
            }, { orientation: true })
        }
    }

    componentDidMount() {
        loadimage(this.props.imageUrl, (img) => {
            img.className = 'fit-to-parent'; // css class: { max-width: 100%; max-height: 100%; }
            if (Reactdom.findDOMNode(this.imageCanvas) != null) {
                Reactdom.findDOMNode(this.imageCanvas).appendChild(img);
            }
        }, { orientation: true })
    }

    render() {

        return (
            <div className="canvas-cover-orientation"
                ref={(ref) => this.imageCanvas = ref} />
        )
    }
}
export default ImageViewer