import { Type } from "../actions/menus";

const INITIAL_STATE = {
    listMenu : null
};

export default function menus(state = INITIAL_STATE, actions) {
    const { type } = actions;
    switch (type) {
        case (Type.GET_LIST_MENU_SUCCESS): {
            return {
                ...state,
                listMenu : actions.data
            }
        }

        case (Type.SEARCH_MENU_SUCCESS) : {
            return {
                ...state,
                listMenu : actions.data
            }
        }
            default : 
            return state
    }
}