import { all } from "redux-saga/effects";
import UserSagas from "./users";
import MenuSagas from './menus';
import CreateOrderSagas from './createOrder';
import LogisticSagas from './logistics';
import AddressSagas from './address';
import ExtraDataSagas from './extraData'


export default function* rootSaga() {
    yield all([UserSagas(), MenuSagas(), CreateOrderSagas(), LogisticSagas(), AddressSagas(),ExtraDataSagas()]);
}

