import React from 'react'

import Select from 'react-select'

const colourStyles = {
  option: (styles, { data, isDisabled, isFocused, isSelected , }) => {
    return {
      ...styles,
      backgroundColor: (isFocused || isFocused || isSelected) &&  '#FEF5F4',
      color: '#212529',
    }
  },
}


const SelectComponent = ({
  placeholder,
  options,
  onChange,
  value,
  getOptionLabel,
  getOptionValue,
  disabled,
}) => {
  return (
    <div id='select-option-component' className='w-100'>
      <Select
        options={options}
        placeholder={placeholder}
        onChange={onChange}
        value={value}
        getOptionLabel={getOptionLabel}
        getOptionValue={getOptionValue}
        isDisabled={disabled}
        styles={colourStyles}
      />
    </div>
  )
}

export default SelectComponent
