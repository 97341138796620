import React from 'react';
import { connect } from 'react-redux';


class LoadingComponent extends React.Component {
    render() {
        const { isOpen } = this.props;

        return (
            isOpen &&
            <div id="loading-component">
                <div className="wrap-loading">
                    <div className="wrap-icon-loading">

                        <div className="d-flex align-items-center justify-content-center">
                            <div className="loading-box">
                                <div className="">
                                    <div className="spinner-border text-primary" role="status" />
                                    <span className="loading-text">Loading...</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
};

const mapStateToPorps = state => ({
    isOpen: state.loading.isOpen
});
export default connect(mapStateToPorps, null)(LoadingComponent);