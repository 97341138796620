import React from 'react';
import NotificationSystem from 'react-notification-system';
// import IconNotification from '../assets/Icons/IconAlert.svg';
import { connect } from 'react-redux';

class Notification extends React.Component {
    _notificationSystem = null;

    componentWillReceiveProps(nextPorps) {
        if(nextPorps.noitficationAlert !== this.props.noitficationAlert){
            this._notificationSystem.addNotification({
               ...nextPorps.noitficationAlert
            });
        }

    }

    componentDidMount() {
        this._notificationSystem = this.refs.notificationSystem;
        // this._notificationSystem.addNotification({
        //     message: <div><img src={IconNotification} alt="IconNotification"></img><span className="ml-3">การจัดส่งที่ 1 ยังไม่ได้ระบุที่อยู่ในการจัดส่ง</span></div>,
        //     level: 'error',
        //     autoDismiss: 0,
        //     position: "tr"
        // });
    }

    render() {
        return (
            <div id="notification-component">
                <NotificationSystem ref="notificationSystem" />
            </div>
        )
    }
}

const mapStateToProps = state => ({
    noitficationAlert: state.notification.noitficationAlert
});

export default connect(mapStateToProps, null)(Notification);