/* eslint-disable no-useless-constructor */
import React from 'react';
import { Modal, ModalBody, ModalFooter, FormGroup, Input } from 'reactstrap';
import '../../styles/chat/modal.scss'
import { db, timeServer } from '../../config/firebase'
import { isEmptyOrSpaces } from '../../utils/utils'
import GoogleMap from './gooogleMap'
import Geocode from "react-geocode";
import api from '../../config/api' 
// import algoliasearch from "algoliasearch"
import SelectOption from "../Select";
import addressCtrl from '../../controllers/addressCtrl'
import axios from 'axios'
const api_addressCtrl = new addressCtrl();
const randomCode = require('crypto-random-string');


Geocode.setApiKey(api.KEY_GOOGLE_MAP);


class ModalCustomer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: this.props.showModalCreate,
      name: "",
      mobile: "",
      address: "",
      addressExtra: "",
      lat: "13.7563",
      lng: "100.5018",
      errName: true,
      errMobile: true,
      errZipCode: true,
      provinceList: [],
      districtsList: [],
      subDistrictsList: [],
      zipCode: "",
      province: "",
      district: "",
      subdistrict: "",
      createUserMobile: false,
    };
  }

  async componentDidMount(){
    const provinceArr = await api_addressCtrl.getListProvinces()
    this.setState({
      provinceList: provinceArr.data
    })
  }

  componentWillReceiveProps(nextProps){
    if(nextProps.dataUser){
      this.setState({
        address: nextProps.dataUser.address,
        addressExtra: nextProps.dataUser.ddressExtra,
        province: nextProps.dataUser.province,
        district: nextProps.dataUser.district,
        subdistrict: nextProps.dataUser.subdistrict,
        zipCode: nextProps.dataUser.zipCode,
      })

      this.onClickSearchLocation()
    }
  }

  toggleModalCreate = () => {
    this.setState({
      name: "",
      mobile: "",
      address: "",
    })

    this.props.closeModalCreate()
  }

  onClickSubmit = async () => {
    if (this.props.title === "ข้อมูลร้านค้า") {

      axios({
        method: "PATCH",
        url: `${api.SOCKET_API}/user`,
        data: {
          userId: this.props.id,
          nickName: this.state.name || this.props.dataUser.nickName || "",
          mobile: this.state.mobile || this.props.dataUser.mobile || "",
        }
      })

      this.props.dataUser.nickName = this.state.name || this.props.dataUser.nickName || ""
      this.props.dataUser.mobile = this.state.mobile || this.props.dataUser.mobile || ""

      this.props.updateDataUser()
      this.props.closeModalCreate()

      this.setState({
        name: "",
        mobile: "",
        address: "",
      })
    } else {
      if (isEmptyOrSpaces(this.state.name) || isEmptyOrSpaces(this.state.mobile)) {
        if(isEmptyOrSpaces(this.state.name)){
          this.setState({
            errName: false
          })
        }

        if(isEmptyOrSpaces(this.state.mobile)){
          this.setState({
            errMobile: false
          })
        }

      } else {

        this.setState({
          createUserMobile: true
        })
        const code = randomCode({ length: 16 });

        axios({
          method: "POST",
          url: `${api.SOCKET_API}/user`,
          data: {
            platform: "mobile",
            urlImg: "https://thumb.izcene.com/saranros/image/ab8fd127-0b30-43f7-b8e3-0af4aee56f56.png",
            nickName: this.state.name,
            name: this.state.name,
            mobile: this.state.mobile,
            userId: code,
          }
        })

        this.props.closeModalCreate()

        // const client = algoliasearch('WIAJVRIMIM', 'e551c26bcad13637264ec30c6c610ba6');
        // const index = client.initIndex('user-firestore');

        // const obj = [
        //   {
        //     objectID: userAdd.id,
        //     name: this.state.name
        //   }
        // ]

        // index
        // .saveObjects(obj)
        // .then(({ objectIDs }) => {
        //   // console.log(objectIDs);
        // })
        // .catch(err => {
        //   // console.log(err);
        // });

        this.setState({
          name: "",
          mobile: "",
          address: "",
          createUserMobile: false
        })
      }

    }

  }

  onClickSearchLocation = async () => {
    if(this.state.address != "" && this.state.address){
      try {
        const addressGeo = await Geocode.fromAddress(this.state.address + " " + this.state.subdistrict + " " + this.state.district + " " + this.state.province + " " + this.state.zipcode)
        const { lat, lng } = addressGeo.results[0].geometry.location;
        this.setState({
          lat: lat,
          lng: lng
        })
      } catch (error) {
        this.setState({
          lat: "13.7563",
          lng: "100.5018"
        })
      }
      
      
    }
  }

  handleSelectProvince = async (event) => {
    const districtsList = await api_addressCtrl.getListDistricts({ provinceId: event.provinceId })
    this.setState({
      province: event.provinceName,
      districtsList: districtsList.data
    })
  }

  handleSelectDistricts = async (event) => {
    const subDistrictsList = await api_addressCtrl.getListSubDistricts({ districtId: event.districtId })
    this.setState({
      district: event.districtName,
      subDistrictsList: subDistrictsList.data
    })
  }

  handleSelectSubdistrict = async (event) => {
    this.setState({
      subdistrict: event.subdistrictName,
      zipCode: event.zipcode
    })
  }

  render() {
    return (
      <Modal
        isOpen={this.props.showModalCreate}
        className="modal-medium" >
        <div className="modal-header">
          <h3>{this.props.title}</h3>
          <button className="btn-cloes btn" onClick={this.toggleModalCreate}>
            <i className="icon-close" />
          </button>
        </div>
        <ModalBody>

          <FormGroup>
            <label>ชื่อ<span>*</span></label>
            <Input type="text"
              name="name"
              autoComplete="nope"
              className={this.state.errName ? "valid" : "invalid"}
              onChange={(e) => this.setState({ name: e.target.value, errName: true })}
              defaultValue={this.props.dataUser ? this.props.dataUser.nickName : ""}
            />
          </FormGroup>

          <FormGroup>
            <label>เบอร์โทร<span>*</span></label>
            <Input type="number"
              name="phone"
              autoComplete="nope"
              className={this.state.errMobile ? "valid" : "invalid"}
              onChange={(e) => this.setState({ mobile: e.target.value, errMobile: true })}
              defaultValue={this.props.dataUser ? this.props.dataUser.mobile : ""}
            />
          </FormGroup>

          {/* <FormGroup>
            <label>ที่อยู่<span></span></label>

            <div className="row mt-2">
                    <SelectOption
                        placeholder={this.state.province ? this.state.province : "เลือกจังหวัด"}
                        options={this.state.provinceList}
                        getOptionLabel={(option) => option.provinceName}
                        getOptionValue={(option) => option.provinceId}
                        onChange={this.handleSelectProvince}
                        value={this.state.province}
                    />
                </div>

                <div className="row mt-2">
                    <SelectOption
                        placeholder={this.state.district ? this.state.district : "เลือกเขต"}
                        options={this.state.districtsList}
                        getOptionLabel={(option) => option.districtName}
                        getOptionValue={(option) => option.districtId}
                        onChange={this.handleSelectDistricts}
                        value={this.state.district}
                    />
                </div>

                <div className="row mt-2">
                    <SelectOption
                        placeholder={this.state.subdistrict ? this.state.subdistrict : "เลือกแขวง"}
                        options={this.state.subDistrictsList}
                        getOptionLabel={(option) => option.subdistrictName}
                        getOptionValue={(option) => option.subdistrictId}
                        onChange={this.handleSelectSubdistrict}
                        value={this.state.subdistrict}
                    />
                </div>

                <div className="row mt-2">
                    <div className="form-group w-100">
                        <input
                            name="zipcode"
                            type="text"
                            className="form-control input-background-color"
                            placeholder="รหัสไปรษณีย์"
                            onChange={(e) => this.setState({ zipCode: e.target.value})}
                            value={this.state.zipCode}
                        />
                    </div>
                </div>

                <div className="row mt-2">
                    <div className="form-group w-100">
                        <textarea
                            name="address"
                            onChange={(e) => this.setState({ address: e.target.value })}
                            defaultValue={this.state.address}
                            className="form-control input-background-color"
                            rows="3"
                            placeholder="บ้านเลขที่,ซอย,ถนน">
                        </textarea>
                    </div>
                </div>
          </FormGroup> */}
          {/* <div className="center">
            <button className="btn btn-primary-sr btn-map" onClick={this.onClickSearchLocation}>ค้นหาที่อยู่</button>
          </div>

          <div className="container-map">
            <div className="div-map">
              <GoogleMap
                lat={this.state.lat}
                lng={this.state.lng}
                show={true}
              />
            </div>
          </div> */}

          {/* <FormGroup
            className="mb-0"
          >
            <Input type="textarea"
              name="addressExtra"
              autoComplete="nope"
              placeholder="รายละเอียดที่อยู่เพิ่มเติม"
              onChange={(e) => this.setState({ addressExtra: e.target.value })}
              defaultValue={this.props.dataUser ? this.props.dataUser.addressExtra : ""}
            />
          </FormGroup> */}
          {
            this.state.errName && this.state.errMobile ? null : <small className="invalid-text">กรุณากรอกข้อมูลให้ครบ</small>
          }
        </ModalBody>
        <ModalFooter>
        <button className="btn btn-primary-sr btn-map" onClick={this.onClickSubmit} disabled={this.state.createUserMobile}>{this.state.createUserMobile ? "กำลังบันทึก..." : "บันทึก"}</button>
        </ModalFooter>
      </Modal>
    );
  }
}



export default ModalCustomer

