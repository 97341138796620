/* eslint-disable eqeqeq */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-useless-constructor */
import React from 'react';
import { Modal, ModalBody, ModalFooter, FormGroup, Input } from 'reactstrap';
import SelectOption from '../Select';
import '../../styles/chat/modal.scss'
import '../../styles/chat/gallery.scss'
import api from '../../config/api'
import axios from 'axios'
import { isEmptyOrSpaces } from '../../utils/utils'

import {
    getAlbums,
    checkDuplicateAlbum,
    createAlbum,
    createMedia,
} from '../../controllers/mediaGalleryCtrl'


class ModalCreate extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            modal: false,
            file: [],
            imgPreviewUrl: [],
            listAlbum: [],
            toggleCreateAlbum: false,
            albumName: "",
            errDuplicateMsg: false,
            errFillup: false,
            albumId: undefined,
            errSelectAlbum: false,
            errPickImg: false,
            disableBtn: false,
        };
    }

    async componentDidMount() {
        await this.listAlbums()
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            modal: nextProps.showModalCreate,
        })
    }

    listAlbums = async () => {
        const albumsList = await getAlbums()
        this.setState({
            listAlbum: [{ _id: "0000", name: "+เพิ่มอัลบั้มใหม่" }, ...albumsList]
        })
    }

    handleSelectAlbums = async (data) => {
        if (data._id === '0000' && data.name === "+เพิ่มอัลบั้มใหม่") {
            this.setState({
                toggleCreateAlbum: true,
            })
        } else {
            this.setState({
                toggleCreateAlbum: false,
                albumId: data._id,
                disableBtn: false,
                errSelectAlbum: false,
            })
        }
    }

    handleImageChange = async (e) => {
        const checkType = ['image/jpeg', 'image/png', 'image/gif']

        if (e.target.files[0] && checkType.includes(e.target.files[0].type)) {
            const reader = new FileReader();
            const file = e.target.files[0];



            reader.onloadend = () => {
                this.setState({
                    file: [...this.state.file, file],
                    imgPreviewUrl: [...this.state.imgPreviewUrl, { name: file.name, preview: reader.result }],
                    errPickImg: false,
                    disableBtn: false,
                })
            }

            reader.readAsDataURL(file)
        } else {
            alert("โปรดเลือกไฟล์ที่จะอัพโหลดให้ถูกต้อง")
        }
    }

    cancelImage = async (idx) => {
        const currentImg = [...this.state.imgPreviewUrl]
        const currentFile = [...this.state.file]
        currentImg.splice(idx, 1)
        currentFile.splice(idx, 1)

        this.setState({
            imgPreviewUrl: currentImg,
            file: currentFile
        })
    }

    onClickUploadMedia = async () => {
        this.setState({
            errFillup: false,
            errMsg: false,
            errSelectAlbum: false,
            disableBtn: true,
        })

        if (this.state.toggleCreateAlbum) {
            const checkAlbumName = isEmptyOrSpaces(this.state.albumName)
            if (!this.state.albumName || this.state.albumName.length == 0 || checkAlbumName) return this.setState({ errFillup: true })

            const checkAlbumNameExist = await checkDuplicateAlbum(this.state.albumName)

            if (!checkAlbumNameExist) {
                const albumData = await createAlbum({ name: this.state.albumName, lastMedia : "b84f5ff6-dcd9-4daa-962e-8f83ce1abde7.png" })

                if (this.state.file.length > 0) {

                    const path = `${api.MEDIA_API}/upload`

                    const fileUpload = [...this.state.file]

                    await Promise.all(
                        await fileUpload.map(async file => {
                            const formData = new FormData()
                            formData.append('file', file);
                            formData.append('serviceKey', api.MEDIA_SERVICE_KEY);
                            formData.append('secretKey', api.MEDIA_SECRET_KEY);

                            const upload = await axios.post(path, formData)

                            await createMedia({
                                path: upload.data.fileName,
                                name: upload.data.originalFileName,
                                albumId: albumData._id,
                            })

                        })
                    )

                } else {
                    this.setState({
                        errPickImg: true
                    })
                }

                this.setState({
                    modal: false,
                    file: [],
                    imgPreviewUrl: [],
                    toggleCreateAlbum: false,
                    albumName: "",
                    errDuplicateMsg: false,
                    errFillup: false,
                    albumId: undefined,
                    errSelectAlbum: false,
                    errPickImg: false,
                    disableBtn: false,
                })


                await this.props.listAlbums()

            } else {
                this.setState({
                    errMsg: true
                })
            }

        } else {
            if (this.state.albumId || this.props.albumDetail) {

                if (this.state.file.length > 0) {

                    const path = `${api.MEDIA_API}/upload`

                    const fileUpload = [...this.state.file]

                    await Promise.all(
                        await fileUpload.map(async file => {
                            const formData = new FormData()
                            formData.append('file', file);
                            formData.append('serviceKey', api.MEDIA_SERVICE_KEY);
                            formData.append('secretKey', api.MEDIA_SECRET_KEY);

                            const upload = await axios.post(path, formData)

                            await createMedia({
                                path: upload.data.fileName,
                                name: upload.data.originalFileName,
                                albumId: this.state.albumId || this.props.albumDetail._id,
                            })

                        })
                    )

                    if (this.props.albumDetail) this.props.listMediaById(this.props.albumDetail._id)

                    this.setState({
                        modal: false,
                        file: [],
                        imgPreviewUrl: [],
                        toggleCreateAlbum: false,
                        albumName: "",
                        errDuplicateMsg: false,
                        errFillup: false,
                        albumId: undefined,
                        errSelectAlbum: false,
                        errPickImg: false,
                        disableBtn: false,
                    })

                    this.props.listAlbums()
                } else {
                    this.setState({
                        errPickImg: true
                    })
                }
            } else {
                this.setState({
                    errSelectAlbum: true
                })
            }
        }

    }

    closeModal = () => {
        this.props.closeModalCreate()
        this.setState({
            file: [],
            imgPreviewUrl: [],
            toggleCreateAlbum: false,
            albumName: "",
            errDuplicateMsg: false,
            errFillup: false,
            albumId: undefined,
            errSelectAlbum: false,
            errPickImg: false,
            disableBtn: false,
        })
    }






    render() {
        return (
            <Modal className="modal-create" isOpen={this.state.modal} /* toggle={this.props.closeModalCreate} */ >
                <ModalBody>
                    <div className="row box-modalcreate">
                        <div className="col-12 btn">
                            <i className="btn-cloes icon-close" onClick={this.closeModal} />
                        </div>
                        <div className="col-12 tite-text">เพิ่มรูปภาพ/อัลบั้ม</div>

                        
                                {
                                    this.state.imgPreviewUrl.length == 0 ?
                                        <div className="col-12 col-lg-6 box-img">
                                            <div className="div-upload">
                                                    <button className="btn btn-attach-modal" type="button">
                                                        <i className="icon-photo" />
                                                        <p>อัพโหลดรูปภาพ</p>
                                                        <div className="input-img">
                                                            <input type="file" onChange={(e) => this.handleImageChange(e)} accept="image/x-png, image/gif, image/jpeg" />
                                                        </div>
                                                    </button>
                                            </div>
                                        </div>
                                    :
                                        <div className="col-12 col-lg-6 box-img">
                                            <div className="div-upload">
                                                <div className="warp-img">
                                                    <button className="btn btn-attach-modal " type="button">
                                                        <i className="icon-photo" />
                                                        <p>อัพโหลดรูปภาพ</p>
                                                        <div className="input-img">
                                                            <input type="file" onChange={(e) => this.handleImageChange(e)} accept="image/x-png, image/gif, image/jpeg" />
                                                        </div>
                                                    </button>
                                                </div>

                                                {
                                                    this.state.imgPreviewUrl.map((urlImg, idx) =>
                                                        <div className="warp-img" key={urlImg+ idx}>
                                                            <img src={urlImg.preview} />
                                                            <div className="box-close">
                                                                <button className="btn btn-close" type="button" onClick={() => this.cancelImage(idx)}><i className="fas fa-times-circle"></i></button>
                                                            </div>
                                                        </div>
                                                    )
                                                }
                                            </div>
                                        </div>
                                }


                        <div className="col-12 col-lg-6 box-text">

                            <div className="title-addimage">
                                เพิ่มไปยังอัลบั้ม
                                </div>

                            <div className="selete-album">
                                {
                                    this.props.albumDetail ?
                                        <SelectOption
                                            placeholder={this.props.albumDetail.name}
                                            disabled={true}
                                        />
                                        :
                                        <SelectOption
                                            placeholder="เลือกอัลบัม"
                                            options={this.state.listAlbum}
                                            getOptionLabel={(option) => option.name}
                                            getOptionValue={(option) => option._id}
                                            onChange={this.handleSelectAlbums}
                                        />
                                }

                                {
                                    this.state.toggleCreateAlbum ?
                                        <div>
                                            <div>ชื่ออัลบั้ม</div>
                                            <Input value={this.state.albumName} onChange={(e) => this.setState({ albumName: e.target.value, disableBtn: false })} />
                                            <p className="_text-warning">{this.state.errDuplicateMsg ? "มีชื่ออัลบั้มนี้แล้ว กรุณาเปลี่ยนใหม่" : null}</p>
                                            <p className="_text-warning">{this.state.errFillup ? "กรุณากรอกชื่ออัลบัม" : null}</p>
                                        </div>
                                        :
                                        <div>
                                            <p className="_text-warning">{this.state.errSelectAlbum ? "โปรดเลือกอัลบัมที่จะทำการอัพโหลด" : null}</p>
                                            <p className="_text-warning">{this.state.errPickImg && this.state.imgPreviewUrl.length == 0 ? "โปรดเลือกรูปที่จะทำการอัพโหลดอย่างน้อย 1 รูป" : null}</p>
                                        </div>
                                }
                            </div>
                        </div>
                    </div>
                    <button className="btn btn-primary-sr btn-comfirm" disabled={this.state.disableBtn} onClick={this.onClickUploadMedia}>ตกลง</button>
                </ModalBody>


            </Modal>
        );
    }
}



export default ModalCreate

