import React from 'react';
import { Pagination, PaginationItem, PaginationLink } from 'reactstrap';

class App extends React.PureComponent {


    state = {
        currentPage: 0,
        pageSize: 10,
        total: 0,
    };


    componentWillReceiveProps(nexProps) {
        if (this.props.pagination !== nexProps.pagination) {
            this.pagesCount = Math.ceil(nexProps.pagination.total / nexProps.pagination.pageSize);
            this.setState({
                currentPage: nexProps.pagination.currentPage,
                pageSize: nexProps.pagination.pageSize,
                total: nexProps.pagination.total,
            })
        }
    }

    async handleClick(e, index) {
        try {
            e.preventDefault();
            await this.setState({
                currentPage: index
            });
            const skip = index * this.state.pageSize;
            this.props.handleClick(skip, this.state);
        } catch (error) {
            console.log(error);
        }
    }

    handleClickPrevious = async () => {
        try {
            await this.setState({
                currentPage: 0,
            });
            this.props.handleClick(0, this.state);
        } catch (error) {
            console.log(error);
        }
    }

    handleClickNext = async () => {
        try {
            await this.setState({
                currentPage: this.pagesCount - 1,
            });
            const skip = (this.pagesCount - 1) * this.state.pageSize;
            this.props.handleClick(skip, this.state);
        } catch (error) {
            console.log(error);
        }
    }



    render() {

        const { currentPage,total,pageSize } = this.state;

        if(total <= pageSize){
            return ''
        }
        return (

            <div id="pagination-component">

                <Pagination aria-label="Page navigation example">

                    <PaginationItem disabled={currentPage <= 0}>
                        <PaginationLink first
                            onClick={this.handleClickPrevious}
                        />
                    </PaginationItem>

                    <PaginationItem disabled={currentPage <= 0}>

                        <PaginationLink
                            onClick={e => this.handleClick(e, currentPage - 1)}
                            previous
                        />

                    </PaginationItem>
                    {[...Array(this.pagesCount)].map((page, i) =>
                        <PaginationItem active={i === currentPage} key={i}>
                            <PaginationLink onClick={e => this.handleClick(e, i)}>
                                {i + 1}
                            </PaginationLink>
                        </PaginationItem>
                    )}

                    <PaginationItem disabled={currentPage >= this.pagesCount - 1}>

                        <PaginationLink
                            onClick={e => this.handleClick(e, currentPage + 1)}
                            next
                        />

                    </PaginationItem>

                    <PaginationItem disabled={currentPage >= this.pagesCount - 1}>
                        <PaginationLink
                            last
                            onClick={this.handleClickNext}
                        />
                    </PaginationItem>

                </Pagination>
            </div>
        );
    }
}

export default App;
