
import axios_saranros from '../lib/axios-saranros';

class usersCtrl {
    getListLogistic = () => {
        return new Promise(async (resolve, reject) => {
            try {
                const filter = {
                    where: {
                        isActive: true
                    }
                }
                const url = "api/Logistics?filter=" + JSON.stringify(filter);
                const result = await axios_saranros.get(url);
                // Todo : when  save complete 
                resolve(result);
            }
            catch (err) {
                // Todo : save fail
                reject(err)
            }
        });
    }
}
export default usersCtrl;