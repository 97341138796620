import React from 'react';
import { Modal } from 'reactstrap';
import IconSuccessAlert from '../assets/Icons/IconSuccessModalAlert.svg';
import IconFailed from '../assets/Icons/warning-status.png'
import { connect } from 'react-redux';
import * as actions_modal from '../redux/actions/modal';

class ModalAlert extends React.Component {
    handleClickConfirm = () => {
        this.props.closeModalAlert();
    };
    render() {
        const { modalAlert } = this.props;
        const { isOpen,
            title,
            content,
            textbutton,
            status } = modalAlert;
        return (
            <Modal
                isOpen={isOpen}
            >
                <div id="modal-alert-component">
                    <div className="header mt-15">
                        {status == "success" ?  <img src={IconSuccessAlert} alt="IconSuccessAlert" className="icon-close" /> : <img src={IconFailed} alt="IconFailed" style={{width:"100px"}} />}
                    </div>

                    <div className="body">
                        <span className="font-size-20px">
                            {content}
                        </span>
                    </div>

                    <div className="footer pb-15">
                        <button type="button"
                            className="btn btn-primary btn-footer"
                            onClick={this.handleClickConfirm}
                        >
                            {textbutton}
                        </button>
                    </div>
                </div>
            </Modal>
        )
    }
};


const mapDispatchToProps = dispatch => ({
    closeModalAlert: () => dispatch(actions_modal.closeModalAlert())
});


const mapStateToProps = state => ({
    modalAlert: state.modals.modalAlert
});


export default connect(mapStateToProps, mapDispatchToProps)(ModalAlert);