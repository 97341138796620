import React from 'react';
import * as utils from '../../../utils/utils'
import usersCtrl from '../../../controllers/usersCtrl'
const api_usersCtrl = new usersCtrl();

class ListOrderHistory extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            priceTotal: 0,
            priceDeliveryTotal: 0,
            priceDiscountTotal: 0,
            priceFoodTotal: 0,
            user: null
        }
    }


    async   componentWillReceiveProps(nextProps) {

        if (nextProps.listInvocie !== this.props.listInvocie) {
            const listInvocie = nextProps.listInvocie;
            const priceTotal = await listInvocie.reduce((sum, item) => {
                return sum + item.priceTotal
            }, 0);

            const priceDeliveryTotal = await listInvocie.reduce((sum, item) => {
                return sum + item.priceShippingNet
            }, 0);

            const priceDiscountTotal = await listInvocie.reduce((sum, item) => {
                return sum + item.priceDiscount
            }, 0);


            const priceFoodTotal = await listInvocie.reduce((sum, item) => {
                return sum + item.priceNet
            }, 0);

            this.setState({
                priceTotal, priceDeliveryTotal, priceDiscountTotal, priceFoodTotal
            })
        }
    }

    async componentDidMount() {
        const userDetail = await api_usersCtrl.getUserByuserID(this.props.match.params.id)
        this.setState({
            user: userDetail.data
        })

    }

    render() {
        const { listInvocie } = this.props;
        return (

            <div className="row">
                <div className="col-md-6">
                    <div className="list-invoice-waitpayment">
                        <div id="box-order-history">
                            {
                                listInvocie && listInvocie.map((item, idx) =>

                                    <div className={`box-order ${idx !== 0 && 'mt-4'}`} key={idx}>
                                        <div className="d-flex justify-content-start">
                                            <span className="font-primary-16px-text">
                                                คำสั่งซื้อรหัส #{item.invoiceNo}
                                            </span>
                                        </div>
                                        <div className="d-flex justify-content-start mt-1">
                                            <span className="font-size-16px">
                                                ชื่อผู้รับ {(item.contact && item.contact.name) || '-'}
                                            </span>
                                        </div>
                                        <div className="d-flex justify-content-start mt-1">
                                            <span className="font-size-16px">
                                                เบอร์ผู้รับ {(item.contact && item.contact.mobile) || "-"}
                                            </span>
                                        </div>

                                        <div className="mt-4">
                                            {
                                                item.order && item.order.map((item_order, idx_order) =>
                                                    <div className="d-flex justify-content-between mt-2" key={idx_order}>
                                                        <span className="font-size-16px">
                                                            {item_order.product.menu.name.th || item_order.product.menu.name.en}
                                                        </span>
                                                        <span className="font-size-16px">
                                                            {item_order.product.priceNet}.-
                                             </span>
                                                    </div>
                                                )
                                            }
                                        </div>

                                        <hr />
                                        <div className="d-flex justify-content-between mt-2">
                                            <span className="font-size-16px">
                                                ค่าอาหาร
                                </span>
                                            <span className="font-size-16px">
                                                {utils.numberFormatNoDecimal(item.priceNet)}.-
                                </span>
                                        </div>



                                        <div className="d-flex justify-content-between mt-2">
                                            <span className="font-size-16px">
                                                ค่าจัดส่ง
                                 </span>
                                            <span className="font-size-16px">
                                                {utils.numberFormatNoDecimal(item.priceShippingNet)}.-

                                </span>
                                        </div>

                                        <div className="d-flex justify-content-between mt-2">
                                            <span className="font-size-16px">
                                                ส่วนลด
                                </span>
                                            <span className="font-size-16px">
                                                {utils.numberFormatNoDecimal(item.priceDiscount)}.-
                                </span>
                                        </div>
                                        <hr />

                                        <div className="d-flex justify-content-between mt-2">
                                            <span className="font-bold-size-16px">
                                                รวมราคา
                                 </span>
                                            <span className="font-primary-bold-size-16px">
                                                {utils.numberFormatDecimalTwoPoint(item.priceTotal)}.-
                                </span>
                                        </div>
                                    </div>
                                )
                            }


                        </div>
                    </div>

                </div>
                <div className="col-md-6 p-3">
                    <div className="d-flex justify-content-between">
                        <span>
                            ค่าอาหารทั้งหมด
                        </span>
                        <span>
                            {utils.numberFormatDecimalTwoPoint(this.state.priceFoodTotal)}.-
                        </span>
                    </div>

                    <div className="d-flex justify-content-between">
                        <span>
                            ค่าจัดส่ง
                        </span>
                        <span>
                            {utils.numberFormatDecimalTwoPoint(this.state.priceDeliveryTotal)}.-
                        </span>
                    </div>
                    <div className="d-flex justify-content-between">
                        <span>
                            ส่วนลดทั้งหมด
                        </span>
                        <span>
                            {utils.numberFormatDecimalTwoPoint(this.state.priceDiscountTotal)}.-
                        </span>
                    </div>

                    <hr />

                    <div className="d-flex justify-content-between">
                        <span className="font-bold-size-20px">
                            รวม
                        </span>
                        <span className="font-bold-primary-size-20px">
                            {utils.numberFormatDecimalTwoPoint(this.state.priceTotal)}.-
                        </span>
                    </div>
                </div>
            </div>
        )
    }
}

export default ListOrderHistory;