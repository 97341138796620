
import axios_saranros from '../lib/axios-saranros';
import axios_chatmode from '../lib/axios-chatmode'
import * as utils from "../utils/utils";

class usersCtrl {
    login = (params) => {
        return new Promise(async (resolve, reject) => {
            try {
                const url = "/users/login";
                const { username, password } = params;
                const data = {
                    email: username.toLowerCase(),
                    password
                }
                const result = await axios_chatmode.post(url, data);
                // Todo : when  save complete 
                resolve(result);
            }
            catch (err) {
                // Todo : save fail
                reject(err)
            }
        });
    }
    logout = () => {
        return new Promise(async (resolve, reject) => {
            try {
                const url = "/users/logout";
                const result = await axios_chatmode.post(url);
                // Todo : when  save complete 
                resolve(result);
            } catch (error) {
                reject(error)
            }
        });
    }
    getUserByID = () => {
        return new Promise(async (resolve, reject) => {
            try {
                const url = "/api/users/" + utils.getUserId();
                const result = await axios_saranros.get(url);
                // Todo : when  save complete 
                resolve(result);
            } catch (error) {
                reject(error)
            }
        });
    }


    autoUserByLineId = (params) => {
        return new Promise(async (resolve, reject) => {
            try {
                const url = "api/users/autoUserByLineId";
                const data = {
                    displayName: params.displayName,
                    mobile: params.mobile,
                    lineId: params.lineId
                }
                const result = await axios_saranros.post(url, data);
                // Todo : when  save complete 
                resolve(result);
            } catch (error) {
                reject(error)
            }
        });
    }

    autoUserByLineIdOnly = async (params) => {
        try {
            const url = "api/users/autoUserByLineOnly"
            const data = {
                displayName: params.displayName,
                lineId: params.lineId
            }

            const result = await axios_saranros.post(url, data);
            return result.data

        } catch (error) {
            return error
        }

    }

    autoUserByMobile = (params) => {
        return new Promise(async (resolve, reject) => {
            try {
                const url = "api/users/autoUser";
                const data = {
                    displayName: params.displayName,
                    mobile: params.mobile,
                }
                const result = await axios_saranros.post(url, data);
                // Todo : when  save complete 
                resolve(result);
            } catch (error) {
                reject(error)
            }
        });
    }

    getUserByuserID = (userid) => {
        return new Promise(async (resolve, reject) => {
            try {
                const url = "/api/users/" + userid;
                const result = await axios_saranros.get(url);
                // Todo : when  save complete 
                resolve(result);
                
            } catch (error) {
                reject(error)
            }
        });
    }

    checkToken = (token) => {
        return new Promise(async (resolve, reject) => {
            try {
                const url = "/checkToken/" + token;
                const result = await axios_chatmode.get(url);
                // Todo : when  save complete 
                resolve(result);
                
            } catch (error) {
                reject(error)
            }
        });
    }
}
export default usersCtrl;