import React, { Component } from 'react';
import { Modal, ModalBody } from 'reactstrap';
import '../../styles/chat/modal.scss'
import SelectOption from '../Select';
import {
    getAlbums,
} from '../../controllers/mediaGalleryCtrl'

class ModalMove extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            modal: false,
            listAlbum: [],
            albumId: "",
        }
    }

    async componentDidMount() {
        await this.listAlbums()
    }

    listAlbums = async () => {
        const albumsList = await getAlbums()
        this.setState({
            listAlbum: albumsList
        })
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            modal: nextProps.showModalMove,
        })
    }

    closeModal = () => {
        this.props.closeModalMove()
    }

    handleSelectAlbums = (data) => {
        this.setState({
            albumId: data._id
        })
    }

    onClickMove = () => {
        this.props.mediaSelect.map(media => {
            this.props.updateMedia(media._id, { albumId: this.state.albumId })
        })
    }

    render() {
        return (
            <Modal className="modal-movealbum align-self-center" isOpen={this.state.modal}>
                <ModalBody>
                    <div className="col-12 btn" >
                        <i className="btn-cloes icon-close" onClick={this.closeModal} />
                    </div>
                    <div className="box-movealbum">
                        <h4><strong>ย้ายไปที่อัลบั้ม</strong></h4>
                        <div className="row movealbum">
                            <div className="col-12 seletealbum">
                                {/* <SelectOption className="selete-movealbum" placeholder="เลือกอัลบัม" /> */}
                                <SelectOption
                                    className="selete-movealbum"
                                        placeholder="เลือกอัลบัม"
                                        options={this.state.listAlbum}
                                        getOptionLabel={(option) => option.name}
                                        getOptionValue={(option) => option._id}
                                        onChange={this.handleSelectAlbums}
                                    />
                            </div>
                            <button className="col-12 btn-confirmmove" onClick={this.onClickMove}>ตกลง</button>
                        </div>
                    </div>
                </ModalBody>
            </Modal>
        )
    }
}

export default ModalMove