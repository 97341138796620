import React, { useState, useEffect } from 'react';
import logo from '../../assets/images/logo.png'
import { connect } from 'react-redux';
import { logoutRequest } from '../../redux/actions/users';
import * as utils from "../../utils/utils";
import listHeader from '../../config/header';
import * as permission from '../../config/permission';
import history from '../../history';

import {
    Collapse,
    Navbar,
    NavbarToggler,
    NavbarBrand,
    Nav,
    NavItem,
    NavLink
} from 'reactstrap';

const Header = (props) => {

    const [isOpen, setIsOpen] = useState(false);

    const toggle = () => setIsOpen(!isOpen);

    useEffect(() => {
        checkUserLogin();
        const permissionRouteUser = permission.PERRMISSION_ROUTE[utils.getUserType()];
        if (permissionRouteUser) {
            const check_permissionRoute = permissionRouteUser.find(function (item) {
                return item === props.sideBar
            });
            if (check_permissionRoute == null) {
                history.push(permissionRouteUser[0]);
            }
        }
    });

    const checkUserLogin = () => {
        const isLogin = utils.isLogin();
        if (!isLogin) {
            history.push("/login");
        };
    }

    const headerActive = (item) => {

        if (item.urlChildren) {
            const header = item.urlChildren.find((item) => item.url === props.sideBar);
            if (header) {
                return "header-active";
            } else {
                return "header-inactive"
            }
        } else {
            if (props.sideBar === item.url) {
                return "header-active";
            } else {
                return "header-inactive"
            }
        }
    }

    return (
        <div>
            <Navbar className="header padding-0" color="white" light expand="md">
                <div className="">
                    <NavbarBrand href="/"> <img className="logo" src={logo} alt='logo' /> </NavbarBrand>
                </div>
                {utils.isLogin() ?
                    <>
                        <NavbarToggler onClick={toggle} />
                        <Collapse isOpen={isOpen} navbar>
                            <Nav navbar className="nav-bar">
                                {
                                    listHeader && listHeader.map((item, idx) =>
                                        item.isActive && item.permission[utils.getUserType()] &&
                                        <NavItem className="" key={idx}>
                                            <NavLink href={item.url}>
                                                <label
                                                    className={`list-menu-text cursor-pointer ${headerActive(item)}`}>
                                                    {item.title}
                                                </label>
                                            </NavLink>
                                        </NavItem>
                                    )
                                }
                            </Nav>
                            <Nav className="ml-auto" navbar>
                                <NavItem className="nav-header">
                                    <i className="icon-user" />
                                    <NavLink className="nav-menu" onClick={props.logoutRequest}>
                                        <label className="list-menu-text cursor-pointer">
                                            ออกจากระบบ
                                        </label>
                                    </NavLink>
                                </NavItem>
                            </Nav>
                        </Collapse>
                    </>

                    : null}

            </Navbar>
        </div>
    );
}


const mapDispatchToProps = () => dispatch => ({
    logoutRequest: () => dispatch(logoutRequest())
})

export default connect(null, mapDispatchToProps)(Header)
