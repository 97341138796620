import axios_saranros from '../lib/axios-saranros';
import moment from 'moment'


class MenuCtrl {


    getListMenu = (params) => {
        return new Promise(async (resolve, reject) => {
            const { menu, shop, date, time } = params
            const timeAt = []
            timeAt.push(params.time)
            // return new Promise(async (resolve, reject) => {
            try {
                // const dateAt = moment(date).format('YYYY-MM-DD');
                // const timeAt = moment(time).add(1, 'hours').startOf('hour').format('HH:mm');
                const url = '/api/Menus/SearchMenuByDateTime'
                // const dateAt = date || '';
                // const timeAt = time || ''

                const name = menu || null;
                const sort = {
                    menuName: name,
                    date,
                    time: timeAt,
                    isPreOrder: true,
                    shopName: shop,
                    // isBuynow: false,
                    // shopName: name,
                }

                if (menu === '' || menu === null) {
                    // delete sort.shopName;
                    delete sort.menuName;
                }

                if (date === "") {
                    delete sort.date;
                }

                if (time.toString().trim().length === 0) {
                    delete sort.time
                }
                const result = await axios_saranros.post(url, sort)

                resolve(result)
            } catch (error) {
                reject(error);
            }
        })
    }

    mergeDateTime = (date, time) => {
        if (date == "" || time == "") {
            return "";
        }
        const dateDelivery = moment(date && date).format('MM-DD-YYYY')
        const timeDelivery = moment(time && time).format('HH:mm')
        const dateTime = moment(dateDelivery + ' ' + timeDelivery).format('MM-DD-YYYY HH:mm')
        const dateTimeDelivery = new Date(dateTime)
        return dateTimeDelivery.toISOString();
    }


    getOptionMenuList = (shopId) => {

        return new Promise(async (resolve, reject) => {
            try {
                const filter = {
                    where: {
                        shopId: shopId,
                        isActive: true,
                        isComingSoon: false
                    },
                    include: [{
                        relation: "shop"
                    }]
                }


                if (shopId == null || shopId == "") {
                    delete filter.where.shopId
                }

                const result = await axios_saranros.get('/api/Menus?filter=' + JSON.stringify(filter))

                resolve(result)
            } catch (error) {
                console.log(error)
                reject(error)
            }
        })
    }

    getMenuExtraData = (id) => {
        return new Promise(async (resolve, reject) => {
            try {

                const filter = {
                    where: {
                        id
                    },
                    include: [{
                        relation: "menuExtraData",
                        scope:{
                            where : {
                                isActive: true
                            }
                        }
                    },
                    {
                        relation: 'callCenterHistory',
                        scope:{
                            order: 'createAt DESC',
                            limit: '1',
                            where: {
                                fieldsRef: 'ccRemark'
                            }
                        }
                    }
                    // {
                    //     relation: "shop",
                    //     scope: {
                    //         include : {
                    //             relation: "contact"
                    //         }
                    //     }
                    // }
                ]
                }

                const result = await axios_saranros.get('/api/Menus?filter=' + JSON.stringify(filter))
                resolve(result)

            } catch (error) {
                console.log(error)
                reject(error)
            }
        })
    }

    saveDetailCallcenterHistory = async (data) => {
        const path = '/api/CallCenterHistories'
        try {
            const result = await axios_saranros.post(path, data)
            return result.data
        } catch (error) {
            console.log(error)
        }
    }


}

export default MenuCtrl