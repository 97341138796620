import React, { Component } from 'react'
import '../../styles/chat/gallery.scss'
import '../../styles/chat/modal.scss'
import ModalCreateMedia from './ModalCreate'
import ModalDeleteMedia from './ModalDelete'
import ModalEditname from './ModalEditname'
import ModalMoveMedia from './ModalMove'
import movefile from '../../assets/images/move-file.png'
import api from '../../config/api'
import {
    getAlbums,
    getMedia,
    getMediaById,
    searchAlbumByName,
    searchMediaByName,
    updateMedia,
    deletedMedia,
    deleteAlbum,
} from '../../controllers/mediaGalleryCtrl'
import SelectOption from '../Select';

const pathImg = 'https://thumb.izcene.com/saranros/image/';



class Gallery extends Component {
    constructor(props) {
        super(props)
        this.state = {
            listAlbum: [],
            listMedia: [],
            showModalCreate: false,
            album: true,
            albumDetail: undefined,
            showModalDeleteMedia: false,
            showModalDeleteAlbum: false,
            searchName: "",
            showModalEditname: false,
            showModalMove: false,
            listMediaSelect: [],
            checkAll: false,
            mediaId: undefined,
            mediaName: "",
            albumId: undefined,
        }
    }

    async componentDidMount() {
        await this.listAlbums()
    }

    listAlbums = async (sort = "used") => {
        const albumsList = await getAlbums(sort)
        this.setState({
            showModalCreate: false,
            listAlbum: [{ _id: "1111", name: "ภาพทั้งหมด", lastMedia: "8b36ed11-0aec-493d-b85f-6e49deaa689e.png" }, ...albumsList]
        })
    }

    keyPressed = (e) => {
        if (e.key === "Enter" && e.shiftKey === false) {
            e.preventDefault()
            this.searchAlbumOrMediaByName()
        }
    }

    gotoAlbum = async (id, name) => {
        this.setState({
            album: false,
            albumDetail: { _id: id, name: name }
        })

        if (id === "1111") return this.listMediaAll()

        this.listMediaById(id)

    }

    listMediaAll = async () => {
        const mediaData = await getMedia()
        this.setState({
            listMedia: mediaData
        })
    }

    listMediaById = async (id, sort = "used") => {
        const mediaData = await getMedia(`albumId=${id}`, sort)
        this.setState({
            listMedia: mediaData
        })
    }

    backtoMain = async () => {
        this.setState({
            album: true,
            listMedia: [],
            albumDetail: undefined,
        })
    }

    searchAlbumOrMediaByName = async () => {
        // <input placeholder="ค้นหาชื่อ" onChange={(e) => this.setState({ searchName: e.target.value })} onBlur={this.searchAlbumOrMediaByName}></input>
        if (this.state.albumDetail) {
            if (this.state.searchName) {
                const mediaData = await searchMediaByName(this.state.searchName)
                this.setState({
                    listMedia: mediaData
                })
            } else {
                await this.listMediaById(this.state.albumDetail._id)
            }
        } else {
            if (this.state.searchName) {
                const albumData = await searchAlbumByName(this.state.searchName)
                this.setState({
                    listAlbum: albumData
                })
            } else {
                await this.listAlbums()
            }
        }

        this.setState({
            searchName: "",
        })
    }

    onClickSelectImage = async (media) => {
        const arrMediaSelect = [...this.state.listMediaSelect]

        const arrayFind = arrMediaSelect.findIndex((mediaSelect) => mediaSelect._id == media._id)

        if (arrayFind == -1) {
            arrMediaSelect.push(media)
        } else {
            arrMediaSelect.splice(arrayFind, 1)
        }

        this.setState({
            listMediaSelect: arrMediaSelect
        })
    }

    onClickCheckAll = () => {
        if (this.state.checkAll) {
            this.setState({
                checkAll: false,
                listMediaSelect: []
            })
        } else {
            this.setState({
                checkAll: true,
                listMediaSelect: [...this.state.listMedia]
            })
        }

    }

    updateMedia = async (id, data) => {
        this.setState({
            showModalEditname: false,
            showModalMove: false,
            listMediaSelect: [],
            mediaId: undefined,
        })
        await updateMedia(id, data)
        await this.listMediaById(this.state.albumDetail._id)
        this.listAlbums()
    }

    deletedMedia = async (mediaId) => {
        this.setState({
            showModalDeleteMedia: false,
            listMediaSelect: [],
        })
        await deletedMedia(mediaId)
        await this.listMediaById(this.state.albumDetail._id)
        this.listAlbums()
    }

    deletedAlbum = async (alubmId) => {
        this.setState({
            showModalDeleteAlbum: false,
            albumId: undefined,
        })
        await deleteAlbum(alubmId)
        await this.listAlbums()
    }

    selectSorting = (e) => {
        if (this.state.albumDetail) {
            this.listMediaById(this.state.albumDetail._id, e.target.value)
        } else {
            this.listAlbums(e.target.value)
        }
    }


    render() {
        return (
            <div className="wrap-customer gallery-space">
                <div className="container-box">
                    <div className="title-name">
                        {this.state.albumDetail ? this.state.albumDetail.name : "แกลลอรี่"}
                    </div>

                    {
                        this.state.album ?
                            null
                            :
                            <div className="back-btn cursor-pointer" onClick={this.backtoMain}>
                                {"< กลับไปที่หน้าอัลบั้ม"}
                            </div>
                    }
                    <div>

                    </div>

                    <div className="row filter-tab">
                        <div className="col-lg-4 col-sm-12">
                            <div className="text-left input-group mb-3">
                                <div className="input-group-prepend">
                                    <span className="input-group-text" id="basic-addon1">
                                        <i className="fas fa-search"></i></span>
                                </div>
                                <input type="text" className="form-control" placeholder="ค้นหา ชื่อ" aria-describedby="basic-addon1" value={this.state.searchName} onChange={(e) => this.setState({ searchName: e.target.value })} onKeyPress={this.keyPressed} />
                            </div>
                        </div>

                        <div className="col-lg-8 col-sm-12">
                            <form className="form-sort">
                                <div className="form-group row">
                                    <label htmlFor="inputPassword" className=" col-sm-3 col-form-label">เรียงจาก</label>
                                    <div className="col-lg-4 col-sm-9 input-group pb-3">
                                        <select className="select-form" onChange={this.selectSorting}>
                                            <option select="true" value="used">ใช้งานบ่อย</option>
                                            <option value="name">ตามตัวอักษร</option>
                                        </select>
                                        {/* <div className="select-form">
                                            <SelectOption className="" placeholder="เลือกอัลบัม" />
                                        </div> */}
                                    </div>
                                    <div className="col-lg-5 col-sm-12 btn-add-img">
                                        {
                                            this.state.albumDetail ? this.state.albumDetail._id === '1111' ?
                                                // null :
                                                <div className="add-image">
                                                    <button className="btn btn-send" type="button" id="button-addon2" onClick={() => this.setState({ showModalCreate: true })}>เพิ่มรูปภาพ/อัลบั้ม</button>
                                                </div>
                                                :
                                                <div className="add-image">
                                                    <button className="btn btn-send" type="button" id="button-addon2" onClick={() => this.setState({ showModalCreate: true })}>เพิ่มรูปภาพ/อัลบั้ม</button>
                                                </div>
                                                :
                                                <div className="add-image">
                                                    <button className="btn btn-send" type="button" id="button-addon2" onClick={() => this.setState({ showModalCreate: true })}>เพิ่มรูปภาพ/อัลบั้ม</button>
                                                </div>
                                        }
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    {
                        this.state.album ?
                            <div className="div-album">
                                {
                                    this.state.listAlbum.map(album =>
                                        <div className="box-album-relative" key={album._id}>
                                            <div className="box-album">
                                                <img className="img-album" src={pathImg + album.lastMedia} onClick={() => this.gotoAlbum(album._id, album.name)} />
                                                <div className="row">
                                                    <div className="col-9 name-album">{album.name}</div>
                                                    {
                                                        album._id !== "1111" ?
                                                            <i className="col-3 fas fa-trash-alt cur-point" onClick={() => this.setState({ showModalDeleteAlbum: true, albumId: album._id })}></i>
                                                            :
                                                            null
                                                    }

                                                </div>
                                            </div>
                                        </div>
                                    )
                                }
                            </div>
                            :
                            <div>
                                {
                                    this.state.albumDetail._id != "1111" ?
                                        <div className="checkbox-media-all">
                                            <div className="select-all">
                                                <input className="form-check-input" type="checkbox" value="select-all" checked={this.state.listMediaSelect.length == this.state.listMedia.length ? true : false} onChange={this.onClickCheckAll} />
                                                <label>&nbsp;&nbsp;เลือกทั้งหมด</label>
                                            </div>
                                        </div>
                                        :
                                        null
                                }
                                <div className="div-album">
                                    {
                                        this.state.listMedia.map(media =>
                                            <div className={this.state.albumDetail._id == "1111" ? "box-media box-media-padding" : "box-media"} key={media._id}>
                                                {
                                                    this.state.albumDetail._id != "1111" ?
                                                        <div className="checkbox-media">
                                                            <input className="form-check-input" type="checkbox" value="select-media" checked={this.state.listMediaSelect.findIndex((mediaSelect) => mediaSelect._id == media._id) == -1 ? false : true} onChange={() => this.onClickSelectImage(media)} />
                                                        </div>
                                                        :
                                                        null
                                                }
                                                <img className="img-media" src={pathImg + media.path} onClick={() => this.onClickSelectImage(media)} />
                                                <div className="row">
                                                    <div className={this.state.albumDetail._id != "1111" ? "col-9 name-media" : "col-12 name-media"}>{media.name}</div>
                                                    {
                                                        this.state.albumDetail._id != "1111" ?
                                                            <i className="col-3 fas fa-pen" onClick={() => this.setState({ showModalEditname: true, mediaId: media._id, mediaName: media.name })}></i>
                                                            :
                                                            null
                                                    }
                                                    {
                                                        this.state.albumDetail._id == "1111" ?
                                                            <div className="col-12 name-media">อัลบั้ม {media.albumId.name}</div>
                                                            :
                                                            null
                                                    }
                                                </div>
                                            </div>
                                        )
                                    }
                                </div>

                                {
                                    this.state.albumDetail._id != "1111" ?
                                        <div className="row btn-create-media">
                                            <button className={this.state.listMediaSelect.length > 0 ? "btn-move-media-enable" : "btn-move-media-disable"} disabled={this.state.listMediaSelect.length > 0 ? false : true} type="button" onClick={() => this.setState({ showModalMove: true })}>
                                                <img className="movefile" src={movefile} alt='move-file' />
                                        &nbsp;&nbsp;&nbsp;ย้ายอัลบั้ม
                                    </button>
                                            <button className={this.state.listMediaSelect.length > 0 ? "btn-delete-media-enable" : "btn-delete-media-disable"} disabled={this.state.listMediaSelect.length > 0 ? false : true} type="button" onClick={() => this.setState({ showModalDeleteMedia: true })}>
                                                <i className="fas fa-trash-alt"></i>
                                        &nbsp;&nbsp;&nbsp;ลบ
                                    </button>
                                        </div>
                                        :
                                        null
                                }
                            </div>

                    }
                </div>

                <ModalCreateMedia
                    showModalCreate={this.state.showModalCreate}
                    closeModalCreate={() => this.setState({ showModalCreate: false })}
                    listAlbums={this.listAlbums}
                    albumDetail={this.state.albumDetail}
                    listMediaById={this.listMediaById}
                />
                <ModalDeleteMedia
                    showModalDelete={this.state.showModalDeleteAlbum}
                    closeModalDelete={() => this.setState({ showModalDeleteAlbum: false, albumId: undefined })}
                    albumId={this.state.albumId}
                    deletedAlbum={this.deletedAlbum}
                />
                <ModalDeleteMedia
                    showModalDelete={this.state.showModalDeleteMedia}
                    closeModalDelete={() => this.setState({ showModalDeleteMedia: false })}
                    mediaSelect={this.state.listMediaSelect}
                    deletedMedia={this.deletedMedia}
                />
                <ModalEditname
                    showModalEditname={this.state.showModalEditname}
                    closeModalEditname={() => this.setState({ showModalEditname: false, mediaId: undefined })}
                    updateMedia={this.updateMedia}
                    mediaId={this.state.mediaId}
                    mediaName={this.state.mediaName}
                />
                <ModalMoveMedia
                    showModalMove={this.state.showModalMove}
                    closeModalMove={() => this.setState({ showModalMove: false })}
                    updateMedia={this.updateMedia}
                    mediaSelect={this.state.listMediaSelect}
                />

            </div>
        )
    }
}

export default Gallery