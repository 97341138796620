import { Type } from '../actions/extraData'

const initialState = {
    listMenu: [],
    listProductPDF: [],
    isLoadingPDF: false
}

export default function extraData(state = initialState, action) {
    const { type, payload } = action
    switch (type) {
        case (Type.GET_LIST_MENU_SEARCH): {
            return {
                ...state,
                listMenu: payload
            }
        }
        case (Type.ADD_PRODUCT_PDF): {
            return {
                ...state,
                listProductPDF: payload.dataProduct
            }
        }

        case (Type.OPEN_LOADING_BUTTON): {
            return {
                ...state,
                isLoadingPDF: true
            }
        }

        case (Type.CLOSE_LOADING_BUTTON): {
            return {
                ...state,
                isLoadingPDF: false
            }
        }

        default:
            return state
    }
}