import React, { useState, useEffect } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import menuCtrl from '../../../controllers/menuCtrl'
import api from '../../../config/api'
import moment from 'moment'
import 'moment/locale/th'
import { connect } from 'react-redux'
import { openLoading, closeLoading } from '../../../redux/actions/loading'
import {openNotificationAlert} from '../../../redux/actions/notification'
import * as actions_extraData from '../../../redux/actions/extraData'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";
const api_menuCtrl = new menuCtrl()



class ModalExtraData extends React.Component {

    constructor(props) {
        super()
        this.state = {
            detailMenu: props.modal.data.detailMenu,
            mainData: props.modal.data.mainData,
            extraData: null,
            detailCallcenter: '',
            callCenterHistory: []
        }
    }

    async componentDidMount() {
        this.props.openLoading()
        const result = await api_menuCtrl.getMenuExtraData(this.props.modal.data.detailMenu._id)
        if (result.status == 200) {
            this.setState({
                extraData: result.data[0].menuExtraData,
                callCenterHistory: result.data[0].callCenterHistory,
                detailCallcenter: result.data[0].callCenterHistory.length > 0 ? result.data[0].callCenterHistory[0].log : ""
            })
        }
        ////get Image from api
        this.getValuefromExtraData()
        ////get Image from api
        this.props.closeLoading()

    }

    getValuefromExtraData = () => {
        this.state.extraData.map((item, idx) => {
            this.setState({
                [item.keys]: item.values
            })
        })
    }

    dateFormat = (date) => {
        moment.locale('th')
        return moment(date).format('ll')
    }

    checkOpenCustomDateMin = (date, time) => {
        let start = date[0]
        let end = date[date.length - 1]

        if (time == "start") {
            date.map(item => {
                if (moment(item.date).isBefore(moment(start.date))) {
                    start = item
                }
            })
            return start.date

        } else if (time == "end") {
            date.map(item => {
                if (moment(item.date).isAfter(moment(end.date))) {
                    end = item
                }
            })
            return end.date
        }
    }

    onChangeDetail = (e) => {
        this.setState({
            detailCallcenter: e.target.value
        })
    }

    saveDetailData = async (detail, id) => {
        const data = {
            log: this.state.detailCallcenter,
            methods: "UPDATE",
            modelsRef: "Menu",
            menuId: id,
            fieldsRef: "ccRemark"
        }
        await api_menuCtrl.saveDetailCallcenterHistory(data)

        ///get new data////
        const result = await api_menuCtrl.getMenuExtraData(this.props.modal.data.detailMenu._id)
        this.setState({
            callCenterHistory: result.data[0].callCenterHistory,
            detailCallcenter: result.data[0].callCenterHistory.length > 0 ? result.data[0].callCenterHistory[0].log : ""
        })
        this.props.isClose()

    }

    render() {
        const {
            className,
            modal,
            isClose,
        } = this.props;
        const { data } = modal
        const { detailMenu, mainData, packageImage, ingredients, preservation, spicyLevel,
            sweetLevel, combination, additional, atSeason, gourmetPowder, halal,
            packaging, packagingSize, shop, deliveryDate, deliveryTime } = this.state

        return (
            <Modal isOpen={modal.isOpen} toggle={isClose} className={className} id="modal-extraData" size="lg">
                <ModalHeader toggle={isClose}>ดูรายละเอียดเพิ่มเติม</ModalHeader>
                <ModalBody>
                    <div className="col-12 row mt-4" id="detail-menu">
                        {this.props.url == "/extra-data" ?
                            <div className="col-12 text-right p-0">
                                <button className="btn-download" onClick={() => this.props.getDownloadPDF({productId:detailMenu.dataProduct[0]._id,menuName:detailMenu.name.th || detailMenu.name.en})}>{this.props.isLoadingPDF ? <FontAwesomeIcon icon={faCircleNotch} spin/> : ""}  ดาวน์โหลด</button>
                            </div> : ""
                        }

                        <div className="col-6">
                            <img src={
                                `${api.THUMP}/800x600/image/${this.state.detailMenu.dataProduct[0].cover.fileName}`
                            } className="card-img-top" alt="..." />
                        </div>
                        <div className="col-6">
                            <p>ชื่อเมนู</p>
                            <span>{data && data.detailMenu.name && data.detailMenu.name.th}</span>
                        </div>

                        <hr />


                        <div className="col-6">
                            <p>ชื่อครัว</p>
                            <span>{data && data.mainData.dataShop[0].name}</span>
                        </div>
                        <div className="col-6">
                            <p>ราคา (บาท) ไม่รวมค่าจัดส่ง</p>
                            <span>{data.detailMenu.dataProduct[0].priceNet}.-</span>
                        </div>



                        <hr />

                        <div className="col-6">
                            <p>ปริมาณ</p>
                            <span>{detailMenu.quantityPerUnit || "-"}</span>
                        </div>

                        <div className="col-6">
                            <p>จุดเด่น</p>
                            <span>{detailMenu.detail || "-"}</span>
                        </div>

                        <hr />

                        <div className="col-6">
                            <p>ประเภทการสั่งซื้อ</p>
                            <span>{detailMenu.dataProduct[0].preorderTime ? "พรีออเดอร์ล่วงหน้าอย่างน้อย" : ""}
                                {detailMenu && detailMenu.dataProduct[0].preorderTime && detailMenu.dataProduct[0].preorderTime.time}
                                {detailMenu && detailMenu.dataProduct[0].preorderTime && detailMenu.dataProduct[0].preorderTime.unit == "hours" ? "ชั่วโมง" : detailMenu && detailMenu.dataProduct[0].preorderTime ? "วัน" : "-"}</span>
                        </div>
                        <div className="col-6">
                            <p>จำนวน</p>
                            <span>{detailMenu && detailMenu.dataProduct[0].limit ? detailMenu.dataProduct[0].amount : "ไม่จำกัดจำนวน"}</span>
                        </div>



                        <hr />

                        <div className="col-6">
                            <p>สถานที่จัดส่ง</p>
                            <span>{mainData && mainData.dataContact.filter(item => item.no == "1").map(address => address.address) || "-"}</span>
                        </div>
                        <div className="col-6">
                            <p>วันที่เริ่มขาย - สิ้นสุด</p>
                            <span>{detailMenu && detailMenu.dataProduct[0].preorderTime && this.dateFormat(detailMenu.dataProduct[0].openPeriod ? detailMenu.dataProduct[0].openPeriod.open : this.checkOpenCustomDateMin(detailMenu.dataProduct[0].openCustom, "start"))} - {detailMenu && detailMenu.dataProduct[0].preorderTime && this.dateFormat(detailMenu.dataProduct[0].openPeriod ? detailMenu.dataProduct[0].openPeriod.end : this.checkOpenCustomDateMin(detailMenu.dataProduct[0].openCustom, "end"))}</span>
                        </div>




                        <hr />

                        <div className="col-6">
                            <p>วันที่จัดส่งถึงลูกค้า</p>
                            <span>{deliveryDate || "-"}</span>
                        </div>
                        <div className="col-6">
                            <p>เวลาจัดส่งถึงลูกค้า</p>
                            <span>{deliveryTime || "-"}</span>
                        </div>

                        <hr />
                        <div className="col-6">
                            <p>ส่วนประกอบอาหาร</p>
                            <span>{ingredients || "-"}</span>
                        </div>
                        <div className="col-6">
                            <p>การเก็บรักษา</p>
                            <span>{preservation || "-"}</span>
                        </div>

                        <hr />
                        <div className="col-6">
                            <p>ผงชูรส</p>
                            <span>{gourmetPowder == "ใส่" ? "ใส่" : "ไม่ใส่"}</span>
                        </div>
                        <div className="col-6">
                            <p>อิสลาม</p>
                            <span>{halal == "ใช่" ? "อิสลามทานได้" : "อิสลามทานไม่ได้"}</span>
                        </div>
                        <hr />


                        <div className="col-6">
                            <p>ระดับความเผ็ด</p>
                            <span>{spicyLevel || "-"}</span>
                        </div>
                        <div className="col-6">
                            <p>ระดับความหวาน</p>
                            <span>{sweetLevel || "-"}</span>
                        </div>

                        <hr />




                        <div className="col-6">
                            <p>รับประทานกับอะไรอร่อย</p>
                            <span>{combination || "-"}</span>
                        </div>
                        <div className="col-6">
                            <p>ฤดูกาลของสินค้า</p>
                            <span>{atSeason || "-"}</span>
                        </div>

                        <hr />
                        <div className="col-6">
                            <p>ลักษณะบรรจุภัณฑ์</p>
                            <span>{packaging || "-"}</span>
                        </div>
                        <div className="col-6">
                            <p>ขนาดบรรจุภัณฑ์</p>
                            <span>{packagingSize || "-"}</span>
                        </div>

                        <hr />
                        <div className="col-6">
                            <p>รูปบรรจุภัณฑ์</p>
                            <span>
                                {
                                    packageImage ? packageImage.map((item, idx) => (
                                        <img src={
                                            item
                                        } className="card-image" alt="..." key={idx} />
                                    )) : "-"
                                }
                            </span>
                        </div>
                        <div className="col-6">
                            <p>รายละเอียดเพิ่มเติม</p>
                            <span>
                                {
                                    additional || "-"
                                }
                            </span>
                        </div>

                        <hr />

                        {this.props.url == "/extra-data" ?
                            <div className="col-6">
                                <p>รายละเอียดเพิ่มเติมโดย Call Center</p>
                                <span>
                                    {
                                        this.state.callCenterHistory.length > 0 && this.state.callCenterHistory[0].log || "-"
                                    }
                                </span>
                            </div> : <div className="col-6" id="more-detail-callcenter">
                                <p>รายละเอียดเพิ่มเติมโดย Call Center</p>
                                <textarea className="text-area-detail" rows="4" cols="50" onChange={(e) => this.onChangeDetail(e)} value={this.state.detailCallcenter || ""} />
                                <label className="float-right d-flex text-right font-size-12px">
                                    {this.state.callCenterHistory.length > 0 && this.state.callCenterHistory[0].createAt ?
                                        <span className="mr-3">แก้ไขล่าสุดโดย {this.state.callCenterHistory.length > 0 ? this.state.callCenterHistory && this.state.callCenterHistory[0].createBy : ""}<br />
                                            {
                                                (moment(this.state.callCenterHistory && this.state.callCenterHistory[0].createAt).format("DD/MM/") + moment(this.state.callCenterHistory && this.state.callCenterHistory[0].createAt).add(543, 'years').format("YYYY HH:mm"))}
                                        </span> : ""
                                    }

                                    <button className="btn btn-submit" onClick={() => this.saveDetailData(this.state.detailCallcenter, detailMenu._id)}>บันทึก</button>
                                </label>
                            </div>
                        }

                    </div>
                </ModalBody>
            </Modal>
        );
    }
}

const mapStateToProps = (state) => ({
    isLoadingPDF: state.extraData.isLoadingPDF
})

const mapDispatchToProps = (dispatch) => ({
    openLoading: () => dispatch(openLoading()),
    closeLoading: () => dispatch(closeLoading()),
    openNotificationAlert: (payload) => dispatch(openNotificationAlert(payload)),
    getDownloadPDF: (payload) => dispatch(actions_extraData.getDownloadPDF(payload))
})


export default connect(mapStateToProps, mapDispatchToProps)(ModalExtraData)
// export default ModalExtraData;


