import api from '../config/api'
import axios from 'axios'


const getAlbums = async (sort) => {
    const albums = await axios({
        method: 'GET',
        url: `${api.CONNECTOR_SOCIAL_API}/getAlbum/${sort}`,
        headers: {
            'Content-Type': 'application/json',
        }
    })

    return albums.data
}

const checkDuplicateAlbum = async (name) => {
    const albums = await axios({
        method: 'GET',
        url: `${api.CONNECTOR_SOCIAL_API}/checkDuplicateAlbum?name=${name}`,
        headers: {
            'Content-Type': 'application/json',
        }
    })

    return albums.data
}

const createAlbum = async (data) => {
    const albumData = await axios({
        method: 'POST',
        url: `${api.CONNECTOR_SOCIAL_API}/createAlbum`,
        headers: {
            'Content-Type': 'application/json',
        },
        data: data
    })

    return albumData.data
}

const createMedia = async (data) => {
    const mediaData = await axios({
        method: 'POST',
        url: `${api.CONNECTOR_SOCIAL_API}/createMedia`,
        headers: {
            'Content-Type': 'application/json',
        },
        data: data
    })

    return mediaData.data
}

const getMedia = async (query, sort="used") => {
    const url = query ? `${api.CONNECTOR_SOCIAL_API}/getMedia/${sort}?${query}` : `${api.CONNECTOR_SOCIAL_API}/getMedia/${sort}`
    const medias = await axios({
        method: 'GET',
        url: url,
        headers: {
            'Content-Type': 'application/json',
        }
    })

    return medias.data
}

const getMediaById = async (id) => {
    const medias = await axios({
        method: 'GET',
        url: `${api.CONNECTOR_SOCIAL_API}/getMediaById/${id}`,
        headers: {
            'Content-Type': 'application/json',
        }
    })

    return medias.data
}

const saveLogMediaRecent = async (data) => {
    
    await axios({
        method: 'POST',
        url: `${api.CONNECTOR_SOCIAL_API}/createRecentMedia`,
        headers: {
            'Content-Type': 'application/json',
        },
        data: data
    })
}

const getRecentMedia = async (userId) => {

    const recentData = await axios({
        method: 'GET',
        url: `${api.CONNECTOR_SOCIAL_API}/getRecentMedia/${userId}`,
        headers: {
            'Content-Type': 'application/json',
        }
    })

    return recentData.data
}

const createUploadMediaLog = async (data) => {
    
    await axios({
        method: 'POST',
        url: `${api.CONNECTOR_SOCIAL_API}/createUploadMediaLog`,
        headers: {
            'Content-Type': 'application/json',
        },
        data: data
    })
}

const searchAlbumByName = async (albumName) => {

    const albumData = await axios({
        method: 'GET',
        url: `${api.CONNECTOR_SOCIAL_API}/searchAlbumByName/${albumName}`,
        headers: {
            'Content-Type': 'application/json',
        }
    })

    return albumData.data
}

const searchMediaByName = async (mediaName) => {

    const mediaData = await axios({
        method: 'GET',
        url: `${api.CONNECTOR_SOCIAL_API}/searchMediaByName/${mediaName}`,
        headers: {
            'Content-Type': 'application/json',
        }
    })

    return mediaData.data
}

const updateMedia = async (id, data) => {
    const mediaData = await axios({
        method: 'PATCH',
        url: `${api.CONNECTOR_SOCIAL_API}/updateMediaById/${id}`,
        headers: {
            'Content-Type': 'application/json',
        },
        data: data
    })

    return mediaData.data
}

const deletedMedia = async (id) => {
    const mediaData = await axios({
        method: 'DELETE',
        url: `${api.CONNECTOR_SOCIAL_API}/deleteMediaById/${id}`,
        headers: {
            'Content-Type': 'application/json',
        }
    })

    return mediaData.data
}

const deleteAlbum = async (id) => {
    const albumData = await axios({
        method: 'DELETE',
        url: `${api.CONNECTOR_SOCIAL_API}/deleteAlbumById/${id}`,
        headers: {
            'Content-Type': 'application/json',
        }
    })

    return albumData.data
}

export {
    getAlbums,
    checkDuplicateAlbum,
    createAlbum,
    createMedia,
    getMedia,
    getMediaById,
    saveLogMediaRecent,
    getRecentMedia,
    createUploadMediaLog,
    searchAlbumByName,
    searchMediaByName,
    updateMedia,
    deletedMedia,
    deleteAlbum,
}