export const Type = {
    GET_LIST_MENU_REQUIRE: "GET_LIST_MENU_REQUIRE",
    GET_LIST_MENU_SUCCESS: "GET_LIST_MENU_SUCCESS",
    SEARCH_MENU_REQUIRE: "SEARCH_MENU_REQUIRE",
    SEARCH_MENU_SUCCESS: "SEARCH_MENU_SUCCESS",
}

//get List Product //
export const getListMenu = (payload) => ({
    type: Type.GET_LIST_MENU_REQUIRE,
    payload
})
//

export const onSearch = (payload) => 
({
    type : Type.SEARCH_MENU_REQUIRE,
    payload
})

