import React from 'react';
import IconCheckBoxInActive from '../../../assets/Icons/checkBoxInActive.png';
import IconCheckBoxActive from '../../../assets/Icons/checkBoxActive.png';
import IconBin from '../../../assets/Icons/bin.png';
// import InconEdit from '../../../assets/Icons/Edit.png';
import IconCheckActiveListAddress from '../../../assets/Icons/IconCheckActiveListAddress.svg';
import IconCheckInActiveListAddress from '../../../assets/Icons/IconCheckInActiveListAddress.svg';

const ListAddressUser = ({ list, addressId, clickSelect, clickSetDefault, clickDelete, addressDefaultId, showAddressDefault }) => {
    return (
        list && list.map((item, idx) =>
            <div className="position-relative" key={idx}>
                <div
                    className={`mt-2 box-list-address-user ${addressId === item.id ? "box-list-address-active" : "box-list-address-inactive"}`}
                    key={idx}>
                    <div className="check">
                        <img
                            src={addressId === item.id ? IconCheckBoxActive : IconCheckBoxInActive}
                            alt="IconCheckBoxInActive"
                            onClick={() => clickSelect(item)}
                            className="size-icon-16px cursor-pointer" />
                    </div>
                    <div className="column-list-modal">
                        <div className="wrap-list">
                            <div className="address">
                                <span className="font-size-16px">{item.address} {item.subdistrict} {item.district} {item.province} {item.zipcode}</span>
                            </div>
                            <div className="trash">
                                {addressId === item.id ? null : <img
                                    onClick={() => clickDelete(item)}
                                    src={IconBin}
                                    alt="IconBin" className="cursor-pointer"
                                />}

                            </div>
                        </div>
                        <div className="wrap-list">
                            <div className="more-details">
                                <span className="font-size-14px mt-2">รายละเอียดที่อยู่เพิ่มเติม : {item.addressExtra || "-"}</span>
                            </div>
                        </div>


                        {
                            showAddressDefault ?
                                <div>
                                    <div className="wrap-list">
                                        <div className="check-box">
                                            {addressId === item.id ?
                                                <>
                                                    <img
                                                        src={addressDefaultId === item.id ? IconCheckActiveListAddress : IconCheckInActiveListAddress}
                                                        alt="checkBoxActiveSquare"
                                                        className="cursor-pointer cursor-pointer"
                                                        onClick={() => clickSetDefault(item)}
                                                    />
                                                    <label className="font-size-14px"> ตั้งเป็นที่อยู่เริ่มต้น </label>
                                                </>
                                                : null}
                                        </div>
                                        <div className="">
                                            {(addressId === item.id && (addressDefaultId === item.id)) ? <div className="text-address-default"> <span className="font-size-14px ">
                                                ที่อยู่เริ่มต้น
                            </span></div> : null}
                                        </div>
                                    </div>

                                    <div className="wrap-list">
                                        <div className="">
                                            {(addressId !== item.id && (addressDefaultId === item.id)) ? <div className="text-address-default"> <span className="font-size-14px">
                                                ที่อยู่เริ่มต้น
                            </span> </div> : null}
                                        </div>
                                    </div>
                                </div>
                                :
                                null
                        }
                    </div>
                    <div className="cover-bar" />
                </div>
            </div>
        )

    );

}
export default ListAddressUser;