import React from 'react';
import Modal from '../../Modal';
import { connect } from 'react-redux';
import IconNext from '../../../assets/Icons/Next.svg';
import IconPrevious from '../../../assets/Icons/Previous.svg';
import CheckBoxInactiveRed from '../../../assets/Icons/CheckBoxInactiveRed.svg';
import CheckBoxActiveRed from '../../../assets/Icons/CheckBoxActiveRed.svg';
import CheckBoxActivePrimary from '../../../assets/Icons/CheckBoxActivePrimary.svg';
import CheckBoxInActivePrimary from '../../../assets/Icons/CheckBoxInactivePrimary.svg';
import IconLock from '../../../assets/Icons/Lock.svg';
import purchasesCtrl from '../../../controllers/purchasesCtrl';
import promotionCtrl from '../../../controllers/promotionCtrl';
import Cleave from 'cleave.js/react';
import * as actions_Notification from '../../../redux/actions/notification';
import * as utils from '../../../utils/utils';
import moment from 'moment';
import api from '../../../config/api';
import { Tooltip } from 'reactstrap';
import ImageViewer from './ImageViewer';

const api_purchasesCtrl = new purchasesCtrl();
const api_promotionCtrl = new promotionCtrl();
class ModalCheckTranfer extends React.Component {
    state = {
        showImagePreview: false,
        index_purchaseNote: 0,
        purchase: {
            purchaseNote: []
        },
        value: {
            isProblem: false,
            remark: '',
            issueCode: '',
            isConfirm: false,
            encrypted: null
        },
        error: {
            issueCode: false,
            priceNet: false,
            timeTran: false,
            dateTran: false,
            note: false
        },
        valuePurchaseNote: {
            priceNet: '',
            timeTran: '',
            dateTran: '',
            note: ''
        },
        defaultValuePurchaseNote: {
            priceNet: '',
            timeTran: '',
            dateTran: '',
            note: ''
        },
        remarkDefault: "",
        lengthRemark: 0,
        noteDefault: "",
        lengthNote: 0,
        isValidateForm: false,
        isLoadingConfirmPayment: false,
        isLoadingApprovePayment: false,
        isLoadingSaveNote: false,
        isonClickConfirm: false,
        isonClickApprove: false,
        isonClickUnlock: false,
        isOpentooltipConfirm: false,
        isOpentooltipSaveNote: false,
        issueCodeCheckSuccess: false,
        // rotation: 0
    };


    baseState = { ...this.state }

    async componentWillReceiveProps(nextProps) {
        if (nextProps.modalCheckTranfer.isOpen && !this.props.modalCheckTranfer.isOpen) {
            const purchaseId = nextProps.modalCheckTranfer.data.id;
            this.setDataToState(purchaseId);
        }

        if (!nextProps.modalCheckTranfer.isOpen && this.props.modalCheckTranfer.isOpen) {
            this.setState({
                ...this.baseState
            });
        }
    }


    setDataToState = async (purchaseId, index_purchaseNote) => {
        const { data } = await api_purchasesCtrl.getPurchaseById({
            purchaseId
        });
        if (data.purchaseNote && data.purchaseNote.length === 0) {
            return
        }
        const count_purchaseNote = data.purchaseNote.length - 1;
        const valuePurchaseNote = data.purchaseNote[index_purchaseNote != null ? index_purchaseNote : count_purchaseNote];
        const transPayment = moment(valuePurchaseNote.transPayment).subtract(7, "h");
        const date = transPayment.format("DD/MM/YYYY")
        const split_transPayment = date.split("/");
        const year = parseInt(split_transPayment[2]) + 543 ;
        const new_transPayment = split_transPayment[0] + "/" + split_transPayment[1] + "/" + year;
        this.setState({
            value: {
                ...this.state.value,
                remark: data.remark || ""
            },
            purchase: {
                ...data,
                remark: data.remark
            },
            valuePurchaseNote: {
                ...this.state.purchaseNote,
                ...valuePurchaseNote,
                priceNet: valuePurchaseNote.priceNet || 0,
                // dateTran: moment(transPayment).format("DD/MM/YYYY"),
                dateTran: new_transPayment,
                timeTran: moment(transPayment).format("HH:mm"),
                note: valuePurchaseNote.note || '',
            },
            defaultValuePurchaseNote: {
                ...this.state.purchaseNote,
                ...valuePurchaseNote,
                priceNet: valuePurchaseNote.priceNet || 0,
                // dateTran: moment(transPayment).format("DD/MM/YYYY"),
                dateTran: new_transPayment,
                timeTran: moment(transPayment).format("HH:mm"),
                note: valuePurchaseNote.note || '',
            },
            index_purchaseNote: index_purchaseNote != null ? index_purchaseNote : count_purchaseNote,
            lengthNote: (valuePurchaseNote.note && valuePurchaseNote.note.length) || 0,
            noteDefault: valuePurchaseNote.note || '',
            remarkDefault: data.remark || "",
            lengthRemark: (data.remark && data.remark.length) || 0,
        });
    }



    onMouseOverImage = () => {
        if (this.state.showImagePreview) {
            return
        }
        this.setState({
            showImagePreview: true
        });
    }

    onMouseOutImage = () => {
        if (!this.state.showImagePreview) {
            return
        }
        this.setState({
            showImagePreview: false
        });
    }

    checkIsProblem = async () => {
        const { error } = this.state;
        if (error.issueCode) {
            await this.setState({
                value: {
                    ...this.state.value,
                    issueCode: '',
                    encrypted: null
                },
                error: {
                    issueCode: false
                }
            });
        }

        this.setState({
            value: {
                ...this.state.value,
                isProblem: !this.state.value.isProblem,
                isConfirm: false
            }
        });
    }

    checkIsConfirm = () => {
        this.setState({
            value: {
                ...this.state.value,
                isConfirm: !this.state.value.isConfirm
            }
        });
    }

    handleChangeTextProlblem = (evt) => {
        this.setState({
            value: {
                ...this.state.value,
                [evt.target.name]: evt.target.value,
            }
        });
    };


    handleChangeValuePurchaseNote = (evt) => {
        this.setState({
            valuePurchaseNote: {
                ...this.state.valuePurchaseNote,
                [evt.target.name]: evt.target.value
            },
            error:{
                ...this.state.error,
                [evt.target.name]: false
            }
        })
    }


    handleClickUnlock = async () => {
        this.setState({
            isonClickUnlock: true
        });
        try {
            const issueCode = this.state.value.issueCode;
            const { encode } = await api_promotionCtrl.checkCodeIssue({ issueCode });
            this.setState({
                value: {
                    ...this.state.value,
                    encrypted: encode,
                    isProblem: false
                },
                error: {
                    issueCode: false
                },
                isonClickUnlock: false,
                issueCodeCheckSuccess: true
            });

        } catch (error) {
            this.setState({
                value: {
                    ...this.state.value,
                    encrypted: null,
                },
                error: {
                    issueCode: true
                }
            });
        }
    }


    handleClickPrevious = () => {
        const this_index = this.state.index_purchaseNote - 1;
        const this_purchaseNote = this.state.purchase.purchaseNote[this_index];
        const note = this_purchaseNote.note || '';
        const transPayment = moment(this_purchaseNote.transPayment).subtract(7, "h");

        this.setState({
            index_purchaseNote: this_index,
            valuePurchaseNote: {
                ...this_purchaseNote,
                note: this_purchaseNote.note || "",
                dateTran: moment(transPayment).format("DD/MM") + moment(transPayment).add(543,'years').format("/YYYY"),
                timeTran: moment(transPayment).format("HH:mm"),
                lengthNote: (this_purchaseNote.note && this_purchaseNote.note.length) || 0,
                noteDefault: this_purchaseNote.note || ''
            },
            defaultValuePurchaseNote: {
                ...this_purchaseNote,
                note: this_purchaseNote.note || "",
                dateTran: moment(transPayment).format("DD/MM") + moment(transPayment).add(543,'years').format("/YYYY"),
                timeTran: moment(transPayment).format("HH:mm"),
                lengthNote: (this_purchaseNote.note && this_purchaseNote.note.length) || 0,
                noteDefault: this_purchaseNote.note || ''
            },
            noteDefault: note,
            lengthNote: note.length,
            error: {
                ...this.state.error,
                priceNet: false,
                timeTran: false,
                dateTran: false,
                note: false
            },
            isValidateForm: false
        });
    }

    handleClickNext = () => {
        const this_index = this.state.index_purchaseNote + 1;
        const this_purchaseNote = this.state.purchase.purchaseNote[this_index];
        const note = this_purchaseNote.note || '';
        const transPayment = moment(this_purchaseNote.transPayment).subtract(7, "h");
        this.setState({
            index_purchaseNote: this_index,
            valuePurchaseNote: {
                ...this_purchaseNote,
                note: this_purchaseNote.note || "",
                dateTran: moment(transPayment).format("DD/MM") + moment(transPayment).add(543,'years').format("/YYYY"),
                timeTran: moment(transPayment).format("HH:mm"),
                lengthNote: (this_purchaseNote.note && this_purchaseNote.note.length) || 0,
                noteDefault: this_purchaseNote.note || ''
            },
            defaultValuePurchaseNote: {
                ...this_purchaseNote,
                note: this_purchaseNote.note || "",
                dateTran: moment(transPayment).format("DD/MM") + moment(transPayment).add(543,'years').format("/YYYY"),
                timeTran: moment(transPayment).format("HH:mm"),
                lengthNote: (this_purchaseNote.note && this_purchaseNote.note.length) || 0,
                noteDefault: this_purchaseNote.note || ''
            },
            noteDefault: note,
            lengthNote: note.length,
            error: {
                ...this.state.error,
                priceNet: false,
                timeTran: false,
                dateTran: false,
                note: false
            },
            isValidateForm: false
        });
    }



    handleClickSaveNote = async () => {
        this.setState({
            isLoadingSaveNote: true,
        });
        try {
            const { value, remarkDefault, lengthRemark, purchase, index_purchaseNote } = this.state;
            const new_remark = value.remark;
            const remarkDefault_new = new_remark.slice(0, lengthRemark);
            if (remarkDefault_new === remarkDefault) {
                const data_Approve = {
                    purchaseId: purchase.id,
                    userId: purchase.userId,
                    remark: value.remark,
                    priceNet: purchase.priceNet,
                };

                const { data } = await api_purchasesCtrl.approvePayment(data_Approve);
                if (data && data.status === 200 && data.status_text === "OK") {
                    const purchaseId = this.props.modalCheckTranfer.data.id;
                    this.setDataToState(purchaseId, index_purchaseNote);
                    this.setState({
                        isLoadingSaveNote: false,
                        isOpentooltipSaveNote: true
                    });

                    setTimeout(() => {
                        this.setState({
                            isOpentooltipSaveNote: false
                        });
                    }, 2000);
                }
            } 
            else {
                const notification = {
                    message: 'มีการเปลี่ยนแปลงโน้ตเดิม',
                    level: 'error',
                    autoDismiss: 0,
                    position: "tr"
                }
                this.props.openNotificationAlertComponent(notification);
                this.setState({
                    isLoadingSaveNote: false,
                    value: {
                        ...this.state.value,
                        remark: remarkDefault
                    }
                });
            }
        } catch (error) {
            this.setState({
                isLoadingSaveNote: false,
            });
        }
    }


    handleClickConfirmPayment = async () => {
        const { valuePurchaseNote } = this.state

        if (valuePurchaseNote.dateTran.trim().length == 0 || valuePurchaseNote.timeTran.trim().length == 0 || valuePurchaseNote.priceNet.toString().trim().length == 0) {
            if (valuePurchaseNote.dateTran.trim().length == 0) {
                this.setState({
                    error: {
                        ...this.state.error,
                        dateTran: true
                    }
                })
            }
            if (valuePurchaseNote.timeTran.trim().length == 0) {
                this.setState({
                    error: {
                        ...this.state.error,
                        timeTran: true
                    }
                })
            }
            if (valuePurchaseNote.priceNet.toString().trim().length == 0) {
                this.setState({
                    error: {
                        ...this.state.error,
                        priceNet: true
                    }
                })
            }
            return
        }
    

        const checkDate = utils.validateDateRegular.test(this.state.valuePurchaseNote.dateTran)
        if (!checkDate) {
            this.setState({
                error: {
                    ...this.state.error,
                    dateTran: true
                }
            })
        }

        this.setState({
            isLoadingConfirmPayment: true,
            isonClickConfirm: true
        });

        try {
            const { valuePurchaseNote, noteDefault, lengthNote, purchase, index_purchaseNote } = this.state;
            const new_note = valuePurchaseNote.note;
            const noteDefault_new = new_note.slice(0, lengthNote);
            const isValidate = await this.validateForm();

            if (!isValidate) {
                // check new note and note default.
                // if (noteDefault_new === noteDefault) {
                    const split_dateTime = valuePurchaseNote.dateTran.split('/');
                    const year = parseInt(split_dateTime[2]) - 543;
                    const newDateTran = split_dateTime[0] + "/" + split_dateTime[1] + "/" + year;
                    const transPayment = utils.setDateTimeFormatServer(newDateTran, valuePurchaseNote.timeTran, "YYYY-MM-DD", "HH:mm");
                    const split_priceNet = valuePurchaseNote.priceNet.toString();
                    const dataConfirmPayment = {
                        purchaseId: valuePurchaseNote.purchaseId,
                        userId: purchase.userId,
                        transPayment: transPayment,
                        media: valuePurchaseNote.media,
                        priceNet: parseFloat(split_priceNet.replace(',', '')),
                        purchaseNoteId: valuePurchaseNote.id,
                        note: valuePurchaseNote.note || ''
                    };
                    const { data } = await api_purchasesCtrl.confirmPaymentInApprove(dataConfirmPayment);
                    if (data && data.status === "success") {
                        const purchaseId = this.props.modalCheckTranfer.data.id;
                        this.setDataToState(purchaseId, index_purchaseNote);
                        this.setState({
                            isLoadingConfirmPayment: false,
                            isonClickConfirm: false,
                            isOpentooltipConfirm: true
                        });
                        setTimeout(() => { // close tooltip
                            this.setState({
                                isOpentooltipConfirm: false
                            });
                        }, 2000)
                    }
                // } else {
                //     const notification = {
                //         message: 'มีการเปลี่ยนแปลงโน้ตเดิม',
                //         level: 'error',
                //         autoDismiss: 0,
                //         position: "tr"
                //     }
                //     this.props.openNotificationAlertComponent(notification);
                //     this.setState({
                //         valuePurchaseNote: {
                //             ...this.state.valuePurchaseNote,
                //             note: noteDefault
                //         },
                //         isLoadingConfirmPayment: false
                //     });
                // }
            } else {
                this.setState({
                    isLoadingConfirmPayment: false
                });
            }
        } catch (error) {
            console.log(error);
            this.setState({
                isLoadingConfirmPayment: false,
                isonClickConfirm: false
            });
        }
    }


    showTextErrorConfirm = () => {
        const { isValidateForm } = this.state;
        if (isValidateForm) {
            return "ยังกรอกข้อมูลไม่ครบ"
        } else {
            return ""
        }
    };

    showTextError = () => {
        const { error, isValidateForm } = this.state;
        if (isValidateForm) {
            return "ยังกรอกข้อมูลไม่ครบ"
        } else if (error.issueCode) {
            return "Issue code ไม่ถูกต้อง"
        }
    }

    disableButtonApprove = () => {
        const { value } = this.state;
        if (value.isProblem || !value.isConfirm) {
            return true;
        }

        return false;
    }


    validateForm = () => {

        let error = {
            priceNet: false,
            timeTran: false,
            dateTran: false,
            note: false
        }
        let isErrorValidate = false;

        const { valuePurchaseNote } = this.state;

        if (valuePurchaseNote.dateTran.trim().length === 0) {
            error.dateTran = true;
            isErrorValidate = true;
        } else {
            if (!utils.checkFormatDate(valuePurchaseNote.dateTran)) {
                error.dateTran = true;
                isErrorValidate = true;
            }
            // else {
            //     const toDay = moment().format('YYYY-MM-DD');
            //     const transPayment = utils.setDateTimeFormatServer(valuePurchaseNote.dateTran, valuePurchaseNote.timeTran, "YYYY-MM-DD", "HH:mm");
            //     const transPayment_format = moment(transPayment).format("YYYY-MM-DD");
            //     if (moment(transPayment_format).isAfter(toDay)) {

            //     }
            // }
        };

        if (valuePurchaseNote.timeTran.trim().length === 0) {
            error.timeTran = true;
            isErrorValidate = true;
        } else {
            if (!utils.checkFormatTime(valuePurchaseNote.timeTran)) {
                error.timeTran = true;
                isErrorValidate = true;
            }
        };

        if (valuePurchaseNote.priceNet.toString().trim().length === 0) {
            error.priceNet = true;
            isErrorValidate = true;
        };


        this.setState({
            error: {
                ...this.state.error,
                ...error
            },
            isValidateForm: isErrorValidate
        });

        return isErrorValidate;

    }



    handleClickApprove = async () => {
        this.setState({
            isonClickApprove: true,
            isLoadingApprovePayment: true
        });

        const isValidateError = await this.validateForm();
        if (!isValidateError) {
            try {
                const { purchase, value, valuePurchaseNote, defaultValuePurchaseNote } = this.state;

                const new_purchase = {
                    priceNet: valuePurchaseNote.priceNet,
                    timeTran: valuePurchaseNote.timeTran,
                    dateTran: valuePurchaseNote.dateTran,
                    note: valuePurchaseNote.note
                };
                const old_purchase = {
                    priceNet: defaultValuePurchaseNote.priceNet,
                    timeTran: defaultValuePurchaseNote.timeTran,
                    dateTran: defaultValuePurchaseNote.dateTran,
                    note: defaultValuePurchaseNote.note
                }

                if (JSON.stringify(new_purchase) === JSON.stringify(old_purchase)) {
                    const data_Approve = {
                        purchaseId: purchase.id,
                        userId: purchase.userId,
                        remark: value.remark,
                        priceNet: purchase.priceNet,
                        issueCode: value.encrypted
                    };

                    if (!value.encrypted && value.encrypted == null) {
                        delete data_Approve.issueCode;
                    }
                    const res_approve = await api_purchasesCtrl.approvePayment(data_Approve);
                    if (res_approve && res_approve.data && res_approve.data.status_text === "OK") {
                        this.setState({
                            isonClickApprove: false,
                            isLoadingApprovePayment: false
                        });
                        this.props.closeModal('success');
                    }
                } else {
                    const notification = {
                        message: 'กรุณาบันทึกการชำระเงิน ' + (this.state.index_purchaseNote + 1),
                        level: 'error',
                        autoDismiss: 0,
                        position: "tr"
                    }
                    this.props.openNotificationAlertComponent(notification);
                    this.setState({
                        isonClickApprove: false,
                        isLoadingApprovePayment: false
                    });
                }

            } catch (error) {
                const res_error = error.response.data;
                this.setState({
                    // isonClickApprove: false,
                    isLoadingApprovePayment: false
                });

                if (res_error.error && res_error.error.message === "CREATE_SHIPPING_ERR" && res_error.error.name === "UPDATE_INVOICE_FAIL" && res_error.error.statusCode === 422) {
                    const notification = {
                        message: 'ออเดอร์เลยวันจัดส่ง ไม่สามารถทำรายการต่อได้',
                        level: 'error',
                        autoDismiss: 0,
                        position: "tr"
                    }
                    this.props.openNotificationAlertComponent(notification);
                } else {
                    const notification = {
                        message: 'มีปัญหาบางอย่างเกิดขึ้น กรุณาลองใหม่อีกครั้ง',
                        level: 'error',
                        autoDismiss: 0,
                        position: "tr"
                    }
                    this.props.openNotificationAlertComponent(notification);
                }
            }
        } else {
            this.setState({
                isLoadingApprovePayment: false
            });
        }
    }


    toggleIsopenToolti = () => {
        // this.setState(prevState => ({
        //     isOpentooltip: false
        // }));
    }


    handleClickDot = (index) => {
        const this_index = (index);
        const this_purchaseNote = this.state.purchase.purchaseNote[this_index];
        const note = this_purchaseNote.note || '';
        const transPayment = moment(this_purchaseNote.transPayment).subtract(7, "h");

        this.setState({
            index_purchaseNote: this_index,
            valuePurchaseNote: {
                ...this_purchaseNote,
                note: this_purchaseNote.note || "",
                dateTran: moment(transPayment).add(543,'y').format("DD/MM/YYYY"),
                timeTran: moment(transPayment).format("HH:mm"),
                lengthNote: (this_purchaseNote.note && this_purchaseNote.note.length) || 0,
                noteDefault: this_purchaseNote.note || ''
            },
            defaultValuePurchaseNote: {
                ...this_purchaseNote,
                note: this_purchaseNote.note || "",
                dateTran: moment(transPayment).format("DD/MM/YYYY"),
                timeTran: moment(transPayment).format("HH:mm"),
                lengthNote: (this_purchaseNote.note && this_purchaseNote.note.length) || 0,
                noteDefault: this_purchaseNote.note || ''
            },
            noteDefault: note,
            lengthNote: note.length,
            error: {
                ...this.state.error,
                priceNet: false,
                timeTran: false,
                dateTran: false,
                note: false
            },
            isValidateForm: false
        });
    }

    // rotate = () => {
    //     let newRotation = this.state.rotation + 90;
    //     if (newRotation >= 360) {
    //         newRotation = - 360;
    //     }
    //     this.setState({
    //         rotation: newRotation,
    //     })
    // }

    render() {
        
        const { modalCheckTranfer, closeModal } = this.props;
        const { purchase, value, index_purchaseNote, valuePurchaseNote, error } = this.state;
        if (purchase == null) {
            return ''
        }
        const { invoice, purchaseNote } = purchase;

        return (
            <Modal
                isOpen={modalCheckTranfer.isOpen}
                closeModal={closeModal}
                size="lg"
                title={modalCheckTranfer.title}
            >
                <div id="modal-check-tranfer">
                    {
                        purchaseNote && purchaseNote.length === 0 ? <div className="d-flex justify-content-center">
                            <span className="font-bold-size-16px">
                                purchaseNote not found
                            </span>
                        </div>
                            :
                            <div className="wrap-modal">
                                <div className="item-left">
                                    <div className="d-flex justify-content-center">
                                        {valuePurchaseNote.media && valuePurchaseNote.media[0].fileName &&
                                            // <div className="image-slip" style={{width:"25%"}}> 
                                            //      <img src={`${api.MEDIA_API}/image/${valuePurchaseNote.media[0].fileName}`} alt="slip"/>
                                            // </div>

                                            <div onMouseEnter={() => this.onMouseOverImage()}
                                                className="image-slip"
                                                alt="slip">
                                                <ImageViewer
                                                    imageUrl={`${api.MEDIA_API}/image/${valuePurchaseNote.media[0].fileName}`}
                                                />
                                            </div>

                                            // <img
                                            //     style={{transform: `rotate(${this.state.rotation}deg)`}} 
                                            //     onClick={this.rotate}
                                            //     onMouseEnter={() => this.onMouseOverImage()}
                                            //     className="image-slip"
                                            //     src={
                                            //         `${api.THUMP + api.MEDIA_API}/image/800X600/${valuePurchaseNote.media[0].fileName}`}
                                            //     alt="slip" />
                                        }

                                    </div>
                                    <div className="position-relative">
                                        {index_purchaseNote !== 0 &&
                                            <img
                                                className="icon-previous cursor-pointer"
                                                onClick={this.handleClickPrevious}
                                                src={IconPrevious}
                                                alt="IconPrevious" />
                                        }

                                    </div>
                                    <div className="position-relative">
                                        {
                                            index_purchaseNote !== purchaseNote.length - 1 && <img
                                                className="icon-next cursor-pointer"
                                                onClick={this.handleClickNext}
                                                src={IconNext} alt="IconPrevious"
                                            />
                                        }

                                    </div>
                                    <div className="mt-3">
                                        <label className="font-bold-size-16px">
                                            ข้อมูลสลิปโอนเงิน {this.state.index_purchaseNote + 1}
                                        </label>
                                        <div className="form-group mt-2">
                                            <label className="font-size-16px">วันที่โอนเงิน ( ต.ย. 15/12/2562 )</label> <span className="font-color-error">*</span>
                                            {/* <div className="form-control input-background-color">asdasd</div> */}
                                            <Cleave
                                                placeholder="วว/ดด/ปปปป"
                                                options={{ date: false, datePattern: ['d', 'm', 'Y'] }}
                                                className={`input-background-color form-control ${error.dateTran && "border-color-error"}`}
                                                name="dateTran"
                                                onChange={this.handleChangeValuePurchaseNote}
                                                value={valuePurchaseNote.dateTran}
                                                // value={valuePurchaseNote && valuePurchaseNote.dateTran == null ? "" : moment(valuePurchaseNote && valuePurchaseNote.dateTran).format('DD/MM') + moment(valuePurchaseNote && valuePurchaseNote.dateTran).add(543,'years').format('/YYYY')}
                                            />
                                        </div>
                                        <div className="form-group">
                                            <label className="font-size-16px">เวลาที่โอนเงิน ( ต.ย. 14:23 )</label> <span className="font-color-error">*</span>
                                            <Cleave
                                                placeholder="00:00"
                                                options={{ time: true, timePattern: ['h', 'm'] }}
                                                className={`input-background-color form-control ${error.timeTran && "border-color-error"}`}
                                                name="timeTran"
                                                onChange={this.handleChangeValuePurchaseNote}
                                                value={valuePurchaseNote.timeTran}
                                            />
                                        </div>
                                        <div className="form-group">
                                            <label className="font-size-16px">จำนวนเงินที่โอน (บาท)</label> <span className="font-color-error">*</span>
                                            <Cleave
                                                placeholder="0"
                                                options={{
                                                    numeral: true
                                                }}
                                                className={`input-background-color form-control ${error.priceNet && "border-color-error"}`}
                                                name="priceNet"
                                                onChange={this.handleChangeValuePurchaseNote}
                                                value={valuePurchaseNote.priceNet}
                                            />
                                        </div>
                                        <div className="form-group">
                                            <label className="font-size-16px">โน้ต</label>
                                            <textarea
                                                onChange={this.handleChangeValuePurchaseNote}
                                                value={valuePurchaseNote.note}
                                                name="note"
                                                rows="1"
                                                className="form-control input-background-color"
                                            />
                                        </div>

                                        <div className="d-flex justify-content-between">
                                            <span className="font-color-error font-size-16px d-flex align-items-end">
                                                {this.state.isonClickConfirm && this.showTextErrorConfirm()}
                                            </span>
                                            <button
                                                type="button"
                                                className={`btn mt-2 font-bold-size-16px ${this.state.isLoadingConfirmPayment ? "btn-darkgrey" : "btn-primary"}`}
                                                onClick={this.state.isLoadingConfirmPayment ? null : this.handleClickConfirmPayment}
                                                id="Tooltip_Confirm"
                                            >

                                                บันทึกการชำระเงิน {this.state.index_purchaseNote + 1}
                                                {
                                                    this.state.isLoadingConfirmPayment &&
                                                    <div className="ml-3 spinner-border text-light size-icon-20px" role="status">
                                                        <span className="sr-only">Loading...</span>
                                                    </div>
                                                }

                                            </button>

                                            <Tooltip placement="right" isOpen={this.state.isOpentooltipConfirm} target="Tooltip_Confirm" toggle={this.toggleIsopenToolti}>
                                                บันทึกสำเร็จ
                                        </Tooltip>
                                        </div>

                                        <div className="d-flex justify-content-center mt-4">
                                            {purchaseNote && purchaseNote.map((item, idx_dot) =>
                                                <span
                                                    onClick={() => this.handleClickDot(idx_dot)}
                                                    key={"dot" + idx_dot}
                                                    className={`cursor-pointer ${idx_dot === index_purchaseNote ? `dot-active` : `dot-inactive`}`} />
                                            )}
                                        </div>
                                    </div>

                                </div>

                                {this.state.showImagePreview &&
                                    <div onMouseLeave={() => this.onMouseOutImage()}
                                        className="image-slip-preview"
                                    >
                                        <ImageViewer
                                            imageUrl={`${api.MEDIA_API}/image/${valuePurchaseNote.media && valuePurchaseNote.media[0].fileName}`}
                                        />
                                    </div>
                                    // <img
                                    //     onMouseLeave={() => this.onMouseOutImage()}
                                    //     className="image-slip-preview"
                                    //     src={
                                    //         `${api.THUMP + api.MEDIA_API}/image/800X600/${valuePurchaseNote.media && valuePurchaseNote.media[0].fileName}`} alt="preview"
                                    // />   
                                }

                                <div className="item-right border-left-mdoal">
                                    <div className="d-flex">
                                        <span className="font-bold-size-16px">
                                            รหัสการชำระเงิน
                                    </span>
                                        <span className="font-primary-16px-text ml-2">
                                            {purchase.transid || ''}
                                        </span>
                                    </div>

                                    <div className="d-flex mt-2">
                                        <div className="box-list-menu p-2">
                                            <div className="inner">
                                                {
                                                    invoice && invoice.map((item_invoice, idx_invoice) =>
                                                        <div className="d-flex flex-column" key={idx_invoice}>
                                                            <span className="font-primary-14px-text mt-2">
                                                                คำสั่งซื้อรหัส {` #${item_invoice.invoiceNo}`}
                                                            </span>
                                                            {item_invoice.order && item_invoice.order.map((item_order, idx_order) =>
                                                                <span className="font-size-14px mt-2" key={idx_order}>
                                                                    {item_order.product.menu.name.th || item_order.product.menu.name.en}X{item_order.quantity}
                                                                </span>
                                                            )}
                                                            <span className="font-size-14px mt-2">
                                                                ชื่อผู้รับ  {` ${item_invoice.contact.name}`}
                                                            </span>
                                                            <span className="font-size-14px mt-2">
                                                                เบอร์ผู้รับ  {` ${item_invoice.contact.mobile}`}
                                                            </span>
                                                            <div className="line-hr mt-2" />
                                                        </div>
                                                    )
                                                }
                                                <div className="cover-code" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="d-flex justify-content-between mt-3">
                                        <span className="font-size-16px">
                                            ยอดรวม
                                </span>
                                        <span className="font-primary-16px-text">
                                            {utils.numberFormatDecimalTwoPoint(purchase.priceTotal)}
                                        </span>
                                    </div>
                                    <div className="d-flex line-hr mt-3" />

                                    <div className="d-flex justify-content-between mt-3">
                                        <span className="font-size-16px">
                                            ยอดเงินขาด
                                </span>
                                        <span className={`font-size-16px ${purchase.netLoss !== 0 && 'font-color-error'}`}>
                                            {utils.numberFormatDecimalTwoPoint(purchase.netLoss)}
                                        </span>
                                    </div>
                                    <div className="d-flex justify-content-between">
                                        <span className="font-size-16px">
                                            ยอดเงินเกิน
                                </span>
                                        <span className={`font-size-16px ${purchase.netOver !== 0 && 'font-color-error'}`}>
                                            {utils.numberFormatDecimalTwoPoint(purchase.netOver)}
                                        </span>
                                    </div>
                                    <div className="d-flex line-hr mt-3" />
                                    {
                                        (value.netOver !== 0 || value.netLoss !== 0) &&
                                        <div className="d-flex mt-3">
                                            <img
                                                onClick={this.checkIsProblem}
                                                src={value.isProblem ? CheckBoxActiveRed : CheckBoxInactiveRed}
                                                alt="CheckBoxInactiveRed" />
                                            <span className="font-size-16px ml-3 font-color-error">เกิดปัญหาเงินขาด/เงินเกิน</span>
                                        </div>
                                    }


                                    {
                                        !value.isProblem && value.remark &&
                                        <>
                                            <div className="d-flex justify-content-between mt-3">
                                                <span className="font-size-16px">
                                                    โน้ตปัญหาและการแก้ไข
                                    </span>
                                            </div>
                                            <div className="d-flex mt-2">
                                                <div
                                                    className="box-preview-remark"
                                                >
                                                    <span className="font-size-16px">
                                                        {value.remark}
                                                    </span>
                                                </div>
                                            </div>
                                        </>
                                    }




                                    {
                                        value.isProblem && <>
                                            <div className="d-flex justify-content-between mt-3">
                                                <span className="font-size-16px">
                                                    โน้ตปัญหาและการแก้ไข
                                            </span>
                                                <Tooltip placement="top" isOpen={this.state.isOpentooltipSaveNote} target="Tooltip_SaveNote" toggle={this.toggleIsopenToolti}>
                                                    บันทึกสำเร็จ
                                            </Tooltip>
                                                <button
                                                    id="Tooltip_SaveNote"
                                                    onClick={(this.state.isLoadingSaveNote || !value.remark) ? null : this.handleClickSaveNote}
                                                    className={`btn btn-map height-33px ${(this.state.isLoadingSaveNote || !value.remark) ? "btn-darkgrey" : "btn-primary-sr"}`}
                                                >
                                                    บันทึก
                                                {
                                                        this.state.isLoadingSaveNote &&
                                                        <div className="ml-3 spinner-border text-light size-icon-20px" role="status">
                                                            <span className="sr-only">Loading...</span>
                                                        </div>
                                                    }
                                                </button>
                                            </div>
                                            <div className="d-flex mt-2">
                                                <textarea
                                                    onChange={this.handleChangeTextProlblem}
                                                    value={value.remark}
                                                    name="remark"
                                                    className="form-control input-background-color"
                                                    rows="3" placeholder="เพิ่มโน้ต"
                                                />
                                            </div>
                                            <div className="d-flex mt-2">
                                                <input
                                                    onChange={this.handleChangeTextProlblem}
                                                    value={value.issueCode}
                                                    name="issueCode"
                                                    className={`form-control input-background-color mt-2 input-code ${error.issueCode && "border-color-error"}`}
                                                    placeholder="Issue code"
                                                />
                                                <button
                                                    onClick={!value.issueCode ? null : this.handleClickUnlock}
                                                    className={`btn btn-map button-code ${!value.issueCode ? "btn-darkgrey" : "btn-primary-sr"}`}
                                                >
                                                    ปลดล็อค  <img src={IconLock} alt="IconLock" />
                                                </button>
                                            </div>

                                        </>
                                    }



                                    <div className="box-button-confirm wrap-footer">
                                        {
                                            purchase.netOver === 0 && purchase.netLoss === 0 ?
                                                <div className="d-flex mt-2">
                                                    <img onClick={this.checkIsConfirm} src={value.isConfirm ? CheckBoxActivePrimary : CheckBoxInActivePrimary} alt="CheckBoxInactiveRed" />
                                                    <span className="font-size-16px ml-3">ฉันได้ตรวจสอบยอดเงินแล้ว</span>
                                                </div>
                                                :
                                                (!value.isProblem && (value.issueCode && this.state.issueCodeCheckSuccess) && <div className="d-flex mt-2">
                                                    <img onClick={this.checkIsConfirm} src={value.isConfirm ? CheckBoxActivePrimary : CheckBoxInActivePrimary} alt="CheckBoxInactiveRed" />
                                                    <span className="font-size-16px ml-3">ฉันได้ตรวจสอบยอดเงินแล้ว</span>
                                                </div>)
                                        }


                                        <div className="">
                                            <span className="font-color-error font-size-16px d-flex justify-content-center">
                                                {(this.state.isonClickUnlock || this.state.isonClickApprove) && this.showTextError()}
                                            </span>
                                            <button
                                                onClick={(this.disableButtonApprove() || this.state.isLoadingApprovePayment) ? null : this.handleClickApprove}
                                                type="button"
                                                className={`btn w-100 mt-2 font-bold-size-16px ${(this.disableButtonApprove() || this.state.isLoadingApprovePayment) ? "btn-darkgrey" : "btn-primary"}`}
                                            >
                                                ยืนยัน
                                            {
                                                    this.state.isLoadingApprovePayment &&
                                                    <div className="ml-3 spinner-border text-light size-icon-20px" role="status">
                                                        <span className="sr-only">Loading...</span>
                                                    </div>
                                                }
                                            </button>
                                        </div>

                                    </div>
                                </div>
                            </div>
                    }
                </div>
            </Modal >
        )
    }
}

const mapStateToProps = state => ({

});

const mapDispatchToProps = dispatch => ({
    openNotificationAlertComponent: (payload) => dispatch(actions_Notification.openNotificationAlertComponent(payload))
});


export default connect(mapStateToProps, mapDispatchToProps)(ModalCheckTranfer);