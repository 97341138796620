import React, { Component } from 'react'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, InputGroup, InputGroupAddon, Input, FormGroup, Label } from 'reactstrap';
import '../../styles/chat/chat.scss'
import { isEmptyOrSpaces } from '../../utils/utils'
import { getTagColors, getTags, createTags, addTags } from '../../controllers/chatCtrl'

class ModalTag extends Component {
    constructor(props){
        super(props)
        this.state = {
            listTags: [],
            nameTagCreate: "",
            listColorTags: [],
            tagSelect: [],
        }
    }

    componentWillReceiveProps(nextProps){
        if(nextProps.showModalTag){
            this.onClickGetTags()
            this.setState({
                tagSelect: nextProps.tagSelectShow
            })
        }
    }

    onChangeTagName = async (e) => {
        this.setState({
            tagName: e.target.value
        })
    }

    onClickGetTags = async () => {
        const colorTags = await getTagColors()
        const tagsData = await getTags()

        this.setState({
            listColorTags: colorTags,
            listTags: tagsData
        })
    }

    onClickSelectTags = (tag) => {
        const manageArrTag = [...this.state.tagSelect]

        if (manageArrTag.some(tagSelect => tagSelect._id == tag._id)) {
            manageArrTag.map((tagFilter, idx) => {
                if (tagFilter._id == tag._id) {
                    manageArrTag.splice(idx, 1)
                }
            })
        } else {
            manageArrTag.push(tag)
        }

        this.setState({
            tagSelect: manageArrTag,
        })
    }

    onClickCreateTags = async () => {
        this.setState({
            nameTagCreate: ""
        })

        if (!isEmptyOrSpaces(this.state.nameTagCreate) && !isEmptyOrSpaces(this.state.colorTagCreate)) {
            createTags({
                name: this.state.nameTagCreate,
                colorId: this.state.colorTagCreate
            })
            this.onClickGetTags()
        } else {
            alert("กรุณาใส่ข้อมูลให้ครบ")
        }

    }

    onClickSaveTag = async () => {
        if (this.state.tagSelect.length <= 3) {
            this.props.closeModalTag()

            await addTags({
                userId: this.props.id,
                tagSelect: this.state.tagSelect
            })

        }else{
            alert("Tags สามารถ Add ได้ 3 Tags เท่านั้น")
        }

        this.props.refresh(this.props.id)

    }

    render(){
        return(
            <Modal isOpen={this.props.showModalTag} className="modal-medium" backdrop="static">
                    <div className="modal-header">
                        <h3>Tags</h3>
                        <button className="btn-cloes btn" onClick={this.props.closeModalTag}>
                            <i className="icon-close" />
                        </button>
                    </div>

                    <ModalBody className="pt-0">
                        <p className="text-haeder">เลือก Tag ที่ต้องการ</p>
                        
                        <div className="wrap-add-tag ling-tag">
                            {
                                this.state.listTags.map(tag =>
                                    <button key={tag._id} style={{ backgroundColor: this.state.tagSelect.some(tagSelect => tagSelect._id == tag._id) ? tag.colorId.code : "#828282" }} className="btn-sm btn-tags" onClick={() => this.onClickSelectTags(tag)}> {tag.name} </button>
                                )
                            }
                        </div>

                        <div className="wrap-add-tag">
                            <p className="text-haeder">เพิ่ม Tag ใหม่</p>
                            <InputGroup>
                                <Input placeholder="พิมพ์แท็ก" value={this.state.nameTagCreate} maxLength={20} onChange={(e) => this.setState({ nameTagCreate: e.target.value })} />
                                <InputGroupAddon addonType="prepend">
                                    <label><span> {this.state.nameTagCreate.length || 0} </span>/ 20</label>
                                </InputGroupAddon>
                            </InputGroup>

                            <div className="div-btn-pick-color-modal-tag">
                                <div className="box-select">
                                    <span>เลือกสี</span>
                                    <div className="box-button">
                                            {
                                                this.state.listColorTags.map((cTag, index) =>
                                                    <button key={index + "cTag"} style={{ backgroundColor: cTag.code }} className="btn-color-picker btn" onClick={() => this.setState({ colorTagCreate: cTag._id })} />
                                                )
                                            }
                                    </div>
                                </div>

                                <button className="btn-sm btn-primary-sr" onClick={this.onClickCreateTags}>เพิ่ม</button>
                            </div>
                        </div>
                    </ModalBody>

                    <ModalFooter>
                        <button className="btn btn-primary-sr w-100" onClick={this.onClickSaveTag}>บันทึก</button>
                    </ModalFooter>
            </Modal>
        )
    }
}

export default ModalTag;