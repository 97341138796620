import React, { Component } from 'react'
import Modal from '../../Modal';
import * as utils from '../../../utils/utils'
import ImageViewer from '../CheckTranfer/ImageViewer';
import api from '../../../config/api';
import moment from 'moment'
import LeftShape from '../../../assets/Icons/LeftShape.png'
import RightShape from '../../../assets/Icons/RightShape.png'
import { Tooltip } from 'reactstrap';
// import { Modal, ModalBody, ModalFooter, FormGroup, Input } from 'reactstrap';


class ModalPreviewHistory extends Component {
    constructor(props) {
        super(props)
        this.state = {
            foodPrice: 0,
            deliveryPrice: 0,
            priceDiscount: 0,
            totalPrice: 0,
            paymentData: "",
            purchaseNote: "",
            dataPurchaseNote: "",
            indexPurchaseNote: 1,
            showImagePreview: false
        }
    }

    async componentWillReceiveProps(nextProps) {
        if (this.props.showModalPreview !== nextProps.showModalPreview) {
            const price = nextProps.showModalPreview.data && nextProps.showModalPreview.data[0].invoice
            if (price) {
                const foodPrice = await price.reduce((sum, item) => {
                    return sum + item.priceNet
                }, 0)
                const deliveryPrice = await price.reduce((sum, item) => {
                    return sum + item.priceShippingNet
                }, 0)

                const priceDiscount = await price.reduce((sum, item) => {
                    return sum + item.priceDiscount
                }, 0)

                const totalPrice = await price.reduce((sum, item) => {
                    return sum + item.priceTotal
                }, 0)

                this.setState({
                    foodPrice,
                    deliveryPrice,
                    priceDiscount,
                    totalPrice,
                    paymentData: nextProps.showModalPreview.data,
                    purchaseNote: nextProps.showModalPreview.data[0].invoice[0].purchase.purchaseNote,
                    dataPurchaseNote: nextProps.showModalPreview.data[0].invoice[0].purchase.purchaseNote[0],
                })
            }
        }
    }

    onCloseModal = () => {
        this.setState({
            foodPrice: 0,
            deliveryPrice: 0,
            priceDiscount: 0,
            totalPrice: 0,
            paymentData: "",
            purchaseNote: "",
            dataPurchaseNote: "",
            indexPurchaseNote: 1
        })
        this.props.closeModal()
    }

    onClickPreviousPurchase = () => {
        if(this.state.indexPurchaseNote == 1) {
            return
        }
        let index = this.state.indexPurchaseNote
        index = index - 1

        
        this.setState({
            indexPurchaseNote : index,
            dataPurchaseNote : this.state.purchaseNote[index - 1]
        })
    }

    onClickNextPurchase = () => {
        if(this.state.indexPurchaseNote == this.state.purchaseNote.length) {
            return
        }
        let index = this.state.indexPurchaseNote
        index = index + 1

        
        this.setState({
            indexPurchaseNote : index,
            dataPurchaseNote : this.state.purchaseNote[index - 1]
        })
    }

    // slip note
    // setNote = () => {
    //     let note = ""
    //     this.state.purchaseNote && this.state.purchaseNote.map((item, idx) => {
    //         if (item && item.note) {
    //             note += `สลิปที่โอนเงิน ${idx + 1} :\n`
    //             note += `${item.note} \n`
    //         }
    //     })
    //     return note
    // }

    handleClickDot = (idx) => {
        this.setState({
            indexPurchaseNote : idx + 1,
            dataPurchaseNote : this.state.purchaseNote[idx]
        })
    }

    onMouseOverImage = () => {
        if (this.state.showImagePreview) {
            return
        }
        this.setState({
            showImagePreview: true
        });
    }

    onMouseOutImage = () => {
        if (!this.state.showImagePreview) {
            return
        }
        this.setState({
            showImagePreview: false
        });
    }

    render() {
        const { showModalPreview } = this.props
        
        return (
            <Modal
                isOpen={showModalPreview.isOpen}
                closeModal={this.onCloseModal}
                size="lg" >

                <div className="d-flex justify-content-center">
                    <h5> ดูรายละเอียดการชำระเงิน </h5>
                </div>


                {/* {showModalPreview && showModalPreview.data.map((item,idx) => <div>asd</div>)} */}
                <div className="row" id="modal-preview-history">
                    <div className="col-lg-1">
                        {this.state.indexPurchaseNote == 1 ? "" :
                            <img src={LeftShape} alt="LeftShape" style={{ width: "10px" }} className="icon-purchase-note float-right" 
                            onClick={this.onClickPreviousPurchase}
                            />
                        }
                    </div>
                    <div className="col-lg-5">

                        {this.state.dataPurchaseNote && this.state.dataPurchaseNote.media ?
                            <div onMouseEnter={() => this.onMouseOverImage()}
                                className="image-slip"
                                alt="slip">
                                <ImageViewer
                                    imageUrl={`${api.MEDIA_API}/image/800X600/${this.state.dataPurchaseNote && this.state.dataPurchaseNote.media && this.state.dataPurchaseNote.media[0].fileName}`}
                                />
                            </div>
                            : ""}


                            {this.state.showImagePreview &&
                                    <div onMouseLeave={() => this.onMouseOutImage()}
                                        className="image-slip-preview"
                                    >
                                        <ImageViewer
                                            imageUrl={`${api.MEDIA_API}/image/${this.state.dataPurchaseNote.media && this.state.dataPurchaseNote.media[0].fileName}`}
                                        />
                                    </div>
                                    // <img
                                    //     onMouseLeave={() => this.onMouseOutImage()}
                                    //     className="image-slip-preview"
                                    //     src={
                                    //         `${api.THUMP + api.MEDIA_API}/image/800X600/${valuePurchaseNote.media && valuePurchaseNote.media[0].fileName}`} alt="preview"
                                    // />   
                                }


                        <div className="d-flex justify-content-between mt-2">
                        <span className="font-bold-size-16px">ข้อมูลการชำระเงินที่ {this.state.indexPurchaseNote}</span>
                            <span>แจ้งชำระจาก CMS</span>
                        </div>

                        <div className="mt-3">
                            <span>วันที่โอนเงิน</span> <br />
                            <input
                                value={this.state.dataPurchaseNote && this.state.dataPurchaseNote.transPayment == null ? 
                                    moment(this.state.paymentData && this.state.paymentData[0].transPayment).format("DD/MM") + moment(this.state.paymentData && this.state.paymentData[0].transPayment).add(543,'years').format("/YYYY")
                                    : 
                                    `${moment(this.state.dataPurchaseNote && this.state.dataPurchaseNote.transPayment).format('DD/MM') + moment(this.state.dataPurchaseNote && this.state.dataPurchaseNote.transPayment).add(543, 'years').format('/YYYY')}`}
                                className={`input-background-color form-control`}
                                readOnly={true}
                            />
                        </div>
                        <div className="mt-3">
                            <span>เวลาที่โอนเงิน</span> <br />
                            <input
                                value={this.state.dataPurchaseNote && this.state.dataPurchaseNote.transPayment == null ? 
                                    moment(this.state.paymentData && this.state.paymentData[0].transPayment).subtract(7,'hours').format("HH:mm") 
                                    : 
                                    moment(this.state.dataPurchaseNote && this.state.dataPurchaseNote.transPayment).subtract(7,'hours').format("HH:mm")}
                                className={`input-background-color form-control`}
                                readOnly={true}
                            />
                        </div>
                        <div className="mt-3">
                            <span>จำนวนเงินที่โอน (บาท)</span> <br />
                            <input
                                defaultValue={this.state.dataPurchaseNote && this.state.dataPurchaseNote.priceNet}
                                className={`input-background-color form-control`}
                                readOnly={true}
                            />
                        </div>
                        <div className="mt-3">
                            <span>โน้ต</span> <br />
                            <input
                                defaultValue={this.state.dataPurchaseNote && this.state.dataPurchaseNote.note}
                                className={`input-background-color form-control`}
                                readOnly={true}
                            />
                        </div>

                        <div className="d-flex justify-content-center mt-4">
                                            {this.state.purchaseNote && this.state.purchaseNote.map((item, idx_dot) =>
                                                <span
                                                    onClick={() => this.handleClickDot(idx_dot)}
                                                    key={"dot" + idx_dot}
                                                    className={`cursor-pointer ${idx_dot+1 === this.state.indexPurchaseNote ? `dot-active` : `dot-inactive`}`} />
                                            )}
                                        </div>
                                        
                    </div>
                    <div className="col-lg-1">
                        {this.state.indexPurchaseNote == this.state.purchaseNote.length ? "" : 
                        <img src={RightShape} alt="RightShape" style={{ width: "10px", marginBottom: "17px" }} className="icon-purchase-note" onClick={this.onClickNextPurchase}/>
                        }
                        
                    </div>
                    <div className="line-center" />


                    <div className="col-lg-5">

                        <div className="d-flex justify-content-start">
                            <span className="font-bold-size-16px">รหัสการชำระเงิน <span className="font-primary-16px-text"> {showModalPreview.data && showModalPreview.data[0].transid}</span></span>
                        </div>
                        <div className="container background-modalhistory overflow-history mt-2">
                            {showModalPreview.data && showModalPreview.data[0].invoice.map((item, idx) =>

                                <div className={`box-order ${idx !== 0 && 'mt-4'} background-listhistory`} key={idx}>
                                    <div className="d-flex justify-content-start">
                                        <span className="font-primary-16px-text">
                                            คำสั่งซื้อรหัส #{item.invoiceNo}
                                        </span>
                                    </div>
                                    <div className="d-flex justify-content-start mt-1">
                                        <span className="font-size-16px">
                                            ชื่อผู้รับ {(item.contact && item.contact.name) || '-'}
                                        </span>
                                    </div>
                                    <div className="d-flex justify-content-start mt-1">
                                        <span className="font-size-16px">
                                            เบอร์ผู้รับ {(item.contact && item.contact.mobile) || "-"}
                                        </span>
                                    </div>

                                    <div className="mt-4">
                                        {
                                            item.order && item.order.map((item_order, idx_order) =>
                                                <div className="d-flex justify-content-between mt-2" key={idx_order}>
                                                    <span className="font-size-16px">
                                                        {item_order.product.menu.name.th || item_order.product.menu.name.en}
                                                    </span>
                                                    <span className="font-size-16px">
                                                        {item_order.product.priceNet}.-
                            </span>
                                                </div>
                                            )
                                        }
                                    </div>

                                    <hr />
                                    <div className="d-flex justify-content-between mt-2">
                                        <span className="font-size-16px">
                                            ค่าอาหาร
               </span>
                                        <span className="font-size-16px">
                                            {utils.numberFormatNoDecimal(item.priceNet)}.-
               </span>
                                    </div>



                                    <div className="d-flex justify-content-between mt-2">
                                        <span className="font-size-16px">
                                            ค่าจัดส่ง
                </span>
                                        <span className="font-size-16px">
                                            {utils.numberFormatNoDecimal(item.priceShippingNet)}.-
               </span>
                                    </div>

                                    <div className="d-flex justify-content-between mt-2">
                                        <span className="font-size-16px">
                                            ส่วนลด
               </span>
                                        <span className="font-size-16px">
                                            {utils.numberFormatNoDecimal(item.priceDiscount)}.-
               </span>
                                    </div>
                                    <hr />

                                    <div className="d-flex justify-content-between mt-2">
                                        <span className="font-bold-size-16px">
                                            รวมราคา
                </span>
                                        <span className="font-primary-bold-size-16px">
                                            {utils.numberFormatDecimalTwoPoint(item.priceTotal)}.-
               </span>
                                    </div>
                                </div>

                            )}
                        </div>


                        <div className="d-flex justify-content-between mt-3">
                            <span>
                                ค่าอาหารทั้งหมด
                        </span>
                            <span>
                                {utils.numberFormatDecimalTwoPoint(this.state.foodPrice)}.-
                        </span>
                        </div>

                        <div className="d-flex justify-content-between mt-3">
                            <span>
                                ค่าจัดส่ง
                        </span>
                            <span>
                                {utils.numberFormatDecimalTwoPoint(this.state.deliveryPrice)}.-
                        </span>
                        </div>
                        <div className="d-flex justify-content-between mt-3">
                            <span>
                                ส่วนลดทั้งหมด
                        </span>
                            <span>
                                {utils.numberFormatDecimalTwoPoint(this.state.priceDiscount)}.-
                        </span>
                        </div>

                        <hr />

                        <div className="d-flex justify-content-between">
                            <span className="font-bold-size-20px">
                                ยอดรวม
                        </span>
                            <span className="font-bold-primary-size-20px">
                                {utils.numberFormatDecimalTwoPoint(this.state.totalPrice)}.-
                        </span>
                        </div>

                        <hr />

                        <div className="d-flex justify-content-between mt-3">
                            <span>
                                ยอดเงินเกิน
                        </span>
                            <span>
                                {utils.numberFormatDecimalTwoPoint(this.state.paymentData && this.state.paymentData[0] && this.state.paymentData[0].netOver)}.-
                        </span>
                        </div>

                        <div className="d-flex justify-content-between mt-3">
                            <span>
                                ยอดเงินขาด
                        </span>
                            <span>
                                {utils.numberFormatDecimalTwoPoint(this.state.paymentData && this.state.paymentData[0] && this.state.paymentData[0].netLoss)}.-
                        </span>
                        </div>

                        <hr />

                        <div className="d-flex justify-content-between mt-3">
                            <span>
                                โน้ตปัญหาและการแก้ไข
                        </span>
                        </div>
                        <div className="d-flex justify-content-between mt-3">
                            <textarea className="text-area-note" value={this.state.paymentData && this.state.paymentData[0] && this.state.paymentData[0].remark || ""}
                            readOnly={true} />
                        </div>



                    </div>


                </div>
            </Modal>

        )
    }
}


export default ModalPreviewHistory