// import Footer from './Footer';
import Header from './Header';

import React from 'react';
class ChatLayout extends React.Component {
    render() {
        const { children } = this.props;
        return (
            <main>
                <div id="header">
                    <Header 
                    sideBar={children.props.match.path}
                    />
                </div>
                {/* <div id="main"> */}
                    {children}
                {/* </div> */}
                {/* <div id="footer">
                <Footer />
                </div> */}
            </main>
        );
    }
}

export default ChatLayout;
