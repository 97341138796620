import React from 'react';
import {
    // UncontrolledTooltip,
    Nav,
    NavItem,
    NavLink as BSNavLink,
} from 'reactstrap';
import { connect } from 'react-redux'
import { getListMenu } from '../../redux/actions/menus'
import { onClearSearch } from '../../redux/actions/createOrder'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faShoppingBasket,faSearchDollar } from "@fortawesome/free-solid-svg-icons";
import classNames from 'classnames'
import Bracket from '../../assets/Icons/bracket.png'

class Sidebar extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            statusSideBar: '/'
        }
    }


    componentDidMount() {
        this.setState({
            statusSideBar: this.props.sideBar
        })
    }

    getListMenu = async () => {
        const idx = this.props.activeTab
        const data = {
            menu: "",
            date: "",
            time: "",
        }
        await this.props.getListMenu(data)
        await this.props.onClearSearch({ idx })
    }

    showSideBar = (sidebar) => {
        switch (sidebar) {
            case "/all-menu":
                return <aside>
                    <Nav vertical>
                        <label className="ml-4 name-of-side title-sidebar-menu">เมนูอาหาร</label>
                        <div className="side-item">
                            <NavItem className={this.state.statusSideBar === '/all-menu' ? 'list-menu-active' : 'list-menu'}>
                                <BSNavLink >
                                    <span className="display-block cursor-pointer" onClick={this.getListMenu}>เมนูทั้งหมด</span>
                                </BSNavLink>
                            </NavItem>
                            {/* <NavItem className={this.state.statusSideBar === '/extra-data' ? 'list-menu-active' : 'list-menu'}>
                          <BSNavLink >
                              <span className="display-block cursor-pointer" onClick={this.getListMenu}>เมนูทั้งหมด</span>
                          </BSNavLink>
                      </NavItem> */}

                            {/* <NavItem className={this.state.statusSideBar === '/recommend-menu' ? 'list-menu-active' : 'list-menu'}>
                          <BSNavLink href="/recommend-menu">
                              <span className=" ">เมนูแนะนำ</span>
                          </BSNavLink>
                      </NavItem>  <NavItem className={this.state.statusSideBar === '/promotion-menu' ? 'list-menu-active' : 'list-menu'}>
                          <BSNavLink href="/promotion-menu">
                              <span className=" ">เมนูโปรโมชั่น</span>
                          </BSNavLink>
                      </NavItem>  <NavItem className={this.state.statusSideBar === '/season-menu' ? 'list-menu-active' : 'list-menu'}>
                          <BSNavLink href="/season-menu">
                              <span className="">เมนูตามฤดูกาล</span>
                          </BSNavLink>
                      </NavItem> */}
                        </div>
                    </Nav>
                </aside >
                break;
            case "/extra-data":
                return (
                    <div className="sidebar-extra-data cursor-pointer ">
                        <span className={classNames('d-flex','justify-content-between','align-items-center','padding-sidebar',{'side-item-active': this.state.statusSideBar == '/extra-data'})}> 
                        {/* <FontAwesomeIcon icon={faShoppingBasket} className="mr-4"/>  */}
                        <img src={Bracket} alt="bracket" className="w-h-30px" />
                        ข้อมูลเพิ่มเติม</span>
                        {/* <span className=""> <FontAwesomeIcon icon={faShoppingBasket} className="mr-4"/> ข้อมูลเพิ่มเติม</span> */}
                        {/* <NavItem className={this.state.statusSideBar === '/extra-data' ? 'list-menu-active' : 'list-menu'}>
                            <BSNavLink >
                                <span className="display-block cursor-pointer" onClick={this.getListMenu}>ข้อมูลเพิ่มเติม</span>
                            </BSNavLink>
                        </NavItem> */}
                    </div>
                )
                break;
            default:
                return null
        }
    }

    render() {
        return (
            <>
                {this.showSideBar(this.state.statusSideBar)}
            </>
            // <aside>
            //     <Nav vertical>
            //         <label className="ml-4 name-of-side title-sidebar-menu">เมนูอาหาร</label>
            //         <div className="side-item">
            //             <NavItem className={this.state.statusSideBar === '/all-menu' ? 'list-menu-active' : 'list-menu'}>
            //                 <BSNavLink >
            //                     <span className="display-block cursor-pointer" onClick={this.getListMenu}>เมนูทั้งหมด</span>
            //                 </BSNavLink>
            //             </NavItem>

            //             {/* <NavItem className={this.state.statusSideBar === '/recommend-menu' ? 'list-menu-active' : 'list-menu'}>
            //                 <BSNavLink href="/recommend-menu">
            //                     <span className=" ">เมนูแนะนำ</span>
            //                 </BSNavLink>
            //             </NavItem>  <NavItem className={this.state.statusSideBar === '/promotion-menu' ? 'list-menu-active' : 'list-menu'}>
            //                 <BSNavLink href="/promotion-menu">
            //                     <span className=" ">เมนูโปรโมชั่น</span>
            //                 </BSNavLink>
            //             </NavItem>  <NavItem className={this.state.statusSideBar === '/season-menu' ? 'list-menu-active' : 'list-menu'}>
            //                 <BSNavLink href="/season-menu">
            //                     <span className="">เมนูตามฤดูกาล</span>
            //                 </BSNavLink>
            //             </NavItem> */}
            //         </div>
            //     </Nav>
            // </aside >
        );
    }
}

const mapDispatchToProps = dispatch => ({
    getListMenu: ({ menu, date, time }) => dispatch(getListMenu({ menu, date, time })),
    onClearSearch: ({ idx }) => dispatch(onClearSearch({ idx })),
})

const mapStateToProps = (state) => ({
    users: state.users,
    activeTab: state.createOrder.activeTab
})


export default connect(mapStateToProps, mapDispatchToProps)(Sidebar)

// export default Sidebar;
