import {
    put,
    takeLatest,
    call
} from "redux-saga/effects";
import * as actions from "../actions/address";
import addressCtrl from '../../controllers/addressCtrl';
const api_addressCtrl = new addressCtrl();

function* getListProvinces(action) {
    try {
        const { data } = yield call(api_addressCtrl.getListProvinces, {});
        // const optionsProvinces = [];

        // data.map(item => {
        //     optionsProvinces.push({
        //         ...item,
        //         label: item.provinceName,
        //         value: item.provinceId
        //     })
        // });


        yield put({
            type: actions.Type.GET_LIST_PROVINCES_SUCCESS, payload: {
                listProvinces: data
            }
        });
    } catch (error) {
        console.log(error);
    }
}
function* getListDistricts(action) {
    const { payload } = action;
    try {

        const { data } = yield call(api_addressCtrl.getListDistricts, { provinceId: payload.provinceId });
        // const optionsDistricts = [];

        // data.map(item => {
        //     optionsDistricts.push({
        //         ...item,
        //         label: item.districtName,
        //         value: item.districtId
        //     })
        // });

        yield put({
            type: actions.Type.GET_LIST_DISTRICTS_SUCCESS, payload: {
                listDistricts: data
            }
        });

    } catch (error) {
        console.log(error);
    }
}


function* getListSubDistricts(action) {
    const { payload } = action;
    try {
        const { data } = yield call(api_addressCtrl.getListSubDistricts, { districtId: payload.districtId });
        // const optionsSubDistricts = [];
        // data.map(item => {
        //     optionsSubDistricts.push({
        //         ...item,
        //         label: item.subdistrictName,
        //         value: item.subdistrictId
        //     })
        // });


        yield put({
            type: actions.Type.GET_LIST_SUBDISTRICTS_SUCCESS, payload: {
                listSubDistricts: data
            }
        });
    } catch (error) {
        console.log(error);
    }
}

export default function* addressWatch() {
    yield takeLatest(actions.Type.GET_LIST_PROVINCES_REQUEST, getListProvinces);
    yield takeLatest(actions.Type.GET_LIST_DISTRICTS_REQUEST, getListDistricts);
    yield takeLatest(actions.Type.GET_LIST_SUBDISTRICTS_REQUEST, getListSubDistricts);

}