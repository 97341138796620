
import axios from 'axios';
import api from '../config/api';

class usersCtrl {
    getLatLngByAddress = (params) => {
        return new Promise(async (resolve, reject) => {
            try {
                const { address } = params;
                const url = 'https://maps.googleapis.com/maps/api/geocode/json?address=' + address + '&key=' + api.KEY_GOOGLE_MAP;
                const result = await axios.get(url);
                // Todo : when  save complete 
                resolve(result);
            }
            catch (err) {
                // Todo : save fail
                reject(err)
            }
        });
    }
}
export default usersCtrl;