export const Type = {
    GET_LIST_PROVINCES_REQUEST: "GET_LIST_PROVINCES_REQUEST",
    GET_LIST_PROVINCES_SUCCESS: "GET_LIST_PROVINCES_SUCCESS",
    GET_LIST_DISTRICTS_REQUEST: "GET_LIST_DISTRICTS_REQUEST",
    GET_LIST_DISTRICTS_SUCCESS: "GET_LIST_DISTRICTS_SUCCESS",
    GET_LIST_SUBDISTRICTS_REQUEST: "GET_LIST_SUBDISTRICTS_REQUEST",
    GET_LIST_SUBDISTRICTS_SUCCESS: "GET_LIST_SUBDISTRICTS_SUCCESS",
    CLEAR_LIST_DISTRICTS_AND_SUBDISTRICTS: "CLEAR_LIST_DISTRICTS_AND_SUBDISTRICTS"
}


export const getListProvinces = () => ({
    type: Type.GET_LIST_PROVINCES_REQUEST
});


export const getListDistricts = (payload) => ({
    type: Type.GET_LIST_DISTRICTS_REQUEST,
    payload
});

export const getListSubDistricts = (payload) => ({
    type: Type.GET_LIST_SUBDISTRICTS_REQUEST,
    payload
});

export const clearListDistrictAndSubDistricts = () => ({
    type: Type.CLEAR_LIST_DISTRICTS_AND_SUBDISTRICTS
});