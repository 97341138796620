export const Type = {
    LOGIN_REQUEST: "LOGIN_REQUEST",
    LOGIN_SUCCESS: "LOGIN_SUCCESS",
    LOGOUT_REQUEST: "LOGOUT_REQUEST",
    LOGOUT_SUCCESS: "LOGOUT_SUCCESS",
    GET_USER_BY_ID_REQUIRE : "GET_USER_BY_ID_REQUIRE",
    GET_USER_BY_ID_SUCCCESS : "GET_USER_BY_ID_SUCCCESS",
    LOGIN_FAIL: "LOGIN_FAIL",
    GET_CODE_CREATE: "GET_CODE_CREATE"
};

/// Login  ////
export const loginRequest = ({ username, password }) => ({
    type: Type.LOGIN_REQUEST,
    payload: {
        username,
        password
    }
});
/// Login  ////

/// Logout ////
export const logoutRequest = () => ({
    type: Type.LOGOUT_REQUEST
})
/// Logout ////


export const getUserByID = () => ({
    type: Type.GET_USER_BY_ID_REQUIRE,
});

export const getCode = (payload) => ({
    type : Type.GET_CODE_CREATE,
    payload
})