
import React from 'react';
import {
    put,
    takeLatest,
    call,
    select,
    take,
    delay
} from "redux-saga/effects";

import * as actions_extraData from '../actions/extraData'
import { getExtraData } from './selectors'
import extraDataCtrl from '../../controllers/extraDataCtrl'

const api_extraData = new extraDataCtrl()

function* addProductPDF(action) {
    const { payload } = action
    const stateExtraData = yield select(getExtraData)
    const dataProduct = [...stateExtraData.listProductPDF]

    if (dataProduct.some((item, idx) => item.productId == payload.productId)) {
        dataProduct.splice(dataProduct.findIndex(item => item.productId == payload.productId), 1)
    } else {
        dataProduct.push({
            productId: payload.productId,
            menuName: payload.menuName,
            shopName: payload.shopName
        })
    }

    yield put({
        type: actions_extraData.Type.ADD_PRODUCT_PDF,
        payload: {
            dataProduct
        }
    })
}

function* addProductAll(action) {
    const { payload } = action

    const stateExtraData = yield select(getExtraData)
    let dataProduct = [...stateExtraData.listProductPDF]
    // const allProduct = []

    if (dataProduct.length != stateExtraData.listMenu.length) {
        payload.map(item => item.data.map((data) => {
            const product = {
                productId: data.dataProduct[0]._id,
                menuName: data.name.th || data.name.en,
                shopName: item.dataShop[0].name
            }
            if (dataProduct.some(data => data.productId == product.productId)) {
            } else {
                dataProduct.push(product)
            }
        }))
    } else {
        dataProduct = [] // if dataSelected == listMenu
    }

    yield put({
        type: actions_extraData.Type.ADD_PRODUCT_PDF,
        payload: {
            dataProduct
        }
    })
}

function* clearProduct(action) {
    yield put({
        type: actions_extraData.Type.ADD_PRODUCT_PDF,
        payload: {
            dataProduct: []
        }
    })
}


function* getDownLoadPDF(action) {
    const { payload } = action
    let sendDataPDF
    yield put({
        type: actions_extraData.Type.OPEN_LOADING_BUTTON
    })
    const stateExtraData = yield select(getExtraData)
    const listPDF = stateExtraData.listProductPDF
    
    //////// set shopname only same menuName ///////
    // let checkSameItem = false
    //    listPDF.map((item, idx) => {
    //        const listItem = [...stateExtraData.listProductPDF]
    //         listItem.splice(idx,1)
    //        const filterItem = listItem.some(e => e.menuName == item.menuName)
    //        if(filterItem){
    //            listPDF[idx] = {
    //                ...listPDF[idx],
    //                menuName: listPDF[idx].menuName + " " +listPDF[idx].shopName
    //            }
    //            return checkSameItem = filterItem
    //        }
    //     })
    //////// set name only same menuName ///////

    const listItemDownload = []
    listPDF.map((item, idx) => {
        const data = {
            productId: item.productId,
            menuName: item.menuName + " " + item.shopName
        }
        listItemDownload.push(data)
    })
    
    if(payload) {
        sendDataPDF = yield call(api_extraData.downloadPDF, [{productId:payload.productId,menuName:payload.menuName}])
    } else {
        sendDataPDF = yield call(api_extraData.downloadPDF, listItemDownload)
    }
    if (sendDataPDF && sendDataPDF.requestId) {
        let status = yield call(api_extraData.getStatusDownloadPDF, sendDataPDF.requestId)
        while (status.status != 'ready') {
            yield delay(3000)
            status = yield call(api_extraData.getStatusDownloadPDF, sendDataPDF.requestId)
        }
        if (status.status == 'ready') {
            window.open(yield call(api_extraData.pathDownloadPDF, sendDataPDF.requestId))
            yield call(api_extraData.deletePDFDownload, sendDataPDF.requestId)
        }
    }

    yield put({
        type: actions_extraData.Type.CLOSE_LOADING_BUTTON
    })
}

export default function* extraDataWatch() {
    yield takeLatest(actions_extraData.Type.ADD_PRODUCT_PDF_REQUIRE, addProductPDF)
    yield takeLatest(actions_extraData.Type.ADD_PRODUCT_ALL_REQUIRE, addProductAll)
    yield takeLatest(actions_extraData.Type.CLEAR_PRODUCT_SELECTED, clearProduct)
    yield takeLatest(actions_extraData.Type.DOWNLOAD_PDF_REQUIRE, getDownLoadPDF)
}