import React from 'react';
import Header from './Header';

const EmptyLayout = ({ children, ...restProps }) => (
    <main {...restProps}>
        <Header
            sideBar={children.props.match.path}
        />
        {children}
    </main>
);

export default EmptyLayout;
