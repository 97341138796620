import React from 'react';
import ModalCheckTranfer from '../../../components/pages/CheckTranfer/ModalCheckTranfer';
import Pagination from '../../../components/Pagination';
import invoicesCtrl from '../../../controllers/invoicesCtrl';
import classnames from 'classnames';
import IconAlertError from '../../../assets/Icons/IconAlertError.svg'
import * as utils from '../../../utils/utils';
import Close from '../../../assets/Icons/Close.png'
import ModalPreviewHistory from './ModalPreviewHistory';
import moment from 'moment'

const api_invoicesCtrl = new invoicesCtrl();

class CheckTranfer extends React.Component {
    state = {
        listPurchase: [],
        modalPreviewHistory: {
            isOpen: false,
            title: '',
            data: null
        },
        pagination: {
            skip: 0,
            pageSize: 10,
            total: 0,
            currentPage: 0
        },
        search: {
            transid: ''
        }
    }
    timeGetListPurchases = null

    // componentDidMount() {
    //     try {
    //         this.setTimeGetListPurchases();
    //     } catch (error) {
    //         console.log(error);
    //     }
    // }

    componentWillReceiveProps(nextProps) {
        if (this.props.activeTab !== nextProps.activeTab) {
            if (nextProps.activeTab === "success_payment") {
                try {
                    this.setTimeGetListPurchases();
                } catch (error) {
                    console.log(error);
                }
            }
        }

    }


    setTimeGetListPurchases = () => {
        // clearInterval(this.timeGetListPurchases);
        this.getListPurchases();
        // this.timeGetListPurchases = setInterval(() => {
        //     this.getListPurchases();
        // }, 5000);
    }

    openmodalCheckTranfer = async (purchase) => {
        const listInvoice = await api_invoicesCtrl.getDetailInvoicePaymentStatusSuccess({ transid: purchase.transid});
        this.setState({
            modalPreviewHistory: {
                isOpen: true,
                title: 'ตรวจสอบการประวัติชำระเงิน',
                data: listInvoice
            }
        });
    }

    closemodalPreviewHistory = async (status) => {
        this.setState({
            modalPreviewHistory: {
                isOpen: false,
                title: '',
            }
        });
        if (status === "success") {
            await this.setState({
                search: {
                    transid: ''
                }
            });
            this.setTimeGetListPurchases();
        }
    }
    handleClickPagination = async (skip, pagination) => {
        await this.setState({
            pagination: {
                ...this.state.pagination,
                skip,
                ...pagination
            }
        });
        this.setTimeGetListPurchases();
    };


    getListPurchases = async () => {
        try {
            const { list, count } = await api_invoicesCtrl.listInvoiceByPaymentStatusSuccess({ userId: this.props.match.params.id, pagination: this.state.pagination, search: this.state.search });
            this.setState({
                listPurchase: list,
                pagination: {
                    ...this.state.pagination,
                    total: count
                }
            });
        } catch (error) {
            console.log(error);
        }
    };

    handleClickSearch = async (evt) => {
        evt.preventDefault()
        await this.setState({
            pagination: {
                skip: 0,
                pageSize: 10,
                total: 0,
                currentPage: 0
            }
        });
        this.setTimeGetListPurchases();
    };

    handleChangeChange = (evt) => {
        this.setState({
            search: {
                transid: evt.target.value
            }
        });
    };


    handleClickClearSearch = async () => {
        if (this.state.search.transid.trim() === 0) {
            return;
        }
        await this.setState({
            search: {
                ...this.state.search,
                transid: ''
            }
        });
        this.setTimeGetListPurchases();
    }

   
    render() {
        const { listPurchase } = this.state;
        return (
            <div className="bg-white">
                <div className="container-box-custom">
                    <div className="row mt-3">
                        <div className="col-md-8">
                            <label className="font-primary-32px-text">
                                ชำระเงินแล้ว
                                </label>
                        </div>
                        <div className="col-md-4">
                            <div className="form-group">
                                <form
                                    onSubmit={this.handleClickSearch}
                                >
                                    <input type="text"
                                        onChange={this.handleChangeChange}
                                        className="form-control input-search-purchase"
                                        value={this.state.search.transid}
                                        // onChange={(e) => this.onChangeSearch(e, this.props.createOrder.activeTab)}
                                        // onKeyPress={(e, tab) => this.onSubmitSearch(e, this.props.createOrder.activeTab)}
                                        // value={this.props.createOrder.orderShop[this.props.createOrder.activeTab].search.menu}
                                        placeholder="ค้นหารหัสการชำระเงิน"
                                    />

                                    <img src={Close}
                                        onClick={this.handleClickClearSearch}
                                        alt="Close"
                                        className="icon-close-search-purchase size-icon-20px cursor-pointer"
                                    />
                                </form>
                            </div>
                        </div>
                    </div>
                    <div id="table-component">
                        <table className="table mt-3">
                            <thead >
                                <tr className="thead-custom">
                                    <th scope="col" className="text-center">รหัสการชำระเงิน</th>
                                    <th scope="col"></th>
                                    <th scope="col" className="text-center">วันที่ยืนยันชำระเงิน</th>
                                    <th scope="col" className="text-center">เวลาที่ยืนยันชำระเงิน</th>
                                    <th scope="col" className="text-center">ยอดที่โอน</th>
                                    <th scope="col"></th>

                                </tr>
                            </thead>
                            <tbody>
                                {listPurchase && listPurchase.map((item, idx) =>
                                    <tr key={idx} className={classnames({ "tr-odd": (idx % 2 === 0) }, { "tr-even": (idx % 2 === 1) })}>
                                        <td className="text-center">
                                            {item.purchase.transid}
                                        </td>
                                        <td>
                                            {
                                                (item.purchase && item.purchase.remark != null) && <img src={IconAlertError} alt="IconAlertError" />
                                            }
                                        </td>
                                        <td className="text-center">{utils.changeFormatDateNotDay(moment(item.purchase.transPayment).subtract(7,'hours'))}</td>
                                        <td className="text-center">{utils.changeFormatTime(moment(item.purchase.transPayment).subtract(7,'hours'))} น.</td>
                                        <td className="text-center">
                                            {
                                                utils.numberFormatDecimalTwoPoint(item.purchase.priceNet)
                                            }
                                        </td>
                                        <td className="text-center">
                                            <button
                                                className="btn btn-primary-sr btn-map"
                                                onClick={() => this.openmodalCheckTranfer(item.purchase)}
                                            >ดูรายละเอียด</button>
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                        {
                            listPurchase && listPurchase.length === 0 &&
                            <div className="d-flex justify-content-center align-items-center height-60vh">
                                <span className="font-size-20px corlor-darkgrey">
                                    {!this.state.search.transid ? "ขณะนี้ ยังไม่มีออเดอร์ที่มีการชำระเงินแล้ว" : "ไม่พบข้อมูลที่ค้นหา"}
                                </span>
                            </div>
                        }
                    </div>
                    <div className="float-right">
                        <Pagination
                            pagination={this.state.pagination}
                            handleClick={this.handleClickPagination}
                        />
                    </div>
                </div>
                <ModalPreviewHistory
                    showModalPreview={this.state.modalPreviewHistory}
                    closeModal={(evt) => {
                        this.closemodalPreviewHistory(evt)
                    }}
                />
            </div>
        )
    }
};

export default CheckTranfer;