import { combineReducers } from "redux";
import UserReducer from "./users";
import MenuReducer from './menus';
import CreateOrderReducer from './createOrder';
import ModalReducer from './modal';
import LogisticReducer from './logistics';
import NotificationReducer from './notification';
import AddressReducer from './address';
import LoadingReducer from './loading';
import ExtraDataReducer from './extraData'



export default combineReducers({
  users: UserReducer,
  menus: MenuReducer,
  createOrder: CreateOrderReducer,
  modals: ModalReducer,
  logistics: LogisticReducer,
  notification: NotificationReducer,
  address: AddressReducer,
  loading: LoadingReducer,
  extraData: ExtraDataReducer,
});