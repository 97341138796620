import { Type } from "../actions/notification";

const INITIAL_STATE = {
    noitficationAlert: {
        message: null,
        level: '',
        autoDismiss: 0,
        position: "tr"
    }
};

export default function noitfication(state = INITIAL_STATE, actions) {
    const { type, payload } = actions;
    switch (type) {
        case (Type.OPEN_NOTIFICATION_ALERT): {
            return {
                ...state,
                noitficationAlert: {
                    ...payload
                }
            }
        }
        default:
            return {
                ...state
            }
    }
}