import React from 'react';
import { Modal } from 'reactstrap';
import IconClose from '../assets/Icons/Close.png';

class ModalComponent extends React.Component {
    render() {
        const { isOpen, closeModal, children, size, title } = this.props;
        return (
            <Modal
                isOpen={isOpen}
                size={size}
            >
                <div id="modal-main-compenent">
                    <div className="modal-main-header">
                        <label className="text-title">
                            {title}
                        </label>
                        <img src={IconClose} alt="IconClose" className="size-icon-20px icon-close cursor-pointer" onClick={closeModal} />
                    </div>

                    <div className="modal-main-body">
                        {children}
                        <div className="cover-bar-modal" />
                    </div>
                </div>
            </Modal >
        )
    }
}

export default ModalComponent;