
import axios_saranros from '../lib/axios-saranros';

class productsCtrl {
    listTimeByProduct = (params) => {
        return new Promise(async (resolve, reject) => {
            try {
                const { productId, datePreorder, userId } = params;
                const data = {
                    params: {
                        productId : productId,
                        datePreorder : datePreorder, 
                        userId : userId
                    }
                } 

                
                const url = `/api/Products/listTimeByProduct`

                const result = await axios_saranros.get(url,data);
                // Todo : when  save complete 
                resolve(result);
            }
            catch (err) {
                // Todo : save fail
                reject(err)
            }
        });
    }
}
export default productsCtrl;