import React from 'react';
import Modal from '../../Modal';
import { connect } from 'react-redux'
import * as actions_createOrder from '../../../redux/actions/createOrder';
import * as utils from '../../../utils/utils';
import checkBoxActiveSquare from '../../../assets/Icons/checkBoxActiveSquare.png';
import checkBoxIsActiveSquare from '../../../assets/Icons/checkBoxIsActiveSquare.svg';
import { getListLogistic } from '../../../redux/actions/logistics';
import { store } from '../../../redux/store'

class ModalAddress extends React.Component {
    state = {
        title: "สรุปราคาของรอบส่งนี้",
        textButton: "ยืนยันพร้อมส่งข้อมูลให้ลูกค้า",
        sumOrderAll: {
            sumTotal: 0,
            sumdeliveryPrice: 0,
            sumFood: 0,
        },
        orderType: {
            SKOOTAR: [],
            CPIU: [],
            KERRY: []
        }
    }


    async componentWillReceiveProps(nextProps) {
        if (nextProps.modalSummary.isOpen && !this.props.modalSummary.isOpen) {
            const { orderShop } = nextProps;
            let deliveryPrice = 0;
            let sumFood = 0;
            let discountDeliveryPrice = 0;
            let discountSumFood = 0;
            for (let i in orderShop) {
                // deliveryPrice += (orderShop[i].deliveryOption === "LLM" || orderShop[i].deliveryOption === "SKOOTAR" || orderShop[i].deliveryOption === "KERRY") ? orderShop[i].shippingCost.deliveryPrice : 0;
                deliveryPrice += orderShop[i].shippingCost.deliveryPrice || 0
                // (orderShop[i].isDefaultContactCustomer ? orderShop[0].shippingCost.deliveryPrice : orderShop[i].shippingCost.deliveryPrice) : 0;
                const sumPriceFood = this.sumPriceNet(orderShop[i].listMenuSelect);
                sumFood += sumPriceFood;
                discountDeliveryPrice += this.calculatePriceDiscountDrlivery(orderShop[i]);
                discountSumFood += this.calculatePriceDiscountFood(orderShop[i]);
            }


            const type_SKOOTAR = await orderShop.filter((item) => (item.deliveryOption === "SKOOTAR" || item.deliveryOption === "LLM"));
            const type_CPIU = await orderShop.filter((item) => item.deliveryOption === "CPIU");
            const type_KERRY = await orderShop.filter((item) => item.deliveryOption === "KERRY");


            this.setState({
                sumOrderAll: {
                    sumTotal: (sumFood + deliveryPrice) - (discountSumFood + discountDeliveryPrice),
                    sumdeliveryPrice: deliveryPrice - discountDeliveryPrice,
                    sumFood: sumFood - discountSumFood,
                },
                orderType: {
                    SKOOTAR: type_SKOOTAR,
                    CPIU: type_CPIU,
                    KERRY: type_KERRY
                }
            });
        }
    }


    showOrderSKOOTAR = (item, idx, orderShop) => {
        if (item.mainData == null) {
            return
        }
        const listMenuSelect = { ...item.listMenuSelect };
        const deliveryAtDefault = orderShop[0].checkShippingCosts.deliveryAt[0];
        const deliveryAtItem = item.checkShippingCosts.deliveryAt[0];
        return <div>
            <span>● การจัดส่งที่ {idx + 1}</span><br />
            <span>สินค้าจาก  {item.mainData.dataShop[0].name} :</span><br />
            {
                Object.keys(listMenuSelect).map((key, index) => {
                    const this_order = listMenuSelect[key];
                    return <React.Fragment key={key}>
                        <span>-{this_order.product.name.th || this_order.product.name.en}
                            &nbsp; x {this_order.quantity} ราคา {utils.numberFormatDecimalTwoPoint(this_order.product.dataProduct[0].priceNet * this_order.quantity)} บาท
                        </span>
                        <br />
                    </React.Fragment>
                })
            }
            <br />
            <span>จัดส่ง:</span> <br />
            <span>คุณ {item.createUser.name}</span><br />
            <span>เบอร์: {utils.formatPhoneNumber(item.createUser.mobile)}</span> <br />
            <span>{
                `${deliveryAtItem.address} ${deliveryAtItem.subdistrict.subdistrictName} ${deliveryAtItem.district.districtName} ${deliveryAtItem.province.provinceName} ${deliveryAtItem.zipcode}`
            }
            </span><br />
            <span>
                {deliveryAtItem.addressExtra}
            </span><br />
            <span>จัดส่งโดย {item.deliveryOption}</span><br />
            <span>รอบ {utils.changeFormatDateFull(deliveryAtItem.dateDelivery)} {utils.changeFormatTime(deliveryAtItem.dateDelivery)} น.+++ โดยประมาณ</span><br />

            <br />

            {
                this.showSumOrderSKOOTAR(item, orderShop.length)
            }
        </div>
    };


    showOrderCPIU = (item, idx, orderShop) => {
        if (item.mainData == null) {
            return
        }
        const listMenuSelect = { ...item.listMenuSelect };
        const deliveryAtDefault = orderShop[0].checkShippingCosts.deliveryAt[0];
        const deliveryAtItem = item.checkShippingCosts.deliveryAt[0];
        return <div>
            <span>● การจัดส่งที่ {idx + 1}</span><br />
            <span>สินค้าจาก  {item.mainData.dataShop[0].name} :</span><br />
            {
                Object.keys(listMenuSelect).map((key, index) => {
                    const this_order = listMenuSelect[key];
                    return <React.Fragment key={key}>
                        <span>-{this_order.product.name.th || this_order.product.name.en}
                            &nbsp; x {this_order.quantity} ราคา {utils.numberFormatDecimalTwoPoint(this_order.product.dataProduct[0].priceNet * this_order.quantity)} บาท
                        </span>
                        <br />
                    </React.Fragment>

                })
            }
            <br />
            <span>จัดส่ง:</span> <br />
            <span>คุณ {item.createUser.name}</span><br />
            {/* {item.isDefaultContactCustomer ? orderShop[0].createUser.name : item.createUser.name}</span><br /> */}
            <span>เบอร์: {utils.formatPhoneNumber(item.createUser.mobile)}</span> <br />
            {/* {item.isDefaultContactCustomer ? utils.formatPhoneNumber(orderShop[0].createUser.mobile) : utils.formatPhoneNumber(item.createUser.mobile)}</span> <br /> */}
            <span>สถานที่นัดรับ
            {
                    ` ${deliveryAtItem.address} ${deliveryAtItem.subdistrict.subdistrictName} ${deliveryAtItem.district.districtName} ${deliveryAtItem.province.provinceName} ${deliveryAtItem.zipcode}`
                }
            </span><br />
            <span>วันเวลานัดรับ {utils.changeFormatDateFull(deliveryAtItem.dateDelivery)} {utils.changeFormatTime(deliveryAtItem.dateDelivery)} น.</span><br />

            <br />

            {
                this.showSumOrerCPIU(item, orderShop.length)
            }
        </div>
    };

    showOrderKERRY = (item, idx, orderShop) => {
        if (item.mainData == null) {
            return
        }
        const listMenuSelect = { ...item.listMenuSelect };
        const deliveryAtDefault = orderShop[0].checkShippingCosts.deliveryAt[0];
        const deliveryAtItem = item.checkShippingCosts.deliveryAt[0];
        return <div>
            <span>● การจัดส่งที่ {idx + 1}</span><br />
            <span>สินค้าจาก  {item.mainData.dataShop[0].name} :</span><br />
            {
                Object.keys(listMenuSelect).map((key, index) => {
                    const this_order = listMenuSelect[key];
                    return <React.Fragment key={key}>
                        <span>-{this_order.product.name.th || this_order.product.name.en}
                            &nbsp; x {this_order.quantity} ราคา {utils.numberFormatDecimalTwoPoint(this_order.product.dataProduct[0].priceNet * this_order.quantity)} บาท
                        </span>
                        <br />
                    </React.Fragment>

                })
            }
            <br />
            <span>จัดส่ง:</span> <br />
            <span>คุณ {item.createUser.name}</span><br />
            <span>เบอร์: {utils.formatPhoneNumber(item.createUser.mobile)}</span> <br />
            <span>{
                `${deliveryAtItem.address} ${deliveryAtItem.subdistrict.subdistrictName} ${deliveryAtItem.district.districtName} ${deliveryAtItem.province.provinceName} ${deliveryAtItem.zipcode}`
            }
            </span><br />
            <span>
                {deliveryAtItem.addressExtra}
            </span><br />
            <span>จัดส่งโดย Kerry</span><br />
            <span>รอบ {utils.changeFormatDateFull(deliveryAtItem.dateDelivery)} {utils.changeFormatTime(deliveryAtItem.dateDelivery)} น.+++ โดยประมาณ</span><br />

            <br />

            {
                this.showSumOrderSKOOTAR(item, orderShop.length)
            }
        </div>
    };

    showOrderLogisticAll = (item, idx, orderShop) => {
        if (item.mainData == null) {
            return
        }
        const listMenuSelect = { ...item.listMenuSelect };
        const deliveryAtDefault = orderShop[0].checkShippingCosts.deliveryAt[0];
        const deliveryAtItem = item.checkShippingCosts.deliveryAt[0];
        const logisticLabel = this.props.orderShop[idx] && this.props.orderShop[idx].listLogistic.filter((code) => item.deliveryOption == code.logistic.code)

        return <div>
            <span>● การจัดส่งที่ {idx + 1}</span><br />
            <span>สินค้าจาก  {item.mainData.dataShop[0].name} :</span><br />
            {
                Object.keys(listMenuSelect).map((key, index) => {
                    const this_order = listMenuSelect[key];
                    return <React.Fragment key={key}>
                        <span>-{this_order.product.name.th || this_order.product.name.en}
                            &nbsp; x {this_order.quantity} ราคา {utils.numberFormatDecimalTwoPoint(this_order.product.dataProduct[0].priceNet * this_order.quantity)} บาท
                        </span>
                        <br />
                    </React.Fragment>
                })
            }
            <br />
            <span>จัดส่ง </span> <br />
            <span>คุณ {item.createUser.name}</span><br />
            <span>เบอร์: {utils.formatPhoneNumber(item.createUser.mobile)}</span> <br />
            <span>{
                `${deliveryAtItem.address} ${deliveryAtItem.subdistrict.subdistrictName} ${deliveryAtItem.district.districtName} ${deliveryAtItem.province.provinceName} ${deliveryAtItem.zipcode}`
            }
            </span><br />
            <span>
                {deliveryAtItem.addressExtra}
            </span><br />
            <span>จัดส่งโดย {logisticLabel && logisticLabel[0] && logisticLabel[0].logistic.label}</span><br />
            <span>รอบ {utils.changeFormatDateFull(deliveryAtItem.dateDelivery)} {utils.changeFormatTime(deliveryAtItem.dateDelivery)} น.+++ โดยประมาณ</span><br />

            <br />

            {
                this.showSumOrderSKOOTAR(item, orderShop.length)
            }
        </div>
    };

    showOrderSpecial = (item, idx, orderShop) => {
        if (item.mainData == null) {
            return
        }
        const listMenuSelect = { ...item.listMenuSelect };
        const deliveryAtDefault = orderShop[0].checkShippingCosts.deliveryAt[0];
        const deliveryAtItem = item.checkShippingCosts.deliveryAt[0];
        const logisticLabel = this.props.listLogistic.filter((code) => item.deliveryOption == code.logistic.code)

        return <div>
            <span>● การจัดส่งที่ {idx + 1}</span><br />
            <span>สินค้าจาก  {item.mainData.dataShop[0].name} :</span><br />
            {
                Object.keys(listMenuSelect).map((key, index) => {
                    const this_order = listMenuSelect[key];
                    return <React.Fragment key={key}>
                        <span>-{this_order.product.name.th || this_order.product.name.en}
                            &nbsp; x {this_order.quantity} ราคา {utils.numberFormatDecimalTwoPoint(this_order.product.dataProduct[0].priceNet * this_order.quantity)} บาท
                        </span>
                        <br />
                    </React.Fragment>
                })
            }
            <br />
            <span>จัดส่ง </span> <br />
            <span>คุณ {item.createUser.name}</span><br />
            <span>เบอร์: {utils.formatPhoneNumber(item.createUser.mobile)}</span> <br />
            <span>{
                `${deliveryAtItem.address} ${deliveryAtItem.subdistrict.subdistrictName} ${deliveryAtItem.district.districtName} ${deliveryAtItem.province.provinceName} ${deliveryAtItem.zipcode}`
            }
            </span><br />
            <span>
                {deliveryAtItem.addressExtra}
            </span><br />
            <span>จัดส่งโดย ขนส่งพิเศษ {item.checkShippingCosts.deliveryNote ? ` ( ${item.checkShippingCosts.deliveryNote} ) ` : ""}  </span><br />
        <span>รอบ {utils.changeFormatDateFull(deliveryAtItem.dateDelivery)} {utils.changeFormatTime(deliveryAtItem.dateDelivery)} {item.checkShippingCosts.deliveryNote == "promotion99" ? "-18.00 น. โดยประมาณ" : "น.+++ โดยประมาณ"} </span><br />

            <br />

            {
                this.showSumOrderSKOOTAR(item, orderShop.length)
            }
        </div>
    };

    showSumOrerCPIU = (item, countOrderShop) => {
        if (countOrderShop !== 1) {
            return <>
                <span>
                    ค่าอาหาร {utils.numberFormatDecimalTwoPoint(this.sumPriceNet(item.listMenuSelect))} บาท
            </span>
                <br />
            </>
        }
        return null;
    };

    showSumOrderSKOOTAR = (item, countOrderShop) => {
        if (countOrderShop === 1) {
            return <>
                <span>
                    ค่าอาหารทั้งหมด {utils.numberFormatDecimalTwoPoint(this.sumPriceNet(item.listMenuSelect) - this.calculatePriceDiscountFood(item))} บาท
            </span>
                <br />
                <span>
                    ค่าจัดส่งทั้งหมด {utils.numberFormatDecimalTwoPoint(item.shippingCost.deliveryPrice - this.calculatePriceDiscountDrlivery(item))} บาท
            </span>
                <br />
            </>
        } else {
            return <>
                <span>
                    ค่าอาหาร {utils.numberFormatDecimalTwoPoint(this.sumPriceNet(item.listMenuSelect) - this.calculatePriceDiscountFood(item))} บาท
            </span>
                <br />
                <span>
                    ค่าจัดส่ง {utils.numberFormatDecimalTwoPoint(item.shippingCost.deliveryPrice - this.calculatePriceDiscountDrlivery(item))} บาท
                    {/* {item.isDefaultContactCustomer ? utils.numberFormatDecimalTwoPoint(this.props.orderShop[0].shippingCost.deliveryPrice) : utils.numberFormatDecimalTwoPoint(item.shippingCost.deliveryPrice)} บาท */}
                </span>
                <br />
                <span>
                    ยอดรวม {utils.numberFormatDecimalTwoPoint(((item.shippingCost.deliveryPrice + this.sumPriceNet(item.listMenuSelect)) - (this.calculatePriceDiscountFood(item) + this.calculatePriceDiscountDrlivery(item))))} บาท
                    {/* {item.isDefaultContactCustomer ? utils.numberFormatDecimalTwoPoint(this.props.orderShop[0].shippingCost.deliveryPrice + this.sumPriceNet(item.listMenuSelect)) : utils.numberFormatDecimalTwoPoint(item.shippingCost.deliveryPrice + this.sumPriceNet(item.listMenuSelect))} บาท */}
                </span>
                <br />
            </>
        }
    };

    showSumOrderKERRY = (item, countOrderShop) => {
        if (countOrderShop === 1) {
            return <>
                <span>
                    ค่าอาหารทั้งหมด {utils.numberFormatDecimalTwoPoint(this.sumPriceNet(item.listMenuSelect))} บาท
            </span>
                <br />
                <span>
                    ค่าจัดส่งทั้งหมด {utils.numberFormatDecimalTwoPoint(item.shippingCost.deliveryPrice)} บาท
            </span>
                <br />
            </>
        } else {
            return <>
                <span>
                    ค่าอาหาร {utils.numberFormatDecimalTwoPoint(this.sumPriceNet(item.listMenuSelect))} บาท
            </span>
                <br />
                <span>
                    ค่าจัดส่ง {utils.numberFormatDecimalTwoPoint(item.shippingCost.deliveryPrice)} บาท
                    {/* {item.isDefaultContactCustomer ? utils.numberFormatDecimalTwoPoint(this.props.orderShop[0].shippingCost.deliveryPrice) : utils.numberFormatDecimalTwoPoint(item.shippingCost.deliveryPrice)} บาท */}
                </span>
                <br />
                <span>
                    ยอดรวม {utils.numberFormatDecimalTwoPoint(item.shippingCost.deliveryPrice + this.sumPriceNet(item.listMenuSelect))} บาท
                    {/* {item.isDefaultContactCustomer ? utils.numberFormatDecimalTwoPoint(this.props.orderShop[0].shippingCost.deliveryPrice + this.sumPriceNet(item.listMenuSelect)) : utils.numberFormatDecimalTwoPoint(item.shippingCost.deliveryPrice + this.sumPriceNet(item.listMenuSelect))} บาท */}
                </span>
                <br />
            </>
        }
    };


    sumPriceNet = (listMenuSelects) => {
        const total = Object.keys(listMenuSelects).reduce(function (previous, key) { // for loop sum total price
            const result = parseFloat(previous + (listMenuSelects[key].quantity * listMenuSelects[key].product.dataProduct[0].priceNet));
            if (isNaN(result)) {
                return 0
            }
            return result
        }, 0);
        return total;
    }


    summaryAll = () => {
        const { orderShop } = this.props;
        const countOrderShop = orderShop.length;

        if (countOrderShop === 1) {
            return <>
                <br />
                <span>ยอดโอนชำระทั้งหมด {utils.numberFormatDecimalTwoPoint(this.state.sumOrderAll.sumTotal)} บาท</span>
            </>
        } else {
            const { orderType } = this.state
            if (orderType.SKOOTAR.length !== 0 && orderType.CPIU.length === 0 || orderType.KERRY.length === 0) { // type SKOOTAR all.
                return <>
                    <span>● สรุป</span><br />
                    <span> ค่าอาหารทั้งหมด {utils.numberFormatDecimalTwoPoint(this.state.sumOrderAll.sumFood)} บาท </span><br />
                    <span> ค่าจัดส่งทั้งหมด {utils.numberFormatDecimalTwoPoint(this.state.sumOrderAll.sumdeliveryPrice)} บาท</span><br />
                    <br />
                    <span>  ยอดโอนชำระทั้งหมด {utils.numberFormatDecimalTwoPoint(this.state.sumOrderAll.sumTotal)} บาท</span><br />
                </>
            } else if (orderType.CPIU.length !== 0 && orderType.SKOOTAR.length === 0 || orderType.KERRY.length === 0) { // type CPIU all.
                return <>
                    <span>● สรุป</span><br />
                    <span>  ยอดโอนชำระทั้งหมด {utils.numberFormatDecimalTwoPoint(this.state.sumOrderAll.sumTotal)} บาท</span><br />
                </>
            } else if (orderType.KERRY.length !== 0 && orderType.SKOOTAR.length === 0 && orderType.CPIU.length === 0) { // type CPIU all.
                return <>
                    <span>● สรุป</span><br />
                    <span>  ยอดโอนชำระทั้งหมด {utils.numberFormatDecimalTwoPoint(this.state.sumOrderAll.sumTotal)} บาท</span><br />
                </>
            } else { // all type
                return <>
                    <span>● สรุป</span><br />
                    <span> ค่าอาหารทั้งหมด {utils.numberFormatDecimalTwoPoint(this.state.sumOrderAll.sumFood)} บาท </span><br />
                    <span> ค่าจัดส่งทั้งหมด {utils.numberFormatDecimalTwoPoint(this.state.sumOrderAll.sumdeliveryPrice)} บาท</span><br />
                    <br />
                    <span>  ยอดโอนชำระทั้งหมด {utils.numberFormatDecimalTwoPoint(this.state.sumOrderAll.sumTotal)} บาท</span><br />
                </>
            }

        }
    };

    mainSummaryOrder = () => {
        const { orderShop } = this.props;
        const countOrderShop = orderShop.length;

        return orderShop.map((item, idx) =>
            <React.Fragment key={idx + "Main"}>
                {this.checkShowTypeOrder(item, idx, orderShop)}
                {/* {item.deliveryOption === "SKOOTAR" ? this.showOrderSKOOTAR(item, idx, orderShop) : (item.deliveryOption === "CPIU" ? this.showOrderCPIU(item, idx, orderShop) : null)} */}
                {countOrderShop === 1 ? null : <>
                    --------------------------------------------<br />
                </>}
            </React.Fragment>
        )
    };


    checkShowTypeOrder = (item, idx, orderShop) => {
        if (item.deliveryOption === "SKOOTAR" || item.deliveryOption === "LLM") {
            return this.showOrderLogisticAll(item, idx, orderShop)
        } else if (item.deliveryOption === "CPIU") {
            return this.showOrderCPIU(item, idx, orderShop)
        } else if (item.deliveryOption === "KERRY") {
            return this.showOrderLogisticAll(item, idx, orderShop)
        } else if (item.deliveryOption === "SPECIAL") {
            return this.showOrderSpecial(item, idx, orderShop)
        } else {
            return this.showOrderLogisticAll(item, idx, orderShop)
        }
    }


    confirmSendData = () => {
        this.props.setLoaddingCreateOrrder();
        this.props.handleClickConfirmSendData({
            success: () => {
                this.props.closeModalSummaryOrder()
            }
        });
    };



    calculatePriceDiscountDrlivery = (item) => {
        const { updateInvoiceById, errors } = item;
        if (updateInvoiceById.priceSpecialDiscount && !errors.specialDiscount.code) {
            return parseFloat(updateInvoiceById.priceSpecialDiscount.shippingDiscount) || 0;
        }
        return 0
    }

    calculatePriceDiscountFood = (item) => {
        const { updateInvoiceById, errors } = item;
        if (updateInvoiceById.priceSpecialDiscount && !errors.specialDiscount.code) {
            return parseFloat(updateInvoiceById.priceSpecialDiscount.foodDiscount) || 0;
        }
        return 0
    }

    onClickSendMsg = () => {
        this.props.handleClickSendMsg()
    }



    render() {
        const { closeModalSummaryOrder, isLoadingCreateOrder } = this.props;
        const { isOpen } = this.props.modalSummary;

        return (
            <Modal
                isOpen={isOpen}
                closeModal={isLoadingCreateOrder ? null : closeModalSummaryOrder}
                size="lg"
                title={this.state.title}
            >
                <div className="container">
                    <div className="box-show-summary-order">
                        <div className="row justify-content-between">
                            <span className="font-size-16px">
                                ค่าอาหารทั้งหมด
                        </span>
                            <span className="font-size-16px">
                                {utils.numberFormatDecimalTwoPoint(this.state.sumOrderAll.sumFood) || 0}.-
                        </span>
                        </div>

                        <div className="row justify-content-between">
                            <span className="font-size-16px">
                                ค่าจัดส่ง
                        </span>
                            <span className="font-size-16px">
                                {utils.numberFormatDecimalTwoPoint(this.state.sumOrderAll.sumdeliveryPrice) || 0}.-
                        </span>
                        </div>
                        <div className="row line-hr mt-1 mb-2" />


                        <div className="row justify-content-between">
                            <span className="font-bold-size-20px">
                                รวม
                        </span>
                            <span className="font-primary-bold-size-16px">
                                {utils.numberFormatDecimalTwoPoint(this.state.sumOrderAll.sumTotal) || 0}.-
                        </span>
                        </div>

                        <div className="row mt-3">
                            <div className="box-message">
                                <div className="header">
                                    <label className="font-bold-primary-size-20px ">
                                        ข้อความที่จะส่งให้ลูกค้า
                                    </label>
                                </div>

                                <div className="content">
                                    <div >
                                        <span> รบกวนตรวจสอบ Order ก่อนชำระค่าบริการ</span><br />
                                        --------------------------------------------<br />
                                        {isOpen && this.mainSummaryOrder()}
                                    </div>
                                    {
                                        this.summaryAll()
                                    }
                                </div>
                            </div>
                        </div>

                        <div className="row mt-2per">
                            <img
                                src={this.props.createOrder.sendMsg ? checkBoxActiveSquare : checkBoxIsActiveSquare}
                                alt="checkBoxActiveSquare"
                                className="size-icon-20px cursor-pointer"
                                onClick={this.onClickSendMsg}
                            />
                            <span className="ml-2">
                                ส่งข้อความให้ลูกค้า
                            </span>
                        </div>


                        <div className="row mt-5per">
                            <button
                                type="button"
                                className={`btn w-100 ${isLoadingCreateOrder ? "btn-darkgrey" : "btn-primary"}`}
                                onClick={isLoadingCreateOrder ? null : this.confirmSendData}>
                                {this.state.textButton}
                                {
                                    isLoadingCreateOrder ?
                                        <div className="ml-3 spinner-border text-light size-icon-20px" role="status">
                                            <span className="sr-only">Loading...</span>
                                        </div>
                                        :
                                        null
                                }

                            </button>
                        </div>
                    </div>

                </div>
            </Modal>
        )
    }
}


const mapStatetoProps = state => ({
    orderShop: state.createOrder.orderShop,
    isLoadingCreateOrder: state.createOrder.isLoadingCreateOrder,
    createOrder: state.createOrder,
    listLogistic: state.logistics.listLogistic
});

const mapDispatchToPorps = dispatch => ({
    setLoaddingCreateOrrder: () => dispatch(actions_createOrder.setLoaddingCreateOrrder()),
    handleClickSendMsg: () => dispatch(actions_createOrder.handleClickSendMsg()),
    handleClickConfirmSendData: (({ success }) => dispatch(actions_createOrder.handleClickConfirmSendData({ success }))),
});

export default connect(mapStatetoProps, mapDispatchToPorps)(ModalAddress);