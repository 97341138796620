export const Type = {
    OPEN_LOADING : "OPEN_LOADING",
    CLOSE_LOADING : "CLOSE_LOADING"
}

export const openLoading  = () => ({
    type: Type.OPEN_LOADING
});

export const closeLoading  = () => ({
    type: Type.CLOSE_LOADING
});