import {
    put,
    takeLatest,
    call
} from "redux-saga/effects";
import * as actions from "../actions/users";
import usersCtrl from '../../controllers/usersCtrl';
import history from '../../history';
import Cookies from 'js-cookie';
import * as PERMISSION from "../../config/permission";


const api_usersCtrl = new usersCtrl();

function* login(action) {
    const { payload } = action;
    try {

        const { data } = yield call(api_usersCtrl.login, payload);

        const userType = data.userType;
        Cookies.set('access_token', data.token, { expires: 7 });
        Cookies.set('user_id', data._id, { expires: 7 });
        Cookies.set('userType', data.userType, { expires: 7 });
        Cookies.set('userName', data.username, { expires: 7 })
        yield put({
            type: actions.Type.LOGIN_SUCCESS, payload: {
                displayName: data.username,
                id: data._id,
                userType: data.userType
            }
        });

        if (PERMISSION.SUPERADMIN === userType || PERMISSION.CALLCENTER === userType) {
            history.push('/');

        } else if (PERMISSION.ADMIN === userType) {
            history.push('/check-transfer');
        } else {
            yield yield put(actions.logoutRequest());
        }
    } catch (error) {
        const err_res = error.response;
        if (err_res.status === 401) {
            yield put({
                type: actions.Type.LOGIN_FAIL,
                payload: {
                    code: err_res.data.error.code,
                    message: err_res.data.error.message
                }
            });
        }
    }
}

function* logout(action) {
    try {
        yield call(api_usersCtrl.logout);
        yield put({
            type: actions.Type.LOGOUT_SUCCESS
        });
        Cookies.remove('access_token');
        Cookies.remove('user_id');
        Cookies.remove('userType');
        Cookies.remove('userName');
        history.push('/login')
    } catch (error) {
        console.log(error)
    }
}

function* getUserByID() {
    try {
        const { data } = yield call(api_usersCtrl.getUserByID);
        yield put({
            type: actions.Type.GET_USER_BY_ID_SUCCCESS,
            payload: {
                displayName: data.displayName,
                id: data.displayName,
                userType: data.userType
            }
        });
    } catch (error) {
        console.log(error);
    }
}

export default function* usersWatch() {
    yield takeLatest(actions.Type.LOGIN_REQUEST, login);
    yield takeLatest(actions.Type.LOGOUT_REQUEST, logout);
    yield takeLatest(actions.Type.GET_USER_BY_ID_REQUIRE, getUserByID)

}