/*global google*/
import React from 'react';
import {
    withScriptjs,
    withGoogleMap,
    GoogleMap,
    Marker,
} from "react-google-maps";
import { SearchBox } from "react-google-maps/lib/components/places/SearchBox";
import { compose, withProps, lifecycle } from "recompose";
import _ from 'lodash';
import api from '../config/api';

const MapWithASearchBox = compose(
    withProps({
        googleMapURL: "https://maps.googleapis.com/maps/api/js?key=" + api.KEY_GOOGLE_MAP + "&v=3.exp&libraries=geometry,drawing,places",
        loadingElement: <div style={{ height: `100%` }} />,
        containerElement: <div style={{ height: `350px` }} />,
        mapElement: <div style={{ height: `100%` }} />,
        center: { lat: 13.736717, lng: 100.523186 },
    }),
    lifecycle({
        componentWillReceiveProps(nextProps) {
            if (nextProps.markerPosition && nextProps.markerPosition.lat != null && nextProps.markerPosition.lng != null) {
                this.setState({
                    center: {
                        lat: parseFloat(nextProps.markerPosition.lat), lng: parseFloat(nextProps.markerPosition.lng)
                    }
                });
            }

        },
        componentWillMount() {
            const refs = {}

            this.setState({
                bounds: null,
                center: { lat: 13.736717, lng: 100.523186 },
                markers: [],
                onMapMounted: ref => {
                    refs.map = ref;
                },
                // onBoundsChanged: () => {
                //     this.setState({
                //         bounds: refs.map.getBounds(),
                //         center: refs.map.getCenter(),
                //     })
                // },
                onSearchBoxMounted: ref => {
                    refs.searchBox = ref;
                },
                onPlacesChanged: () => {
                    const places = refs.searchBox.getPlaces();
                    const bounds = new google.maps.LatLngBounds();

                    places.forEach(place => {
                        if (place.geometry.viewport) {
                            bounds.union(place.geometry.viewport)
                        } else {
                            bounds.extend(place.geometry.location)
                        }
                    });
                    const nextMarkers = places.map(place => ({
                        position: place.geometry.location,
                    }));
                    const nextCenter = _.get(nextMarkers, '0.position', this.state.center);
                    this.props.onClickSearchMap(nextCenter.lat(), nextCenter.lng())
                    this.setState({
                        center: nextCenter,
                        markers: nextMarkers,
                    });
                    // refs.map.fitBounds(bounds);
                },
            })
        },
    }),
    withScriptjs,
    withGoogleMap
)(props =>
    <GoogleMap
        ref={props.onMapMounted}
        defaultZoom={15}
        center={props.center}
        onClick={props.onClickMap}
        // onBoundsChanged={props.onBoundsChanged}
        options={
            {
                draggable: true,
                // fullscreenControl: false,
                // zoomControl: false,
                streetViewControl: false,
                mapTypeControl: false
            }
        }
    >
        <SearchBox
            ref={props.onSearchBoxMounted}
            bounds={props.bounds}
            controlPosition={google.maps.ControlPosition.TOP_LEFT}
            onPlacesChanged={props.onPlacesChanged}
        >
            <input
                type="text"
                placeholder="Customized your placeholder"
                style={{
                    boxSizing: `border-box`,
                    border: `1px solid transparent`,
                    width: `240px`,
                    height: `32px`,
                    marginTop: `20px`,
                    marginLeft: `20px`,
                    padding: `0 12px`,
                    borderRadius: `3px`,
                    boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
                    fontSize: `14px`,
                    outline: `none`,
                    textOverflow: `ellipses`,
                }}
            />
        </SearchBox>
        <Marker
            draggable={true}
            position={props.markerPosition ? { lat: parseFloat(props.markerPosition.lat), lng: parseFloat(props.markerPosition.lng) } : { lat: props.center.lat, lng: props.center.lng }}
            onDragEnd={props.onClickMap}
        />
    </GoogleMap>
);

class Map extends React.Component {
    onClickMap = (event) => {
        const location = {
            lat: event.latLng.lat(),
            lng: event.latLng.lng()
        }
        this.props.getAddress(location);
    }
    onClickSearchMap = (lat, lng) => {
        const location = {
            lat,
            lng
        };
        this.props.getAddress(location);
    }
    render() {
        const { location } = this.props;

        return (
            <div className="row">
                <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 pr-0 pl-0">
                    <MapWithASearchBox
                        onClickMap={this.onClickMap}
                        markerPosition={location.lat && location.lng && location}
                        onClickSearchMap={this.onClickSearchMap}
                    />
                </div>
            </div>
        )
    }
}

export default Map;