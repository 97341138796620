
import * as PERMISSSION from '../config/permission';

const header = [{
    url: "/",
    urlChildren: [{
        url: '/'
    },
    {
        url: '/all-menu'
    },
    {
        url: '/order-history/:id'
    },
    ],
    title: "แชท",
    permission: PERMISSSION.PERRMISSION_HEADER_CHAT,
    isActive: true
},
// {
//     url: "/check-transfer",
//     title: "ตรวจสอบยอดโอน",
//     permission: PERMISSSION.PERRMISSION_HEADER_CHECK_TRANFER,
//     isActive: true
// },
// {
//     url: "/list-order-all",
//     title: "ออเดอร์ทั้งหมด",
//     permission: PERMISSSION.PERRMISSION_HEADER_LIST_ORDER_ALL,
//     isActive: false
// },
// {
//     url: "/extra-data",
//     title: "ข้อมูลเพิ่มเติม",
//     permission: PERMISSSION.PERRMISSION_HEADER_CHAT,
//     isActive: true
// },
{
    url: "/media-gallery",
    title: "แกลลอรี่",
    permission: PERMISSSION.PERRMISSION_HEADER_LIST_ORDER_ALL,
    isActive: true
}
];

export default header;
