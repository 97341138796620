import React from 'react'; 
import { BrowserRouter, Redirect, Switch, Router } from 'react-router-dom';

import EmptyLayout from './components/Layout/EmptyLayout';
import LayoutRoute from './components/Layout/LayoutRoute';
import MainLayout from './components/Layout/MainLayout';
import EmptySidebarLayout from './components/Layout/EmptySidebarLayout';


/// Component Page ///
import Login from './pages/Login';
import CreateOrder from './pages/CreateOrder';
import Chat from './pages/Chat';
import CheckTransfer from './pages/CheckTransfer';
import ListOrderAll from './pages/ListOrderAll';
import OrderHistory from './pages/OrderHistory';
import FullExtraData from './pages/FullExtraData';
import MediaGallery from './pages/MediaGallery';

/// Component Page ///


// config history //
import history from './history';
// config history //

// util //
import * as utils from "./utils/utils";
// util //



class App extends React.Component {
  // componentDidMount() {
  // const isLogin = utils.isLogin();
  // if (!isLogin) {
  //   history.push("/login");
  // }
  // }
  render() {
    return (
      <BrowserRouter>
        <Router history={history}>
          <Switch>
            <LayoutRoute
              exact
              path="/login"
              layout={EmptyLayout}
              component={Login}
            />

            <LayoutRoute
              exact
              path="/"
              layout={EmptySidebarLayout}
              component={Chat}
            />

            <LayoutRoute
              exact
              path="/recommend-menu"
              layout={MainLayout}
              component={CreateOrder}
            />

            <LayoutRoute
              exact
              path="/promotion-menu"
              layout={MainLayout}
              component={CreateOrder}
            />

            <LayoutRoute
              exact
              path="/season-menu"
              layout={MainLayout}
              component={CreateOrder}
            />

            <LayoutRoute
              exact
              path="/chat"
              layout={EmptySidebarLayout}
              component={Chat}
            />

            <LayoutRoute
              exact
              path="/all-menu"
              layout={MainLayout}
              component={CreateOrder}
            />


            <LayoutRoute
              exact
              path="/order-history/:id"
              layout={EmptySidebarLayout}
              component={OrderHistory}
            />


            <LayoutRoute
              exact
              path="/check-transfer"
              layout={EmptySidebarLayout}
              component={CheckTransfer}
            />

            <LayoutRoute
              exact
              path="/list-order-all"
              layout={EmptySidebarLayout}
              component={ListOrderAll}
            />

            <LayoutRoute
              exact
              path="/extra-data"
              layout={MainLayout}
              component={FullExtraData}
            />

            <LayoutRoute
              exact
              path="/media-gallery"
              layout={EmptySidebarLayout}
              component={MediaGallery}
            />



            <Redirect to="/" />
          </Switch>
        </Router>
      </BrowserRouter>
    )
  }
}


export default (App);