export const Type = {
    OPEN_MODAL_ALERT: "OPEN_MODAL_ALERT",
    CLOSE_MODAL_ALERT: "CLOSE_MODAL_ALERT",
}

export const openModalAlert = (payload) => {
    return {
        Type: Type.OPEN_MODAL_ALERT,
        payload
    }
}

export const closeModalAlert = () => {
    return {
        type: Type.CLOSE_MODAL_ALERT
    }
}