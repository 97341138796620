import { Type } from "../actions/users";

const INITIAL_STATE = {
    user: {
        displayName: '',
        id: '',
        userType: ''
    },
    error: {
        isError: false,
        message: '',
        code: ''
    }
};

export default function users(state = INITIAL_STATE, actions) {
    const { type, payload } = actions;
    switch (type) {
        case (Type.LOGIN_SUCCESS): {
            return {
                ...state,
                user: {
                    ...payload
                }
            };
        }

        case (Type.LOGOUT_SUCCESS): {
            return {
                ...state
            }
        }
        case (Type.GET_USER_BY_ID_SUCCCESS): {
            return {
                ...state
            }
        }
        case (Type.LOGIN_FAIL): {            
            return {
                ...state,
                error: {
                    isError: true,
                    message: payload.message,
                    code: payload.code

                }
            }
        }
        case (Type.GET_CODE_CREATE): {
            return {
                ...state,
                code : payload.code
            }
        }
        default:
            return {
                ...state,
                user: {
                    ...payload
                }
            }
    }
}