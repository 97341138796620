
import axios_saranros from '../lib/axios-saranros';

class addressCtrl {
    getListProvinces = (params) => {
        return new Promise(async (resolve, reject) => {
            try {
                const filter = {
                    "order": "index ASC"
                }
                const url = "api/Provinces?filter=" + JSON.stringify(filter);
                const result = await axios_saranros.get(url);
                // Todo : when  save complete 
                resolve(result);
            }
            catch (err) {
                // Todo : save fail
                reject(err)
            }
        });
    }

    getListDistricts = (params) => {
        return new Promise(async (resolve, reject) => {
            try {
                const filter = {
                    where: {
                        provinceId: params.provinceId
                    }
                }
                const url = "api/Districts?filter=" + JSON.stringify(filter);
                const result = await axios_saranros.get(url);
                // Todo : when  save complete 
                resolve(result);
            }
            catch (err) {
                // Todo : save fail
                reject(err)
            }
        });
    }

    getListSubDistricts = (params) => {
        return new Promise(async (resolve, reject) => {
            try {
                const filter = {
                    where: {
                        districtId: params.districtId
                    }
                }
                const url = "api/SubDistricts?filter=" + JSON.stringify(filter);
                const result = await axios_saranros.get(url);
                // Todo : when  save complete 
                resolve(result);
            }
            catch (err) {
                // Todo : save fail
                reject(err)
            }
        });
    }


    getAllAddressName = (params) => {
        return new Promise(async (resolve, reject) => {
            try {
                if (params.districtName == null || params.provinceName == null || params.subdistrictName == null) {
                    resolve({
                        list_province: null,
                        list_districts: null,
                        list_subDistricts: null
                    });
                }

                const filter_getProvince = { where: { provinceName: params.provinceName } };
                const url_getProvince = "api/Provinces?filter=" + JSON.stringify(filter_getProvince);
                const list_province = await axios_saranros.get(url_getProvince);



                const filter_getDistricts = {
                    where: {
                        districtName: params.districtName,
                        provinceId: list_province.data[0].provinceId
                    }
                };
                const url_getDistricts = "api/Districts?filter=" + JSON.stringify(filter_getDistricts);
                const list_districts = await axios_saranros.get(url_getDistricts);



                const filter_getSubDistricts = {
                    where: {
                        subdistrictName: params.subdistrictName,
                        districtId: list_districts.data[0].districtId
                    }
                };
                const url_getSubDistricts = "api/SubDistricts?filter=" + JSON.stringify(filter_getSubDistricts);
                const list_subDistricts = await axios_saranros.get(url_getSubDistricts);


                resolve({
                    list_province: list_province.data[0] || null,
                    list_districts: list_districts.data[0] || null,
                    list_subDistricts: list_subDistricts.data[0] || null
                });
            } catch (error) {
                reject(error);
            }
        });
    }



}
export default addressCtrl;