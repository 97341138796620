import React, { Component } from 'react';
import { Modal, ModalBody } from 'reactstrap';
import '../../styles/chat/modal.scss'

class ModalEditname extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            modal: false,
            mediaName: "",

        }
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            modal: nextProps.showModalEditname,
            mediaName: nextProps.mediaName || "",
        })
    }

    closeModal = () => {
        this.props.closeModalEditname()
    }

    onClickSaveData = async () => {
        this.props.updateMedia(this.props.mediaId, { name: this.state.mediaName })
    }

    render() {
        return (
            <Modal className="modal-editname align-self-center" isOpen={this.state.modal}>
                <ModalBody>
                    <div className="col-12 btn" >
                        <i className="btn-cloes icon-close" onClick={this.closeModal} />
                    </div>
                    <div className="box-modaleditname">
                        <h4><strong>เปลี่ยนชื่อไฟล์</strong></h4>
                        <div className="row editname">
                            <input type="text" className="col-12 input-editname" placeholder={this.state.mediaName} onChange={(e) => this.setState({ mediaName: e.target.value })}/>
                            <button className="col-12 btn-save-editname" onClick={this.onClickSaveData} disabled={this.state.mediaName.length > 0 ? false : true}>บันทึก</button>
                        </div>
                        {/* <p>มีชื่อไฟล์นี้แล้ว กรุณาเปลี่ยนใหม่</p> */}
                    </div>
                </ModalBody>
            </Modal>
        )
    }
}
export default ModalEditname