import React from 'react';
import SelectOption from '../../Select';
import MapComponent from '../../Map';
import { connect } from 'react-redux';
import * as action_address from '../../../redux/actions/address';
import * as actions_createOrder from '../../../redux/actions/createOrder';
import Cookies from 'js-cookie'

class FromAddress extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
        }
    }
    componentDidMount() {
        this.props.getListProvinces();
    }

    componentWillReceiveProps(nextProps) {
        if (this.state.location != nextProps.value.location) {
            this.setState({
                location: nextProps.value.location.lat + ',' + nextProps.value.location.lng
            })
        }
    }

    onClickSendLocation = async (value, id, platform) => {
        const address = `${value.address} ${value.subdistrict.subdistrictName} ${value.district.districtName} ${value.province.provinceName} ${value.zipcode} ${value.addressExtra}`
        const data = {///// ส่ง data ชุดนี้ไป api sendDataToLine_Promise โดยต้องหา id มาใส่ แะ platform sender ด้วย
            message: "location",
            id,
            type: "location",
            locate: {
                type: "location",
                latitude: value.location.lat,
                longitude: value.location.lng,
                address: 'ที่อยู่ตาม location',
                title: 'ที่อยู่ตาม location',
            },
            platform,
            sender: Cookies.get('userName')
        }
        this.props.setSendLocation(data)
    }

    onChangeLocation = (e) => {
        e.preventDefault()
        const arr = e.target.value.split(',')
        this.setState({
            location: arr[0] + ',' + arr[1],
        })
        if (e.key === 'Enter') {
            e.preventDefault()
            this.props.getAddress({ lat: arr[0], lng: arr[1] }, { idx: this.props.idx })
          }
       

    }


    render() {
        const { handleChangeAddress_AddressExtra, handleClickSearchLatLongByAddress,
            getAddress, value, listProvincesGlobal, handleSelectProvince, listDistrictsGlobal,
            handleSelectDistricts, listSubDistrictsGlobal, handleSelectSubdistrict,
            handleChangeZipcode, isGlobalAddress, listDistricts, listSubDistricts
        } = this.props;

        return (
            <div>
                <div className="row mt-2">
                    <SelectOption
                        placeholder="เลือกจังหวัด"
                        options={listProvincesGlobal}
                        getOptionLabel={(option) => option.provinceName}
                        getOptionValue={(option) => option.provinceId}
                        onChange={handleSelectProvince}
                        value={value.province || null}
                    />
                </div>
                <div className="row mt-2">
                    <SelectOption
                        placeholder="เลือกเขต"
                        options={isGlobalAddress ? listDistrictsGlobal : listDistricts}
                        getOptionLabel={(option) => option.districtName}
                        getOptionValue={(option) => option.districtId}
                        onChange={handleSelectDistricts}
                        value={value.district || null}
                    />
                </div>
                <div className="row mt-2">
                    <SelectOption
                        placeholder="เลือกแขวง"
                        options={isGlobalAddress ? listSubDistrictsGlobal : listSubDistricts}
                        onChange={handleSelectSubdistrict}
                        getOptionLabel={(option) => option.subdistrictName}
                        getOptionValue={(option) => option.subdistrictId}
                        value={value.subdistrict || null}
                    />
                </div>
                <div className="row mt-2">
                    <div className="form-group w-100">
                        <input
                            name="zipcode"
                            type="text"
                            className="form-control input-background-color"
                            placeholder="รหัสไปรษณีย์"
                            onChange={(evt) => handleChangeZipcode(evt)}
                            value={(value && value.zipcode) || ''}
                        />
                    </div>
                </div>
                <div className="row mt-2">
                    <div className="form-group w-100">
                        <textarea
                            name="address"
                            onChange={(evt) => handleChangeAddress_AddressExtra(evt)}
                            value={(value && value.address) || ''}
                            className="form-control input-background-color"
                            rows="3"
                            placeholder="บ้านเลขที่,ซอย,ถนน"

                        ></textarea>
                    </div>
                </div>
                <div className="row">
                    <button type="button"
                        className="btn btn-primary w-100 color-primary btn-primary"
                        onClick={() => handleClickSearchLatLongByAddress()}>ค้นหาที่อยู่
                        </button>
                </div>

                {this.props.isAddressOnChange ? "" : <div>
                    <div className="mt-3">
                        <MapComponent
                            location={{
                                lat: value && value.location.lat,
                                lng: value && value.location.lng
                            }}
                            getAddress={(location) => getAddress(location)}
                        />
                    </div>

                    {
                        this.props.sendLatLng && (this.props.sendLatLng == "ContentTabUser" || this.props.sendLatLng == "ModalAddress") ?
                            <div className="row mt-4">
                                <div className="form-group w-100">
                                    <label className="font-bold-size-16px">
                                        แลต,ลอง
                            </label>

                                    <div className="d-flex">
                                        <input
                                            name="latlng"
                                            type="text"
                                            className="form-control input-background-color"
                                            onChange={(e) => this.setState({ location: e.target.value })}
                                            onKeyPress={(e) => this.onChangeLocation(e)} //// ตรงนี้เมื่อใส่ lat lng แล้วให้นำค่าไปหาใน gg map ใหม่
                                            value={`${this.state.location}`}

                                        />

                                        <button
                                            className="btn w-50 ml-2 color-primary btn-primary"
                                            // onClick={() => this.onClickSendLocation(value, this.props.userChat && this.props.userChat.id, this.props.userChat && this.props.userChat.platform)} ////  หา id มาใส่
                                            onClick={() => this.onClickSendLocation(value, this.props.sendLatLng == "ContentTabUser" ? this.props.userChat && this.props.userChat.id : this.props.userData && this.props.userData.id, this.props.sendLatLng == "ContentTabUser" ? this.props.userChat && this.props.userChat.platform : this.props.userData && this.props.userData.platform)}

                                        >
                                            ส่ง location
                                        </button>
                                    </div>

                                </div>
                            </div> : ""
                    } 


                    <div className="row mt-4">
                        <div className="form-group w-100">
                            <textarea
                                className="form-control input-background-color"
                                name="addressExtra"
                                value={value && value.addressExtra}
                                onChange={(evt) => handleChangeAddress_AddressExtra(evt)}
                                rows="3"
                                placeholder="รายละเอียดที่อยู่เพิ่มเติม"></textarea>
                        </div>
                    </div>
                </div>
                }

            </div>
        )
    }
};


const mapStateToProps = state => ({
    listProvincesGlobal: state.address.listProvinces,
    listDistrictsGlobal: state.address.listDistricts,
    listSubDistrictsGlobal: state.address.listSubDistricts,
    idx: state.createOrder.activeTab,
    userChat: state.createOrder.userChat
});


const mapDispatchToPorps = dispatch => ({
    getListProvinces: () => dispatch(action_address.getListProvinces()),
    setLatLng: ({ lat, lng, idx }) => dispatch(actions_createOrder.setLatLng({ lat, lng, idx })),
    setSendLocation: (data) => dispatch(actions_createOrder.setSendLocation(data))
});


export default connect(mapStateToProps, mapDispatchToPorps)(FromAddress);