const SARANROS_API =  process.env.REACT_APP_SARANROS_API;
const MEDIA_API = process.env.REACT_APP_MEDIA_API;
const MEDIA_SERVICE_KEY = process.env.REACT_APP_MEDIA_API_SERVICE_KEY
const MEDIA_SECRET_KEY = process.env.REACT_APP_MEDIA_API_SECRET_KEY
const THUMP = process.env.REACT_APP_THUMP;
const THUMP_NEW = process.env.REACT_APP_THUMP_NEW;
const PROMOTION_API = process.env.REACT_APP_PROMOTION_API;

const SERVICE_KEY_PROMOTION = process.env.REACT_APP_SERVICE_KEY_PROMOTION;
const PRIVATE_KEY_PROMOTION = process.env.REACT_APP_PRIVATE_KEY_PROMOTION;
const KEY_GOOGLE_MAP = process.env.REACT_APP_KEY_GOOGLE_MAP;

const CONNECTOR_SOCIAL_API = process.env.REACT_APP_CONNECTOR_SOCIAL_API
const SOCKET_API = process.env.REACT_APP_SOCKET_API

const api = {
    SARANROS_API,
    KEY_GOOGLE_MAP,
    MEDIA_API,
    MEDIA_SERVICE_KEY,
    MEDIA_SECRET_KEY,
    THUMP,
    THUMP_NEW,
    PROMOTION_API,
    SERVICE_KEY_PROMOTION,
    PRIVATE_KEY_PROMOTION,
    CONNECTOR_SOCIAL_API,
    SOCKET_API,
};
export default api;