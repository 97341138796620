import {
    put,
    takeLatest,
    call,
    select
} from "redux-saga/effects";
import * as actions_menus from "../actions/menus";
import { getCreateOrder } from './selectors';
import * as actions_loading from "../actions/loading";
import MenuCtrl from "../../controllers/menuCtrl";
import productsCtrl from "../../controllers/productsCtrl";
import moment from 'moment'

const api_menuCtrl = new MenuCtrl()
const api_productsCtrl = new productsCtrl();


function* getListMenu(action) {
    const { payload } = action;
    
    try {
        // yield put(actions_loading.openLoading());// open loading.
        const { data } = yield call(api_menuCtrl.getListMenu, payload);
        
        yield put({
            type: actions_menus.Type.GET_LIST_MENU_SUCCESS, data
        });
        // yield put({ // close loading.
        //     type: actions_loading.Type.CLOSE_LOADING
        // });
        // yield put(actions_loading.closeLoading());// close loading.
    } catch (error) {
        console.log(error)
        // yield put({ // close loading.
        //     type: actions_loading.Type.CLOSE_LOADING
        // });
    }
}



export default function* menusWatch() {
    yield takeLatest(actions_menus.Type.GET_LIST_MENU_REQUIRE, getListMenu);
    yield takeLatest(actions_menus.Type.SEARCH_MENU_REQUIRE, getListMenu);
} 