import {
    put,
    takeLatest,
    call
} from "redux-saga/effects";
import * as actions from "../actions/logistics";
import logisticsCtrl from '../../controllers/logisticsCtrl';
const api_logisticsCtrl = new logisticsCtrl();

function* getListLogistic(action) {
    const { payload } = action;
    try {

        const { data } = yield call(api_logisticsCtrl.getListLogistic, payload);        
        yield put({
            type: actions.Type.GET_LIST_LOGISTIC_SUCCESS, payload: [
                ...data
            ]
        });
    } catch (error) {
        console.log(error);

    }
}

export default function* logisticWatch() {
    yield takeLatest(actions.Type.GET_LIST_LOGISTIC_REQUIRE, getListLogistic);
}