import React, { Component } from 'react'
import Gallery from '../components/gallery/gallery'
import '../styles/chat/gallery.scss'
import api from '../config/api'
import axios from 'axios'



class MediaGallery extends Component {
    constructor(props) {
        super(props)
        this.state = {
        }
    }

    render() {
        return (
            <div className="main-div">
                <Gallery/>
            </div>
        )
    }
}

export default MediaGallery