/* eslint-disable no-useless-constructor */
import React from 'react';
// import { Modal, ModalBody, ModalFooter, FormGroup, Input } from 'reactstrap';
import '../../styles/chat/modal.scss'
import api from '../../config/api'
import axios from 'axios'
import moment from 'moment'
import ListAddressUser from '../pages/CreateOrder/ListAddressUser';
import FromAddress from '../pages/CreateOrder/FromAddress';
import IconAddAddress from '../../assets/Icons/Add_1.png';
import IconNotification from '../../assets/Icons/IconAlert.svg';

import Modal from '../../components/Modal';
import { connect } from "react-redux";
import addressUsersCtrl from '../../controllers/addressUsersCtrl';
import * as actions_address from '../../redux/actions/address';
import googleMapCtrl from '../../controllers/googleMapCtrl';
import * as actions_notification from '../../redux/actions/notification';

const api_googleMapCtrl = new googleMapCtrl();
const api_addressUsersCtrl = new addressUsersCtrl();

class ModalListAddress extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            status: "list",
            title: "เลือกที่อยู่อื่น",
            textButton: "เพิ่มและใช้ที่อยู่นี้",
            select: {
                address: null,
                addressDefault: null
            },
            listAddressCustomer: [],
            value: {
                address: "",
                addressExtra: "",
                subdistrict: null,
                district: null,
                province: null,
                location: [],
                zipcode: ""
            },
        };
        this.baseState = { ...this.state }
    }


    async  componentWillReceiveProps(nextPorps) {
        if (nextPorps.isOpen && !this.props.isOpen) {
            const { data } = await api_addressUsersCtrl.getAddressUsers({
                userId: nextPorps.userId
            });

            const addressDefault = data.find(item => item.no === 1);
            if (addressDefault) {
                this.setState({
                    listAddressCustomer: data,
                    select: {
                        address: addressDefault,
                        addressDefault
                    }
                });
            }

            if (nextPorps.editAddress === 'edit') {
                this.setState({
                    status: 'edit',
                    // value: nextPorps.valueAddress,
                    value: {
                        ...nextPorps.valueAddress,
                        location: [nextPorps.valueAddress.location.lat, nextPorps.valueAddress.location.lng]
                    },
                })
            }
        }

        if (!nextPorps.isOpen && this.props.isOpen) {
            this.setState({
                ...this.baseState
            });
        }
    }

    addNewAddress = () => {
        this.setState({
            status: 'add',
            title: "เพิ่มที่อยู่ใหม่",
            textButton: "เพิ่มและใช้ที่อยู่นี้"
        });
    }


    handleClickSelectAddressUser = (selected) => {
        this.setState({
            select: {
                ...this.state.select,
                address: selected
            }
        })
    }


    handleClickSetDefaultAddress = (selected) => {
        this.setState({
            select: {
                ...this.state.select,
                addressDefault: selected
            }
        });
    }

    handleSelectProvince = async (selected) => {
        await this.props.getListDistricts({
            provinceId: selected.provinceId
        });
        this.setState({
            value: {
                ...this.state.value,
                province: selected,
                district: null,
                subdistrict: null
            },
        });
    }

    handleSelectDistricts = (selected) => {
        this.props.getListSubDistricts({
            districtId: selected.districtId
        });
        this.setState({
            value: {
                ...this.state.value,
                district: selected,
                subdistrict: null
            }
        });

    }
    handleSelectSubdistrict = (selected) => {
        this.setState({
            value: {
                ...this.state.value,
                subdistrict: selected,
                zipcode: selected.zipcode
            }
        });
    }


    handleChangeAddress_AddressExtra = (evt) => {
        this.setState({
            value: {
                ...this.state.value,
                [evt.target.name]: evt.target.value
            }
        });
    }

    handleClickSearchLatLongByAddress = async () => {
        try {
            const {
                address,
                subdistrict,
                district,
                province,
                zipcode
            } = this.state.value;

            let concatAddress = ''

            if (address !== "") {
                concatAddress += address
            }
            if (subdistrict !== null) {
                concatAddress += " " + subdistrict.subdistrictName
            }
            if (district !== null) {
                concatAddress += " " + district.districtName
            }
            if (province !== null) {
                concatAddress += " " + province.provinceName
            }
            if (zipcode !== "") {
                concatAddress += " " + zipcode
            }

            const { data } = await api_googleMapCtrl.getLatLngByAddress({ address: concatAddress });
            if (data.results.length > 0) {
                const location = data.results[0].geometry.location;
                this.setState({
                    value: {
                        ...this.state.value,
                        location: [location.lat, location.lng]
                    }
                });
            } else {
                this.openNotificationAlert('ค้นหาที่อยู่ไม่พบ')
            }
        } catch (error) {
            console.log(error);
        }
    }

    handleGetLatLongInMap = ({ lat, lng }) => {
        this.setState({
            value: {
                ...this.state.value,
                location: [lat, lng]
            }
        });
    }

    handleChangeZipcode = (evt) => {
        this.setState({
            value: {
                ...this.state.value,
                zipcode: evt.target.value
            }
        });
    }


    openNotificationAlert = (message) => {
        const data = {
            message: <div><img src={IconNotification} alt="IconNotification"></img><span className="ml-3">{message}</span></div>,
            level: 'error',
            autoDismiss: 0,
            position: "tr"
        }
        this.props.openNotificationAlert(data);
    }

    backToListAddress = () => {
        // this.setState({ ...this.baseState });
        this.setState({
            listAddressCustomer: [...this.state.listAddressCustomer],
            select: {
                ...this.state.select
            },
            status: "list",
            title: "เลือกที่อยู่อื่น",
            textButton: "เพิ่มและใช้ที่อยู่นี้",
            value: {
                ...this.baseState.value
            }
        });
    }


    handleClickCreateAddress = async () => {
        try {
            const isValiDateFrom = await this.validateFrom();
            if (isValiDateFrom) {
                const {
                    address,
                    addressExtra,
                    subdistrict,
                    district,
                    province,
                    location,
                    zipcode
                } = this.state.value;


                const dataCreateAddress = {
                    address: address,
                    addressExtra: addressExtra,
                    subdistrict: subdistrict.subdistrictName,
                    district: district.districtName,
                    province: province.provinceName,
                    location: [location[0].toString(), location[1].toString()],
                    userId: this.props.userId,
                    zipcode: zipcode.toString(),
                    name: this.props.displayName
                };


                const { data } = await api_addressUsersCtrl.createAddressUser(dataCreateAddress);

                if (data) {
                    const res_listAddressUser = await api_addressUsersCtrl.getAddressUsers({
                        userId: this.props.userId
                    });

                    this.setState({
                        listAddressCustomer: res_listAddressUser.data,
                        status: "list",
                        title: "เลือกที่อยู่อื่น",
                        textButton: "เพิ่มและใช้ที่อยู่นี้",
                    });
                }

            }
        } catch (error) {
            console.log(error);
        }

    }
    validateFrom = async () => {
        let isSusscess = true;
        const reg = new RegExp('^[0-9]+$');
        const {
            address,
            subdistrict,
            district,
            province,
            zipcode,
            location
        } = this.state.value;
        if (address === "") {
            await this.openNotificationAlert('กรุณากรอกบ้านเลขที่,ซอย,ถนน');
            isSusscess = false;
        }
        if (subdistrict == null) {
            await this.openNotificationAlert('กรุณาเลือกแขวง');
            isSusscess = false;
        }
        if (district == null) {
            await this.openNotificationAlert('กรุณาเลือกเขต');
            isSusscess = false;
        }
        if (province == null) {
            await this.openNotificationAlert('กรุณาเลือกจังหวัด');
            isSusscess = false;
        }
        if (zipcode.toString().trim().length !== 5 || !reg.test(zipcode)) {
            await this.openNotificationAlert('กรุณากรอกรหัสไปรษณีย์ใหถูกต้อง');
            isSusscess = false;
        }
        if (location[0] == null || location[1] == null) {
            await this.openNotificationAlert('กรุณาเลือก Lat Long');
            isSusscess = false;
        }
        return isSusscess;
    }


    handleClickDeleteAddress = async (selected) => {
        const deleteAddres = await api_addressUsersCtrl.deleteAddressUser({
            addressId: selected.id
        });

        if (deleteAddres && deleteAddres.data.count) {
            const res_listAddressUser = await api_addressUsersCtrl.getAddressUsers({
                userId: this.props.userId
            });

            this.setState({
                listAddressCustomer: res_listAddressUser.data
            });
        }
    }
    handleClickConfirm = async () => {
        this.props.refresh()
        const res_update = await api_addressUsersCtrl.updateAddressUser({
            no: 1,
            addressId: this.state.select.address.id
        });


        if (res_update && res_update.data && res_update.status === 200) {
            this.props.closeModal();
            this.setState({
                ...this.baseState
            });
        }
    }


    render() {
        const { closeModal, isOpen } = this.props;
        return (
            <Modal
                isOpen={isOpen}
                closeModal={closeModal}
                size="lg"
                title={this.state.title}
            >
                <div className="container">
                    {this.state.status === "list" ?
                        <>
                            <div className="row">
                                <div className="d-flex align-istems-center cursor-pointer" onClick={this.addNewAddress}>
                                    <img src={IconAddAddress} alt="IconAddPromoCode" className="size-icon-20px" />
                                    <span className="font-primary-16px-text ml-2">
                                        เพิ่มที่อยู่ใหม่
                                </span>
                                </div>
                            </div>
                            <ListAddressUser
                                list={this.state.listAddressCustomer}
                                addressId={this.state.select && this.state.select.address && this.state.select.address.id}
                                addressDefaultId={this.state.select && this.state.select.addressDefault && this.state.select.addressDefault.id}
                                clickSelect={this.handleClickSelectAddressUser}
                                clickSetDefault={this.handleClickSetDefaultAddress}
                                clickDelete={(selected) => this.handleClickDeleteAddress(selected)}
                                showAddressDefault={false}
                            />
                            <div className="row mt-10per">
                                <button
                                    type="button"
                                    className={`btn w-100  ${this.state.listAddressCustomer.length === 0 ? "btn-darkgrey" : "btn-primary"}`}
                                    onClick={this.state.listAddressCustomer.length === 0 ? null : () => this.handleClickConfirm()}

                                >ยืนยัน</button>
                            </div>
                        </>

                        : <>
                            <FromAddress
                                handleSelectProvince={this.handleSelectProvince}
                                handleSelectDistricts={this.handleSelectDistricts}
                                handleSelectSubdistrict={this.handleSelectSubdistrict}
                                handleChangeAddress_AddressExtra={(evt) => this.handleChangeAddress_AddressExtra(evt)}
                                handleClickSearchLatLongByAddress={() => this.handleClickSearchLatLongByAddress()}
                                getAddress={(location) => this.handleGetLatLongInMap(location)}
                                value={
                                    {
                                        address: this.state.value.address,
                                        addressExtra: this.state.valueaddressExtra,
                                        location: {
                                            lat: this.state.value.location[0],
                                            lng: this.state.value.location[1]
                                        },
                                        province: this.state.value.province,
                                        district: this.state.value.district,
                                        subdistrict: this.state.value.subdistrict,
                                        zipcode: this.state.value.zipcode
                                    }

                                }
                                handleChangeZipcode={(evt) => this.handleChangeZipcode(evt)}
                                isGlobalAddress={true}
                                listDistrictsGlobal={this.props.listDistricts}
                                listSubDistrictsGlobal={this.props.listSubDistricts}
                                sendLatLng="ModalAddress"
                                userData={{
                                    id: this.props.userLineId,
                                    platform: this.props.platform
                                }}
                            />
                            <div className="row">
                                <span className="font-primary-16px-text cursor-pointer" onClick={this.backToListAddress}>
                                    กลับไปเลือกที่อยู่อื่น
                              </span>
                            </div>
                            <div className="row mt-10per">
                                <button
                                    type="button"
                                    className="btn w-100 btn-primary"
                                    onClick={this.handleClickCreateAddress}
                                >{this.state.textButton}</button>
                            </div>
                        </>
                    }
                </div>
            </Modal>
        );
    }
}

const mapStateToProps = state => ({

});

const mapDisPatchToProps = dispatch => ({
    getListDistricts: ({ provinceId }) => dispatch(actions_address.getListDistricts({ provinceId })),
    getListSubDistricts: ({ districtId }) => dispatch(actions_address.getListSubDistricts({ districtId })),
    openNotificationAlert: (payload) => dispatch(actions_notification.openNotificationAlert(payload)),
});


export default connect(mapStateToProps, mapDisPatchToProps)(ModalListAddress)

