import { Type } from "../actions/modal";

const INITIAL_STATE = {
    modalAlert: {
        isOpen: false,
        title: '',
        content: '',
        textbutton: '',
        status: "success"
    }
};

export default function modal(state = INITIAL_STATE, actions) {
    const { type, payload } = actions;
    switch (type) {
        case (Type.OPEN_MODAL_ALERT): {
            return {
                ...state,
                modalAlert: {
                    ...state.modalAlert,
                    ...payload
                }
            }
        }
        case (Type.CLOSE_MODAL_ALERT): {
            return {
                ...state,
                modalAlert: {
                    ...INITIAL_STATE.modalAlert
                }
            }
        }
        default:
            return {
                ...state
            }
    }
}