import React from 'react';


class ListOrderAll extends React.Component{
    render(){
        return (
            <h1>
                ListOrderAll
            </h1>
        )
    }
};

export default ListOrderAll;