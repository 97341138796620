// import Footer from './Footer';
import Header from './Header';
import Sidebar from './Sidebar';

import React from 'react';
class MainLayout extends React.Component {
    render() {
        const { children } = this.props;
        return (
            <main className="container-main">
                <div id="header">
                    <Header sideBar={children.props.match.path}/>
                </div>
                <div id="sidebar-left">
                    <Sidebar
                        sideBar={children.props.match.path}
                    />
                </div>
                <div id={children.props.match.path == "/all-menu" ? "main" : "main-extra"}>
                    {children}
                </div>
                {/* <div id="footer">
                <Footer />
                </div> */}
            </main>
        );
    }
}

export default MainLayout;
