import { Type } from "../actions/logistics";

const INITIAL_STATE = {
    listLogistic: []
};

export default function logistic(state = INITIAL_STATE, actions) {
    const { type, payload } = actions;
    switch (type) {
        case (Type.GET_LIST_LOGISTIC_SUCCESS): {
            return {
                ...state,
                listLogistic: payload
            }
        }

        case (Type.GET_LIST_LOGISTIC_CUSTOM) : {
            return {
                ...state,
                listLogistic: payload.listLogistic
            }
        }

        default:
            return {
                ...state
            }
    }
}