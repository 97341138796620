import React from 'react';
import ListOrderHistory from './ListOrderHistory';
import invoicesCtrl from '../../../controllers/invoicesCtrl';
const api_invoicesCtrl = new invoicesCtrl();

class WaitPayment extends React.Component {


    constructor(props) {
        super(props);

        this.state = {
            listInvocie: []
        }
    }
    componentDidMount() {
        this.getListInvoice();

    }
    componentWillReceiveProps(nextProps) {
        if (this.props.activeTab !== nextProps.activeTab) {
            if (nextProps.activeTab === "wait_payment") {
                this.getListInvoice();
            }
        }
    }


    getListInvoice = async () => {
        try {
            const listInvocie = await api_invoicesCtrl.listInvoiceByPaymentStatusWait({ userId: this.props.match.params.id });
            this.setState({
                listInvocie
            })
        } catch (error) {
            console.log(error);
        }
    }
    render() {
        return (
            <div className="bg-white p-3">
                <div className="row">
                    <div className="col-md-8">
                        <label className="font-primary-32px-text">
                            รอชำระเงิน
                        </label>
                    </div>
                </div>
                <hr />
                <ListOrderHistory {...this.props} listInvocie={this.state.listInvocie} />
            </div>
        )
    }
};

export default WaitPayment;