import { Type } from "../actions/loading";

const INITIAL_STATE = {
    isOpen: false
};

export default function loadding(state = INITIAL_STATE, actions) {
    const { type } = actions;
    switch (type) {
        case (Type.OPEN_LOADING): {
            return {
                ...state,
                isOpen: true
            }
        }

        case (Type.CLOSE_LOADING): {
            return {
                ...state,
                isOpen: false
            }
        }
        default:
            return state
    }
}