
import Cookies from 'js-cookie';
import moment from 'moment';
import numeral from 'numeral';

const getAccessToken = () => Cookies.get('access_token');
const getUserId = () => Cookies.get('user_id');
const getUserType = () => Cookies.get('userType');

const isLogin = () => {
  const access_token = getAccessToken();
  const user_id = getUserId();
  if (access_token == null || user_id == null) {
    return false;
  }
  return true;
}

const isEmptyOrSpaces = (input) => {
  return input === null || input === undefined || input.match(/^ *$/) !== null;
}

const convertTimeStampToFormant = (time) => {
  const day = changeFormatDateNotDay(time)
  return day + ", " + moment(time).format('HH:mm')
}


///format 'ส 18 ต.ค. 62'
const changeFormatDate = (date) => {
  const daysOfWeek = ["อา.", "จ.", "อ.", "พ.", "พฤ.", "ศ.", "ส."]
  const monthsOfYear = ["ม.ค.", "ก.พ.", "มี.ค.", "เม.ย.", "พ.ค.", "มิ.ย.", "ก.ค.", "ส.ค.", "ก.ย.", "ต.ค.", "พ.ย.", "ธ.ค."]
  const monthDelivery = monthsOfYear[moment(date).month()]
  const year = parseInt(moment(date).format('YYYY')) + 543
  const weekDay = daysOfWeek[moment(date).day()]
  const dateDelivery = moment(date).format('DD')
  return weekDay + " " + dateDelivery + " " + monthDelivery + " " + year.toString().slice(-2)
}

const changeFormatDateNotDay = (date) => {
  const monthsOfYear = ["ม.ค.", "ก.พ.", "มี.ค.", "เม.ย.", "พ.ค.", "มิ.ย.", "ก.ค.", "ส.ค.", "ก.ย.", "ต.ค.", "พ.ย.", "ธ.ค."]
  const monthDelivery = monthsOfYear[moment(date).month()]
  const year = parseInt(moment(date).format('YYYY')) + 543
  const dateDelivery = moment(date).format('DD')
  return dateDelivery + " " + monthDelivery + " " + year.toString().slice(-2)
}

///format 'ส 18 ต.ค. 62'
const changeFormatDateFull = (date) => {
  const daysOfWeek = ["อาทิตย์", "จันทร์", "อังคาร", "พุธ", "พฤหัส", "ศุกร์", "เสาร์"]
  // const daysOfWeek = ["อา", "จ", "อ", "พ", "พฤ", "ศ", "ส"]
  const monthsOfYear = ["ม.ค.", "ก.พ.", "มี.ค.", "เม.ย.", "พ.ค.", "มิ.ย.", "ก.ค.", "ส.ค.", "ก.ย.", "ต.ค.", "พ.ย.", "ธ.ค."]
  const monthDelivery = monthsOfYear[moment(date).month()]
  const year = parseInt(moment(date).format('YYYY')) + 543
  const weekDay = daysOfWeek[moment(date).day()]
  const dateDelivery = moment(date).format('DD')
  return weekDay + " " + dateDelivery + " " + monthDelivery + " " + year.toString().slice(-2)
}

const changeFormatTime = (date) => moment(date).format('HH:mm');


const showMessageError = (messageError) => {
  switch (messageError) {
    case "NOT_ENOUGH_STOCK":
      return "ไม่สามรถเพิ่มจำนวนออเดอร์ได้ เนื่องจากจำนวนไม่พอ"
    case "FIND_NOTFOUND":
      return "เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง"
    case "CREATE_NOTI_ERROR":
      return "เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง"
    case "MENU_INACTIVE":
      return "เมนูนี้ไม่พร้อมให้บริการ"
    case "SHOP_CLOSE":
      return "ไม่สามารถทำรายการได้ เนื่องจากร้านปิด"
    case "COMINGSOON":
      return "ไม่สามารถทำรายการได้ เนื่องจากเป็นเมนูเร็วๆนี้"
    case "PRODUCT_TYPE_INACTIVE":
      return "PRODUCT_TYPE_INACTIVE"
    case "MOBILE_NOT_VERIFY":
      return "MOBILE_NOT_VERIFY"
    case "DALIVERYDATE_NOT_BUY":
      return "กรุณาเลือกวันจัดส่งวันอื่น"
    case "PAYMENT_EXPIRE":
      return "PAYMENT_EXPIRE"
    case "OUT_OF_SERVICE_AREA":
      return "ไม่อยู่ในพื้นที่การจัดส่ง"
    case "SOLDOUT":
      return "สินค้าหมด"
    case "DELIVERY_ERROR":
      return "ไม่อยู่ในพื้นที่การจัดส่ง"
    default:
      return ''
    // code block
  }
};

const formatPhoneNumber = (str) => {
  const length = str.length;
  const cleaned = ('' + str).replace(/\D/g, '');

  if (length === 10) {
    const match = cleaned.match(/^(\d{3})(\d{7})$/);
    return match && match[1] + '-' + match[2];
  } else if (length === 9) {
    const match = cleaned.match(/^(\d{2})(\d{7})$/);
    return match && match[1] + '-' + match[2];
  } else {
    return str;
  }
}


const concatenateDateTime = (date, time) => {
  return moment(date + " " + time, 'DD/MM/YYYY HH:mm')
}

const setDateTimeFormatServer = (date, time, formatDate, formatTime) => {
  const dataTime = concatenateDateTime(date, time);
  return moment(dataTime).format(formatDate) + "T" + moment(dataTime).format(formatTime) + ":00.000Z"
}
const numberFormatDecimalTwoPoint = (number) => numeral(number).format('0,0.00');
const numberFormatNoDecimal = (number) => numeral(number).format('0,0');

const checkFormatDate = (date) => {
  const date_regex = /^(0[1-9]|1\d|2\d|3[01])\/(0[1-9]|1[0-2])\/(25|26)\d{2}$/;
  return date_regex.test(date)
}

const checkFormatTime = (time) => {
  const date_regex = /^([0-1]?[0-9]|2[0-4]):([0-5][0-9])(:[0-5][0-9])?$/;
  return date_regex.test(time)
}


const calculatePagination = (current, pageSize) => {
  const start = parseInt((current - 1) * pageSize);
  const end = parseInt(current * pageSize);
  return {
    start,
    end
  }
}

const validateDateRegular = /^\d{2}\/\d{2}\/\d{4}$/;

const checkTimeBetween = (beforeTime, afterTime) => {
  var format = 'HH:mm:ss'
  var time = moment()
  time = moment(time,format)
  beforeTime = moment(beforeTime,format)
  afterTime = moment(afterTime,format)
  if (time.isBetween(beforeTime, afterTime, null, [])) {
    return true
  } else {
    return false
  }
}

export {
  getAccessToken,
  isLogin,
  isEmptyOrSpaces,
  convertTimeStampToFormant,
  getUserId,
  changeFormatDate,
  showMessageError,
  changeFormatDateFull,
  changeFormatTime,
  numberFormatDecimalTwoPoint,
  formatPhoneNumber,
  getUserType,
  changeFormatDateNotDay,
  concatenateDateTime,
  setDateTimeFormatServer,
  checkFormatDate,
  checkFormatTime,
  calculatePagination,
  numberFormatNoDecimal,
  validateDateRegular,
  checkTimeBetween
}