/* eslint-disable no-useless-constructor */
import React from 'react';
import { Modal, ModalBody, ModalFooter, FormGroup, Input, ModalHeader } from 'reactstrap';
import SelectOption from '../Select';
import '../../styles/chat/modal.scss'
// import '../../styles/chat/gallery.scss'
import api from '../../config/api'
import axios from 'axios'
import { isEmptyOrSpaces } from '../../utils/utils'
import Cookies from 'js-cookie'
import { sendDataToLine } from '../../controllers/chatCtrl'
import ModalEditname from './ModalEditname'

import {
    getAlbums,
    getMedia,
    saveLogMediaRecent,
    getRecentMedia,
    searchAlbumByName,
    searchMediaByName,
} from '../../controllers/mediaGalleryCtrl'

const pathImg = 'https://thumb.izcene.com/saranros/image/';


class ModalSelectMedia extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            modal: false,
            listAlbum: [],
            listMedia: [],
            albumDetail: undefined,
            listMediaSelect: [],
            listRecent: [],
            searchName: "",
            checkAll: false,
            showModalEditname: false,
        };
    }

    async componentDidMount() {
        await this.listAlbums()
        await this.listRecentAlbum()
    }

    async componentWillReceiveProps(nextProps) {
        this.setState({
            modal: nextProps.showModalSelect
        })
        await this.listAlbums()
        await this.listRecentAlbum()
    }

    listAlbums = async (sort="used") => {
        const albumsList = await getAlbums(sort)
        this.setState({
            listAlbum: [{ _id: "1111", name: "ภาพทั้งหมด", lastMedia: "8b36ed11-0aec-493d-b85f-6e49deaa689e.png" }, ...albumsList]
        })
    }

    keyPressed = (e) => {
        if (e.key === "Enter" && e.shiftKey === false) {
            e.preventDefault()
            this.searchAlbumOrMediaByName()
        }
    }

    listRecentAlbum = async () => {
        const albumsListRecent = await getRecentMedia(Cookies.get('user_id'))
        this.setState({
            listRecent: albumsListRecent
        })
    }

    gotoAlbum = async (id, name) => {
        this.setState({
            album: false,
            albumDetail: { _id: id, name: name }
        })

        if (id === "1111") return this.listMediaAll()

        this.listMediaById(id)

    }

    listMediaAll = async () => {
        const mediaData = await getMedia()
        this.setState({
            listMedia: mediaData
        })
    }

    listMediaById = async (id, sort="used") => {
        const mediaData = await getMedia(`albumId=${id}`, sort)
        this.setState({
            listMedia: mediaData
        })
    }

    backtoMain = async () => {
        this.setState({
            listMedia: [],
            albumDetail: undefined,
        })
    }

    onClickSelectImage = async (media) => {
        const arrMediaSelect = [...this.state.listMediaSelect]

        const arrayFind = arrMediaSelect.findIndex((mediaSelect) => mediaSelect._id == media._id)

        if (arrayFind == -1) {
            arrMediaSelect.push(media)
        } else {
            arrMediaSelect.splice(arrayFind, 1)
        }

        this.setState({
            listMediaSelect: arrMediaSelect
        })
    }

    onClickSend = async () => {
        await Promise.all(
            await this.state.listMediaSelect.map(async urlImg => {
                const data = {
                    urlImage: urlImg.path,
                    id: this.props.id,
                    type: "image",
                    platform: this.props.platform,
                    sender: Cookies.get('userName')
                }

                await sendDataToLine(data)
                saveLogMediaRecent({
                    mediaId: urlImg._id,
                    userId: Cookies.get('user_id'),
                })
            })
        )

        this.setState({
            albumDetail: undefined,
            listMediaSelect: [],
        })

        this.props.closeModalSelect()
    }

    closeModal = () => {
        this.props.closeModalSelect()
        this.setState({
            listAlbum: [],
            listMedia: [],
            albumDetail: undefined,
            listMediaSelect: [],
        })
    }

    searchAlbumOrMediaByName = async () => {
        if (this.state.albumDetail) {
            if (this.state.searchName) {
                const mediaData = await searchMediaByName(this.state.searchName)
                this.setState({
                    listMedia: mediaData
                })
            } else {
                await this.listMediaById(this.state.albumDetail._id)
            }
        } else {
            if (this.state.searchName) {
                const albumData = await searchAlbumByName(this.state.searchName)
                this.setState({
                    listAlbum: albumData
                })
            } else {
                await this.listAlbums()
            }
        }

        this.setState({
            searchName: "",
        })
    }

    onClickCheckAll = () => {
        if (this.state.checkAll) {
            this.setState({
                checkAll: false,
                listMediaSelect: []
            })
        } else {
            this.setState({
                checkAll: true,
                listMediaSelect: [...this.state.listMedia]
            })
        }

    }

    selectSorting = async(e) => {
        if(this.state.albumDetail){
            this.listMediaById(this.state.albumDetail._id, e.target.value)
        }else{
            this.listAlbums(e.target.value)
        }
    }


    render() {
        return (
            <Modal
                isOpen={this.state.modal}
                // toggle={this.closeModal}
                className="modal-select" >
                <ModalBody>
                    <div className="col-12 btn" >
                        <i className="btn-cloes icon-close cur-point" onClick={this.closeModal} />
                    </div>
                    <div className="px-5">
                        <div className="title-albumDetail">
                            {this.state.albumDetail ? this.state.albumDetail.name : "แกลลอรี่"}
                        </div>

                        {
                            this.state.albumDetail ?
                                <div className="back-btn cursor-pointer" onClick={this.backtoMain}>
                                    {"< กลับไปที่หน้าอัลบั้ม"}
                                </div>
                                :
                                null
                        }

                        <div className="row filter-tab p-0">
                            <div className="col-lg-4 col-sm-12 p-0">
                                <div className="text-left input-group">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text" id="basic-addon1">
                                            <i className="fas fa-search"></i></span>
                                    </div>
                                    <input type="text" className="form-control" placeholder="ค้นหา ชื่อ" aria-describedby="basic-addon1" value={this.state.searchName} onChange={(e) => this.setState({ searchName: e.target.value })} onKeyPress={this.keyPressed} />
                                </div>
                            </div>

                            <div className="col-lg-8 col-sm-12 p-0">
                                <form className="form-sort">
                                    <div className="form-group row">
                                        <label htmlFor="inputPassword" className="col-sm-4 col-form-label pl-0">เรียงจาก</label>
                                        <div className="col-lg-8 col-sm-9 input-group pb-3 px-0">
                                            <select className="select-form" onChange={this.selectSorting}>
                                                <option select="true" value="used">ใช้งานบ่อย</option>
                                                <option value="name">ตามตัวอักษร</option>
                                            </select>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>

                        {
                            this.state.albumDetail ?
                                <div>
                                    <div className="checkbox-media-all">
                                        <input id="checkbox" type="checkbox" checked={this.state.listMediaSelect.length == this.state.listMedia.length ? true : false} onChange={this.onClickCheckAll} />
                                        <label>&nbsp;&nbsp;เลือกทั้งหมด</label>
                                    </div>

                                    <div className="div-recent">
                                        {
                                            this.state.listMedia.map(media =>
                                                <div key={media._id} className={this.state.albumDetail._id != "1111" ? "box-recent p-0" : "box-recent"}>
                                                    <img className="img-album" src={pathImg + media.path} onClick={() => this.onClickSelectImage(media)} />
                                                    <div className="select-media">
                                                        {this.state.listMediaSelect.findIndex((mediaSelect) => mediaSelect._id == media._id) == -1 ? null : <div className="index-select">{this.state.listMediaSelect.findIndex((mediaSelect) => mediaSelect._id == media._id) + 1}</div>}
                                                    </div>
                                                    <div className="checkbox-media">
                                                        <input id="checkbox" type="checkbox" checked={this.state.listMediaSelect.findIndex((mediaSelect) => mediaSelect._id == media._id) == -1 ? false : true} onChange={() => this.onClickSelectImage(media)} />
                                                    </div>

                                                    <div className="row">
                                                        <div className="col-9 name-media">{media.name}</div>
                                                        {/* {
                                                            this.state.albumDetail._id != "1111" ?
                                                                <i className="col-3 fas fa-pen" onClick={() => this.setState({ showModalEditname: true })}></i>
                                                                :
                                                                null
                                                        } */}
                                                    </div>

                                                    {
                                                        this.state.albumDetail._id == "1111" ?
                                                            <div className="name-media">อัลบัม {media.albumId.name}</div>
                                                            :
                                                            null
                                                    }
                                                </div>
                                            )
                                        }
                                    </div>
                                </div>
                                :
                                <div>
                                    <div>
                                        <label className="pt-2">รูปที่ใช้งานล่าสุด</label>
                                        <div className="div-recent">
                                            {
                                                this.state.listRecent.map(recent =>
                                                    <div key={recent._id} className="box-recent bt-0">
                                                        <img className="img-album" src={pathImg + recent.mediaId.path} onClick={() => this.onClickSelectImage(recent.mediaId)} />
                                                        <div className="checkbox-media">
                                                            <input id="checkbox" type="checkbox" checked={this.state.listMediaSelect.findIndex((mediaSelect) => mediaSelect._id == recent.mediaId._id) == -1 ? false : true} onChange={() => this.onClickSelectImage(recent.mediaId)} />
                                                        </div>
                                                        <div className="name-media">{recent.mediaId.name}</div>
                                                        <div className="name-media">อัลบัม {recent.mediaId.albumId.name}</div>
                                                    </div>
                                                )
                                            }
                                        </div>
                                    </div>

                                    <div>
                                        <label className="pt-3">อัลบัม</label>
                                        <div className="album-all">
                                            <div className="div-album">
                                                {
                                                    this.state.listAlbum.map(album =>
                                                        <div className="box-album-relative" key={album._id}>
                                                            <div className="box-album" onClick={() => this.gotoAlbum(album._id, album.name)}>
                                                                <img className="img-album" src={pathImg + album.lastMedia} />
                                                                <div className="row">
                                                                    <div className="col-9 name-album">{album.name}</div>
                                                                    {/* <i className="col-3 fas fa-trash-alt"></i> */}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                        }
                        <button className="btn btn-send btn-comfirm" type="button" id="button-addon2" onClick={this.onClickSend} disabled={this.state.listMediaSelect.length > 0 ? false : true}><i className="icon-sent" />ส่ง</button>
                    </div>
                    <ModalEditname
                        showModalEditname={this.state.showModalEditname}
                        closeModalEditname={() => this.setState({ showModalEditname: false })}
                    />
                </ModalBody>
            </Modal>
        );
    }

}



export default ModalSelectMedia

