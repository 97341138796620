import React from 'react';
import IconNotification from '../../assets/Icons/IconAlert.svg';
export const Type = {
    OPEN_NOTIFICATION_ALERT: "OPEN_NOTIFICATION_ALERT"
}

export const openNotificationAlert = (payload) => ({
    type: Type.OPEN_NOTIFICATION_ALERT,
    payload
});


export const openNotificationAlertComponent = (payload) => ({
    type: Type.OPEN_NOTIFICATION_ALERT,
    payload: {
        ...payload,
        message: <div><img src={IconNotification} alt="IconNotification"></img><span className="ml-3">{payload.message}</span></div>,
    }
});