import React from 'react';
import CheckBoxActive from '../../../assets/Icons/checkBoxActive.png';
import CheckBoxInActive from '../../../assets/Icons/checkBoxInActive.png';
import { connect } from 'react-redux'
import * as actions_createOrder from '../../../redux/actions/createOrder';
import * as actions_menus from '../../../redux/actions/menus';
import * as actions_extraData from '../../../redux/actions/extraData'
import api from '../../../config/api';
import moment from 'moment';
import * as utils from '../../../utils/utils';
import { UncontrolledTooltip } from 'reactstrap';
import ModalExtraData from '../CreateOrder/ModalExtraData';


class CardMenu extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            checkbox: false,
            modalExtraData: {
                isOpen: false,
                data: null
            }
        }
    }


    /// insert/remove menu to cart
    onChangeCheckbox = async (detailMenu, idx, mainData, userId) => {
        const { dataProduct, name } = detailMenu

        const data = {
            productId: dataProduct[0]._id,
            menuName: name.th || name.en,
            shopName: mainData.dataShop[0].name
        }
        this.props.addProductPDFRequire(data)
    }


    ///count menu in cart
    countMenuInCart = () => {
        const orderShop = this.props.orderShop[this.props.activeTab].listMenuSelect
        return Object.keys(orderShop).length
    }

    closeModalExtraData = () => {
        this.setState({
            modalExtraData: {
                isOpen: false,
                data: null
            }
        })
    }


    // checkStringLengthShopName = (name) => {
    //     if (name.length > 23) {
    //         return name = name.substring(0, 23) + "...";
    //     } else {
    //         return name
    //     }
    // }

    render() {
        const { props } = this;
        const { orderShop, detailMenu, createOrder } = props;
        const dataInTab = orderShop[props.activeTab];
        const { listMenuSelect } = dataInTab;
        const product = detailMenu.dataProduct[0];

        return (
            <div className="col-md-3 col-xl-2 pt-15 cursor-pointer">
                <div className="card card-menu">
                    {
                        dataInTab.mainData && dataInTab.mainData.shopId !== detailMenu.shopId && Object.keys(listMenuSelect).length !== 0 ? <div className="disable-card-product" />
                            : null
                    }
                    <div onClick={dataInTab.mainData && dataInTab.mainData.shopId !== detailMenu.shopId && Object.keys(listMenuSelect).length !== 0 ? null : () => this.onChangeCheckbox(props.detailMenu, props.activeTab, props.mainData, createOrder.userCustomer.id)}>
                        <img src={
                            `${api.THUMP}/800x600/image/${props.detailMenu.dataProduct[0].cover.fileName}`
                        } className="card-img-top" alt="..." />
                        <div className="card-body">
                            <div className="wrap-content">
                                <span className="font-size-16px text-break text-overflow cursor-pointer" id={`detailMenu` + detailMenu._id}>{props.detailMenu.name && props.detailMenu.name.th}
                                    {props.detailMenu.name && props.detailMenu.name.th.length > 12 ? <UncontrolledTooltip placement="auto" target={`detailMenu` + detailMenu._id}>
                                        {props.detailMenu.name && props.detailMenu.name.th}
                                    </UncontrolledTooltip> : ""}
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="detail-menu" onClick={() => this.setState({ modalExtraData: { isOpen: true, data: { detailMenu: props.detailMenu, mainData: props.mainData } } })}>
                        ดูรายละเอียดเพิ่มเติม
                        </div>
                    <div className="select-menu d-flex justify-content-between m-2 align-items-center">
                        {/* <p className="card-text">{utils.numberFormatDecimalTwoPoint(props.detailMenu.dataProduct[0].priceNet)}.-</p> */}
                    <span className="text-update">อัพเดทข้อมูล {moment(detailMenu.updateAt).format('DD/MM/YYYY')}</span>
                        <img className="size-icon-20px" src={this.props.listProductPDF.some(item => item.productId == product._id) ? CheckBoxActive : CheckBoxInActive} alt="checkbox" />
                    </div>
                </div>

                {this.state.modalExtraData.isOpen ?
                    <ModalExtraData
                        modal={this.state.modalExtraData}
                        isClose={this.closeModalExtraData}
                        url={props.url}
                    /> : ""
                }


            </div>
        )
    }
}

const mapDispatchToProps = dispatch => ({
    addMenuToCart: ({ detailMenu, idx, mainData, productId, datePreorder, userId, }) => dispatch(actions_createOrder.addMenuToCart({ detailMenu, idx, mainData, productId, datePreorder, userId, })),
    removeMenuFromCart: ({ detailMenu, mainData }) => dispatch(actions_createOrder.removeMenuFromCart({ detailMenu, mainData })),
    checkListDateTime: ({ productId, datePreorder, userId, idx }) => dispatch(actions_createOrder.checkListDateTime({ productId, datePreorder, userId, idx })),
    getListMenu: ({ shop, menu, date, time }) => dispatch(actions_menus.getListMenu({ shop, menu, date, time })),
    setSearch: ({ data, idx }) => dispatch(actions_createOrder.setSearch({ data, idx })),
    addProductPDFRequire: (payload) => dispatch(actions_extraData.addProductPDFRequire(payload))
})
const mapStateToProps = state => ({
    activeTab: state.createOrder.activeTab,
    orderShop: state.createOrder.orderShop,
    createOrder: state.createOrder,
    listProductPDF: state.extraData.listProductPDF
})
export default connect(mapStateToProps, mapDispatchToProps)(CardMenu)