/* eslint-disable no-useless-constructor */
import React from 'react';
import { Modal, ModalBody, ModalFooter, FormGroup, Input } from 'reactstrap';
import '../../styles/chat/modal.scss'


class ModalPreview extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: this.props.showModalPreview,
    };
  }

  toggleModalPreview = () => {
    this.props.closeModalPreview()
  }



  render() {
    return (
      <Modal
        isOpen={this.props.showModalPreview}
        toggle={this.toggleModalPreview}
        className="modal-medium" >
        <ModalBody className="preview">
          <div className="box-img">
            <button className="btn-cloes btn" onClick={this.toggleModalPreview}>
              <i className="icon-close" />
            </button>
            <img src={this.props.imagePreview} />
          </div>
        </ModalBody>
      </Modal>
    );
  }
}



export default ModalPreview

