import React from 'react';
import TabHisTory from '../components/pages/OrderHistory/TabHistory';
import usersCtrl from '../controllers/usersCtrl'
import { Link } from 'react-router-dom';
const api_usersCtrl = new usersCtrl();


class OrderHistory extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            user : ""
        }
    }

    async componentDidMount(){
        const userDetail = await api_usersCtrl.getUserByuserID(this.props.match.params.id)
        this.setState({
            user : userDetail.data
        })
    }

    render() {
        return (
            <div>
                <div className="bg-white mt-3 p-4">
                    <div className="row">
                        <span className="ml-5 font-color-black-size-16px">
                          <Link to = "/"> <span>แชท </span></Link> 
                            <span>{"< ประวัติสั่งซื้อ"}</span>
                        </span>
                    </div>
                    <div className="row justify-content-center m-2">
                        <label className="font-primary-32px-text">
                            ประวัติการสั่งซื้อของ
                        </label>
                        <label className="font-primary-blob-32px-text ml-3">
                            {this.state.user.displayName}
                        </label>
                    </div>
                </div>

                <div className="m-3 bg-white">
                    <TabHisTory {...this.props} />
                </div>
            </div>
        )
    }
};

export default OrderHistory;