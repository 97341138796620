import React from 'react';
import {
    put,
    takeLatest,
    call,
    select,
    take
} from "redux-saga/effects";
import * as actions_createOrder from "../actions/createOrder";
import * as actions_menus from "../actions/menus";
import * as actions_notification from "../actions/notification";
import * as actions_modal from "../actions/modal";
import * as actions_loading from "../actions/loading";
import * as actions_address from "../actions/address";
import * as actions_logistics from "../actions/logistics";
import googleMapCtrl from '../../controllers/googleMapCtrl';
import menuCtrl from '../../controllers/menuCtrl';
import promotionCtrl from '../../controllers/promotionCtrl';
import productsCtrl from '../../controllers/productsCtrl';
import invoicesCtrl from '../../controllers/invoicesCtrl';
import usersCtrl from '../../controllers/usersCtrl';
import ordersCtrl from '../../controllers/ordersCtrl';
import addressUsersCtrl from '../../controllers/addressUsersCtrl';
import addressCtrl from '../../controllers/addressCtrl';
import * as chatCtrl from '../../controllers/chatCtrl';
import * as ustils from '../../utils/utils';
import history from '../../history';
import { getCreateOrder } from './selectors';
import IconNotification from '../../assets/Icons/IconAlert.svg';
import moment from 'moment';
import Cookies from 'js-cookie'
const api_googleMapCtrl = new googleMapCtrl();
const api_menuCtrl = new menuCtrl();
const api_promotionCtrl = new promotionCtrl();
const api_invoicesCtrl = new invoicesCtrl();
const api_productsCtrl = new productsCtrl();
const api_usersCtrl = new usersCtrl();
const api_ordersCtrl = new ordersCtrl();
const api_addressUsersCtrl = new addressUsersCtrl();
const api_addressCtrl = new addressCtrl();


function* addTab(action) {
    // const { payload } = action;
    try {
        yield put({
            type: actions_createOrder.Type.ADD_TAB_SUCCESS
        });

        // const stateCreateOrder = yield select(getCreateOrder);
        // const activeTab = stateCreateOrder.activeTab
        // const this_order = stateCreateOrder.orderShop[activeTab]
        // const deliveryAt = this_order.checkShippingCosts.deliveryAt[0];
        // const shopId = (this_order.mainData && this_order.mainData.shopId) || null;
        // const contactId = this_order.contactShop._id;
        // const listProductId = Object.keys(stateCreateOrder.orderShop[0].listMenuSelect)

        // if (contactId && shopId && deliveryAt.address !== "" && deliveryAt.subdistrict !== null && deliveryAt.district !== null && deliveryAt.province !== null && deliveryAt.location[0] && deliveryAt.location[1]) { /// check calculate price lalamove.

        //     const queryShippingCost = {
        //         deliveryAt: {
        //             location: [deliveryAt.location[0].toString(), deliveryAt.location[1].toString()],
        //             address: deliveryAt.address,
        //             addressExtra: deliveryAt.addressExtra,
        //             subdistrict: deliveryAt.subdistrict.subdistrictName,
        //             district: deliveryAt.district.districtName,
        //             province: deliveryAt.province.provinceName,
        //             zipcode: deliveryAt.zipcode,
        //             typeCar: "MOTORCYCLE",
        //             isBox: this_order.isBox,
        //             priceShippingNet: this_order.shippingCost.deliveryPrice
        //         },
        //         methodPayment: "transfer",
        //         shopId: shopId,
        //         contactId: contactId,
        //         productId: listProductId
        //     }

        //     if (this_order.deliveryOption !== "KERRY") {
        //         const { data } = yield call(api_invoicesCtrl.checkShippingCostNoOrder, { ...queryShippingCost });
        //         yield put({
        //             type: actions_createOrder.Type.SET_SHIPING_COST,
        //             payload: { shippingCost: data, idx: activeTab }
        //         });


        //         const outOfServiceArea = this_order.errors.calculateShipingcost.outOfServiceArea;
        //         if (outOfServiceArea) {
        //             /// clear error outOfServiceArea
        //             yield put({
        //                 type: actions_createOrder.Type.SET_ERROR_ADDRESS_OUT_OF_AREA,
        //                 payload: { idx: activeTab, status: false }
        //             });
        //         }
        //     }
        // }
    } catch (error) {
        console.log(error);

    }
}




function* addMenuToCart(action) {
    const { payload } = action;
    try {
        const stateCreateOrder = yield select(getCreateOrder);
        const activeTab = stateCreateOrder.activeTab;
        const this_orderShop = stateCreateOrder.orderShop[activeTab];
        const listMenuSelect = this_orderShop.listMenuSelect;
        const productId = payload && payload.detailMenu && payload.detailMenu.dataProduct[0]._id;
        if (listMenuSelect[productId]) {
            yield put(actions_loading.openLoading());// open loading.
            const listMenuSelect_copy = { ...listMenuSelect };
            delete listMenuSelect_copy[productId];

            const this_order = listMenuSelect[productId];
            if (this_order.error.statusCreate && this_order.orderSuccess) { // check order create success delete order.
                yield put(actions_loading.openLoading());// open loading.
                const dataDeleteOrder = {
                    orderId: this_order.orderSuccess.id,
                    invoiceId: this_order.orderSuccess.invoiceId,
                }
                yield call(api_ordersCtrl.deleteOrdertMulti, dataDeleteOrder); // call api delete;
            }

            yield put({
                type: actions_createOrder.Type.REMOVE_MENU_FROM_CART_SUCCESS,
                payload: {
                    ...payload,
                    listMenuSelect: listMenuSelect_copy
                }
            });


            // checkshippingcost after add menu ///
            const statecheckShippingCost = yield select(getCreateOrder);
            const stateCreate = statecheckShippingCost.orderShop[payload.idx];
            const shippingCost = stateCreate.checkShippingCosts.deliveryAt[0]
            const listProductId = Object.keys(stateCreate.listMenuSelect)
            const orders = []
            const orderlist = Object.keys(stateCreate.listMenuSelect).map((key, idx) => {
                const dataOrder = {
                    productId: key,
                    quantity: stateCreate.listMenuSelect[key].quantity
                }
                orders.push(dataOrder)
            })

            const shopId = (stateCreate.mainData && stateCreate.mainData.shopId) || null;
            const contactId = stateCreate.contactShop._id;

            if (Object.keys(stateCreate.listMenuSelect).length != 0) {
                if (contactId && shopId && shippingCost.address !== "" && shippingCost.subdistrict !== null && shippingCost.district !== null && shippingCost.province !== null && shippingCost.location[0] && shippingCost.location[1]) {
                    const queryShippingCost = {
                        deliveryAt: {
                            location: [shippingCost.location[0].toString(), shippingCost.location[1].toString()],
                            address: shippingCost.address,
                            addressExtra: shippingCost.addressExtra,
                            subdistrict: shippingCost.subdistrict.subdistrictName,
                            district: shippingCost.district.districtName,
                            province: shippingCost.province.provinceName,
                            zipcode: shippingCost.zipcode,
                            typeCar: "MOTORCYCLE",
                            isBox: stateCreate.isBox
                        },
                        methodPayment: "transfer",
                        shopId: shopId,
                        contactId: contactId,
                        // productId: listProductId
                        orders: orders
                    }

                    // if (stateCreate.deliveryOption !== "KERRY") {
                    const { data } = yield call(api_invoicesCtrl.checkShippingCostNoOrder, { ...queryShippingCost });
                    yield put({
                        type: actions_logistics.Type.GET_LIST_LOGISTIC_SUCCESS,
                        payload: data
                    });

                    yield put({
                        type: actions_createOrder.Type.SET_LIST_LOGISTIC_SUCCESS,
                        payload: {
                            idx: payload.idx,
                            listLogistic: data
                        }
                    })

                    const stateCreate = statecheckShippingCost.orderShop[payload.idx];
                    if (data && Object.keys(stateCreate.listMenuSelect).length != 0) {
                        var logisticPick = null
                        logisticPick = data.filter((item, idx) => item.logistic.code == stateCreate.deliveryOption)
                        yield put({
                            type: actions_createOrder.Type.SET_SHIPING_COST,
                            payload: { shippingCost: logisticPick, idx: payload.idx }
                        });
                    }
                    if (Object.keys(stateCreate.listMenuSelect).length == 0) {
                        const shippingCost = [{
                            deliveryPrice: 0,
                            discountPrice: 0
                        }]
                        yield put({
                            type: actions_createOrder.Type.SET_SHIPING_COST,
                            payload: { shippingCost: shippingCost, idx: payload.idx }
                        });
                    } else {
                        // logisticPick = data.filter((item, idx) => item.logistic.name == "express")
                        // yield put({
                        //     type: actions_createOrder.Type.SET_SHIPING_COST,
                        //     payload: { shippingCost: logisticPick, idx: payload.idx }
                        // });
                    }


                    const outOfServiceArea = stateCreate.errors.calculateShipingcost.outOfServiceArea;
                    if (outOfServiceArea) {
                        /// clear error outOfServiceArea
                        yield put({
                            type: actions_createOrder.Type.SET_ERROR_ADDRESS_OUT_OF_AREA,
                            payload: { idx: payload.idx, status: false }
                        });
                    }
                    // }
                }
            }



            const checkStateCreateOrder = yield select(getCreateOrder);
            if (Object.keys(checkStateCreateOrder.orderShop[activeTab].listMenuSelect).length === 0) {
                yield put(actions_loading.openLoading());// open loading.
                yield put({
                    type: actions_createOrder.Type.CLEAR_SEARCH_MENU,
                    payload: {
                        idx: checkStateCreateOrder.activeTab
                    }
                })

                yield put({
                    type: actions_createOrder.Type.REMOVE_DATA_WHEN_NONE_SHOP,
                    payload: {
                        idx: checkStateCreateOrder.activeTab,
                    }
                })
                const stateCreateOrder = yield select(getCreateOrder);
                const { data } = yield call(api_menuCtrl.getListMenu, stateCreateOrder.orderShop[stateCreateOrder.activeTab].search);
                yield put({
                    type: actions_menus.Type.GET_LIST_MENU_SUCCESS,
                    data
                })
                yield put({
                    type: actions_createOrder.Type.REMOVE_DELIVERY_AT_DATE_DELIVERY,
                    payload: {
                        idx: activeTab,
                    }
                });
                yield put({
                    type: actions_createOrder.Type.SET_PRICE_SPECIAL,
                    payload: {
                        priceSpecial: "",
                        idx: activeTab
                    }
                })
                yield put({
                    type: actions_createOrder.Type.SET_DELIVERY_OPTION_SUCCESS,
                    payload: {
                        deliveryOption: "",
                        idx: payload.idx
                    }
                })
            }

            const stateCreateOrder = yield select(getCreateOrder);

            if (Object.keys(checkStateCreateOrder.orderShop[activeTab].listMenuSelect).length === 0) {
                try {
                    const { data } = yield call(api_menuCtrl.getListMenu, stateCreateOrder.orderShop[stateCreateOrder.activeTab].search);
                    yield put({
                        type: actions_menus.Type.GET_LIST_MENU_SUCCESS,
                        data
                    })
                } catch (error) {
                    console.log(error)
                }
            }


            const stateDateDelivery = yield select(getCreateOrder)
            const listMenu = stateDateDelivery.orderShop[activeTab].listMenuSelect
            const checkMenu = Object.keys(listMenu).filter(function (key, idx) {
                return [key].toString() == stateDateDelivery.orderShop[activeTab].listStockByDateTime.id
            })
            const listMenuObject = Object.keys(listMenu)
            if (checkMenu.toString().trim().length == 0) {
                if (Object.keys(listMenu).length >= 1) {
                    yield put({
                        type: actions_createOrder.Type.REPLACE_LIST_STOCK_BY_DATE_TIME,
                        payload: {
                            data: listMenu[listMenuObject[0]].listStockByDateTime || "", idx: payload.idx, id: productId
                        }
                    })
                }

                const stateAddListStock = yield select(getCreateOrder)
                const listStock = stateAddListStock.orderShop[activeTab].listStockByDateTime

                const date = listStock && listStock.date
                const time = listStock && listStock.timeDefault

                const dateDelivery = []
                const timeDelivery = []
                const dateTimeDelivery = []
                const datemoment = moment().add(2, 'hours')
                const dateNow = new Date(datemoment).toISOString()


                date && date.map(date => {
                    time && time.map(time => {
                        dateTimeDelivery.push(api_menuCtrl.mergeDateTime(date, time))
                    })
                })
                const dateAdd = dateTimeDelivery.filter((item) => item > dateNow)
                const dateNotRepeat = dateAdd.filter((item, idx) => dateAdd.indexOf(item) === idx)
                // const filterDateNotToday = date.filter(item => item > dateNow)
                // const dateNotToday = filterDateNotToday
                // for (let i = 0; i < dateNotToday.length; i++) {
                //     dateDelivery.push(dateNotToday && dateNotToday[i])
                //     timeDelivery.push(time && time[i])
                // }
                // console.log(dateTimeDelivery);
                // console.log('datemoment',datemoment);

                // const dateToday = listStock && listStock.timeDefault
                // const filterDateToday = dateToday.filter(item => item > dateNow)
                // filterDateToday.map(item => {
                //     dateTimeDelivery.push(item)
                // })
                // const filterDateNotToday = date.filter(item => item > dateNow)
                // const dateNotToday = filterDateNotToday

                // if ((date && date.length != 0) && (time && time != 0)) {
                //     for (let i = 0; i < filterDateNotToday.length; i++) {
                //         dateDelivery.push(filterDateNotToday && filterDateNotToday[i])
                //         timeDelivery.push(time && time[i])
                //     }

                //     const resultTimeDelivery = timeDelivery.filter(item => (typeof item != 'undefined'))
                //     dateDelivery && dateDelivery.map(filterDateNotToday => {
                //         resultTimeDelivery && resultTimeDelivery.map(time => {
                //             dateTimeDelivery.push(api_menuCtrl.mergeDateTime(filterDateNotToday && filterDateNotToday, time))
                //         })
                //     })
                // }
                const stateCheckListStock = yield select(getCreateOrder)
                const listMenuStock = stateCheckListStock.orderShop[activeTab].listMenuSelect

                // dateAdd.map(function* (item, idx) {
                //     console.log('777',item);
                //     const body = {
                //         productId: Object.keys(listMenuStock)[0],
                //         datePreorder: item,
                //         userId: stateDateDelivery.userCustomer.id,
                //     }
                //     console.log('777',body);

                //     const { data } = yield call(api_productsCtrl.listTimeByProduct, body);
                //     console.log('data',data);

                //     if (data.stock.stock == 0) {
                //         delete dateAdd[idx]
                //     }
                // })


                const dateCheckStock = dateAdd.filter(e => e.length)
                yield put({
                    type: actions_createOrder.Type.SET_TIME_DELIVERY_SUCCESS,
                    payload: {
                        // date: dateCheckStock.slice(0, 3),
                        date: dateNotRepeat,
                        idx: activeTab
                    }
                });


                const dataDelivery = stateDateDelivery.orderShop[activeTab].checkShippingCosts.deliveryAt[0].dateDelivery || ""
                const search = {
                    ...stateDateDelivery.orderShop[activeTab].search,
                    shop: stateDateDelivery.orderShop[activeTab].mainData && stateDateDelivery.orderShop[activeTab].mainData.dataShop[0].name || "",
                    date: dataDelivery.length > 0 ? moment(dataDelivery).format('YYYY-MM-DD') : "",
                    time: dataDelivery.length > 0 ? moment(dataDelivery).format('HH:mm') : ""
                }

                yield put({
                    type: actions_createOrder.Type.SET_SEARCH_DATA,
                    payload: {
                        data: search,
                        idx: activeTab
                    }
                })
                const stateCreateOrder = yield select(getCreateOrder);
                const { data } = yield call(api_menuCtrl.getListMenu, stateCreateOrder.orderShop[stateCreateOrder.activeTab].search);
                yield put({
                    type: actions_menus.Type.GET_LIST_MENU_SUCCESS,
                    data
                })

                const stateDelivery = yield select(getCreateOrder);
                const listStockByDateTime = stateDelivery.orderShop[activeTab].listMenuSelect

                ///set quantity 1
                yield* Object.keys(listStockByDateTime).map(function* (key, idx) {
                    if (listStockByDateTime[key].quantity != 1) {
                        // yield put(actions_loading.openLoading());// open loading.
                        yield put({
                            type: actions_createOrder.Type.SET_QUANTITY_DEFAULT,
                            payload: {
                                id: listStockByDateTime[key].product.dataProduct[0]._id,
                                list: listStockByDateTime[key],
                                idx: activeTab,
                                quantity: 1
                            }
                        })
                    }
                })
                ///set quantity 1

                const stateDateTime = yield select(getCreateOrder);
                const deliveryDate = moment(stateDateTime.orderShop[activeTab].checkShippingCosts.deliveryAt[0].dateDelivery).format('DD-MM-YYYY HH:mm')
                const listMenuDate = []
                const dateListMenu = Object.values(listStockByDateTime).map(function (item) {
                    const dateTimeMerge = []
                    item.listStockByDateTime.time.map(time =>
                        item.listStockByDateTime.date.map(dateTime => dateTimeMerge.push(api_menuCtrl.mergeDateTime(dateTime, time))
                        )
                    )
                    const menuList = dateTimeMerge.map(date => moment(date).format('DD-MM-YYYY HH:mm') === deliveryDate ? true : false)
                    if (menuList.every(item => item == false)) {
                        return listMenuDate.push(item.product.dataProduct[0]._id)
                    } else {
                        return true
                    }
                })
                const listMenus = { ...stateDateTime.orderShop[activeTab].listMenuSelect }
                listMenuDate.map(menu => {
                    if (listMenus[menu]) {
                        delete listMenus[menu]
                    }
                })
                // yield put({
                //     type: actions_createOrder.Type.REMOVE_MENU_FROM_CART_SUCCESS,
                //     payload: {
                //         idx: activeTab,
                //         listMenuSelect: listMenus
                //     }
                // })
            }

            ////if remove menu check listdate if listdate no deliverydate Delete DeliveryDate
            const stateCreateOrders = yield select(getCreateOrder);
            const this_order_ = stateCreateOrders.orderShop[stateCreateOrders.activeTab]

            const dateDelivery = this_order_.listDateDelivery.dateDelivery.filter(item => item == this_order_.checkShippingCosts.deliveryAt[0].dateDelivery)
            if (dateDelivery.length == 0) {

                // yield put({
                //     type: actions_createOrder.Type.SET_TIME_DELIVERY_AT_SUCCESS,
                //     payload
                // })

                // yield put({
                //     type: actions_createOrder.Type.REMOVE_DELIVERY_AT_DATE_DELIVERY,
                //     payload: {
                //         idx: activeTab,
                //     }
                // })
                const stateDateDelivery = yield select(getCreateOrder)
                const listMenu = stateDateDelivery.orderShop[activeTab].listMenuSelect
                const checkMenu = Object.keys(listMenu).filter(function (key, idx) {
                    return [key].toString() == stateDateDelivery.orderShop[activeTab].listStockByDateTime.id
                })
                const listMenuObject = Object.keys(listMenu)

                if (checkMenu.toString().trim().length == 0) {
                    if (Object.keys(listMenu).length >= 1) {
                        yield put({
                            type: actions_createOrder.Type.REPLACE_LIST_STOCK_BY_DATE_TIME,
                            payload: {
                                data: listMenu[listMenuObject[0]].listStockByDateTime || "", idx: payload.idx, id: productId
                            }
                        })
                    }
                    const stateAddListStock = yield select(getCreateOrder)
                    const listStock = stateAddListStock.orderShop[activeTab].listStockByDateTime

                    const date = listStock && listStock.date
                    const time = listStock && listStock.timeDefault

                    const dateDelivery = []
                    const timeDelivery = []
                    const dateTimeDelivery = []
                    const datemoment = moment().add(2, 'hours')
                    const dateNow = new Date(datemoment).toISOString()

                    const dateToday = stateAddListStock.orderShop[activeTab].listMenuSelect && stateAddListStock.orderShop[activeTab].listMenuSelect.timeDefault
                    
                    const filterDateToday = dateToday && dateToday.filter(item => item > dateNow)
                    filterDateToday && filterDateToday.map(item => {
                        dateTimeDelivery.push(item)
                    })



                    const filterDateNotToday = date && date.filter(item => item > dateNow)
                    const dateNotToday = filterDateNotToday

                    if ((date && date.length != 0) && (time && time != 0)) {
                        for (let i = 0; i < dateNotToday.length; i++) {
                            dateDelivery.push(dateNotToday && dateNotToday[i])
                            timeDelivery.push(time && time[i])
                        }

                        const resultTimeDelivery = timeDelivery && timeDelivery.filter(item => (typeof item != 'undefined'))
                        dateDelivery && dateDelivery.map(dateNotToday => {
                            resultTimeDelivery && resultTimeDelivery.map(time => {
                                dateTimeDelivery.push(api_menuCtrl.mergeDateTime(dateNotToday && dateNotToday, time))
                            })
                        })
                    }
                    const stateCheckListStock = yield select(getCreateOrder)
                    const listMenuStock = stateCheckListStock.orderShop[activeTab].listMenuSelect

                    dateTimeDelivery.map(function* (item, idx) {
                        const body = {
                            productId: Object.keys(listMenuStock)[0],
                            datePreorder: item,
                            userId: stateDateDelivery.userCustomer.id,
                        }
                        const { data } = yield call(api_productsCtrl.listTimeByProduct, body);
                        if (data.stock.stock == 0) {
                            delete dateTimeDelivery[idx]
                        }
                    })
                    const dateCheckStock = dateTimeDelivery.filter(e => e.length)
                    yield put({
                        type: actions_createOrder.Type.SET_TIME_DELIVERY_SUCCESS,
                        payload: {
                            // date: dateCheckStock.slice(0, 3),
                            date: dateCheckStock,
                            idx: activeTab
                        }
                    });

                    const dataDelivery = stateDateDelivery.orderShop[activeTab].checkShippingCosts.deliveryAt[0].dateDelivery
                    const search = {
                        ...stateDateDelivery.orderShop[activeTab].search,
                        shop: stateDateDelivery.orderShop[activeTab].mainData && stateDateDelivery.orderShop[activeTab].mainData.dataShop[0].name || "",
                        date: dateDelivery.length > 0 ? moment(dataDelivery).format('YYYY-MM-DD') : "",
                        time: dateDelivery.length > 0 ? moment(dataDelivery).format('HH:mm') : ""
                    }

                    yield put({
                        type: actions_createOrder.Type.SET_SEARCH_DATA,
                        payload: {
                            data: search,
                            idx: activeTab
                        }
                    })
                    const stateCreateOrder = yield select(getCreateOrder);
                    const { data } = yield call(api_menuCtrl.getListMenu, stateCreateOrder.orderShop[stateCreateOrder.activeTab].search);
                    yield put({
                        type: actions_menus.Type.GET_LIST_MENU_SUCCESS,
                        data
                    })

                    const stateDelivery = yield select(getCreateOrder);
                    const listStockByDateTime = stateDelivery.orderShop[activeTab].listMenuSelect

                    ///set quantity 1
                    yield* Object.keys(listStockByDateTime).map(function* (key, idx) {
                        if (listStockByDateTime[key].quantity != 1) {
                            // yield put(actions_loading.openLoading());// open loading.
                            yield put({
                                type: actions_createOrder.Type.SET_QUANTITY_DEFAULT,
                                payload: {
                                    id: listStockByDateTime[key].product.dataProduct[0]._id,
                                    list: listStockByDateTime[key],
                                    idx: activeTab,
                                    quantity: 1
                                }
                            })
                        }
                    })
                    ///set quantity 1

                    const stateDateTime = yield select(getCreateOrder);
                    const deliveryDate = moment(stateDateTime.orderShop[activeTab].checkShippingCosts.deliveryAt[0].dateDelivery).format('DD-MM-YYYY HH:mm')
                    const listMenuDate = []



                    const dateListMenu = Object.values(listStockByDateTime).map(function (item) {
                        const dateTimeMerge = []
                        item.listStockByDateTime.time.map(time =>
                            item.listStockByDateTime.date.map(dateTime => dateTimeMerge.push(api_menuCtrl.mergeDateTime(dateTime, time))
                            )
                        )
                        const menuList = dateTimeMerge.map(date => moment(date).format('DD-MM-YYYY HH:mm') === deliveryDate ? true : false)
                        if (menuList.every(item => item == false)) {
                            return listMenuDate.push(item.product.dataProduct[0]._id)
                        } else {
                            return true
                        }
                    })
                    const listMenus = { ...stateDateTime.orderShop[activeTab].listMenuSelect }
                    listMenuDate.map(menu => {
                        if (listMenus[menu]) {
                            delete listMenus[menu]
                        }
                    })
                    yield put({
                        type: actions_createOrder.Type.REMOVE_MENU_FROM_CART_SUCCESS,
                        payload: {
                            idx: activeTab,
                            listMenuSelect: listMenus
                        }
                    })
                }

                const stateCreateOrder = yield select(getCreateOrder);
                const { data } = yield call(api_menuCtrl.getListMenu, stateCreateOrder.orderShop[stateCreateOrder.activeTab].search);
                yield put({
                    type: actions_menus.Type.GET_LIST_MENU_SUCCESS,
                    data
                })
            }

            ////if remove menu check listdate if listdate no deliverydate Delete DeliveryDate
            yield put(actions_loading.closeLoading());// close loading.

        } else {
            yield put(actions_loading.openLoading());// open loading.
            yield put({
                type: actions_createOrder.Type.ADD_MENU_TO_CART_SUCCESS,
                payload
            });
            const stateCreateOrder = yield select(getCreateOrder);
            const activeTab = stateCreateOrder.activeTab;
            const this_orderShop = stateCreateOrder.orderShop[activeTab];
            const listMenuSelect = this_orderShop.listMenuSelect;

            const body = {
                productId,
                // datePreorder: payload.datePreorder,
                userId: payload.userId,
                // idx: payload.idx
            }


            //check date delivery
            const { data } = yield call(api_productsCtrl.listTimeByProduct, body);
            if (data && data.date.length === 0 || data.timeDefault.length == 0) {
                yield put({
                    type: actions_notification.Type.OPEN_NOTIFICATION_ALERT,
                    payload: {
                        message: <div><img src={IconNotification} alt="IconNotification"></img><span className="ml-3">การจัดส่งที่ {payload.idx + 1} ไม่พบวันที่จัดส่งของเมนูนี้</span></div>,
                        level: 'error',
                        autoDismiss: 0,
                        position: "tr"
                    }
                });

                //if nodate >> remove
                if (listMenuSelect[productId]) {
                    const listMenuSelect_copy = { ...listMenuSelect };
                    delete listMenuSelect_copy[productId];

                    yield put({
                        type: actions_createOrder.Type.REMOVE_MENU_FROM_CART_SUCCESS,
                        payload: {
                            ...payload,
                            listMenuSelect: listMenuSelect_copy
                        }
                    });
                }
                yield put(actions_loading.closeLoading());// close loading.
                return
            }


            if (Object.keys(stateCreateOrder.orderShop[activeTab].listMenuSelect).length === 1) {
                yield put({
                    type: actions_createOrder.Type.CHECK_LIST_DATE_TIME_DEFAULT,
                    payload: {
                        data, idx: payload.idx, id: productId
                    }
                })
            } else {
                yield put({
                    type: actions_createOrder.Type.CHECK_LIST_DATE_TIME_SUCCESS,
                    payload: {
                        data, idx: payload.idx, id: productId
                    }
                })
            }
            const stateDateDelivery = yield select(getCreateOrder);

            //merge datetime delivery
            if (Object.keys(listMenuSelect).length === 1) { // check add first order.
                const this_order = stateDateDelivery.orderShop[payload.idx];
                const shopId = (this_order.mainData && this_order.mainData.shopId) || null;
                const contactId = this_order.contactShop._id;
                const deliveryAt = this_order.checkShippingCosts.deliveryAt[0];
                const listStockByDateTime = stateDateDelivery.orderShop[activeTab].listStockByDateTime
                const date = listStockByDateTime && listStockByDateTime.date
                const time = listStockByDateTime && listStockByDateTime.timeDefault
                const dateDelivery = []
                const timeDelivery = []
                let dateTimeDelivery = []
                const datemoment = moment().add(2, 'hours')
                const dateNow = new Date(datemoment).toISOString()


                const dateToday = listStockByDateTime && listStockByDateTime.timeDefault

                // const filterDateToday = dateToday.filter(item => item > dateNow)
                // filterDateToday.map(item => {
                //     dateTimeDelivery.push(item)
                // })
                // date && date.map(item => dateDelivery.push(item))
                // dateDelivery.sort()
                // time && time.map(item => timeDelivery.push(item))
                // timeDelivery.sort()
                date && date.map(date => {
                    time && time.map(time => {
                        dateTimeDelivery.push(api_menuCtrl.mergeDateTime(date && date, time))
                    })
                })
                // const filterDateNotToday = date.filter(item => item > dateNow)
                // const dateNotToday = filterDateNotToday
                // for (let i = 0; i < dateNotToday.length; i++) {
                //     dateDelivery.push(dateNotToday && dateNotToday[i])
                //     timeDelivery.push(time && time[i])
                // }
                // const resultTimeDelivery = timeDelivery.filter(item => (typeof item != 'undefined'))

                // dateDelivery && dateDelivery.map(dateNotToday => {
                //     resultTimeDelivery && resultTimeDelivery.map(time => {
                //         dateTimeDelivery.push(api_menuCtrl.mergeDateTime(dateNotToday && dateNotToday, time))
                //     })
                // })
                // dateTimeDelivery.sort()

                const dateAdd = dateTimeDelivery.filter((item) => item > dateNow)

                dateTimeDelivery = dateAdd
                dateTimeDelivery.filter((item, idx) => dateTimeDelivery.indexOf(item) === idx)



                dateTimeDelivery.map(function* (item, idx) {
                    const body = {
                        productId,
                        datePreorder: item,
                        userId: stateDateDelivery.userCustomer.id,
                    }
                    const { data } = yield* call(api_productsCtrl.listTimeByProduct, body);
                    if (data.stock.stock == 0) {
                        delete dateTimeDelivery[idx]
                    }
                })

                const dateCheckStock = dateTimeDelivery.filter(e => e.length)
                const resultDate = dateCheckStock.filter((item, idx) => dateCheckStock.indexOf(item) === idx)


                yield put({
                    type: actions_createOrder.Type.SET_TIME_DELIVERY_SUCCESS,
                    payload: {
                        // date: dateCheckStock.slice(0, 3),
                        date: resultDate,
                        idx: activeTab
                    }
                });

                yield put({
                    type: actions_createOrder.Type.SET_TIME_DELIVERY_AT_SUCCESS,
                    payload: {
                        date: resultDate[0],
                        idx: activeTab
                    }
                })

                ////////////check first time click set stock order /////////////////////
                const stateCheckTime = yield select(getCreateOrder);
                const body = {
                    productId,
                    datePreorder: stateCheckTime.orderShop[activeTab].checkShippingCosts.deliveryAt[0].dateDelivery,
                    userId: payload.userId,
                    // idx: payload.idx
                }
                //check date delivery
                const resultListtimeProduct = yield call(api_productsCtrl.listTimeByProduct, body);
                if (Object.keys(stateCheckTime.orderShop[activeTab].listMenuSelect).length === 1) {
                    yield put({
                        type: actions_createOrder.Type.CHECK_LIST_DATE_TIME_DEFAULT,
                        payload: {
                            data: resultListtimeProduct.data, idx: payload.idx, id: productId
                        }
                    })
                } else {
                    yield put({
                        type: actions_createOrder.Type.CHECK_LIST_DATE_TIME_SUCCESS,
                        payload: {
                            data: resultListtimeProduct.data, idx: payload.idx, id: productId
                        }
                    })
                }
                ////////////check first time click set stock order /////////////////////

                const search = {
                    ...stateDateDelivery.orderShop[activeTab].search,
                    shop: payload.mainData.dataShop[0].name,
                    date: moment(resultDate[0]).format('YYYY-MM-DD'),
                    time: moment(resultDate[0]).format('HH:mm')
                }


                yield put({
                    type: actions_createOrder.Type.SET_SEARCH_DATA,
                    payload: {
                        data: search,
                        idx: activeTab
                    }
                })

                //if menu = 1 add date time default delivery
                const stateCreateOrder = yield select(getCreateOrder);
                const { data } = yield call(api_menuCtrl.getListMenu, stateCreateOrder.orderShop[stateCreateOrder.activeTab].search);
                yield put({
                    type: actions_menus.Type.GET_LIST_MENU_SUCCESS,
                    data
                })
            }



            ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
            // checkshippingcost after add menu ///
            const stateCreate = stateDateDelivery.orderShop[payload.idx];
            const shippingCost = stateCreate.checkShippingCosts.deliveryAt[0]
            const listProductId = Object.keys(stateCreate.listMenuSelect)
            const orders = []

            const orderlist = Object.keys(stateCreate.listMenuSelect).map((key, idx) => {

                const dataOrder = {
                    productId: key,
                    quantity: stateCreate.listMenuSelect[key].quantity
                }
                orders.push(dataOrder)
            })
            const shopId = (stateCreate.mainData && stateCreate.mainData.shopId) || null;
            const contactId = stateCreate.contactShop._id;
            if (contactId && shopId && shippingCost.address !== "" && shippingCost.subdistrict !== null && shippingCost.district !== null && shippingCost.province !== null && shippingCost.location[0] && shippingCost.location[1]) {
                const queryShippingCost = {
                    deliveryAt: {
                        location: [shippingCost.location[0].toString(), shippingCost.location[1].toString()],
                        address: shippingCost.address,
                        addressExtra: shippingCost.addressExtra,
                        subdistrict: shippingCost.subdistrict.subdistrictName,
                        district: shippingCost.district.districtName,
                        province: shippingCost.province.provinceName,
                        zipcode: shippingCost.zipcode,
                        typeCar: "MOTORCYCLE",
                        isBox: stateCreate.isBox
                    },
                    methodPayment: "transfer",
                    shopId: shopId,
                    contactId: contactId,
                    // productId: listProductId
                    orders: orders
                }

                // if (stateCreate.deliveryOption !== "KERRY") {
                const { data } = yield call(api_invoicesCtrl.checkShippingCostNoOrder, { ...queryShippingCost });


                yield put({
                    type: actions_logistics.Type.GET_LIST_LOGISTIC_SUCCESS,
                    payload: data
                });

                yield put({
                    type: actions_createOrder.Type.SET_LIST_LOGISTIC_SUCCESS,
                    payload: {
                        idx: payload.idx,
                        listLogistic: data
                    }
                })

                const setDeliveryOption = data.filter((item) => item.logistic.name == "express" || item.logistic.name == "skootar")
                if (setDeliveryOption.length > 0) {
                    yield put({
                        type: actions_createOrder.Type.SET_DELIVERY_OPTION_SUCCESS,
                        payload: {
                            deliveryOption: setDeliveryOption[0].logistic.code,
                            idx: payload.idx
                        }
                    })
                    yield put({
                        type: actions_createOrder.Type.SET_SHIPING_COST,
                        payload: { shippingCost: [{ deliveryPrice: setDeliveryOption[0].deliveryPrice, discountPrice: setDeliveryOption[0].discountPrice }], idx: payload.idx }
                    });
                } else {
                    yield put({
                        type: actions_createOrder.Type.SET_DELIVERY_OPTION_SUCCESS,
                        payload: {
                            deliveryOption: "SPECIAL",
                            idx: payload.idx
                        }
                    })
                }


                // if (stateCreate.deliveryOption.trim().length == 0) {
                //     console.log('delioption0');

                //     yield put({
                //         type: actions_createOrder.Type.SET_SHIPING_COST,
                //         payload: {
                //             shippingCost: [{
                //                 deliveryPrice: 0,
                //                 discountPrice: 0
                //             }], idx: payload.idx
                //         }
                //     });
                // }

                // if (stateCreate.deliveryOption != "SPECIAL") {
                //     const logisticPick = data.filter((item, idx) => item.logistic.code == stateCreate.deliveryOption)

                //     yield put({
                //         type: actions_createOrder.Type.SET_SHIPING_COST,
                //         payload: { shippingCost: logisticPick, idx: payload.idx }
                //     });
                // }

                // if (payload.idx > 0) {



                const stateCheckPromotion = yield select(getCreateOrder);
                if (stateCheckPromotion.orderShop[0].mainData.dataShop[0].hubId && stateCheckPromotion.orderShop[0].mainData.dataShop[0].hubId.trim().length > 0 && stateCheckPromotion.orderShop[0] && payload.idx == 0) {

                    yield put({
                        type: actions_createOrder.Type.SET_DELIVERY_OPTION_SUCCESS,
                        payload: {
                            deliveryOption: "SPECIAL",
                            idx: 0
                        }
                    })

                    const shippingCost = [{
                        deliveryPrice: 99,
                        discountPrice: 0
                    }]
                    yield put({
                        type: actions_createOrder.Type.SET_SHIPING_COST,
                        payload: {
                            shippingCost: shippingCost,
                            idx: 0
                        }
                    })

                    yield put({
                        type: actions_createOrder.Type.SET_DELIVERY_NOTE,
                        payload: { deliveryNote: "promotion99", idx: 0 }
                    })
                    yield put({
                        type: actions_createOrder.Type.SET_PRICE_SPECIAL,
                        payload: { priceSpecial: 99, idx: 0 }
                    })
                }
                // else {
                //     console.log('nohub');
                    
                //     yield put({
                //         type: actions_createOrder.Type.SET_SHIPING_COST,
                //         payload: {
                //             shippingCost: [{
                //                 deliveryPrice: 0,
                //                 discountPrice: 0
                //             }], idx: payload.idx
                //         }
                //     });
                // }
                // console.log('nohub1');


                // for (let i = 1; i <= stateCheckPromotion.orderShop.length - 1; i++) {
                //     const this_order = stateCheckPromotion.orderShop[i]
                //     if (this_order.checkShippingCosts.deliveryAt[0].dateDelivery != "" && this_order.checkShippingCosts.deliveryAt[0].dateDelivery == stateCheckPromotion.orderShop[0].checkShippingCosts.deliveryAt[0].dateDelivery && this_order.mainData.dataShop[0].hubId) {
                //         if ((this_order.mainData.dataShop[0].hubId == stateCheckPromotion.orderShop[0].mainData.dataShop[0].hubId) && this_order.mainData.dataShop[0].hubId) {
                //             yield put({
                //                 type: actions_createOrder.Type.SET_DELIVERY_OPTION_SUCCESS,
                //                 payload: {
                //                     deliveryOption: "SPECIAL",
                //                     idx: 0
                //                 }
                //             })
                //             const shippingCost = [{
                //                 deliveryPrice: 99,
                //                 discountPrice: 0
                //             }]
                //             yield put({
                //                 type: actions_createOrder.Type.SET_SHIPING_COST,
                //                 payload: { shippingCost: shippingCost, idx: 0 }
                //             });
                //             yield put({
                //                 type: actions_createOrder.Type.SET_DELIVERY_NOTE,
                //                 payload: { deliveryNote: "promotion99", idx: 0 }
                //             })
                //             yield put({
                //                 type: actions_createOrder.Type.SET_PRICE_SPECIAL,
                //                 payload: { priceSpecial: 99, idx: 0 }
                //             })

                //             const promoPrice = [{
                //                 deliveryPrice: 0,
                //                 discountPrice: 0
                //             }]
                //             yield put({
                //                 type: actions_createOrder.Type.SET_SHIPING_COST,
                //                 payload: { shippingCost: promoPrice, idx: parseInt(i) }
                //             });
                //             yield put({
                //                 type: actions_createOrder.Type.SET_DELIVERY_OPTION_SUCCESS,
                //                 payload: {
                //                     deliveryOption: "SPECIAL",
                //                     idx: parseInt(i)
                //                 }
                //             })
                //             yield put({
                //                 type: actions_createOrder.Type.SET_DELIVERY_NOTE,
                //                 payload: { deliveryNote: "promotion99", idx: parseInt(i) }
                //             })
                //             yield put({
                //                 type: actions_createOrder.Type.SET_PRICE_SPECIAL,
                //                 payload: { priceSpecial: "0", idx: parseInt(i) }
                //             })
                //         } else {
                //             const promoPrice = [{
                //                 deliveryPrice: 0,
                //                 discountPrice: 0
                //             }]
                //             yield put({
                //                 type: actions_createOrder.Type.SET_SHIPING_COST,
                //                 payload: { shippingCost: promoPrice, idx: parseInt(i) }
                //             });
                //             yield put({
                //                 type: actions_createOrder.Type.SET_DELIVERY_OPTION_SUCCESS,
                //                 payload: {
                //                     deliveryOption: "SPECIAL",
                //                     idx: parseInt(i)
                //                 }
                //             })
                //             yield put({
                //                 type: actions_createOrder.Type.SET_DELIVERY_NOTE,
                //                 payload: { deliveryNote: "", idx: parseInt(i) }
                //             })
                //             yield put({
                //                 type: actions_createOrder.Type.SET_PRICE_SPECIAL,
                //                 payload: { priceSpecial: "", idx: parseInt(i) }
                //             })
                //         }
                //     }
                // }

                const this_order = stateCheckPromotion.orderShop[payload.idx]
                if (this_order.checkShippingCosts.deliveryAt[0].dateDelivery != "" && this_order.checkShippingCosts.deliveryAt[0].dateDelivery == stateCheckPromotion.orderShop[0].checkShippingCosts.deliveryAt[0].dateDelivery && this_order.mainData.dataShop[0].hubId && stateCheckPromotion.orderShop[0].deliveryOption === "SPECIAL" && stateCheckPromotion.orderShop[0].checkShippingCosts.deliveryNote === "promotion99") {
                    if ((this_order.mainData.dataShop[0].hubId == stateCheckPromotion.orderShop[0].mainData.dataShop[0].hubId) && this_order.mainData.dataShop[0].hubId) {

                        yield put({
                            type: actions_createOrder.Type.SET_DELIVERY_OPTION_SUCCESS,
                            payload: {
                                deliveryOption: "SPECIAL",
                                idx: 0
                            }
                        })
                        const shippingCost = [{
                            deliveryPrice: 99,
                            discountPrice: 0
                        }]
                        yield put({
                            type: actions_createOrder.Type.SET_SHIPING_COST,
                            payload: { shippingCost: shippingCost, idx: 0 }
                        });
                        yield put({
                            type: actions_createOrder.Type.SET_DELIVERY_NOTE,
                            payload: { deliveryNote: "promotion99", idx: 0 }
                        })
                        yield put({
                            type: actions_createOrder.Type.SET_PRICE_SPECIAL,
                            payload: { priceSpecial: 99, idx: 0 }
                        })
                        if (payload.idx != 0) {
                            const promoPrice = [{
                                deliveryPrice: 0,
                                discountPrice: 0
                            }]
                            yield put({
                                type: actions_createOrder.Type.SET_SHIPING_COST,
                                payload: { shippingCost: promoPrice, idx: payload.idx }
                            });
                            yield put({
                                type: actions_createOrder.Type.SET_DELIVERY_OPTION_SUCCESS,
                                payload: {
                                    deliveryOption: "SPECIAL",
                                    idx: payload.idx
                                }
                            })
                            yield put({
                                type: actions_createOrder.Type.SET_DELIVERY_NOTE,
                                payload: { deliveryNote: "promotion99", idx: payload.idx }
                            })
                            yield put({
                                type: actions_createOrder.Type.SET_PRICE_SPECIAL,
                                payload: { priceSpecial: "0", idx: payload.idx }
                            })
                        }
                    }
                }

                // }















                const outOfServiceArea = stateCreate.errors.calculateShipingcost.outOfServiceArea;
                if (outOfServiceArea) {
                    /// clear error outOfServiceArea
                    yield put({
                        type: actions_createOrder.Type.SET_ERROR_ADDRESS_OUT_OF_AREA,
                        payload: { idx: payload.idx, status: false }
                    });
                }
                // }
            }
            /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

            else {
                // yield put(actions_loading.openLoading());// open loading.
                yield put({
                    type: actions_createOrder.Type.CHECK_LIST_DATE_TIME_SUCCESS,
                    payload: {
                        data, idx: payload.idx, id: productId
                    }
                })
            }





            const stateCheckStock = yield select(getCreateOrder);
            if (stateCheckStock.orderShop[activeTab].checkShippingCosts.deliveryAt[0].dateDelivery != "") {
                const body = {
                    productId,
                    datePreorder: stateCheckStock.orderShop[stateCheckStock.activeTab].checkShippingCosts.deliveryAt[0].dateDelivery,
                    userId: stateCheckStock.userCustomer.id,
                    // idx: payload.idx
                }

                const { data } = yield call(api_productsCtrl.listTimeByProduct, body);
                yield put({
                    type: actions_createOrder.Type.CHECK_LIST_DATE_TIME_SUCCESS,
                    payload: {
                        data, idx: payload.idx, id: productId
                    }
                })

                if (data.stock.isLimit == true) {
                    if (data.stock.stock == 0) {
                        const listMenuSelect = stateCheckStock.orderShop[stateCheckStock.activeTab].listMenuSelect
                        if (listMenuSelect[productId]) {
                            const listMenuSelect_copy = { ...listMenuSelect };
                            delete listMenuSelect_copy[productId];
                            yield put({
                                type: actions_createOrder.Type.REMOVE_MENU_FROM_CART_SUCCESS,
                                payload: {
                                    ...payload,
                                    listMenuSelect: listMenuSelect_copy
                                }
                            });
                        }
                        yield put({
                            type: actions_notification.Type.OPEN_NOTIFICATION_ALERT,
                            payload: {
                                message: <div><img src={IconNotification} alt="IconNotification"></img><span className="ml-3"> เมนูหมดสต๊อก {payload.detailMenu.name.th.trim().length >= 27 ? (payload.detailMenu.name.th).slice(0, 27) + "..." : payload.detailMenu.name.th}</span></div>,
                                level: 'error',
                                autoDismiss: 3,
                                position: "tr"
                            }
                        });
                        if (Object.keys(listMenuSelect).length === 1) {
                            yield put({
                                type: actions_createOrder.Type.REMOVE_DELIVERY_AT_DATE_DELIVERY,
                                payload: {
                                    idx: payload.idx,
                                }
                            });
                            yield put({
                                type: actions_createOrder.Type.CLEAR_SEARCH_MENU,
                                payload: {
                                    idx: payload.idx
                                }
                            })
                        }

                        const { data } = yield call(api_menuCtrl.getListMenu, stateCreateOrder.orderShop[stateCreateOrder.activeTab].search);
                        yield put({
                            type: actions_menus.Type.GET_LIST_MENU_SUCCESS,
                            data
                        })

                    }
                }
            } else {
                ///////
            }





            yield put(actions_loading.closeLoading());// close loading.
        }


    } catch (error) {
        const errorData = (error.response && error.response.data) || null;
        const stateCreateOrder = yield select(getCreateOrder);
        const this_order = stateCreateOrder.orderShop[payload.idx];

        if (errorData && errorData.error && errorData.error.statusCode === 422) {
            const stateCreateOrder = yield select(getCreateOrder);
            const this_order = stateCreateOrder.orderShop[payload.idx];

            if (
                // this_order.deliveryOption === "SKOOTAR" && 
                this_order.deliveryOption !== "SPECIAL" &&
                this_order.deliveryOption !== "" &&
                errorData.error.name === "ERR_OUT_OF_SERVICE_AREA" || errorData.error.name === "DALIVERY_ERROR") {
                yield put({
                    type: actions_notification.Type.OPEN_NOTIFICATION_ALERT,
                    payload: {
                        message: <div><img src={IconNotification} alt="IconNotification"></img><span className="ml-3">การจัดส่งที่ {payload.idx + 1} ที่อยู่ลูกค้าอยู่นอกพื้นที่บริการ</span></div>,
                        level: 'error',
                        autoDismiss: 3,
                        position: "tr"
                    }
                });

                yield put({
                    type: actions_createOrder.Type.SET_ERROR_ADDRESS_OUT_OF_AREA,
                    payload: { idx: payload.idx, status: true }
                });

                yield put({
                    type: actions_logistics.Type.GET_LIST_LOGISTIC_SUCCESS,
                    payload: []
                });
                // dispatch(showModalAlert('ไม่สามารถสั่งอาหารได้ เนื่องจากอยู่นอกพื้นที่ให้บริการ', "Warning", "primary", "Close"));
            }

            if (errorData.error.name === "ERR_OUT_OF_SERVICE_AREA") {
                yield put({
                    type: actions_createOrder.Type.SET_LIST_LOGISTIC_SUCCESS,
                    payload: {
                        idx: payload.idx,
                        listLogistic: [{
                            logistic: {
                                code: "SPECIAL",
                                name: "special",
                                label: "ขนส่งพิเศษ",
                                type: "special",
                                id: "5e6b01a1ad7098122e134c92"
                            },
                            deliveryPrice: 0,
                            discountPrice: 0
                        }]
                    }
                })
                const this_order = stateCreateOrder.orderShop[payload.idx]
                if (payload.idx == 0) {
                    if (stateCreateOrder.orderShop[0].mainData.dataShop[0] && stateCreateOrder.orderShop[0].mainData.dataShop[0].hubId) {
                        const shippingCost = [{
                            deliveryPrice: 99,
                            discountPrice: 0
                        }]
                        yield put({
                            type: actions_createOrder.Type.SET_SHIPING_COST,
                            payload: { shippingCost: shippingCost, idx: 0 }
                        });
                        yield put({
                            type: actions_createOrder.Type.SET_DELIVERY_NOTE,
                            payload: { deliveryNote: "promotion99", idx: 0 }
                        })
                        yield put({
                            type: actions_createOrder.Type.SET_PRICE_SPECIAL,
                            payload: { priceSpecial: 99, idx: 0 }
                        })
                    } else {
                        yield put({
                            type: actions_createOrder.Type.SET_DELIVERY_NOTE,
                            payload: { deliveryNote: "", idx: 0 }
                        })
                        yield put({
                            type: actions_createOrder.Type.SET_PRICE_SPECIAL,
                            payload: { priceSpecial: "", idx: 0 }
                        })
                        const shippingCost = [{
                            deliveryPrice: 0,
                            discountPrice: 0
                        }]
                        yield put({
                            type: actions_createOrder.Type.SET_SHIPING_COST,
                            payload: { shippingCost: shippingCost, idx: 0 }
                        });
                    }
                }
                if (payload.idx != 0) {
                    if (stateCreateOrder.orderShop[0].mainData.dataShop[0] && stateCreateOrder.orderShop[0].mainData.dataShop[0].hubId && stateCreateOrder.orderShop[0].mainData.dataShop[0].hubId == this_order.mainData.dataShop[0].hubId) {
                        if (stateCreateOrder.orderShop[0].checkShippingCosts.deliveryAt[0].dateDelivery == this_order.checkShippingCosts.deliveryAt[0].dateDelivery) {
                            const promoPrice = [{
                                deliveryPrice: 0,
                                discountPrice: 0
                            }]
                            yield put({
                                type: actions_createOrder.Type.SET_SHIPING_COST,
                                payload: { shippingCost: promoPrice, idx: payload.idx }
                            });
                            yield put({
                                type: actions_createOrder.Type.SET_DELIVERY_OPTION_SUCCESS,
                                payload: {
                                    deliveryOption: "SPECIAL",
                                    idx: payload.idx
                                }
                            })
                            yield put({
                                type: actions_createOrder.Type.SET_DELIVERY_NOTE,
                                payload: { deliveryNote: "promotion99", idx: payload.idx }
                            })
                            yield put({
                                type: actions_createOrder.Type.SET_PRICE_SPECIAL,
                                payload: { priceSpecial: "0", idx: payload.idx }
                            })
                        } else {
                            yield put({
                                type: actions_createOrder.Type.SET_DELIVERY_NOTE,
                                payload: { deliveryNote: "", idx: payload.idx }
                            })
                            yield put({
                                type: actions_createOrder.Type.SET_PRICE_SPECIAL,
                                payload: { priceSpecial: "", idx: payload.idx }
                            })
                            const shippingCost = [{
                                deliveryPrice: 0,
                                discountPrice: 0
                            }]
                            yield put({
                                type: actions_createOrder.Type.SET_SHIPING_COST,
                                payload: { shippingCost: shippingCost, idx: payload.idx }
                            });
                        }
                    } else {
                        yield put({
                            type: actions_createOrder.Type.SET_DELIVERY_NOTE,
                            payload: { deliveryNote: "", idx: payload.idx }
                        })
                        yield put({
                            type: actions_createOrder.Type.SET_PRICE_SPECIAL,
                            payload: { priceSpecial: "", idx: payload.idx }
                        })
                        const shippingCost = [{
                            deliveryPrice: 0,
                            discountPrice: 0
                        }]
                        yield put({
                            type: actions_createOrder.Type.SET_SHIPING_COST,
                            payload: { shippingCost: shippingCost, idx: payload.idx }
                        });
                    }
                }

            }
            const checkStateCreateOrder = yield select(getCreateOrder);
            if (Object.keys(stateCreateOrder.orderShop[payload.idx].listMenuSelect).length == 0) {
                yield put({
                    type: actions_createOrder.Type.CLEAR_SEARCH_MENU,
                    payload: {
                        idx: checkStateCreateOrder.activeTab
                    }
                })

                yield put({
                    type: actions_createOrder.Type.REMOVE_DATA_WHEN_NONE_SHOP,
                    payload: {
                        idx: checkStateCreateOrder.activeTab,
                    }
                })
                const stateCreateOrder = yield select(getCreateOrder);
                const { data } = yield call(api_menuCtrl.getListMenu, stateCreateOrder.orderShop[stateCreateOrder.activeTab].search);
                yield put({
                    type: actions_menus.Type.GET_LIST_MENU_SUCCESS,
                    data
                })
                yield put({
                    type: actions_createOrder.Type.REMOVE_DELIVERY_AT_DATE_DELIVERY,
                    payload: {
                        idx: payload.idx,
                    }
                });
                yield put({
                    type: actions_createOrder.Type.SET_PRICE_SPECIAL,
                    payload: {
                        priceSpecial: "",
                        idx: payload.idx
                    }
                })
                yield put({
                    type: actions_createOrder.Type.SET_DELIVERY_OPTION_SUCCESS,
                    payload: {
                        deliveryOption: "",
                        idx: payload.idx
                    }
                })
            } else {
                yield put({
                    type: actions_createOrder.Type.SET_DELIVERY_OPTION_SUCCESS,
                    payload: {
                        deliveryOption: "SPECIAL",
                        idx: payload.idx
                    }
                })
            }
        }
        yield put(actions_loading.closeLoading());// open loading.
    }
}


function* getLatLngByAddress(action) {
    const { payload } = action;

    const { address } = payload;

    try {
        yield put(actions_loading.openLoading());// open loading.

        let concatAddress = ''

        if (address.address !== "") {
            concatAddress += address.address
        }
        if (address.subdistrict !== null) {
            concatAddress += " " + address.subdistrict.subdistrictName
        }
        if (address.district !== null) {
            concatAddress += " " + address.district.districtName
        }
        if (address.province !== null) {
            concatAddress += " " + address.province.provinceName
        }
        if (address.zipcode !== "") {
            concatAddress += " " + address.zipcode
        }

        if (concatAddress) { // check addres value null.
            const { data } = yield call(api_googleMapCtrl.getLatLngByAddress, { address: concatAddress });

            if (data.results.length > 0) {
                const stateCreateOrder = yield select(getCreateOrder);
                const this_order = stateCreateOrder.orderShop[payload.idx];
                const contactId = this_order.contactShop._id || null;
                const shopId = (this_order.mainData && this_order.mainData.shopId) || null;
                const location = data.results[0].geometry.location;




                const stateCreate = stateCreateOrder.orderShop[payload.idx];
                const shippingCost = stateCreate.checkShippingCosts.deliveryAt[0]
                const listProductId = Object.keys(stateCreate.listMenuSelect)
                const orders = []

                const orderlist = Object.keys(stateCreate.listMenuSelect).map((key, idx) => {

                    const dataOrder = {
                        productId: key,
                        quantity: stateCreate.listMenuSelect[key].quantity
                    }
                    orders.push(dataOrder)
                })

                if (contactId && shopId && address.address !== "" && address.subdistrict != null && address.district != null && address.province != null && location.lat && location.lng) {
                    const listProductId = Object.keys(stateCreateOrder.orderShop[0].listMenuSelect)

                    yield put({
                        type: actions_createOrder.Type.GET_LAT_LNG_BY_ADDRESS_SUCCESS,
                        payload: { location, idx: payload.idx }
                    });
                    const queryShippingCost = {
                        deliveryAt: {
                            location: [location.lat.toString(), location.lng.toString()],
                            address: address.address,
                            addressExtra: address.addressExtra,
                            subdistrict: address.subdistrict.subdistrictName,
                            district: address.district.districtName,
                            province: address.province.provinceName,
                            zipcode: address.zipcode,
                            typeCar: "MOTORCYCLE",
                            isBox: this_order.isBox
                        },
                        methodPayment: "transfer",
                        shopId: shopId,
                        contactId: contactId,
                        orders: orders
                    }

                    const { data } = yield call(api_invoicesCtrl.checkShippingCostNoOrder, { ...queryShippingCost });

                    yield put({
                        type: actions_logistics.Type.GET_LIST_LOGISTIC_SUCCESS,
                        payload: data
                    });
                    yield put({
                        type: actions_createOrder.Type.SET_LIST_LOGISTIC_SUCCESS,
                        payload: {
                            idx: payload.idx,
                            listLogistic: data
                        }
                    })
                    // if (stateCreate.deliveryOption != "SPECIAL") {
                    if (stateCreate.deliveryOption == "") {
                        const setDeliveryOption = data.filter((item) => item.logistic.name == "express")
                        if (setDeliveryOption.length > 0) {
                            yield put({
                                type: actions_createOrder.Type.SET_DELIVERY_OPTION_SUCCESS,
                                payload: {
                                    deliveryOption: setDeliveryOption[0].logistic.code,
                                    idx: payload.idx
                                }
                            })
                        } else {
                            yield put({
                                type: actions_createOrder.Type.SET_DELIVERY_OPTION_SUCCESS,
                                payload: {
                                    deliveryOption: "SPECIAL",
                                    idx: payload.idx
                                }
                            })
                        }
                    }

                    const stateCreateOrderSetShipping = yield select(getCreateOrder);
                    const stateSetShippingCost = stateCreateOrderSetShipping.orderShop[payload.idx];

                    const logisticPick = data.filter((item, idx) => item.logistic.code == stateSetShippingCost.deliveryOption)

                    yield put({
                        type: actions_createOrder.Type.SET_SHIPING_COST,
                        payload: { shippingCost: logisticPick, idx: payload.idx }
                    });
                    // }

                    const stateCheckPromotion = yield select(getCreateOrder);

                    if (stateCheckPromotion.orderShop[0].mainData.dataShop[0] && stateCheckPromotion.orderShop[0].mainData.dataShop[0].hubId) {
                        if (payload.idx == 0) {
                            yield put({
                                type: actions_createOrder.Type.SET_DELIVERY_OPTION_SUCCESS,
                                payload: {
                                    deliveryOption: "SPECIAL",
                                    idx: 0
                                }
                            })

                            const shippingCost = [{
                                deliveryPrice: 99,
                                discountPrice: 0
                            }]
                            yield put({
                                type: actions_createOrder.Type.SET_SHIPING_COST,
                                payload: {
                                    shippingCost: shippingCost,
                                    idx: 0
                                }
                            })

                            yield put({
                                type: actions_createOrder.Type.SET_DELIVERY_NOTE,
                                payload: { deliveryNote: "promotion99", idx: 0 }
                            })
                            yield put({
                                type: actions_createOrder.Type.SET_PRICE_SPECIAL,
                                payload: { priceSpecial: "99", idx: 0 }
                            })
                        }
                    } else {

                    }
                    for (let i = 1; i <= stateCheckPromotion.orderShop.length - 1; i++) {
                        const this_order = stateCheckPromotion.orderShop[i]
                        if (this_order.checkShippingCosts.deliveryAt[0].dateDelivery != "" && this_order.checkShippingCosts.deliveryAt[0].dateDelivery == stateCheckPromotion.orderShop[0].checkShippingCosts.deliveryAt[0].dateDelivery && this_order.mainData.dataShop[0].hubId) {
                            if ((this_order.mainData.dataShop[0].hubId == stateCheckPromotion.orderShop[0].mainData.dataShop[0].hubId) && this_order.mainData.dataShop[0].hubId) {
                                yield put({
                                    type: actions_createOrder.Type.SET_DELIVERY_OPTION_SUCCESS,
                                    payload: {
                                        deliveryOption: "SPECIAL",
                                        idx: 0
                                    }
                                })
                                const shippingCost = [{
                                    deliveryPrice: 99,
                                    discountPrice: 0
                                }]
                                yield put({
                                    type: actions_createOrder.Type.SET_SHIPING_COST,
                                    payload: { shippingCost: shippingCost, idx: 0 }
                                });
                                yield put({
                                    type: actions_createOrder.Type.SET_DELIVERY_NOTE,
                                    payload: { deliveryNote: "promotion99", idx: 0 }
                                })
                                yield put({
                                    type: actions_createOrder.Type.SET_PRICE_SPECIAL,
                                    payload: { priceSpecial: 99, idx: 0 }
                                })




                                const promoPrice = [{
                                    deliveryPrice: 0,
                                    discountPrice: 0
                                }]
                                yield put({
                                    type: actions_createOrder.Type.SET_SHIPING_COST,
                                    payload: { shippingCost: promoPrice, idx: parseInt(i) }
                                });
                                yield put({
                                    type: actions_createOrder.Type.SET_DELIVERY_OPTION_SUCCESS,
                                    payload: {
                                        deliveryOption: "SPECIAL",
                                        idx: parseInt(i)
                                    }
                                })
                                yield put({
                                    type: actions_createOrder.Type.SET_DELIVERY_NOTE,
                                    payload: { deliveryNote: "promotion99", idx: parseInt(i) }
                                })
                                yield put({
                                    type: actions_createOrder.Type.SET_PRICE_SPECIAL,
                                    payload: { priceSpecial: "0", idx: parseInt(i) }
                                })
                            } else {
                                const promoPrice = [{
                                    deliveryPrice: 0,
                                    discountPrice: 0
                                }]
                                yield put({
                                    type: actions_createOrder.Type.SET_SHIPING_COST,
                                    payload: { shippingCost: promoPrice, idx: parseInt(i) }
                                });
                                yield put({
                                    type: actions_createOrder.Type.SET_DELIVERY_OPTION_SUCCESS,
                                    payload: {
                                        deliveryOption: "SPECIAL",
                                        idx: parseInt(i)
                                    }
                                })
                                yield put({
                                    type: actions_createOrder.Type.SET_DELIVERY_NOTE,
                                    payload: { deliveryNote: "", idx: parseInt(i) }
                                })
                                yield put({
                                    type: actions_createOrder.Type.SET_PRICE_SPECIAL,
                                    payload: { priceSpecial: "", idx: parseInt(i) }
                                })
                            }
                        }
                    }
                    const outOfServiceArea = this_order.errors.calculateShipingcost.outOfServiceArea;
                    if (outOfServiceArea) {
                        /// clear error outOfServiceArea
                        yield put({
                            type: actions_createOrder.Type.SET_ERROR_ADDRESS_OUT_OF_AREA,
                            payload: { idx: payload.idx, status: false }
                        });
                    }

                } else {
                    yield put({
                        type: actions_notification.Type.OPEN_NOTIFICATION_ALERT,
                        payload: {
                            message: <div><img src={IconNotification} alt="IconNotification"></img><span className="ml-3">ยังไม่สามารถคำนวณค่าจัดส่งได้เนื่องจากข้อมูลไม่ครบ</span></div>,
                            level: 'error',
                            autoDismiss: 3,
                            position: "tr"
                        }
                    });
                }

                if (payload.idx === 0) {
                    const stateCreateOrder = yield select(getCreateOrder);
                    for (let i in stateCreateOrder.orderShop) {
                        const this_order = stateCreateOrder.orderShop[i]
                        if (Object.keys(stateCreateOrder.orderShop[i].listMenuSelect).length >= 1) {
                            if (stateCreateOrder.orderShop[i].isDefaultContactCustomer) { /// for check price shiping other tab check is default address.
                                const stateCreateOrders = yield select(getCreateOrder);
                                yield put({
                                    type: actions_createOrder.Type.COPY_DEFAULT_ADDRESS,
                                    payload: {
                                        idx: parseInt(i),
                                        province: stateCreateOrders.orderShop[0].checkShippingCosts.deliveryAt[0].province,
                                        district: stateCreateOrders.orderShop[0].checkShippingCosts.deliveryAt[0].district,
                                        subdistrict: stateCreateOrders.orderShop[0].checkShippingCosts.deliveryAt[0].subdistrict,
                                        zipcode: stateCreateOrders.orderShop[0].checkShippingCosts.deliveryAt[0].zipcode,
                                        address: stateCreateOrders.orderShop[0].checkShippingCosts.deliveryAt[0].address,
                                        addressExtra: stateCreateOrders.orderShop[0].checkShippingCosts.deliveryAt[0].addressExtra,
                                        location: stateCreateOrders.orderShop[0].checkShippingCosts.deliveryAt[0].location,
                                        listDistricts: stateCreateOrders.orderShop[0].listDistricts,
                                        listSubDistricts: stateCreateOrders.orderShop[0].listSubDistricts,
                                        // deliveryOption: stateCreateOrders.orderShop[0].deliveryOption,
                                        isAddressOnChange: stateCreateOrders.orderShop[0].isAddressOnChange,
                                        outOfServiceArea: stateCreateOrder.orderShop[0].errors.calculateShipingcost.outOfServiceArea,
                                        // listLogistic: stateCreateOrder.orderShop[0].listLogistic
                                    }
                                })
                                const stateCreate = stateCreateOrder.orderShop[payload.idx];
                                const shippingCost = stateCreate.checkShippingCosts.deliveryAt[0]
                                const listProductId = Object.keys(stateCreate.listMenuSelect)

                                const orders = []

                                const orderlist = Object.keys(this_order.listMenuSelect).map((key, idx) => {


                                    const dataOrder = {
                                        productId: key,
                                        quantity: this_order.listMenuSelect[key].quantity
                                    }
                                    orders.push(dataOrder)
                                })

                                if (contactId && shopId && address.address !== "" && address.subdistrict != null && address.district != null && address.province != null && address.location[0] && address.location[1]) {
                                    const listProductId = Object.keys(stateCreateOrder.orderShop[0].listMenuSelect)
                                    const queryShippingCost = {
                                        deliveryAt: {
                                            location: [location.lat.toString(), location.lng.toString()],
                                            address: address.address,
                                            addressExtra: address.addressExtra,
                                            subdistrict: address.subdistrict.subdistrictName,
                                            district: address.district.districtName,
                                            province: address.province.provinceName,
                                            zipcode: address.zipcode,
                                            typeCar: "MOTORCYCLE",
                                            isBox: address.isBox
                                        },
                                        methodPayment: "transfer",
                                        shopId: stateCreateOrders.orderShop[i].mainData._id,
                                        contactId: stateCreateOrders.orderShop[i].contactShop._id,
                                        orders: orders
                                    }
                                    const { data } = yield call(api_invoicesCtrl.checkShippingCostNoOrder, { ...queryShippingCost });

                                    yield put({
                                        type: actions_logistics.Type.GET_LIST_LOGISTIC_SUCCESS,
                                        payload: data
                                    });
                                    yield put({
                                        type: actions_createOrder.Type.SET_LIST_LOGISTIC_SUCCESS,
                                        payload: {
                                            idx: parseInt(i),
                                            listLogistic: data
                                        }
                                    })

                                    const logisticPick = data.filter((item, idx) => item.logistic.code == this_order.deliveryOption)

                                    if (this_order.deliveryOption != "SPECIAL") {
                                        yield put({
                                            type: actions_createOrder.Type.SET_SHIPING_COST,
                                            payload: { shippingCost: logisticPick, idx: parseInt(i) }
                                        });
                                    }
                                    if (this_order.deliveryOption == "SPECIAL") {
                                        const shipping = [{
                                            deliveryPrice: this_order.priceSpecial,
                                            discountPrice: 0
                                        }]
                                        yield put({
                                            type: actions_createOrder.Type.SET_SHIPING_COST,
                                            payload: { shippingCost: shipping, idx: parseInt(i) }
                                        });
                                    }
                                }
                            }
                        }

                    }
                }
                yield put(actions_loading.closeLoading());// open loading.

            }
            else {
                yield put({
                    type: actions_notification.Type.OPEN_NOTIFICATION_ALERT,
                    payload: {
                        message: <div><img src={IconNotification} alt="IconNotification"></img><span className="ml-3">ไม่สามารถค้นหาที่อยู่ได้</span></div>,
                        level: 'error',
                        autoDismiss: 3,
                        position: "tr"
                    }
                });

                yield put(actions_loading.closeLoading());// open loading.
            }
        }
        else {
            yield put({
                type: actions_notification.Type.OPEN_NOTIFICATION_ALERT,
                payload: {
                    message: <div><img src={IconNotification} alt="IconNotification"></img><span className="ml-3">ไม่สามารถค้นหาที่อยู่ได้</span></div>,
                    level: 'error',
                    autoDismiss: 3,
                    position: "tr"
                }
            });
        }
        yield put(actions_loading.closeLoading());// open loading.
    } catch (error) {
        console.log(error);
        const errorData = error.response.data;
        if (errorData.error && errorData.error.statusCode === 422) {
            const stateCreateOrder = yield select(getCreateOrder);
            const this_order = stateCreateOrder.orderShop[payload.idx];

            yield put({
                type: actions_createOrder.Type.SET_LIST_LOGISTIC_SUCCESS,
                payload: {
                    idx: 0,
                    listLogistic: [{
                        logistic: {
                            code: "SPECIAL",
                            name: "special",
                            label: "ขนส่งพิเศษ",
                            type: "special",
                            id: "5e6b01a1ad7098122e134c92"
                        },
                        deliveryPrice: 0,
                        discountPrice: 0
                    }]
                }
            })
            yield put({
                type: actions_createOrder.Type.SET_DELIVERY_OPTION_SUCCESS,
                payload: {
                    deliveryOption: "SPECIAL",
                    idx: 0
                }
            })

            if (payload.idx == 0) {
                if (stateCreateOrder.orderShop[0].mainData.dataShop[0] && stateCreateOrder.orderShop[0].mainData.dataShop[0].hubId) {

                    const shippingCost = [{
                        deliveryPrice: 99,
                        discountPrice: 0
                    }]
                    yield put({
                        type: actions_createOrder.Type.SET_SHIPING_COST,
                        payload: { shippingCost: shippingCost, idx: 0 }
                    });
                    yield put({
                        type: actions_createOrder.Type.SET_DELIVERY_NOTE,
                        payload: { deliveryNote: "promotion99", idx: 0 }
                    })
                    yield put({
                        type: actions_createOrder.Type.SET_PRICE_SPECIAL,
                        payload: { priceSpecial: 99, idx: 0 }
                    })
                } else {
                    const promoPrice = [{
                        deliveryPrice: 0,
                        discountPrice: 0
                    }]
                    yield put({
                        type: actions_createOrder.Type.SET_SHIPING_COST,
                        payload: { shippingCost: promoPrice, idx: payload.idx }
                    });
                    yield put({
                        type: actions_createOrder.Type.SET_DELIVERY_OPTION_SUCCESS,
                        payload: {
                            deliveryOption: "SPECIAL",
                            idx: payload.idx
                        }
                    })
                    yield put({
                        type: actions_createOrder.Type.SET_DELIVERY_NOTE,
                        payload: { deliveryNote: "", idx: payload.idx }
                    })
                    yield put({
                        type: actions_createOrder.Type.SET_PRICE_SPECIAL,
                        payload: { priceSpecial: "", idx: payload.idx }
                    })
                }
                for (let i = 1; i <= stateCreateOrder.orderShop.length - 1; i++) {
                    const this_order = stateCreateOrder.orderShop[i]
                    if (stateCreateOrder.orderShop[i].isDefaultContactCustomer) { /// for check price shiping other tab check is default address.
                        if (errorData.error.name === "ERR_OUT_OF_SERVICE_AREA") {
                            yield put({
                                type: actions_createOrder.Type.SET_LIST_LOGISTIC_SUCCESS,
                                payload: {
                                    idx: parseInt(i),
                                    listLogistic: [{
                                        logistic: {
                                            code: "SPECIAL",
                                            name: "special",
                                            label: "ขนส่งพิเศษ",
                                            type: "special",
                                            id: "5e6b01a1ad7098122e134c92"
                                        },
                                        deliveryPrice: 0,
                                        discountPrice: 0
                                    }]
                                }
                            })
                            yield put({
                                type: actions_createOrder.Type.SET_DELIVERY_OPTION_SUCCESS,
                                payload: {
                                    deliveryOption: "SPECIAL",
                                    idx: parseInt(i)
                                }
                            })

                            if (stateCreateOrder.orderShop[0].mainData.dataShop[0] && stateCreateOrder.orderShop[0].mainData.dataShop[0].hubId && stateCreateOrder.orderShop[0].mainData.dataShop[0].hubId == this_order.mainData.dataShop[0].hubId) {
                                if (stateCreateOrder.orderShop[0].checkShippingCosts.deliveryAt[0].dateDelivery == this_order.checkShippingCosts.deliveryAt[0].dateDelivery) {
                                    yield put({
                                        type: actions_createOrder.Type.SET_DELIVERY_OPTION_SUCCESS,
                                        payload: {
                                            deliveryOption: "SPECIAL",
                                            idx: 0
                                        }
                                    })
                                    const shippingCost = [{
                                        deliveryPrice: 99,
                                        discountPrice: 0
                                    }]
                                    yield put({
                                        type: actions_createOrder.Type.SET_SHIPING_COST,
                                        payload: { shippingCost: shippingCost, idx: 0 }
                                    });
                                    yield put({
                                        type: actions_createOrder.Type.SET_DELIVERY_NOTE,
                                        payload: { deliveryNote: "promotion99", idx: 0 }
                                    })
                                    yield put({
                                        type: actions_createOrder.Type.SET_PRICE_SPECIAL,
                                        payload: { priceSpecial: 99, idx: 0 }
                                    })

                                    const promoPrice = [{
                                        deliveryPrice: 0,
                                        discountPrice: 0
                                    }]
                                    yield put({
                                        type: actions_createOrder.Type.SET_SHIPING_COST,
                                        payload: { shippingCost: promoPrice, idx: parseInt(i) }
                                    });
                                    yield put({
                                        type: actions_createOrder.Type.SET_DELIVERY_OPTION_SUCCESS,
                                        payload: {
                                            deliveryOption: "SPECIAL",
                                            idx: parseInt(i)
                                        }
                                    })
                                    yield put({
                                        type: actions_createOrder.Type.SET_DELIVERY_NOTE,
                                        payload: { deliveryNote: "promotion99", idx: parseInt(i) }
                                    })
                                    yield put({
                                        type: actions_createOrder.Type.SET_PRICE_SPECIAL,
                                        payload: { priceSpecial: "0", idx: parseInt(i) }
                                    })
                                } else {
                                    const promoPrice = [{
                                        deliveryPrice: 0,
                                        discountPrice: 0
                                    }]
                                    yield put({
                                        type: actions_createOrder.Type.SET_SHIPING_COST,
                                        payload: { shippingCost: promoPrice, idx: parseInt(i) }
                                    });
                                    yield put({
                                        type: actions_createOrder.Type.SET_DELIVERY_OPTION_SUCCESS,
                                        payload: {
                                            deliveryOption: "SPECIAL",
                                            idx: parseInt(i)
                                        }
                                    })
                                    yield put({
                                        type: actions_createOrder.Type.SET_DELIVERY_NOTE,
                                        payload: { deliveryNote: "", idx: parseInt(i) }
                                    })
                                    yield put({
                                        type: actions_createOrder.Type.SET_PRICE_SPECIAL,
                                        payload: { priceSpecial: "", idx: parseInt(i) }
                                    })
                                }
                            } else {
                                const promoPrice = [{
                                    deliveryPrice: 0,
                                    discountPrice: 0
                                }]
                                yield put({
                                    type: actions_createOrder.Type.SET_SHIPING_COST,
                                    payload: { shippingCost: promoPrice, idx: parseInt(i) }
                                });
                                yield put({
                                    type: actions_createOrder.Type.SET_DELIVERY_OPTION_SUCCESS,
                                    payload: {
                                        deliveryOption: "SPECIAL",
                                        idx: parseInt(i)
                                    }
                                })
                                yield put({
                                    type: actions_createOrder.Type.SET_DELIVERY_NOTE,
                                    payload: { deliveryNote: "", idx: parseInt(i) }
                                })
                                yield put({
                                    type: actions_createOrder.Type.SET_PRICE_SPECIAL,
                                    payload: { priceSpecial: "", idx: parseInt(i) }
                                })
                            }
                        }
                    }
                }
            }

            if (payload.idx != 0) {
                const promoPrice = [{
                    deliveryPrice: 0,
                    discountPrice: 0
                }]
                yield put({
                    type: actions_createOrder.Type.SET_SHIPING_COST,
                    payload: { shippingCost: promoPrice, idx: payload.idx }
                });
                yield put({
                    type: actions_createOrder.Type.SET_DELIVERY_OPTION_SUCCESS,
                    payload: {
                        deliveryOption: "SPECIAL",
                        idx: payload.idx
                    }
                })
                yield put({
                    type: actions_createOrder.Type.SET_DELIVERY_NOTE,
                    payload: { deliveryNote: "", idx: payload.idx }
                })
                yield put({
                    type: actions_createOrder.Type.SET_PRICE_SPECIAL,
                    payload: { priceSpecial: "", idx: payload.idx }
                })
            }
            yield put(actions_loading.closeLoading());// close loading.
        } else {
            yield put({
                type: actions_notification.Type.OPEN_NOTIFICATION_ALERT,
                payload: {
                    message: <div><img src={IconNotification} alt="IconNotification"></img><span className="ml-3">เกิดข้อผิดพลาดในการค้นหาที่อยู่</span></div>,
                    level: 'error',
                    autoDismiss: 3,
                    position: "tr"
                }
            });
        }
        yield put(actions_loading.closeLoading());// close loading.
    }
}


function* setLatLng(action) {
    const { payload } = action;
    try {
        yield put(actions_loading.openLoading());// open loading.
        const stateCreateOrder = yield select(getCreateOrder);
        const this_order = stateCreateOrder.orderShop[payload.idx];
        const contactId = this_order.contactShop._id || null;
        const shopId = (this_order.mainData && this_order.mainData.shopId) || null;


        const deliveryAt = this_order.checkShippingCosts.deliveryAt[0];
        if (payload.idx == 0) {
            for (let i in stateCreateOrder.orderShop) {
                if (stateCreateOrder.orderShop[i].isDefaultContactCustomer) {
                    if (Object.keys(stateCreateOrder.orderShop[i].listMenuSelect).length >= 1) {
                        if (contactId && shopId && deliveryAt.address !== "" && deliveryAt.subdistrict !== null && deliveryAt.district !== null && deliveryAt.province !== null && payload.lat && payload.lng) {
                            yield put({
                                type: actions_createOrder.Type.SET_LAT_LNG_SUCCESS,
                                payload: {
                                    lat: payload.lat,
                                    lng: payload.lng,
                                    idx: parseInt(i)
                                }
                            });

                            const stateCreateOrder = yield select(getCreateOrder);
                            const this_orderDefault = stateCreateOrder.orderShop[i];
                            const listProductId = Object.keys(stateCreateOrder.orderShop[0].listMenuSelect)
                            const stateCreate = stateCreateOrder.orderShop[i];
                            const shippingCost = stateCreate.checkShippingCosts.deliveryAt[0]
                            // const this_orderTab = stateCreateOrder.orderShop[i];

                            const orders = []

                            const orderlist = Object.keys(stateCreate.listMenuSelect).map((key, idx) => {
                                const dataOrder = {
                                    productId: key,
                                    quantity: stateCreate.listMenuSelect[key].quantity
                                }
                                orders.push(dataOrder)
                            })



                            const queryShippingCost = {
                                deliveryAt: {
                                    location: [this_orderDefault.checkShippingCosts.deliveryAt[0].location[0].toString(), this_orderDefault.checkShippingCosts.deliveryAt[0].location[1].toString()],
                                    address: this_orderDefault.checkShippingCosts.deliveryAt[0].address,
                                    addressExtra: this_orderDefault.checkShippingCosts.deliveryAt[0].addressExtra,
                                    subdistrict: this_orderDefault.checkShippingCosts.deliveryAt[0].subdistrict.subdistrictName,
                                    district: this_orderDefault.checkShippingCosts.deliveryAt[0].district.districtName,
                                    province: this_orderDefault.checkShippingCosts.deliveryAt[0].province.provinceName,
                                    zipcode: this_orderDefault.checkShippingCosts.deliveryAt[0].zipcode,
                                    typeCar: "MOTORCYCLE",
                                    isBox: this_orderDefault.checkShippingCosts.deliveryAt[0].isBox
                                },
                                methodPayment: "transfer",
                                shopId: stateCreateOrder.orderShop[i].mainData._id,
                                contactId: stateCreateOrder.orderShop[i].contactShop._id,
                                orders: orders
                            }

                            const { data } = yield call(api_invoicesCtrl.checkShippingCostNoOrder, { ...queryShippingCost });
                            yield put({
                                type: actions_logistics.Type.GET_LIST_LOGISTIC_SUCCESS,
                                payload: data
                            });
                            yield put({
                                type: actions_createOrder.Type.SET_LIST_LOGISTIC_SUCCESS,
                                payload: {
                                    idx: parseInt(i),
                                    listLogistic: data
                                }
                            })

                            const logisticPick = data.filter((item, idx) => item.logistic.code == stateCreate.deliveryOption)
                            if (this_orderDefault.deliveryOption != "SPECIAL" && this_orderDefault.deliveryOption != "") {
                                yield put({
                                    type: actions_createOrder.Type.SET_SHIPING_COST,
                                    payload: { shippingCost: logisticPick, idx: parseInt(i) }
                                });
                            } else {
                                const shippingCost = [{
                                    deliveryPrice: this_orderDefault.priceSpecial,
                                    discountPrice: 0
                                }]
                                yield put({
                                    type: actions_createOrder.Type.SET_SHIPING_COST,
                                    payload: { shippingCost: shippingCost, idx: parseInt(i) }
                                });
                            }


                            const outOfServiceArea = this_order.errors.calculateShipingcost.outOfServiceArea;
                            if (outOfServiceArea) {
                                /// clear error outOfServiceArea
                                yield put({
                                    type: actions_createOrder.Type.SET_ERROR_ADDRESS_OUT_OF_AREA,
                                    payload: { idx: parseInt(i), status: false }
                                });
                            }

                            // } else if (this_orderDefault.deliveryOption === "CPIU") {
                            //     const data = [{
                            //         deliveryPrice: 0,
                            //         discountPrice: 0
                            //     }]
                            //     yield put({
                            //         type: actions_createOrder.Type.SET_SHIPING_COST,
                            //         payload: { shippingCost: data, idx: parseInt(i) }
                            //     });
                            // } else {
                            // }


                        } else {
                            yield put({
                                type: actions_notification.Type.OPEN_NOTIFICATION_ALERT,
                                payload: {
                                    message: <div><img src={IconNotification} alt="IconNotification"></img><span className="ml-3">ยังไม่สามารถคำนวณค่าจัดส่งได้เนื่องจากข้อมูลไม่ครบ</span></div>,
                                    level: 'error',
                                    autoDismiss: 3,
                                    position: "tr"
                                }
                            });
                        }
                    }
                }
            }
        } else {

            if (contactId && shopId && deliveryAt.address !== "" && deliveryAt.subdistrict !== null && deliveryAt.district !== null && deliveryAt.province !== null && payload.lat && payload.lng) {
                yield put({
                    type: actions_createOrder.Type.SET_LAT_LNG_SUCCESS,
                    payload: {
                        lat: payload.lat,
                        lng: payload.lng,
                        idx: payload.idx
                    }
                });

                const stateCreateOrder = yield select(getCreateOrder);
                const this_orderDefault = stateCreateOrder.orderShop[payload.idx];
                const listProductId = Object.keys(stateCreateOrder.orderShop[0].listMenuSelect)
                const stateCreate = stateCreateOrder.orderShop[payload.idx];
                const shippingCost = stateCreate.checkShippingCosts.deliveryAt[0]
                // const this_orderTab = stateCreateOrder.orderShop[i];

                const orders = []

                const orderlist = Object.keys(stateCreate.listMenuSelect).map((key, idx) => {
                    const dataOrder = {
                        productId: key,
                        quantity: stateCreate.listMenuSelect[key].quantity
                    }
                    orders.push(dataOrder)
                })



                const queryShippingCost = {
                    deliveryAt: {
                        location: [this_orderDefault.checkShippingCosts.deliveryAt[0].location[0].toString(), this_orderDefault.checkShippingCosts.deliveryAt[0].location[1].toString()],
                        address: this_orderDefault.checkShippingCosts.deliveryAt[0].address,
                        addressExtra: this_orderDefault.checkShippingCosts.deliveryAt[0].addressExtra,
                        subdistrict: this_orderDefault.checkShippingCosts.deliveryAt[0].subdistrict.subdistrictName,
                        district: this_orderDefault.checkShippingCosts.deliveryAt[0].district.districtName,
                        province: this_orderDefault.checkShippingCosts.deliveryAt[0].province.provinceName,
                        zipcode: this_orderDefault.checkShippingCosts.deliveryAt[0].zipcode,
                        typeCar: "MOTORCYCLE",
                        isBox: this_orderDefault.checkShippingCosts.deliveryAt[0].isBox
                    },
                    methodPayment: "transfer",
                    shopId: stateCreateOrder.orderShop[payload.idx].mainData._id,
                    contactId: stateCreateOrder.orderShop[payload.idx].contactShop._id,
                    orders: orders
                }

                const { data } = yield call(api_invoicesCtrl.checkShippingCostNoOrder, { ...queryShippingCost });
                yield put({
                    type: actions_logistics.Type.GET_LIST_LOGISTIC_SUCCESS,
                    payload: data
                });
                yield put({
                    type: actions_createOrder.Type.SET_LIST_LOGISTIC_SUCCESS,
                    payload: {
                        idx: payload.idx,
                        listLogistic: data
                    }
                })

                const logisticPick = data.filter((item, idx) => item.logistic.code == stateCreate.deliveryOption)
                if (this_order.deliveryOption != "SPECIAL" && this_order.deliveryOption != "") {
                    yield put({
                        type: actions_createOrder.Type.SET_SHIPING_COST,
                        payload: { shippingCost: logisticPick, idx: payload.idx }
                    });
                } else {
                    const shippingCost = [{
                        deliveryPrice: this_orderDefault.priceSpecial,
                        discountPrice: 0
                    }]
                    yield put({
                        type: actions_createOrder.Type.SET_SHIPING_COST,
                        payload: { shippingCost: shippingCost, idx: payload.idx }
                    });
                }


                const outOfServiceArea = this_order.errors.calculateShipingcost.outOfServiceArea;
                if (outOfServiceArea) {
                    /// clear error outOfServiceArea
                    yield put({
                        type: actions_createOrder.Type.SET_ERROR_ADDRESS_OUT_OF_AREA,
                        payload: { idx: payload.idx, status: false }
                    });
                }

                // } else if (this_orderDefault.deliveryOption === "CPIU") {
                //     const data = [{
                //         deliveryPrice: 0,
                //         discountPrice: 0
                //     }]
                //     yield put({
                //         type: actions_createOrder.Type.SET_SHIPING_COST,
                //         payload: { shippingCost: data, idx: parseInt(i) }
                //     });
                // } else {
                // }


            } else {
                yield put({
                    type: actions_notification.Type.OPEN_NOTIFICATION_ALERT,
                    payload: {
                        message: <div><img src={IconNotification} alt="IconNotification"></img><span className="ml-3">ยังไม่สามารถคำนวณค่าจัดส่งได้เนื่องจากข้อมูลไม่ครบ</span></div>,
                        level: 'error',
                        autoDismiss: 3,
                        position: "tr"
                    }
                });
            }


        }



        yield put(actions_loading.closeLoading());// close loading.

    } catch (error) {
        const errorData = error.response.data;
        if (errorData.error && errorData.error.statusCode === 422) {
            const stateCreateOrder = yield select(getCreateOrder);
            const this_order = stateCreateOrder.orderShop[payload.idx];
            if (errorData.error.name == "ERR_OUT_OF_SERVICE_AREA") {
                yield put({
                    type: actions_notification.Type.OPEN_NOTIFICATION_ALERT,
                    payload: {
                        message: <div><img src={IconNotification} alt="IconNotification"></img><span className="ml-3">ที่อยู่ลูกค้าอยู่นอกพื้นที่ขนส่ง</span></div>,
                        level: 'error',
                        autoDismiss: 3,
                        position: "tr"
                    }
                });
            }

            if (payload.idx == 0) {
                if (stateCreateOrder.orderShop[0].mainData.dataShop[0] && stateCreateOrder.orderShop[0].mainData.dataShop[0].hubId) {
                    yield put({
                        type: actions_createOrder.Type.SET_LIST_LOGISTIC_SUCCESS,
                        payload: {
                            idx: 0,
                            listLogistic: [{
                                logistic: {
                                    code: "SPECIAL",
                                    name: "special",
                                    label: "ขนส่งพิเศษ",
                                    type: "special",
                                    id: "5e6b01a1ad7098122e134c92"
                                },
                                deliveryPrice: 0,
                                discountPrice: 0
                            }]
                        }
                    })
                    yield put({
                        type: actions_createOrder.Type.SET_DELIVERY_OPTION_SUCCESS,
                        payload: {
                            deliveryOption: "SPECIAL",
                            idx: 0
                        }
                    })
                    const shippingCost = [{
                        deliveryPrice: 99,
                        discountPrice: 0
                    }]
                    yield put({
                        type: actions_createOrder.Type.SET_SHIPING_COST,
                        payload: { shippingCost: shippingCost, idx: 0 }
                    });
                    yield put({
                        type: actions_createOrder.Type.SET_DELIVERY_NOTE,
                        payload: { deliveryNote: "promotion99", idx: 0 }
                    })
                    yield put({
                        type: actions_createOrder.Type.SET_PRICE_SPECIAL,
                        payload: { priceSpecial: 99, idx: 0 }
                    })
                } else {

                }
                for (let i = 1; i <= stateCreateOrder.orderShop.length - 1; i++) {
                    const this_order = stateCreateOrder.orderShop[i]
                    if (stateCreateOrder.orderShop[i].isDefaultContactCustomer) { /// for check price shiping other tab check is default address.
                        if (errorData.error.name === "ERR_OUT_OF_SERVICE_AREA") {
                            yield put({
                                type: actions_createOrder.Type.SET_LIST_LOGISTIC_SUCCESS,
                                payload: {
                                    idx: parseInt(i),
                                    listLogistic: [{
                                        logistic: {
                                            code: "SPECIAL",
                                            name: "special",
                                            label: "ขนส่งพิเศษ",
                                            type: "special",
                                            id: "5e6b01a1ad7098122e134c92"
                                        },
                                        deliveryPrice: 0,
                                        discountPrice: 0
                                    }]
                                }
                            })
                            yield put({
                                type: actions_createOrder.Type.SET_DELIVERY_OPTION_SUCCESS,
                                payload: {
                                    deliveryOption: "SPECIAL",
                                    idx: parseInt(i)
                                }
                            })

                            if (stateCreateOrder.orderShop[0].mainData.dataShop[0] && stateCreateOrder.orderShop[0].mainData.dataShop[0].hubId && stateCreateOrder.orderShop[0].mainData.dataShop[0].hubId == this_order.mainData.dataShop[0].hubId) {
                                if (stateCreateOrder.orderShop[0].checkShippingCosts.deliveryAt[0].dateDelivery == this_order.checkShippingCosts.deliveryAt[0].dateDelivery) {
                                    yield put({
                                        type: actions_createOrder.Type.SET_DELIVERY_OPTION_SUCCESS,
                                        payload: {
                                            deliveryOption: "SPECIAL",
                                            idx: 0
                                        }
                                    })
                                    const shippingCost = [{
                                        deliveryPrice: 99,
                                        discountPrice: 0
                                    }]
                                    yield put({
                                        type: actions_createOrder.Type.SET_SHIPING_COST,
                                        payload: { shippingCost: shippingCost, idx: 0 }
                                    });
                                    yield put({
                                        type: actions_createOrder.Type.SET_DELIVERY_NOTE,
                                        payload: { deliveryNote: "promotion99", idx: 0 }
                                    })
                                    yield put({
                                        type: actions_createOrder.Type.SET_PRICE_SPECIAL,
                                        payload: { priceSpecial: 99, idx: 0 }
                                    })

                                    const promoPrice = [{
                                        deliveryPrice: 0,
                                        discountPrice: 0
                                    }]
                                    yield put({
                                        type: actions_createOrder.Type.SET_SHIPING_COST,
                                        payload: { shippingCost: promoPrice, idx: parseInt(i) }
                                    });
                                    yield put({
                                        type: actions_createOrder.Type.SET_DELIVERY_OPTION_SUCCESS,
                                        payload: {
                                            deliveryOption: "SPECIAL",
                                            idx: parseInt(i)
                                        }
                                    })
                                    yield put({
                                        type: actions_createOrder.Type.SET_DELIVERY_NOTE,
                                        payload: { deliveryNote: "promotion99", idx: parseInt(i) }
                                    })
                                    yield put({
                                        type: actions_createOrder.Type.SET_PRICE_SPECIAL,
                                        payload: { priceSpecial: "0", idx: parseInt(i) }
                                    })
                                } else {
                                    const promoPrice = [{
                                        deliveryPrice: 0,
                                        discountPrice: 0
                                    }]
                                    yield put({
                                        type: actions_createOrder.Type.SET_SHIPING_COST,
                                        payload: { shippingCost: promoPrice, idx: parseInt(i) }
                                    });
                                    yield put({
                                        type: actions_createOrder.Type.SET_DELIVERY_OPTION_SUCCESS,
                                        payload: {
                                            deliveryOption: "SPECIAL",
                                            idx: parseInt(i)
                                        }
                                    })
                                    yield put({
                                        type: actions_createOrder.Type.SET_DELIVERY_NOTE,
                                        payload: { deliveryNote: "", idx: parseInt(i) }
                                    })
                                    yield put({
                                        type: actions_createOrder.Type.SET_PRICE_SPECIAL,
                                        payload: { priceSpecial: "", idx: parseInt(i) }
                                    })
                                }
                            } else {
                                const promoPrice = [{
                                    deliveryPrice: 0,
                                    discountPrice: 0
                                }]
                                yield put({
                                    type: actions_createOrder.Type.SET_SHIPING_COST,
                                    payload: { shippingCost: promoPrice, idx: parseInt(i) }
                                });
                                yield put({
                                    type: actions_createOrder.Type.SET_DELIVERY_OPTION_SUCCESS,
                                    payload: {
                                        deliveryOption: "SPECIAL",
                                        idx: parseInt(i)
                                    }
                                })
                                yield put({
                                    type: actions_createOrder.Type.SET_DELIVERY_NOTE,
                                    payload: { deliveryNote: "", idx: parseInt(i) }
                                })
                                yield put({
                                    type: actions_createOrder.Type.SET_PRICE_SPECIAL,
                                    payload: { priceSpecial: "", idx: parseInt(i) }
                                })
                            }
                        }
                    }
                }
            }

            if (payload.idx != 0) {
                const promoPrice = [{
                    deliveryPrice: 0,
                    discountPrice: 0
                }]
                yield put({
                    type: actions_createOrder.Type.SET_SHIPING_COST,
                    payload: { shippingCost: promoPrice, idx: payload.idx }
                });
                yield put({
                    type: actions_createOrder.Type.SET_DELIVERY_OPTION_SUCCESS,
                    payload: {
                        deliveryOption: "SPECIAL",
                        idx: payload.idx
                    }
                })
                yield put({
                    type: actions_createOrder.Type.SET_DELIVERY_NOTE,
                    payload: { deliveryNote: "", idx: payload.idx }
                })
                yield put({
                    type: actions_createOrder.Type.SET_PRICE_SPECIAL,
                    payload: { priceSpecial: "", idx: payload.idx }
                })
            } else {
                // if (this_order.deliveryOption != "SPECIAL" &&
                //     this_order.deliveryOption != "" &&
                //     errorData.error.name === "ERR_OUT_OF_SERVICE_AREA" || errorData.error.name === "DALIVERY_ERROR") {
                //     yield put({
                //         type: actions_notification.Type.OPEN_NOTIFICATION_ALERT,
                //         payload: {
                //             message: <div><img src={IconNotification} alt="IconNotification"></img><span className="ml-3">การจัดส่งที่ {payload.idx + 1} ที่อยู่ลูกค้าอยู่นอกพื้นที่บริการ</span></div>,
                //             level: 'error',
                //             autoDismiss: 3,
                //             position: "tr"
                //         }
                //     });
                //     yield put({
                //         type: actions_createOrder.Type.SET_ERROR_ADDRESS_OUT_OF_AREA,
                //         payload: { idx: payload.idx, status: true }
                //     });
                // }
                // dispatch(showModalAlert('ไม่สามารถสั่งอาหารได้ เนื่องจากอยู่นอกพื้นที่ให้บริการ', "Warning", "primary", "Close"));
                const data = [{
                    deliveryPrice: this_order.priceSpecial,
                    discountPrice: 0
                }]
                yield put({
                    type: actions_createOrder.Type.SET_SHIPING_COST,
                    payload: { shippingCost: data, idx: parseInt(payload.idx) }
                });
                // dispatch(showModalAlert('ไม่สามารถสั่งอาหารได้ เนื่องจากอยู่นอกพื้นที่ให้บริการ', "Warning", "primary", "Close"));
                yield put({
                    type: actions_createOrder.Type.SET_LIST_LOGISTIC_SUCCESS,
                    payload: {
                        idx: payload.idx,
                        listLogistic: [{
                            logistic: {
                                code: "SPECIAL",
                                name: "special",
                                label: "ขนส่งพิเศษ",
                                type: "special",
                                id: "5e6b01a1ad7098122e134c92"
                            },
                            deliveryPrice: 0,
                            discountPrice: 0
                        }]
                    }
                })
            }
            yield put(actions_loading.closeLoading());// close loading.
        } else {
            yield put({
                type: actions_notification.Type.OPEN_NOTIFICATION_ALERT,
                payload: {
                    message: <div><img src={IconNotification} alt="IconNotification"></img><span className="ml-3">เกิดข้อผิดพลาดในการค้นหาที่อยู่</span></div>,
                    level: 'error',
                    autoDismiss: 3,
                    position: "tr"
                }
            });
        }
        yield put(actions_loading.closeLoading());// close loading.
    }
}


function* setActiveTab(action) {
    const { payload } = action;
    try {
        yield put(actions_loading.openLoading());// open loading.
        yield put({
            type: actions_createOrder.Type.SET_ACTIVE_TAB_SUCCESS,
            payload: { ...payload }
        });
        const stateCreateOrder = yield select(getCreateOrder); // get value search.

        const { data } = yield call(api_menuCtrl.getListMenu, stateCreateOrder.orderShop[stateCreateOrder.activeTab].search);
        yield put({
            type: actions_menus.Type.GET_LIST_MENU_SUCCESS, data
        });
        yield put(actions_loading.closeLoading());// close loading.
    } catch (error) {
        console.log(error);
        yield put(actions_loading.closeLoading());// close loading.
    }
}

function* checkSpecialCode(action) {
    const { payload } = action;
    try {
        const stateCreateOrder = yield select(getCreateOrder);
        const order = stateCreateOrder.orderShop;
        const isCodeAlready = order.filter((item, idx) => {
            if (idx !== payload.idx) {
                if (item.updateInvoiceById.priceSpecialDiscount) {
                    if (item.updateInvoiceById.priceSpecialDiscount.code === payload.code) {
                        return item
                    }
                }
            }
            return null
        });
        if (isCodeAlready.length > 0) { // code Already.
            yield put({
                type: actions_createOrder.Type.CHECK_SPECIAL_CODE_SUCCCESS,
                payload: {
                    idx: payload.idx,
                    status: true,
                    encode: null
                }
            });
        } else {
            const { encode } = yield call(api_promotionCtrl.checkSpecialCode, { code: payload.code });
            yield put({
                type: actions_createOrder.Type.CHECK_SPECIAL_CODE_SUCCCESS,
                payload: {
                    idx: payload.idx,
                    status: false,
                    encode
                }
            });
        }

    } catch (error) {
        yield put({
            type: actions_createOrder.Type.CHECK_SPECIAL_CODE_SUCCCESS,
            payload: {
                idx: payload.idx,
                status: true,
                encode: null
            }
        });
    }
}


function* setContactShop(action) {
    const { payload } = action;
    try {
        yield put(actions_loading.openLoading());// open loading.
        yield put({
            type: actions_createOrder.Type.SET_CONTACT_SHOP_SUCCESS,
            payload: {
                ...payload
            }
        });



        const stateCreateOrder = yield select(getCreateOrder);
        const this_order = stateCreateOrder.orderShop[payload.idx];
        const shopId = (this_order.mainData && this_order.mainData.shopId) || null;
        const contactId = payload.contactShop._id;
        const deliveryAt = this_order.checkShippingCosts.deliveryAt[0];
        const listProductId = Object.keys(stateCreateOrder.orderShop[0].listMenuSelect)
        const stateCreate = stateCreateOrder.orderShop[payload.idx];
        const shippingCost = stateCreate.checkShippingCosts.deliveryAt[0]
        // const listProductId = Object.keys(stateCreate.listMenuSelect)
        const orders = []
        const orderlist = Object.keys(stateCreate.listMenuSelect).map((key, idx) => {
            const dataOrder = {
                productId: key,
                quantity: stateCreate.listMenuSelect[key].quantity
            }
            orders.push(dataOrder)
        })


        if (contactId && shopId && deliveryAt.address !== "" && deliveryAt.subdistrict !== null && deliveryAt.district !== null && deliveryAt.province !== null && deliveryAt.location[0] && deliveryAt.location[1]) {
            const queryShippingCost = {
                deliveryAt: {
                    location: [deliveryAt.location[0].toString(), deliveryAt.location[1].toString()],
                    address: deliveryAt.address,
                    addressExtra: deliveryAt.addressExtra,
                    subdistrict: deliveryAt.subdistrict.subdistrictName,
                    district: deliveryAt.district.districtName,
                    province: deliveryAt.province.provinceName,
                    zipcode: deliveryAt.zipcode,
                    typeCar: "MOTORCYCLE",
                    isBox: this_order.isBox
                },
                methodPayment: "transfer",
                shopId: shopId,
                contactId: contactId,
                orders: orders
            }


            const { data } = yield call(api_invoicesCtrl.checkShippingCostNoOrder, { ...queryShippingCost });
            yield put({
                type: actions_logistics.Type.GET_LIST_LOGISTIC_SUCCESS,
                payload: data
            });
            yield put({
                type: actions_createOrder.Type.SET_LIST_LOGISTIC_SUCCESS,
                payload: {
                    idx: payload.idx,
                    listLogistic: data
                }
            })
            if (stateCreate.deliveryOption != "SPECIAL") {
                const logisticPick = data.filter((item, idx) => item.logistic.code == stateCreate.deliveryOption)
                yield put({
                    type: actions_createOrder.Type.SET_SHIPING_COST,
                    payload: { shippingCost: logisticPick, idx: payload.idx }
                });
            }

            const outOfServiceArea = this_order.errors.calculateShipingcost.outOfServiceArea;
            if (outOfServiceArea) {
                /// clear error outOfServiceArea
                yield put({
                    type: actions_createOrder.Type.SET_ERROR_ADDRESS_OUT_OF_AREA,
                    payload: { idx: payload.idx, status: false }
                });
            }
        } else {
            yield put({
                type: actions_notification.Type.OPEN_NOTIFICATION_ALERT,
                payload: {
                    message: <div><img src={IconNotification} alt="IconNotification"></img><span className="ml-3">กรุณาคำนวณค่าจัดส่งใหม่</span></div>,
                    level: 'error',
                    autoDismiss: 3,
                    position: "tr"
                }
            });
        }
        yield put(actions_loading.closeLoading());// close loading.

    } catch (error) {
        const errorData = error.response.data;
        if (errorData.error && errorData.error.statusCode === 422) {
            const stateCreateOrder = yield select(getCreateOrder);
            const this_order = stateCreateOrder.orderShop[payload.idx];

            if (this_order.deliveryOption !== "SPECIAL" && this_order.deliveryOption != "" && errorData.error.name === "ERR_OUT_OF_SERVICE_AREA" || errorData.error.name === "DALIVERY_ERROR") {
                yield put({
                    type: actions_notification.Type.OPEN_NOTIFICATION_ALERT,
                    payload: {
                        message: <div><img src={IconNotification} alt="IconNotification"></img><span className="ml-3">การจัดส่งที่ {payload.idx + 1} ที่อยู่ลูกค้าอยู่นอกพื้นที่บริการ</span></div>,
                        level: 'error',
                        autoDismiss: 3,
                        position: "tr"
                    }
                });
                yield put({
                    type: actions_createOrder.Type.SET_ERROR_ADDRESS_OUT_OF_AREA,
                    payload: { idx: payload.idx, status: true }
                });
                // dispatch(showModalAlert('ไม่สามารถสั่งอาหารได้ เนื่องจากอยู่นอกพื้นที่ให้บริการ', "Warning", "primary", "Close"));
            }
        }
        yield put(actions_loading.closeLoading());// close loading.
    }
}

function* setStockFromMenuList(action) {
    const { payload } = action;
    const body = {
        userId: payload.userId,
        productId: payload.id,
        datePreorder: payload.date
    }
    try {
        yield put(actions_loading.openLoading());// open loading.
        const { data } = yield call(api_productsCtrl.listTimeByProduct, body)
        const stateCreateOrder = yield select(getCreateOrder);
        const activeTab = stateCreateOrder.activeTab;
        const this_orderShop = stateCreateOrder.orderShop[activeTab];
        const listMenuSelect = this_orderShop.listMenuSelect;
        const productId = payload.id;
        const quantity = (listMenuSelect[productId].quantity);
        const this_order = listMenuSelect[productId];
        const stock = listMenuSelect[payload.id].listStockByDateTime.stock.stock;


        if (payload.type == "add") {
            if (quantity < stock || !listMenuSelect[payload.id].listStockByDateTime.stock.isLimit) {
                if (this_order.error.statusCreate && this_order.orderSuccess) { // check order create success.
                    const dataUpdateQuantity = {
                        orderId: this_order.orderSuccess.id,
                        invoiceId: this_order.orderSuccess.invoiceId,
                        quantity: quantity + 1,
                        typeOrder: 'preorder'
                    }
                    yield call(api_ordersCtrl.updateOrderQuatlity, dataUpdateQuantity)
                }

                //////////////////////////////ON_ADD_QUANTITY_MENU_SUCCESS/////////////////////////////////////////////////////////////////ON_ADD_QUANTITY_MENU_SUCCESS///////////////////////////////////
                yield put({
                    type: actions_createOrder.Type.ON_ADD_QUANTITY_MENU_SUCCESS,
                    payload
                });
                const stateStockOrder = yield select(getCreateOrder);
                const stateCreate = stateStockOrder.orderShop[payload.idx];
                const shippingCost = stateCreate.checkShippingCosts.deliveryAt[0]
                const listProductId = Object.keys(stateCreate.listMenuSelect)
                const orders = []

                const orderlist = Object.keys(stateCreate.listMenuSelect).map((key, idx) => {

                    const dataOrder = {
                        productId: key,
                        quantity: stateCreate.listMenuSelect[key].quantity
                    }
                    orders.push(dataOrder)
                })
                const shopId = (stateCreate.mainData && stateCreate.mainData.shopId) || null;
                const contactId = stateCreate.contactShop._id;
                if (contactId && shopId && shippingCost.address !== "" && shippingCost.subdistrict !== null && shippingCost.district !== null && shippingCost.province !== null && shippingCost.location[0] && shippingCost.location[1]) {
                    const queryShippingCost = {
                        deliveryAt: {
                            location: [shippingCost.location[0].toString(), shippingCost.location[1].toString()],
                            address: shippingCost.address,
                            addressExtra: shippingCost.addressExtra,
                            subdistrict: shippingCost.subdistrict.subdistrictName,
                            district: shippingCost.district.districtName,
                            province: shippingCost.province.provinceName,
                            zipcode: shippingCost.zipcode,
                            typeCar: "MOTORCYCLE",
                            isBox: stateCreate.isBox
                        },
                        methodPayment: "transfer",
                        shopId: shopId,
                        contactId: contactId,
                        // productId: listProductId
                        orders: orders
                    }

                    // if (stateCreate.deliveryOption !== "KERRY") {
                    const { data } = yield call(api_invoicesCtrl.checkShippingCostNoOrder, { ...queryShippingCost });

                    yield put({
                        type: actions_logistics.Type.GET_LIST_LOGISTIC_SUCCESS,
                        payload: data
                    });
                    yield put({
                        type: actions_createOrder.Type.SET_LIST_LOGISTIC_SUCCESS,
                        payload: {
                            idx: payload.idx,
                            listLogistic: data
                        }
                    })

                    const logisticPick = data.filter((item, idx) => item.logistic.code == stateCreate.deliveryOption)

                    if (this_orderShop.deliveryOption == "SPECIAL") {
                        const shippingCost = [{
                            deliveryPrice: this_orderShop.priceSpecial,
                            discountPrice: 0
                        }]
                        yield put({
                            type: actions_createOrder.Type.SET_SHIPING_COST,
                            payload: { shippingCost: shippingCost, idx: payload.idx }
                        });
                    } else {
                        yield put({
                            type: actions_createOrder.Type.SET_SHIPING_COST,
                            payload: { shippingCost: logisticPick, idx: payload.idx }
                        });
                    }

                    const outOfServiceArea = stateCreate.errors.calculateShipingcost.outOfServiceArea;
                    if (outOfServiceArea) {
                        /// clear error outOfServiceArea
                        yield put({
                            type: actions_createOrder.Type.SET_ERROR_ADDRESS_OUT_OF_AREA,
                            payload: { idx: payload.idx, status: false }
                        });
                    }
                    // }
                }
                ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
                ////ON_ADD_QUANTITY_MENU_SUCCESS/////////////////////////////////////////////////////////////////ON_ADD_QUANTITY_MENU_SUCCESS///////////////////////////////////
            }
            yield put(actions_loading.closeLoading());// close loading.
        } else if (payload.type == "reduce") {
            if (quantity > 1 || (!listMenuSelect[payload.id].listStockByDateTime.stock.isLimit && quantity < 1)) {
                if (this_order.error.statusCreate && this_order.orderSuccess) { // check order create success.
                    const dataUpdateQuantity = {
                        orderId: this_order.orderSuccess.id,
                        invoiceId: this_order.orderSuccess.invoiceId,
                        quantity: quantity - 1,
                        typeOrder: 'preorder'
                    }

                    yield call(api_ordersCtrl.updateOrderQuatlity, dataUpdateQuantity)
                }
                yield put({
                    type: actions_createOrder.Type.ON_REDUCE_QUANTITY_MENU_SUCCESS,
                    payload
                })

                const stateStockOrder = yield select(getCreateOrder);
                const stateCreate = stateStockOrder.orderShop[payload.idx];
                const shippingCost = stateCreate.checkShippingCosts.deliveryAt[0]
                const listProductId = Object.keys(stateCreate.listMenuSelect)
                const orders = []

                const orderlist = Object.keys(stateCreate.listMenuSelect).map((key, idx) => {

                    const dataOrder = {
                        productId: key,
                        quantity: stateCreate.listMenuSelect[key].quantity
                    }
                    orders.push(dataOrder)
                })
                const shopId = (stateCreate.mainData && stateCreate.mainData.shopId) || null;
                const contactId = stateCreate.contactShop._id;
                if (contactId && shopId && shippingCost.address !== "" && shippingCost.subdistrict !== null && shippingCost.district !== null && shippingCost.province !== null && shippingCost.location[0] && shippingCost.location[1]) {
                    const queryShippingCost = {
                        deliveryAt: {
                            location: [shippingCost.location[0].toString(), shippingCost.location[1].toString()],
                            address: shippingCost.address,
                            addressExtra: shippingCost.addressExtra,
                            subdistrict: shippingCost.subdistrict.subdistrictName,
                            district: shippingCost.district.districtName,
                            province: shippingCost.province.provinceName,
                            zipcode: shippingCost.zipcode,
                            typeCar: "MOTORCYCLE",
                            isBox: stateCreate.isBox
                        },
                        methodPayment: "transfer",
                        shopId: shopId,
                        contactId: contactId,
                        // productId: listProductId
                        orders: orders
                    }

                    // if (stateCreate.deliveryOption !== "KERRY") {
                    const { data } = yield call(api_invoicesCtrl.checkShippingCostNoOrder, { ...queryShippingCost });

                    yield put({
                        type: actions_logistics.Type.GET_LIST_LOGISTIC_SUCCESS,
                        payload: data
                    });
                    yield put({
                        type: actions_createOrder.Type.SET_LIST_LOGISTIC_SUCCESS,
                        payload: {
                            idx: payload.idx,
                            listLogistic: data
                        }
                    })

                    const logisticPick = data.filter((item, idx) => item.logistic.code == stateCreate.deliveryOption)
                    if (this_orderShop.deliveryOption == "SPECIAL") {
                        const shippingCost = [{
                            deliveryPrice: this_orderShop.priceSpecial,
                            discountPrice: 0
                        }]
                        yield put({
                            type: actions_createOrder.Type.SET_SHIPING_COST,
                            payload: { shippingCost: shippingCost, idx: payload.idx }
                        });
                    } else {
                        yield put({
                            type: actions_createOrder.Type.SET_SHIPING_COST,
                            payload: { shippingCost: logisticPick, idx: payload.idx }
                        });
                    }

                    const outOfServiceArea = stateCreate.errors.calculateShipingcost.outOfServiceArea;
                    if (outOfServiceArea) {
                        /// clear error outOfServiceArea
                        yield put({
                            type: actions_createOrder.Type.SET_ERROR_ADDRESS_OUT_OF_AREA,
                            payload: { idx: payload.idx, status: false }
                        });
                    }
                    // }
                }


            }
            yield put(actions_loading.closeLoading());// close loading.
        }
    } catch (error) {
        yield put(actions_loading.closeLoading());// close loading.
    }
}


function* mainCreateOrder(action) {
    const { payload } = action;
    let globalOrderIndex = 0;
    try {
        const stateCreateOrder = yield select(getCreateOrder);
        const { orderShop, userChat, listAddressCustomer, userCustomer } = stateCreateOrder;
        let isCreateOrderSuccess = true;
        let token_payment = '';
        const orderSuccess = []
        const orderError = []
        for (let i in orderShop) {
            globalOrderIndex = i;
            const res_createOrder = yield call(api_ordersCtrl.managecreateOrder, orderShop[i], stateCreateOrder);
            if (res_createOrder.orderError.length > 0) {
                orderError.push(res_createOrder.orderError[0])
            }

            if (res_createOrder.orderSuccess.length > 0) {
                orderSuccess.push(res_createOrder.orderSuccess[0])
                yield put({
                    type: actions_createOrder.Type.SET_MENU_CREATE_ORDER,
                    payload: {
                        listMenuSelect: res_createOrder.objectOrder,
                        idx: parseInt(i)
                    }
                });
            }

            const stateCreate = yield select(getCreateOrder);
            if (res_createOrder.orderError.length > 0 || orderError.length > 0) {
                yield* stateCreate.orderShop.map(function* (item, idx) {
                    yield* Object.keys(item.listMenuSelect).map(function* (key, index) {
                        if (item.listMenuSelect[key] && item.listMenuSelect[key].orderSuccess) {
                            yield put({
                                type: actions_createOrder.Type.DELETE_ORDER_MULTI_REQIURE,
                                payload: {
                                    orderSuccess: [item.listMenuSelect[key].orderSuccess],
                                    idx: parseInt(idx),
                                    clear: true
                                }
                            });
                        }
                    })
                })
                // yield put({
                //     type: actions_createOrder.Type.DELETE_ORDER_MULTI_REQIURE,
                //     payload: {
                //         orderSuccess: orderSuccess,
                //         idx: parseInt(i),
                //         clear: true
                //     }
                // });
                isCreateOrderSuccess = false
                payload.success && payload.success();
            }

            if (res_createOrder.statusCreateOrder && res_createOrder.orderSuccess && res_createOrder.orderSuccess.length > 0) {
                const res_createShippingCosts = yield call(api_ordersCtrl.createShippingCosts, { order: orderShop[i], stateCreateOrder, orderSuccess: res_createOrder.orderSuccess });
                if (res_createShippingCosts && res_createShippingCosts.data) {
                    /// add api  update last address user.
                    const res_updateInvoiceById = yield call(api_ordersCtrl.updateInvoiceById, { order: orderShop[i], orderSuccess: res_createOrder.orderSuccess });
                    if (res_updateInvoiceById && res_updateInvoiceById.data) {
                        const res_updateInvoiceByUserId = yield call(api_ordersCtrl.updateInvoiceByUserId, { stateCreateOrder });
                        if (res_updateInvoiceByUserId && res_updateInvoiceByUserId.data) {
                            token_payment = res_updateInvoiceByUserId.data.token;
                            // const invoiceId = res_createOrder.orderSuccess[0].invoiceId;
                            // const invoice = res_updateInvoiceByUserId.data.invoice.find(item => item.id === invoiceId);
                            // const order = invoice.order;
                            // const listMenuSelect = res_createOrder.objectOrder;
                            // let newListMenuSelect = {};
                            // let isUpdateInvocieByUserIdError = false;
                            // for (let j in order) {
                            //     const this_order = order[j];
                            //     const productId = this_order.product.id;
                            //     if (listMenuSelect[productId]) {
                            //         if (this_order.status.isFail) {
                            //             isUpdateInvocieByUserIdError = true;
                            //             newListMenuSelect = {
                            //                 ...newListMenuSelect,
                            //                 [productId]: {
                            //                     ...listMenuSelect[productId],
                            //                     error: {
                            //                         ...listMenuSelect[productId].error,
                            //                         isErrorUpdateInvoice: true,
                            //                         messageError: this_order.status.name,
                            //                     }
                            //                 }
                            //             }

                            //             // yield put({
                            //             //     type: actions_notification.Type.OPEN_NOTIFICATION_ALERT,
                            //             //     payload: {
                            //             //         message: <div><img src={IconNotification} alt="IconNotification"></img>
                            //             //             <span className="ml-3">การจัดส่งที่ {parseInt(i) + 1} เมนู {this_order.product.name.th || this_order.product.name.en} {ustils.showMessageError(this_order.status.name)}</span>
                            //             //         </div>,
                            //             //         level: 'error',
                            //             //         autoDismiss: 0,
                            //             //         position: "tr"
                            //             //     }
                            //             // });
                            //         } else {
                            //             newListMenuSelect = {
                            //                 ...newListMenuSelect,
                            //                 [productId]: {
                            //                     ...listMenuSelect[productId],
                            //                     error: {
                            //                         ...listMenuSelect[productId].error,
                            //                     }
                            //                 }
                            //             }
                            //         }
                            //     }
                            // }

                            // if (isUpdateInvocieByUserIdError) { // fail
                            //     isCreateOrderSuccess = false;
                            //     yield put({
                            //         type: actions_createOrder.Type.SET_MENU_CREATE_ORDER,
                            //         payload: {
                            //             listMenuSelect: newListMenuSelect,
                            //             idx: parseInt(i)
                            //         }
                            //     });
                            // }
                        } else { // error updateInvoiceByUserId.
                            // isCreateOrderSuccess = false;
                            yield put({
                                type: actions_createOrder.Type.DELETE_ORDER_MULTI_REQIURE,
                                payload: {
                                    orderSuccess: res_createOrder.orderSuccess,
                                    idx: parseInt(i)
                                }
                            });
                        }
                    } else { // error updateInvoiceById.
                        // isCreateOrderSuccess = false;
                        yield put({
                            type: actions_createOrder.Type.DELETE_ORDER_MULTI_REQIURE,
                            payload: {
                                orderSuccess: res_createOrder.orderSuccess,
                                idx: parseInt(i)
                            }
                        });
                    }
                } else { // error createShippingCosts.
                    // isCreateOrderSuccess = false;
                    yield put({
                        type: actions_createOrder.Type.DELETE_ORDER_MULTI_REQIURE,
                        payload: {
                            orderSuccess: res_createOrder.orderSuccess,
                            idx: parseInt(i)
                        }
                    });
                }
            } else { // error create order
                isCreateOrderSuccess = false;
                const orderError = res_createOrder.orderError;
                // const orderSuccess = res_createOrder.orderSuccess

                for (let j in orderError) {
                    const this_order = orderError[j];

                    if (!this_order.error.statusCreate) {
                        yield put({
                            type: actions_notification.Type.OPEN_NOTIFICATION_ALERT,
                            payload: {
                                message: <div><img src={IconNotification} alt="IconNotification"></img>
                                    <span className="ml-3">การจัดส่งที่ {parseInt(i) + 1} เมนู {this_order.product.name.th || this_order.product.name.en} {ustils.showMessageError(this_order.error.messageError)}</span>
                                </div>,
                                level: 'error',
                                autoDismiss: 0,
                                position: "tr"
                            }
                        });
                    }
                }
                /// 
            }
        }

        // const checkTimesendMsg = ustils.checkTimeBetween("15:00:00", "18:00:00")
        // if(checkTimesendMsg){
        // }
        if (isCreateOrderSuccess) {
            if (stateCreateOrder.sendMsg) {
                const message = yield call(api_ordersCtrl.createForMatMessage, orderShop, userChat, token_payment, stateCreateOrder.userChat.platform);

                if (message.message.length <= 2000) {
                    const data = {
                        message: message.message,
                        id: userChat.id,
                        type: "text",
                        platform: stateCreateOrder.userChat.platform,
                        sender: Cookies.get('userName')
                    }
                    const res_sendMessage = yield call(chatCtrl.sendDataToLine_Promise, data);
                    payload.success && payload.success();
                    if (res_sendMessage.data === "Done" && userChat.platform == "line") {
                        yield call(chatCtrl.pushBtnPaymentToLine, {
                            lineId: userChat.id
                        });
                    } else {
                        // yield call(chatCtrl.pushBtnPaymentToLine, {
                        //     lineId: userChat.id
                        // });
                    }
                } else {
                    const lastSecondaryItem = message.messageSplit[message.messageSplit.length - 2].toString()
                    const lastItem = message.messageSplit[message.messageSplit.length - 1].toString()
                    const summary = lastSecondaryItem + lastItem
                    const fisrtItem = message.messageSplit[0]

                    for (let i = 0; i < message.messageSplit.length - 2; i++) {
                        const data = {
                            message: message.messageSplit[i],
                            id: userChat.id,
                            type: "text",
                            platform: stateCreateOrder.userChat.platform,
                            sender: Cookies.get('userName')
                        }
                        const res_sendMessage = yield call(chatCtrl.sendDataToLine_Promise, data);
                    }
                    const data = {
                        message: summary,
                        id: userChat.id,
                        type: "text",
                        platform: stateCreateOrder.userChat.platform,
                        sender: Cookies.get('userName')
                    }
                    const res_sendMessage = yield call(chatCtrl.sendDataToLine_Promise, data);

                    payload.success && payload.success();
                    if (res_sendMessage.data === "Done" && userChat.platform == "line") {
                        yield call(chatCtrl.pushBtnPaymentToLine, {
                            lineId: userChat.id
                        });
                    }
                }
            }


            /// create address user ////
            const { userCustomer } = stateCreateOrder;
            const res_addressUser = yield call(api_addressUsersCtrl.getAddressUsers, {
                userId: userCustomer.id
            });

            let addressUser = null;

            if (res_addressUser && res_addressUser.data.length === 0) {
                const deliveryAt_userDefault = orderShop[0].checkShippingCosts.deliveryAt[0];
                const data_AddressUser = {
                    address: deliveryAt_userDefault.address,
                    addressExtra: deliveryAt_userDefault.addressExtra,
                    subdistrict: deliveryAt_userDefault.subdistrict.subdistrictName,
                    district: deliveryAt_userDefault.district.districtName,
                    province: deliveryAt_userDefault.province.provinceName,
                    location: [deliveryAt_userDefault.location[0].toString(), deliveryAt_userDefault.location[1].toString()],
                    zipcode: deliveryAt_userDefault.zipcode.toString(),
                    name: userCustomer.displayName,
                    mobile: userCustomer.mobile,
                    userId: userCustomer.id
                }

                const res_createAddressUer = yield call(api_addressUsersCtrl.createAddressUser, {
                    ...data_AddressUser
                });
                addressUser = res_createAddressUer.data;
            }
            /// create address user ////



            yield put(actions_menus.getListMenu({ menu: "", shop: "", date: "", time: "" }));
            yield put({
                type: actions_createOrder.Type.CONFIRM_SEND_DATA_SUCCESS,
                payload: {
                    addressUser
                }
            });
            yield put({
                type: actions_createOrder.Type.SET_LOADDING_CREATE_ORDER
            });
            payload.success && payload.success();
            yield put({
                type: actions_modal.Type.OPEN_MODAL_ALERT,
                payload: {
                    isOpen: true,
                    title: "",
                    content: "สร้างออเดอร์ ให้ลูกค้าสำเร็จ",
                    textbutton: "ตกลง",
                    status: "success"
                }
            });
        } else {
            yield put({
                type: actions_createOrder.Type.SET_LOADDING_CREATE_ORDER
            });
            yield put({
                type: actions_modal.Type.OPEN_MODAL_ALERT,
                payload: {
                    isOpen: true,
                    title: "",
                    content: "พบปัญหา ไม่สามารถสร้างออเดอร์ได้ กรุณาลองใหม่อีกครั้ง",
                    textbutton: "ตกลง",
                    status: "failed"
                }
            });
        }





    } catch (error) {
        const errorData = (error.response && error.response.data) || null;
        if (errorData && errorData.error && errorData.error.statusCode === 422) {

            if (errorData.error.message === "ERR_INVALID_SCHEDULE_TIME") {
                yield put({
                    type: actions_notification.Type.OPEN_NOTIFICATION_ALERT,
                    payload: {
                        message: <div><img src={IconNotification} alt="IconNotification"></img><span className="ml-3">การจัดส่งที่ {parseInt(globalOrderIndex) + 1} กรุณาเลือกเวลารับอาหารใหม่ให้มากกว่าเวลาที่เลือกไว้</span></div>,
                        level: 'error',
                        autoDismiss: 0,
                        position: "tr"
                    }
                });
            }
        }
        yield put({
            type: actions_createOrder.Type.SET_LOADDING_CREATE_ORDER
        });
        console.log(error);
    }
}


function* deleteOrdertMulti(action) {
    const { payload } = action;
    try {
        yield put(actions_loading.openLoading());// open loading.
        const { idx, orderSuccess } = payload;
        const stateCreateOrder = yield select(getCreateOrder);
        const { orderShop, userChat, listAddressCustomer, userCustomer } = stateCreateOrder;
        if (orderSuccess.length === 0) {
            return;
        }
        for (let i in orderSuccess) {
            const this_order = orderSuccess[i];
            const data = {
                orderId: this_order.id,
                invoiceId: this_order.invoiceId
            }
            yield call(api_ordersCtrl.deleteOrdertMulti, data);
        }

        if (parseInt(idx) === 0) {
            yield put({
                type: actions_createOrder.Type.CLEAR_DATA_ORDER_DEFAULT,
                payload: {
                    idx: parseInt(idx)
                }
            });
        } else {
            yield put({
                type: actions_createOrder.Type.REMOVE_TAB,
                payload: {
                    idx: parseInt(idx)
                }
            });
        }
        // yield put({
        //     type: actions_notification.Type.OPEN_NOTIFICATION_ALERT,
        //     payload: {
        //         message: <div><img src={IconNotification} alt="IconNotification"></img>
        //             <span className="ml-3">การจัดส่งที่ {parseInt(idx) + 1} เกิดข้อผิดพลาดในการสร้างออเดอร์ </span>
        //         </div>,
        //         level: 'error',
        //         autoDismiss: 0,
        //         position: "tr"
        //     }
        // });


        if (payload.clear) {
            yield put({
                type: actions_createOrder.Type.CLEAR_DATA_ORDER_DEFAULT,
                payload: {
                    idx: parseInt(0)
                }
            });
            if (orderShop.length > 1) {
                for (var i = orderShop.length - 1; i > 0; i--) {
                    yield put({
                        type: actions_createOrder.Type.REMOVE_TAB,
                        payload: {
                            idx: parseInt(i)
                        }
                    });
                }
            }
            const res_getAddressUser = yield call(api_addressUsersCtrl.getAddressUsers, {
                userId: stateCreateOrder.userCustomer.id
            });

            const addressUser = res_getAddressUser.data.find(item => item && item.no === 1)

            //// get list option ////
            const res_getAllAddressByName = yield call(api_addressCtrl.getAllAddressName, {
                provinceName: (addressUser && addressUser.province) || null,
                districtName: (addressUser && addressUser.district) || null,
                subdistrictName: (addressUser && addressUser.subdistrict) || null
            });
            const listDistricts = [];
            const listSubDistricts = [];
            if (res_getAllAddressByName.list_province && res_getAllAddressByName.list_districts) {

                const res_getListDistricts = yield call(api_addressCtrl.getListDistricts, { provinceId: res_getAllAddressByName.list_province.provinceId });
                const res_getListSubDistricts = yield call(api_addressCtrl.getListSubDistricts, { districtId: res_getAllAddressByName.list_districts.districtId });

                listDistricts.push(
                    ...res_getListDistricts.data
                );

                listSubDistricts.push(
                    ...res_getListSubDistricts.data
                );
            }

            yield put(actions_menus.getListMenu({ menu: "", shop: "", date: "", time: "" }));
            const { data } = yield call(api_menuCtrl.getListMenu, stateCreateOrder.orderShop[0].search);
            yield put({
                type: actions_menus.Type.GET_LIST_MENU_SUCCESS,
                data
            })

            yield put({
                type: actions_createOrder.Type.SET_DATA_NEW_USER_SUCCESS,
                payload: {
                    userChat: stateCreateOrder.userChat,
                    userCustomer: stateCreateOrder.userCustomer,
                    // location: (location && location.results[0] && location.results[0].geometry && location.results[0].geometry.location) || null,
                    addressUser: addressUser || null,
                    listAddressUserOption: res_getAllAddressByName,
                    listDistricts: listDistricts || null,
                    listSubDistricts: listSubDistricts || null
                }
            });
        }
        yield put(actions_loading.closeLoading());// open loading.
    } catch (error) {
        console.log(error);
    }
}


function* setDataNewUser(action) {

    const { payload } = action;
    try {

        if (!payload.user || !payload.user.name || !payload.user.mobile || !payload.user.id) {
            history.push('/');
            yield put({
                type: actions_notification.Type.OPEN_NOTIFICATION_ALERT,
                payload: {
                    message: <div><img src={IconNotification} alt="IconNotification"></img>
                        <span className="ml-3">ข้อมูลลูกค้าไม่สมบูรณ์</span>
                    </div>,
                    level: 'error',
                    autoDismiss: 0,
                    position: "tr"
                }
            });
            return
        }
        let res_createUser = null;
        const platform = payload.user.platform;
        switch (platform) {
            case 'line':
                const dataLine = {
                    displayName: payload.user.name,
                    mobile: payload.user.mobile,
                    lineId: payload.user.id
                };
                res_createUser = yield call(api_usersCtrl.autoUserByLineId, dataLine);
                break;
            case 'mobile':
                const dataMobile = {
                    displayName: payload.user.name,
                    mobile: payload.user.mobile,
                };
                res_createUser = yield call(api_usersCtrl.autoUserByMobile, dataMobile);
                break;
            case 'facebook':
                const dataFaceBook = {
                    displayName: payload.user.name,
                    mobile: payload.user.mobile,
                    lineId: payload.user.id
                };
                res_createUser = yield call(api_usersCtrl.autoUserByLineId, dataFaceBook);
                break;
            default:
                history.push('/');
                yield put({
                    type: actions_notification.Type.OPEN_NOTIFICATION_ALERT,
                    payload: {
                        message: <div><img src={IconNotification} alt="IconNotification"></img>
                            <span className="ml-3">ข้อมูลลูกค้าไม่สมบูรณ์</span>
                        </div>,
                        level: 'error',
                        autoDismiss: 0,
                        position: "tr"
                    }
                });
                break;
        }
        // const userId = res_createUser.data.id;

        // const res_listInvoiceByUserId = yield call(api_invoicesCtrl.listInvoiceByUserId, userId, "wait")



        const res_getAddressUser = yield call(api_addressUsersCtrl.getAddressUsers, {
            userId: res_createUser.data.id
        });

        const addressUser = res_getAddressUser.data.find(item => item && item.no === 1)

        //// get list option ////
        const res_getAllAddressByName = yield call(api_addressCtrl.getAllAddressName, {
            provinceName: (addressUser && addressUser.province) || null,
            districtName: (addressUser && addressUser.district) || null,
            subdistrictName: (addressUser && addressUser.subdistrict) || null
        });
        const listDistricts = [];
        const listSubDistricts = [];
        if (res_getAllAddressByName.list_province && res_getAllAddressByName.list_districts) {

            const res_getListDistricts = yield call(api_addressCtrl.getListDistricts, { provinceId: res_getAllAddressByName.list_province.provinceId });
            const res_getListSubDistricts = yield call(api_addressCtrl.getListSubDistricts, { districtId: res_getAllAddressByName.list_districts.districtId });

            listDistricts.push(
                ...res_getListDistricts.data
            );

            listSubDistricts.push(
                ...res_getListSubDistricts.data
            );
            // yield put(actions_address.getListDistricts({ provinceId: res_getAllAddressByName.list_province.provinceId }));
            // yield put(actions_address.getListSubDistricts({ districtId: res_getAllAddressByName.list_districts.districtId }));
        }
        //// get list option ////



        // const res_getLatLngByAddress = yield call(api_googleMapCtrl.getLatLngByAddress, {
        //     address: payload.user.address
        // });

        // TO DO
        // const location = res_getLatLngByAddress.data;

        yield put({
            type: actions_createOrder.Type.SET_DATA_NEW_USER_SUCCESS,
            payload: {
                userChat: payload.user,
                userCustomer: res_createUser.data,
                // location: (location && location.results[0] && location.results[0].geometry && location.results[0].geometry.location) || null,
                addressUser: addressUser || null,
                listAddressUserOption: res_getAllAddressByName,
                listDistricts: listDistricts || null,
                listSubDistricts: listSubDistricts || null
            }
        });
    } catch (error) {
        history.push('/');
        yield put({
            type: actions_notification.Type.OPEN_NOTIFICATION_ALERT,
            payload: {
                message: <div><img src={IconNotification} alt="IconNotification"></img>
                    <span className="ml-3">ข้อมูลลูกค้าไม่สมบูรณ์</span>
                </div>,
                level: 'error',
                autoDismiss: 0,
                position: "tr"
            }
        });
        console.log(error);

    }
}





function* setDateDelivery(action) {
    const { payload } = action

    try {
        yield put(actions_loading.openLoading());// open loading.
        const stateDateCreateOrder = yield select(getCreateOrder);
        const userCustomer = stateDateCreateOrder.userCustomer.id


        if (stateDateCreateOrder.orderShop[payload.idx].checkShippingCosts.deliveryAt[0].dateDelivery != payload.date) {
            yield put({
                type: actions_createOrder.Type.SET_TIME_DELIVERY_AT_SUCCESS,
                payload
            })

            const stateCreateOrder = yield select(getCreateOrder);

            const activeTab = stateCreateOrder.activeTab
            const listStockByDateTime = stateCreateOrder.orderShop[activeTab].listMenuSelect

            yield* Object.keys(listStockByDateTime).map(function* (key, idx) {
                yield put(actions_loading.openLoading());// open loading.
                const body = {
                    productId: listStockByDateTime[key].product.dataProduct[0]._id,
                    datePreorder: stateCreateOrder.orderShop[activeTab].checkShippingCosts.deliveryAt[0].dateDelivery,
                    userId: userCustomer
                }

                const { data } = yield call(api_productsCtrl.listTimeByProduct, body)
                yield put({
                    type: actions_createOrder.Type.CHECK_LIST_DATE_TIME_SUCCESS,
                    payload: {
                        data,
                        id: body.productId,
                        idx: activeTab
                    }
                })
            })

            ///set quantity 1
            yield* Object.keys(listStockByDateTime).map(function* (key, idx) {
                if (listStockByDateTime[key].quantity != 1) {
                    // yield put(actions_loading.openLoading());// open loading.
                    yield put({
                        type: actions_createOrder.Type.SET_QUANTITY_DEFAULT,
                        payload: {
                            id: listStockByDateTime[key].product.dataProduct[0]._id,
                            list: listStockByDateTime[key],
                            idx: activeTab,
                            quantity: 1
                        }
                    })
                }
            })
            ///set quantity 1

            const stateDateTime = yield select(getCreateOrder);

            const dateDelivery = moment(stateDateTime.orderShop[payload.idx].checkShippingCosts.deliveryAt[0].dateDelivery).format('DD-MM-YYYY HH:mm')
            const listMenuDate = []
            const dateListMenu = Object.values(listStockByDateTime).map(function (item) {
                const dateTimeMerge = []
                item.listStockByDateTime.timeDefault.map(item => dateTimeMerge.push(item))
                item.listStockByDateTime.timeDefault.map(time =>
                    item.listStockByDateTime.date.map(dateTime => dateTimeMerge.push(api_menuCtrl.mergeDateTime(dateTime, time))
                    )
                )

                const menuList = dateTimeMerge.map(date => moment(date).format('DD-MM-YYYY HH:mm') === dateDelivery ? true : false)

                if (menuList.every(item => item == false)) {
                    return listMenuDate.push(item.product.dataProduct[0]._id)
                } else {
                    return true
                }
            })
            const listMenuSelect = { ...stateDateTime.orderShop[payload.idx].listMenuSelect }

            yield* listMenuDate.map(function* (menu) {
                if (listMenuSelect[menu]) {
                    delete listMenuSelect[menu]
                }
                const nameMenu = stateDateTime.orderShop[payload.idx].listMenuSelect[menu].product.name.th
                yield put({
                    type: actions_notification.Type.OPEN_NOTIFICATION_ALERT,
                    payload: {
                        message: <div><img src={IconNotification} alt="IconNotification"></img><span className="ml-3"> เมนูหมดสต๊อก {nameMenu.trim().length >= 27 ? (nameMenu).slice(0, 27) + "..." : nameMenu}</span></div>,
                        level: 'error',
                        autoDismiss: 3,
                        position: "tr"
                    }
                });


            })
            yield put({
                type: actions_createOrder.Type.REMOVE_MENU_FROM_CART_SUCCESS,
                payload: {
                    idx: payload.idx,
                    listMenuSelect
                }
            })

            // yield put({
            //     type: actions_createOrder.Type.CLEAR_SEARCH_MENU,
            //     payload: {
            //         idx: payload.idx
            //     }
            // })





            const stateCheckPromotion = yield select(getCreateOrder);
            const promotion99 = []

            // if(payload.idx == 0 && stateCheckPromotion.orderShop[0].mainData.dataShop[0].hubId) {
            //     yield put({
            //         type: actions_createOrder.Type.SET_DELIVERY_OPTION_SUCCESS,
            //         payload: {
            //             deliveryOption: "SPECIAL",
            //             idx: 0
            //         }
            //     })
            //     const shippingCost = [{
            //         deliveryPrice: "99",
            //         discountPrice: 0
            //     }]
            //     yield put({
            //         type: actions_createOrder.Type.SET_SHIPING_COST,
            //         payload: { shippingCost: shippingCost, idx: 0 }
            //     });
            //     yield put({
            //         type: actions_createOrder.Type.SET_DELIVERY_NOTE,
            //         payload: { deliveryNote: "promotion99", idx: 0 }
            //     })
            //     yield put({
            //         type: actions_createOrder.Type.SET_PRICE_SPECIAL,
            //         payload: { priceSpecial: "99", idx: 0 }
            //     })
            // }


            for (let i = 1; i <= stateCheckPromotion.orderShop.length - 1; i++) {
                const this_order = stateCheckPromotion.orderShop[i]

                if (this_order.checkShippingCosts.deliveryAt[0].dateDelivery != "" && this_order.checkShippingCosts.deliveryAt[0].dateDelivery == stateCheckPromotion.orderShop[0].checkShippingCosts.deliveryAt[0].dateDelivery && this_order.mainData.dataShop[0].hubId) {
                    if (this_order.mainData.dataShop[0].hubId == stateCheckPromotion.orderShop[0].mainData.dataShop[0].hubId && this_order.mainData.dataShop[0].hubId) {

                        promotion99.push(this_order.mainData.shopId)
                        yield put({
                            type: actions_createOrder.Type.SET_DELIVERY_OPTION_SUCCESS,
                            payload: {
                                deliveryOption: "SPECIAL",
                                idx: 0
                            }
                        })
                        const shippingCost = [{
                            deliveryPrice: "99",
                            discountPrice: 0
                        }]
                        yield put({
                            type: actions_createOrder.Type.SET_SHIPING_COST,
                            payload: { shippingCost: shippingCost, idx: 0 }
                        });
                        yield put({
                            type: actions_createOrder.Type.SET_DELIVERY_NOTE,
                            payload: { deliveryNote: "promotion99", idx: 0 }
                        })
                        yield put({
                            type: actions_createOrder.Type.SET_PRICE_SPECIAL,
                            payload: { priceSpecial: "99", idx: 0 }
                        })




                        const promoPrice = [{
                            deliveryPrice: 0,
                            discountPrice: 0
                        }]
                        yield put({
                            type: actions_createOrder.Type.SET_SHIPING_COST,
                            payload: { shippingCost: promoPrice, idx: parseInt(i) }
                        });
                        yield put({
                            type: actions_createOrder.Type.SET_DELIVERY_OPTION_SUCCESS,
                            payload: {
                                deliveryOption: "SPECIAL",
                                idx: parseInt(i)
                            }
                        })
                        yield put({
                            type: actions_createOrder.Type.SET_DELIVERY_NOTE,
                            payload: { deliveryNote: "promotion99", idx: parseInt(i) }
                        })
                        yield put({
                            type: actions_createOrder.Type.SET_PRICE_SPECIAL,
                            payload: { priceSpecial: "0", idx: parseInt(i) }
                        })
                    }
                } else {
                    ////////////////////// ////////////////////// ////////////////////// ////////////////////// ////////////////////// ////////////////////// ////////////////////// //////////////////////
                    if (promotion99.length == 0 && this_order.mainData.dataShop[0].hubId) {
                        yield put({
                            type: actions_createOrder.Type.SET_DELIVERY_NOTE,
                            payload: { deliveryNote: "", idx: 0 }
                        })
                        yield put({
                            type: actions_createOrder.Type.SET_DELIVERY_OPTION_SUCCESS,
                            payload: {
                                deliveryOption: "",
                                idx: 0
                            }
                        })
                        yield put({
                            type: actions_createOrder.Type.SET_PRICE_SPECIAL,
                            payload: { priceSpecial: "", idx: 0 }
                        })
                        const stateCheckPromotion = yield select(getCreateOrder);
                        if (stateCheckPromotion.orderShop[0].deliveryOption == "") {
                            const setDeliveryOption = stateCheckPromotion.orderShop[0].listLogistic.filter((item) => item.logistic.name == "express")
                            if (setDeliveryOption.length > 0) {
                                yield put({
                                    type: actions_createOrder.Type.SET_DELIVERY_OPTION_SUCCESS,
                                    payload: {
                                        deliveryOption: setDeliveryOption[0].logistic.code,
                                        idx: 0
                                    }
                                })
                                yield put({
                                    type: actions_createOrder.Type.SET_SHIPING_COST,
                                    payload: { shippingCost: setDeliveryOption, idx: 0 }
                                })
                            } else {
                                yield put({
                                    type: actions_createOrder.Type.SET_DELIVERY_OPTION_SUCCESS,
                                    payload: {
                                        deliveryOption: "SPECIAL",
                                        idx: 0
                                    }
                                })
                                const shippingCost = [{
                                    deliveryPrice: 0,
                                    discountPrice: 0
                                }]
                                yield put({
                                    type: actions_createOrder.Type.SET_SHIPING_COST,
                                    payload: { shippingCost: shippingCost, idx: 0 }
                                })
                            }
                        }
                    }

                    if (this_order.mainData.dataShop[0].hubId) {
                        yield put({
                            type: actions_createOrder.Type.SET_DELIVERY_NOTE,
                            payload: { deliveryNote: "", idx: parseInt(i) }
                        })
                        yield put({
                            type: actions_createOrder.Type.SET_DELIVERY_OPTION_SUCCESS,
                            payload: {
                                deliveryOption: "",
                                idx: parseInt(i)
                            }
                        })
                        yield put({
                            type: actions_createOrder.Type.SET_PRICE_SPECIAL,
                            payload: { priceSpecial: "", idx: parseInt(i) }
                        })

                        if (stateCheckPromotion.orderShop[0].mainData.dataShop[0].hubId) {
                            promotion99.push(this_order.mainData.shopId)
                            yield put({
                                type: actions_createOrder.Type.SET_DELIVERY_OPTION_SUCCESS,
                                payload: {
                                    deliveryOption: "SPECIAL",
                                    idx: 0
                                }
                            })
                            const shippingCost = [{
                                deliveryPrice: "99",
                                discountPrice: 0
                            }]
                            yield put({
                                type: actions_createOrder.Type.SET_SHIPING_COST,
                                payload: { shippingCost: shippingCost, idx: 0 }
                            });
                            yield put({
                                type: actions_createOrder.Type.SET_DELIVERY_NOTE,
                                payload: { deliveryNote: "promotion99", idx: 0 }
                            })
                            yield put({
                                type: actions_createOrder.Type.SET_PRICE_SPECIAL,
                                payload: { priceSpecial: "99", idx: 0 }
                            })
                        }
                    }

                    const shopId = (this_order.mainData && this_order.mainData.shopId) || null;
                    const contactId = this_order.contactShop._id;
                    const stateCreate = stateCreateOrder.orderShop[i];
                    const orders = []

                    const orderlist = Object.keys(stateCreate.listMenuSelect).map((key, idx) => {
                        const dataOrder = {
                            productId: key,
                            quantity: stateCreate.listMenuSelect[key].quantity
                        }
                        orders.push(dataOrder)
                    })


                    if (contactId && shopId && stateCreateOrder.orderShop[i].checkShippingCosts.deliveryAt[0].address !== "" && stateCreateOrder.orderShop[i].checkShippingCosts.deliveryAt[0].subdistrict !== "" && stateCreateOrder.orderShop[i].checkShippingCosts.deliveryAt[0].district !== "" && stateCreateOrder.orderShop[i].checkShippingCosts.deliveryAt[0].province !== "") {

                        const queryShippingCost = {
                            deliveryAt: {
                                location: [stateCreateOrder.orderShop[i].checkShippingCosts.deliveryAt[0].location[0].toString(), stateCreateOrder.orderShop[i].checkShippingCosts.deliveryAt[0].location[1].toString()],
                                address: stateCreateOrder.orderShop[i].checkShippingCosts.deliveryAt[0].address,
                                addressExtra: stateCreateOrder.orderShop[i].checkShippingCosts.deliveryAt[0].addressExtra,
                                subdistrict: stateCreateOrder.orderShop[i].checkShippingCosts.deliveryAt[0].subdistrict.subdistrictName,
                                district: stateCreateOrder.orderShop[i].checkShippingCosts.deliveryAt[0].district.districtName,
                                province: stateCreateOrder.orderShop[i].checkShippingCosts.deliveryAt[0].province.provinceName,
                                zipcode: stateCreateOrder.orderShop[i].checkShippingCosts.deliveryAt[0].zipcode,
                                typeCar: "MOTORCYCLE",
                                isBox: stateCreateOrder.orderShop[i].checkShippingCosts.deliveryAt[0].isBox
                            },
                            methodPayment: "transfer",
                            shopId: stateCreateOrder.orderShop[i].mainData._id,
                            contactId: stateCreateOrder.orderShop[i].contactShop._id,
                            orders: orders
                        }
                        const { data } = yield call(api_invoicesCtrl.checkShippingCostNoOrder, { ...queryShippingCost });

                        yield put({
                            type: actions_logistics.Type.GET_LIST_LOGISTIC_SUCCESS,
                            payload: data
                        });
                        yield put({
                            type: actions_createOrder.Type.SET_LIST_LOGISTIC_SUCCESS,
                            payload: {
                                idx: parseInt(i),
                                listLogistic: data
                            }
                        })

                        const stateCheckPromotion = yield select(getCreateOrder);
                        const this_order = stateCheckPromotion.orderShop[i]
                        if (this_order.deliveryOption == "") {
                            const setDeliveryOption = data.filter((item) => item.logistic.name == "express")

                            if (setDeliveryOption.length > 0) {
                                yield put({
                                    type: actions_createOrder.Type.SET_DELIVERY_OPTION_SUCCESS,
                                    payload: {
                                        deliveryOption: setDeliveryOption[0].logistic.code,
                                        idx: parseInt(i)
                                    }
                                })
                            } else {
                                yield put({
                                    type: actions_createOrder.Type.SET_DELIVERY_OPTION_SUCCESS,
                                    payload: {
                                        deliveryOption: "SPECIAL",
                                        idx: parseInt(i)
                                    }
                                })
                            }
                        }

                        const checkLogistic = yield select(getCreateOrder);
                        const stateCreate = checkLogistic.orderShop[i]
                        const logisticPick = data.filter((item, idx) => item.logistic.code == stateCreate.deliveryOption)

                        if (stateCreate.deliveryOption == "SPECIAL" || stateCreate.deliveryOption == "") {
                            const shippingCost = [{
                                deliveryPrice: 0,
                                discountPrice: 0
                            }]
                            yield put({
                                type: actions_createOrder.Type.SET_SHIPING_COST,
                                payload: { shippingCost: shippingCost, idx: parseInt(i) }
                            });
                        } else {
                            yield put({
                                type: actions_createOrder.Type.SET_SHIPING_COST,
                                payload: { shippingCost: logisticPick, idx: parseInt(i) }
                            });
                        }

                        const outOfServiceArea = this_order.errors.calculateShipingcost.outOfServiceArea;
                        if (outOfServiceArea) {
                            /// clear error outOfServiceArea
                            yield put({
                                type: actions_createOrder.Type.SET_ERROR_ADDRESS_OUT_OF_AREA,
                                payload: { idx: parseInt(i), status: false }
                            });
                        }
                    } else {
                        yield put({
                            type: actions_notification.Type.OPEN_NOTIFICATION_ALERT,
                            payload: {
                                message: <div><img src={IconNotification} alt="IconNotification"></img><span className="ml-3">ยังไม่สามารถคำนวณค่าจัดส่งได้เนื่องจากข้อมูลไม่ครบ</span></div>,
                                level: 'error',
                                autoDismiss: 3,
                                position: "tr"
                            }
                        });
                    }
                }
            }


            yield put(actions_loading.closeLoading());// close loading.
        } else {
            // yield put(actions_loading.openLoading());// open loading.
            yield put({
                type: actions_createOrder.Type.REMOVE_DELIVERY_AT_DATE_DELIVERY,
                payload: {
                    idx: payload.idx,
                }
            });
        }
        yield put(actions_loading.closeLoading());// close loading.

    } catch (error) {
        const errorData = (error.response && error.response.data) || null;

        const stateCreateOrder = yield select(getCreateOrder);

        if (errorData && errorData.error && errorData.error.statusCode === 422) {
            const stateCreateOrder = yield select(getCreateOrder);
            const this_order = stateCreateOrder.orderShop[payload.idx];

            if (
                // this_order.deliveryOption === "SKOOTAR" && 
                this_order.deliveryOption !== "SPECIAL" &&
                this_order.deliveryOption !== "" &&
                errorData.error.name === "ERR_OUT_OF_SERVICE_AREA" || errorData.error.name === "DALIVERY_ERROR") {
                yield put({
                    type: actions_notification.Type.OPEN_NOTIFICATION_ALERT,
                    payload: {
                        message: <div><img src={IconNotification} alt="IconNotification"></img><span className="ml-3">การจัดส่งที่ {payload.idx + 1} ที่อยู่ลูกค้าอยู่นอกพื้นที่บริการ</span></div>,
                        level: 'error',
                        autoDismiss: 3,
                        position: "tr"
                    }
                });

                yield put({
                    type: actions_createOrder.Type.SET_ERROR_ADDRESS_OUT_OF_AREA,
                    payload: { idx: payload.idx, status: true }
                });

                yield put({
                    type: actions_logistics.Type.GET_LIST_LOGISTIC_SUCCESS,
                    payload: []
                });
                // dispatch(showModalAlert('ไม่สามารถสั่งอาหารได้ เนื่องจากอยู่นอกพื้นที่ให้บริการ', "Warning", "primary", "Close"));
            }

            if (errorData.error.name === "ERR_OUT_OF_SERVICE_AREA") {
                yield put({
                    type: actions_createOrder.Type.SET_LIST_LOGISTIC_SUCCESS,
                    payload: {
                        idx: payload.idx,
                        listLogistic: [{
                            logistic: {
                                code: "SPECIAL",
                                name: "special",
                                label: "ขนส่งพิเศษ",
                                type: "special",
                                id: "5e6b01a1ad7098122e134c92"
                            },
                            deliveryPrice: 0,
                            discountPrice: 0
                        }]
                    }
                })
                const this_order = stateCreateOrder.orderShop[payload.idx]
                if (payload.idx == 0) {
                    if (stateCreateOrder.orderShop[0].mainData.dataShop[0] && stateCreateOrder.orderShop[0].mainData.dataShop[0].hubId) {
                        const shippingCost = [{
                            deliveryPrice: 99,
                            discountPrice: 0
                        }]
                        yield put({
                            type: actions_createOrder.Type.SET_SHIPING_COST,
                            payload: { shippingCost: shippingCost, idx: 0 }
                        });
                        yield put({
                            type: actions_createOrder.Type.SET_DELIVERY_NOTE,
                            payload: { deliveryNote: "promotion99", idx: 0 }
                        })
                        yield put({
                            type: actions_createOrder.Type.SET_PRICE_SPECIAL,
                            payload: { priceSpecial: 99, idx: 0 }
                        })
                    } else {
                        yield put({
                            type: actions_createOrder.Type.SET_DELIVERY_NOTE,
                            payload: { deliveryNote: "", idx: 0 }
                        })
                        yield put({
                            type: actions_createOrder.Type.SET_PRICE_SPECIAL,
                            payload: { priceSpecial: "", idx: 0 }
                        })
                        const shippingCost = [{
                            deliveryPrice: 0,
                            discountPrice: 0
                        }]
                        yield put({
                            type: actions_createOrder.Type.SET_SHIPING_COST,
                            payload: { shippingCost: shippingCost, idx: 0 }
                        });
                    }
                }
                if (payload.idx != 0) {
                    if (stateCreateOrder.orderShop[0].mainData.dataShop[0] && stateCreateOrder.orderShop[0].mainData.dataShop[0].hubId && stateCreateOrder.orderShop[0].mainData.dataShop[0].hubId == this_order.mainData.dataShop[0].hubId) {
                        if (stateCreateOrder.orderShop[0].checkShippingCosts.deliveryAt[0].dateDelivery == this_order.checkShippingCosts.deliveryAt[0].dateDelivery) {
                            const promoPrice = [{
                                deliveryPrice: 0,
                                discountPrice: 0
                            }]
                            yield put({
                                type: actions_createOrder.Type.SET_SHIPING_COST,
                                payload: { shippingCost: promoPrice, idx: payload.idx }
                            });
                            yield put({
                                type: actions_createOrder.Type.SET_DELIVERY_OPTION_SUCCESS,
                                payload: {
                                    deliveryOption: "SPECIAL",
                                    idx: payload.idx
                                }
                            })
                            yield put({
                                type: actions_createOrder.Type.SET_DELIVERY_NOTE,
                                payload: { deliveryNote: "promotion99", idx: payload.idx }
                            })
                            yield put({
                                type: actions_createOrder.Type.SET_PRICE_SPECIAL,
                                payload: { priceSpecial: "0", idx: payload.idx }
                            })
                        } else {
                            yield put({
                                type: actions_createOrder.Type.SET_DELIVERY_NOTE,
                                payload: { deliveryNote: "", idx: payload.idx }
                            })
                            yield put({
                                type: actions_createOrder.Type.SET_PRICE_SPECIAL,
                                payload: { priceSpecial: "", idx: payload.idx }
                            })
                            const shippingCost = [{
                                deliveryPrice: 0,
                                discountPrice: 0
                            }]
                            yield put({
                                type: actions_createOrder.Type.SET_SHIPING_COST,
                                payload: { shippingCost: shippingCost, idx: payload.idx }
                            });
                        }
                    } else {
                        yield put({
                            type: actions_createOrder.Type.SET_DELIVERY_NOTE,
                            payload: { deliveryNote: "", idx: payload.idx }
                        })
                        yield put({
                            type: actions_createOrder.Type.SET_PRICE_SPECIAL,
                            payload: { priceSpecial: "", idx: payload.idx }
                        })
                        const shippingCost = [{
                            deliveryPrice: 0,
                            discountPrice: 0
                        }]
                        yield put({
                            type: actions_createOrder.Type.SET_SHIPING_COST,
                            payload: { shippingCost: shippingCost, idx: payload.idx }
                        });
                    }
                }

            }
            const checkStateCreateOrder = yield select(getCreateOrder);
            if (Object.keys(stateCreateOrder.orderShop[payload.idx].listMenuSelect).length == 0) {
                yield put({
                    type: actions_createOrder.Type.CLEAR_SEARCH_MENU,
                    payload: {
                        idx: checkStateCreateOrder.activeTab
                    }
                })

                yield put({
                    type: actions_createOrder.Type.REMOVE_DATA_WHEN_NONE_SHOP,
                    payload: {
                        idx: checkStateCreateOrder.activeTab,
                    }
                })
                const stateCreateOrder = yield select(getCreateOrder);
                const { data } = yield call(api_menuCtrl.getListMenu, stateCreateOrder.orderShop[stateCreateOrder.activeTab].search);
                yield put({
                    type: actions_menus.Type.GET_LIST_MENU_SUCCESS,
                    data
                })
                yield put({
                    type: actions_createOrder.Type.REMOVE_DELIVERY_AT_DATE_DELIVERY,
                    payload: {
                        idx: payload.idx,
                    }
                });
                yield put({
                    type: actions_createOrder.Type.SET_PRICE_SPECIAL,
                    payload: {
                        priceSpecial: "",
                        idx: payload.idx
                    }
                })
                yield put({
                    type: actions_createOrder.Type.SET_DELIVERY_OPTION_SUCCESS,
                    payload: {
                        deliveryOption: "",
                        idx: payload.idx
                    }
                })
            } else {
                yield put({
                    type: actions_createOrder.Type.SET_DELIVERY_OPTION_SUCCESS,
                    payload: {
                        deliveryOption: "SPECIAL",
                        idx: payload.idx
                    }
                })
            }
        }
        yield put(actions_loading.closeLoading());// open loading.
    }
}

function* getListAddressUser(action) {
    try {
        const stateCreateOrder = yield select(getCreateOrder);
        const { userCustomer } = stateCreateOrder;
        if (userCustomer && userCustomer.id) {
            const { data } = yield call(api_addressUsersCtrl.getAddressUsers, {
                userId: userCustomer.id
            });

            yield put({
                type: actions_createOrder.Type.GET_LIST_ADDRESS_USER_SUCCESS,
                payload: {
                    listAddressCustomer: data
                }
            });
        }
    } catch (error) {
        console.log(error);
    }
}


function* setAddressUser(action) {
    const { payload } = action;
    try {

        const stateCreateOrder = yield select(getCreateOrder);
        const this_order = stateCreateOrder.orderShop[payload.idx];
        const shopId = (this_order.mainData && this_order.mainData.shopId) || null;
        const contactId = this_order.contactShop._id;
        const listProductId = Object.keys(stateCreateOrder.orderShop[0].listMenuSelect)
        const addressSelect = payload.select.address;
        const stateCreate = stateCreateOrder.orderShop[payload.idx];
        const shippingCost = stateCreate.checkShippingCosts.deliveryAt[0]
        const orders = []

        const orderlist = Object.keys(stateCreate.listMenuSelect).map((key, idx) => {
            const dataOrder = {
                productId: key,
                quantity: stateCreate.listMenuSelect[key].quantity
            }
            orders.push(dataOrder)
        })

        if (this_order.deliveryOption != "SPECIAL" || this_order.deliveryOption != "") {
            if (contactId && shopId && addressSelect.address !== "" && addressSelect.subdistrict !== "" && addressSelect.district !== "" && addressSelect.province !== "") {

                const queryShippingCost = {
                    deliveryAt: {
                        location: [addressSelect.location.lat.toString(), addressSelect.location.lng.toString()],
                        address: addressSelect.address,
                        addressExtra: addressSelect.addressExtra,
                        subdistrict: addressSelect.subdistrict,
                        district: addressSelect.district,
                        province: addressSelect.province,
                        zipcode: addressSelect.zipcode ? addressSelect.zipcode.toString() : '',
                        typeCar: "MOTORCYCLE",
                        isBox: this_order.isBox
                    },
                    methodPayment: "transfer",
                    shopId: shopId,
                    contactId: contactId,
                    orders: orders
                };
                const { data } = yield call(api_invoicesCtrl.checkShippingCostNoOrder, { ...queryShippingCost });

                yield put({
                    type: actions_logistics.Type.GET_LIST_LOGISTIC_SUCCESS,
                    payload: data
                });
                yield put({
                    type: actions_createOrder.Type.SET_LIST_LOGISTIC_SUCCESS,
                    payload: {
                        idx: payload.idx,
                        listLogistic: data
                    }
                })
                const logisticPick = data.filter((item, idx) => item.logistic.code == stateCreate.deliveryOption)
                if (this_order.deliveryOption != "SPECIAL" && this_order.deliveryOption != "") {

                    yield put({
                        type: actions_createOrder.Type.SET_SHIPING_COST,
                        payload: { shippingCost: logisticPick, idx: payload.idx }
                    });
                } else {
                    const shippingCost = [{
                        deliveryPrice: this_order.priceSpecial,
                        discountPrice: 0
                    }]
                    yield put({
                        type: actions_createOrder.Type.SET_SHIPING_COST,
                        payload: { shippingCost: shippingCost, idx: payload.idx }
                    });
                }

                const outOfServiceArea = this_order.errors.calculateShipingcost.outOfServiceArea;
                if (outOfServiceArea) {
                    /// clear error outOfServiceArea
                    yield put({
                        type: actions_createOrder.Type.SET_ERROR_ADDRESS_OUT_OF_AREA,
                        payload: { idx: payload.idx, status: false }
                    });
                }
            } else {
                yield put({
                    type: actions_notification.Type.OPEN_NOTIFICATION_ALERT,
                    payload: {
                        message: <div><img src={IconNotification} alt="IconNotification"></img><span className="ml-3">ยังไม่สามารถคำนวณค่าจัดส่งได้เนื่องจากข้อมูลไม่ครบ</span></div>,
                        level: 'error',
                        autoDismiss: 3,
                        position: "tr"
                    }
                });
            }
        } else if (this_order.deliveryOption === "SPECIAL" || this_order.deliveryOption === "") {
            const data = [{
                deliveryPrice: this_order.priceSpecial,
                discountPrice: 0
            }]
            yield put({
                type: actions_createOrder.Type.SET_SHIPING_COST,
                payload: { shippingCost: data, idx: payload.idx }
            });
        } else {

        }
        yield call(api_addressUsersCtrl.updateAddressUser, {
            addressId: payload.select.addressDefault.id,
            no: 1
        });



        //// get list option ////
        const res_getAllAddressByName = yield call(api_addressCtrl.getAllAddressName, {
            provinceName: payload.select.address.province,
            districtName: payload.select.address.district,
            subdistrictName: payload.select.address.subdistrict
        });
        yield put({
            type: actions_createOrder.Type.SET_ADDRESS_USER_SUCCESS,
            payload: {
                addressUser: payload.select.address,
                idx: payload.idx,
                listAddressUserOption: res_getAllAddressByName
            }
        });
        yield put(actions_address.getListDistricts({ provinceId: res_getAllAddressByName.list_province.provinceId }));
        yield put(actions_address.getListSubDistricts({ districtId: res_getAllAddressByName.list_districts.districtId }));
        //// get list option ////
        //// change address tab 1 Check Shiping cost All order.
        if (payload.idx === 0) {
            for (let i in stateCreateOrder.orderShop) {
                if (stateCreateOrder.orderShop[i].isDefaultContactCustomer) {
                    if (Object.keys(stateCreateOrder.orderShop[i].listMenuSelect).length >= 1) {
                        yield put({
                            type: actions_createOrder.Type.SET_ADDRESS_USER_SUCCESS,
                            payload: {
                                addressUser: payload.select.address,
                                idx: parseInt(i),
                                listAddressUserOption: res_getAllAddressByName
                            }
                        });

                        const stateCreateOrder = yield select(getCreateOrder);
                        const this_orderDefault = stateCreateOrder.orderShop[0];

                        const stateCreate = stateCreateOrder.orderShop[payload.idx];
                        const shippingCost = stateCreate.checkShippingCosts.deliveryAt[0]
                        const listProductId = Object.keys(stateCreate.listMenuSelect)
                        const orders = []

                        const orderlist = Object.keys(stateCreate.listMenuSelect).map((key, idx) => {

                            const dataOrder = {
                                productId: key,
                                quantity: stateCreate.listMenuSelect[key].quantity
                            }
                            orders.push(dataOrder)
                        })

                        if (this_orderDefault.deliveryOption !== "SPECIAL") {
                            const queryShippingCost = {
                                deliveryAt: {
                                    location: [stateCreateOrder.orderShop[0].checkShippingCosts.deliveryAt[0].location[0].toString(), stateCreateOrder.orderShop[0].checkShippingCosts.deliveryAt[0].location[1].toString()],
                                    address: stateCreateOrder.orderShop[0].checkShippingCosts.deliveryAt[0].address,
                                    addressExtra: stateCreateOrder.orderShop[0].checkShippingCosts.deliveryAt[0].addressExtra,
                                    subdistrict: stateCreateOrder.orderShop[0].checkShippingCosts.deliveryAt[0].subdistrict.subdistrictName,
                                    district: stateCreateOrder.orderShop[0].checkShippingCosts.deliveryAt[0].district.districtName,
                                    province: stateCreateOrder.orderShop[0].checkShippingCosts.deliveryAt[0].province.provinceName,
                                    zipcode: stateCreateOrder.orderShop[0].checkShippingCosts.deliveryAt[0].zipcode,
                                    typeCar: "MOTORCYCLE",
                                    isBox: stateCreateOrder.orderShop[0].checkShippingCosts.deliveryAt[0].isBox
                                },
                                methodPayment: "transfer",
                                shopId: stateCreateOrder.orderShop[i].mainData._id,
                                contactId: stateCreateOrder.orderShop[i].contactShop._id,
                                orders: orders
                            }

                            const { data } = yield call(api_invoicesCtrl.checkShippingCostNoOrder, { ...queryShippingCost });
                            yield put({
                                type: actions_logistics.Type.GET_LIST_LOGISTIC_SUCCESS,
                                payload: data
                            });
                            yield put({
                                type: actions_createOrder.Type.SET_LIST_LOGISTIC_SUCCESS,
                                payload: {
                                    idx: payload.idx,
                                    listLogistic: data
                                }
                            })

                            yield put({
                                type: actions_createOrder.Type.SET_SHIPING_COST,
                                payload: { shippingCost: data, idx: parseInt(i) }
                            });
                            if (this_orderDefault.deliveryOption == "") {
                                const setDeliveryOption = data.filter((item) => item.logistic.name == "express")
                                if (setDeliveryOption.length > 0) {
                                    yield put({
                                        type: actions_createOrder.Type.SET_DELIVERY_OPTION_SUCCESS,
                                        payload: {
                                            deliveryOption: setDeliveryOption[0].logistic.code,
                                            idx: payload.idx
                                        }
                                    })
                                } else {
                                    yield put({
                                        type: actions_createOrder.Type.SET_DELIVERY_OPTION_SUCCESS,
                                        payload: {
                                            deliveryOption: "SPECIAL",
                                            idx: payload.idx
                                        }
                                    })
                                }
                            }
                        }
                    }
                }
            }
        }
        yield call(api_addressUsersCtrl.updateAddressUser, {
            addressId: payload.select.addressDefault.id,
            no: 1
        });
        yield put({
            type: actions_createOrder.Type.SET_LOADDING_CONFIRM_SELECT_ADDRESS_USER
        });

        payload.success && payload.success();

    } catch (error) {
        const errorData = (error.response && error.response.data) || null;
        if (errorData && errorData.error && errorData.error.statusCode === 422) {
            const stateCreateOrder = yield select(getCreateOrder);
            const this_order = stateCreateOrder.orderShop[payload.idx];
            if (this_order.deliveryOption == "" || this_order == "SPECIAL" && errorData.error.name === "ERR_OUT_OF_SERVICE_AREA" || errorData.error.name === "DALIVERY_ERROR") {
                yield put({
                    type: actions_notification.Type.OPEN_NOTIFICATION_ALERT,
                    payload: {
                        message: <div><img src={IconNotification} alt="IconNotification"></img><span className="ml-3">การจัดส่งที่ {payload.idx + 1} ที่อยู่ลูกค้าอยู่นอกพื้นที่บริการ</span></div>,
                        level: 'error',
                        autoDismiss: 3,
                        position: "tr"
                    }
                });
                yield put({
                    type: actions_createOrder.Type.SET_ERROR_ADDRESS_OUT_OF_AREA,
                    payload: { idx: payload.idx, status: true }
                });
            }




            //// get list option ////
            const res_getAllAddressByName = yield call(api_addressCtrl.getAllAddressName, {
                provinceName: payload.select.address.province,
                districtName: payload.select.address.district,
                subdistrictName: payload.select.address.subdistrict
            });
            yield put({
                type: actions_createOrder.Type.SET_ADDRESS_USER_SUCCESS,
                payload: {
                    addressUser: payload.select.address,
                    idx: payload.idx,
                    listAddressUserOption: res_getAllAddressByName
                }
            });
            yield put(actions_address.getListDistricts({ provinceId: res_getAllAddressByName.list_province.provinceId }));
            yield put(actions_address.getListSubDistricts({ districtId: res_getAllAddressByName.list_districts.districtId }));
            yield call(api_addressUsersCtrl.updateAddressUser, {
                addressId: payload.select.addressDefault.id,
                no: 1
            });
            //// get list option ////
            //// change address tab 1 Check Shiping cost All order.
            if (payload.idx === 0) {
                for (let i in stateCreateOrder.orderShop) {
                    if (stateCreateOrder.orderShop[i].isDefaultContactCustomer) {
                        if (Object.keys(stateCreateOrder.orderShop[i].listMenuSelect).length >= 1) {
                            yield put({
                                type: actions_createOrder.Type.SET_ADDRESS_USER_SUCCESS,
                                payload: {
                                    addressUser: payload.select.address,
                                    idx: parseInt(i),
                                    listAddressUserOption: res_getAllAddressByName
                                }
                            });
                            yield put({
                                type: actions_createOrder.Type.SET_LIST_LOGISTIC_SUCCESS,
                                payload: {
                                    idx: parseInt(i),
                                    listLogistic: [{
                                        logistic: {
                                            code: "SPECIAL",
                                            name: "special",
                                            label: "ขนส่งพิเศษ",
                                            type: "special",
                                            id: "5e6b01a1ad7098122e134c92"
                                        },
                                        deliveryPrice: 0,
                                        discountPrice: 0
                                    }]
                                }
                            })
                            yield put({
                                type: actions_createOrder.Type.SET_PRICE_SPECIAL,
                                payload: {
                                    priceSpecial: "",
                                    idx: parseInt(i)
                                }
                            })
                            yield put({
                                type: actions_createOrder.Type.SET_DELIVERY_OPTION_SUCCESS,
                                payload: {
                                    deliveryOption: "SPECIAL",
                                    idx: parseInt(i)
                                }
                            })
                            const data = [{
                                deliveryPrice: 0,
                                discountPrice: 0
                            }]
                            yield put({
                                type: actions_createOrder.Type.SET_SHIPING_COST,
                                payload: { shippingCost: data, idx: parseInt(i) }
                            });
                        }
                    }
                }

                // const stateCreateOrder = yield select(getCreateOrder);
                // const { data } = yield call(api_menuCtrl.getListMenu, stateCreateOrder.orderShop[stateCreateOrder.activeTab].search);
                // yield put({
                //     type: actions_menus.Type.GET_LIST_MENU_SUCCESS,
                //     data
                // })

            }

        }
        yield put({
            type: actions_createOrder.Type.SET_LOADDING_CONFIRM_SELECT_ADDRESS_USER
        });
    }
}


function* deleteAddressUser(action) {
    const { payload } = action;
    try {
        yield call(api_addressUsersCtrl.deleteAddressUser, {
            addressId: payload.address.id
        });
        yield put(actions_createOrder.getListAddressUser()); // list address user .
    } catch (error) {
        console.log(error);
    }

}

function* selectListOptionAddressUser(action) {
    const { payload } = action;
    try {
        const { address } = payload;
        if (payload.name === "province") {
            const { data } = yield call(api_addressCtrl.getListDistricts, {
                provinceId: address.provinceId
            });
            yield put({
                type: actions_createOrder.Type.SELECT_LIST_OPTION_ADDRESS_USER_SUCCESS,
                payload: {
                    ...payload,
                    listDistricts: data
                }
            });

        } else if (payload.name === "district") {
            const { data } = yield call(api_addressCtrl.getListSubDistricts, {
                districtId: address.districtId
            });

            yield put({
                type: actions_createOrder.Type.SELECT_LIST_OPTION_ADDRESS_USER_SUCCESS,
                payload: {
                    ...payload,
                    listSubDistricts: data
                }
            });
        } else {
            yield put({
                type: actions_createOrder.Type.SELECT_LIST_OPTION_ADDRESS_USER_SUCCESS,
                payload: {
                    ...payload
                }
            });
        }
    } catch (error) {
        console.log(error);
    }
}


function* setDeliveryOption(action) {
    const { payload } = action;
    try {
        yield put(actions_loading.openLoading());// open loading.
        const stateCreateOrder = yield select(getCreateOrder);
        const this_order = stateCreateOrder.orderShop[payload.idx];
        const shopId = (this_order.mainData && this_order.mainData.shopId) || null;
        const contactId = this_order.contactShop._id;

        const deliveryAt = this_order.checkShippingCosts.deliveryAt[0];
        yield put({
            type: actions_createOrder.Type.SET_DELIVERY_OPTION_SUCCESS,
            payload: {
                deliveryOption: payload.itemLogistic.logistic.code,
                idx: payload.idx
            }
        });

        // if (payload.deliveryOption === "SKOOTAR" || payload.setDeliveryOption === "LLM") {
        //     yield put({
        //         type: actions_createOrder.Type.SET_IS_INDIVIDUALLY_DEFAULT,
        //         payload: {
        //             idx: payload.idx,
        //             isIndividually: true
        //         }
        //     })
        // }

        // if (payload.deliveryOption === "KERRY" || payload.deliveryOption === "CPIU") {
        //     yield put({
        //         type: actions_createOrder.Type.SET_IS_BOX_DEFAULT,
        //         payload: {
        //             ...payload,
        //             isBox: false
        //         }
        //     });
        //     yield put({
        //         type: actions_createOrder.Type.SET_IS_INDIVIDUALLY_DEFAULT,
        //         payload: {
        //             idx: payload.idx,
        //             isIndividually: false
        //         }
        //     })
        // }

        // const listProductId = Object.keys(stateCreateOrder.orderShop[0].listMenuSelect)
        const stateCreate = stateCreateOrder.orderShop[payload.idx];
        // const shippingCost = stateCreate.checkShippingCosts.deliveryAt[0]
        const orders = []

        const orderlist = Object.keys(stateCreate.listMenuSelect).map((key, idx) => {

            const dataOrder = {
                productId: key,
                quantity: stateCreate.listMenuSelect[key].quantity
            }
            orders.push(dataOrder)
        })
        if (payload.itemLogistic.logistic.code != "SPECIAL") {
            if (contactId && shopId && deliveryAt.address !== "" && deliveryAt.subdistrict !== null && deliveryAt.district !== null && deliveryAt.province !== null && deliveryAt.location[0] && deliveryAt.location[1]) {
                const queryShippingCost = {
                    deliveryAt: {
                        location: [deliveryAt.location[0].toString(), deliveryAt.location[1].toString()],
                        address: deliveryAt.address,
                        addressExtra: deliveryAt.addressExtra,
                        subdistrict: deliveryAt.subdistrict.subdistrictName,
                        district: deliveryAt.district.districtName,
                        province: deliveryAt.province.provinceName,
                        zipcode: deliveryAt.zipcode,
                        typeCar: "MOTORCYCLE",
                        isBox: this_order.isBox
                    },
                    methodPayment: "transfer",
                    shopId: shopId,
                    contactId: contactId,
                    orders: orders
                }
                const { data } = yield call(api_invoicesCtrl.checkShippingCostNoOrder, { ...queryShippingCost });
                yield put({
                    type: actions_logistics.Type.GET_LIST_LOGISTIC_SUCCESS,
                    payload: data
                });
                yield put({
                    type: actions_createOrder.Type.SET_LIST_LOGISTIC_SUCCESS,
                    payload: {
                        idx: payload.idx,
                        listLogistic: data
                    }
                })

                const logisticPick = data.filter((item, idx) => item.logistic.code == payload.itemLogistic.logistic.code)

                if (payload.itemLogistic.logistic.code != "SPECIAL") {
                    yield put({
                        type: actions_createOrder.Type.SET_SHIPING_COST,
                        payload: { shippingCost: logisticPick, idx: payload.idx }
                    });
                } else if (payload.itemLogistic.logistic.code == "SPECIAL") {
                    const shippingPrice = [{
                        deliveryPrice: stateCreate.priceSpecial,
                        discountPrice: payload.itemLogistic.discountPrice
                    }]

                    yield put({
                        type: actions_createOrder.Type.SET_SHIPING_COST,
                        payload: { shippingCost: shippingPrice, idx: payload.idx }
                    });
                } else {

                }

                const outOfServiceArea = this_order.errors.calculateShipingcost.outOfServiceArea;
                if (outOfServiceArea) {
                    /// clear error outOfServiceArea
                    yield put({
                        type: actions_createOrder.Type.SET_ERROR_ADDRESS_OUT_OF_AREA,
                        payload: { idx: payload.idx, status: false }
                    });
                }
            }
        } else {
            const shippingPrice = [{
                deliveryPrice: stateCreate.priceSpecial,
                discountPrice: payload.itemLogistic.discountPrice
            }]

            yield put({
                type: actions_createOrder.Type.SET_SHIPING_COST,
                payload: { shippingCost: shippingPrice, idx: payload.idx }
            });
        }
        // if (payload.idx === 0) {
        // for (let i in stateCreateOrder.orderShop) {
        // const this_orderDefault = stateCreateOrder.orderShop[payload.idx];
        // if (stateCreateOrder.orderShop[i].isDefaultContactCustomer) {
        // if (Object.keys(stateCreateOrder.orderShop[i].listMenuSelect).length >= 1) {
        yield put({
            type: actions_createOrder.Type.SET_DELIVERY_OPTION_SUCCESS,
            payload: {
                deliveryOption: payload.itemLogistic.logistic.code,
                idx: parseInt(payload.idx)
            }
        });



        if (payload.idx == 0) {
            for (let i = 1; i <= stateCreateOrder.orderShop.length - 1; i++) {
                const orderShopi = stateCreateOrder.orderShop[i]
                if (orderShopi.deliveryOption == "SPECIAL" && orderShopi.checkShippingCosts.deliveryNote == "promotion99" && orderShopi.isDefaultContactCustomer) {
                    if (contactId && shopId && deliveryAt.address !== "" && deliveryAt.subdistrict !== null && deliveryAt.district !== null && deliveryAt.province !== null && deliveryAt.location[0] && deliveryAt.location[1]) {
                        const shopId = (orderShopi.mainData && orderShopi.mainData.shopId) || null;
                        const contactId = orderShopi.contactShop._id;
                        const deliveryAt = orderShopi.checkShippingCosts.deliveryAt[0];
                        const queryShippingCost = {
                            deliveryAt: {
                                location: [deliveryAt.location[0].toString(), deliveryAt.location[1].toString()],
                                address: deliveryAt.address,
                                addressExtra: deliveryAt.addressExtra,
                                subdistrict: deliveryAt.subdistrict.subdistrictName,
                                district: deliveryAt.district.districtName,
                                province: deliveryAt.province.provinceName,
                                zipcode: deliveryAt.zipcode,
                                typeCar: "MOTORCYCLE",
                                isBox: orderShopi.isBox
                            },
                            methodPayment: "transfer",
                            shopId: shopId,
                            contactId: contactId,
                            orders: orders
                        }
                        const { data } = yield call(api_invoicesCtrl.checkShippingCostNoOrder, { ...queryShippingCost });
                        yield put({
                            type: actions_logistics.Type.GET_LIST_LOGISTIC_SUCCESS,
                            payload: data
                        });
                        yield put({
                            type: actions_createOrder.Type.SET_LIST_LOGISTIC_SUCCESS,
                            payload: {
                                idx: parseInt(i),
                                listLogistic: data
                            }
                        })
                        const setDeliveryOption = data.filter((item) => item.logistic.name == "express")
                        if (setDeliveryOption.length > 0) {
                            yield put({
                                type: actions_createOrder.Type.SET_DELIVERY_OPTION_SUCCESS,
                                payload: {
                                    deliveryOption: setDeliveryOption[0].logistic.code,
                                    idx: parseInt(i)
                                }
                            })
                            yield put({
                                type: actions_createOrder.Type.SET_SHIPING_COST,
                                payload: { shippingCost: setDeliveryOption, idx: parseInt(i) }
                            });
                        } else {
                            yield put({
                                type: actions_createOrder.Type.SET_DELIVERY_OPTION_SUCCESS,
                                payload: {
                                    deliveryOption: "SPECIAL",
                                    idx: parseInt(i)
                                }
                            })
                            const setDeliveryOption = [{
                                deliveryPrice: orderShopi.priceSpecial,
                                discountPrice: 0
                            }]
                            yield put({
                                type: actions_createOrder.Type.SET_SHIPING_COST,
                                payload: { shippingCost: setDeliveryOption, idx: parseInt(i) }
                            });
                        }
                    }
                }
                if (payload.itemLogistic.logistic.code == "SPECIAL" && orderShopi.checkShippingCosts.deliveryNote == "promotion99") {
                    yield put({
                        type: actions_createOrder.Type.SET_DELIVERY_OPTION_SUCCESS,
                        payload: {
                            deliveryOption: "SPECIAL",
                            idx: parseInt(i)
                        }
                    })
                    const setDeliveryOption = [{
                        deliveryPrice: orderShopi.priceSpecial,
                        discountPrice: 0
                    }]
                    yield put({
                        type: actions_createOrder.Type.SET_SHIPING_COST,
                        payload: { shippingCost: setDeliveryOption, idx: parseInt(i) }
                    });
                }
            }
        }

        // if (payload.deliveryOption === "SKOOTAR") {

        //     const stateCreateOrder = yield select(getCreateOrder);
        //     const listProductId = Object.keys(stateCreateOrder.orderShop[0].listMenuSelect)

        //     const stateCreate = stateCreateOrder.orderShop[payload.idx];
        //     const shippingCost = stateCreate.checkShippingCosts.deliveryAt[0]
        //     const orders = []

        //     const orderlist = Object.keys(stateCreate.listMenuSelect).map((key, idx) => {
        //         const dataOrder = {
        //             productId: key,
        //             quantity: stateCreate.listMenuSelect[key].quantity
        //         }
        //         orders.push(dataOrder)
        //     })


        //     const queryShippingCost = {
        //         deliveryAt: {
        //             location: [this_orderDefault.checkShippingCosts.deliveryAt[0].location[0].toString(), this_orderDefault.checkShippingCosts.deliveryAt[0].location[1].toString()],
        //             address: this_orderDefault.checkShippingCosts.deliveryAt[0].address,
        //             addressExtra: this_orderDefault.checkShippingCosts.deliveryAt[0].addressExtra,
        //             subdistrict: this_orderDefault.checkShippingCosts.deliveryAt[0].subdistrict.subdistrictName,
        //             district: this_orderDefault.checkShippingCosts.deliveryAt[0].district.districtName,
        //             province: this_orderDefault.checkShippingCosts.deliveryAt[0].province.provinceName,
        //             zipcode: this_orderDefault.checkShippingCosts.deliveryAt[0].zipcode,
        //             typeCar: "MOTORCYCLE",
        //             isBox: this_orderDefault.checkShippingCosts.deliveryAt[0].isBox
        //         },
        //         methodPayment: "transfer",
        //         shopId: this_orderDefault.mainData._id,
        //         contactId: this_orderDefault.contactShop._id,
        //         orders: orders
        //     };
        //     const { data } = yield call(api_invoicesCtrl.checkShippingCostNoOrder, { ...queryShippingCost });
        //     yield put({
        //         type: actions_logistics.Type.GET_LIST_LOGISTIC_SUCCESS,
        //         payload: data
        //     });
        //     yield put({
        //         type: actions_createOrder.Type.SET_SHIPING_COST,
        //         payload: { shippingCost: data, idx: parseInt(payload.idx) }
        //     });

        // } else if (payload.deliveryOption === "CPIU") {

        //     const noData = [{
        //         deliveryPrice: 0,
        //         discountPrice: 0
        //     }]
        //     yield put({
        //         type: actions_createOrder.Type.SET_SHIPING_COST,
        //         payload: { shippingCost: noData, idx: parseInt(payload.idx) }
        //     });
        // } else if (payload.deliveryOption === "KERRY") {
        //     const noData = [{
        //         deliveryPrice: 0,
        //         discountPrice: 0
        //     }]
        //     yield put({
        //         type: actions_createOrder.Type.SET_SHIPING_COST,
        //         payload: { shippingCost: noData, idx: parseInt(payload.idx) }
        //     });
        // }
        // else {

        // }
        // }
        // }
        // }
        // }
        yield put(actions_loading.closeLoading());// close loading.    }

    } catch (error) {
        console.log(error);
        const errorData = error.response.data;
        if (errorData.error && errorData.error.statusCode === 422) {
            if (payload.itemLogistic.logistic.code != "SPECIAL" &&
                payload.itemLogistic.logistic.code != "" &&
                errorData.error.name === "ERR_OUT_OF_SERVICE_AREA" || errorData.error.name === "DALIVERY_ERROR") {
                yield put({
                    type: actions_notification.Type.OPEN_NOTIFICATION_ALERT,
                    payload: {
                        message: <div><img src={IconNotification} alt="IconNotification"></img><span className="ml-3">การจัดส่งที่ {payload.idx + 1} ที่อยู่ลูกค้าอยู่นอกพื้นที่บริการ</span></div>,
                        level: 'error',
                        autoDismiss: 3,
                        position: "tr"
                    }
                });
                yield put({
                    type: actions_createOrder.Type.SET_ERROR_ADDRESS_OUT_OF_AREA,
                    payload: { idx: payload.idx, status: true }
                });
                // dispatch(showModalAlert('ไม่สามารถสั่งอาหารได้ เนื่องจากอยู่นอกพื้นที่ให้บริการ', "Warning", "primary", "Close"));
            }
        }
        yield put(actions_loading.closeLoading());// close loading.    }
    }
}

function* setIsBox(action) { //set IsBox to defaultContact
    const { payload } = action

    try {

        // if (payload.idx == 0) {
        const stateCreateOrder = yield select(getCreateOrder);
        const this_orderDefault = stateCreateOrder.orderShop[payload.idx];
        // if (stateCreateOrder.orderShop.length > 1) {
        yield put(actions_loading.openLoading());// open loading.
        // yield* stateCreateOrder.orderShop.map(function* (item, id) {
        // if (item.isDefaultContactCustomer && id != 0) {
        yield put({
            type: actions_createOrder.Type.SET_IS_BOX_DEFAULT,
            payload: {
                idx: payload.idx,
                isBox: stateCreateOrder.orderShop[payload.idx].isBox
            }
        })
        const listProductId = Object.keys(stateCreateOrder.orderShop[0].listMenuSelect)

        const stateCreate = stateCreateOrder.orderShop[payload.idx];
        const shippingCost = stateCreate.checkShippingCosts.deliveryAt[0]

        const orders = []

        const orderlist = Object.keys(stateCreate.listMenuSelect).map((key, idx) => {


            const dataOrder = {
                productId: key,
                quantity: stateCreate.listMenuSelect[key].quantity
            }
            orders.push(dataOrder)
        })


        const queryShippingCost = {
            deliveryAt: {
                location: [this_orderDefault.checkShippingCosts.deliveryAt[0].location[0].toString(), this_orderDefault.checkShippingCosts.deliveryAt[0].location[1].toString()],
                address: this_orderDefault.checkShippingCosts.deliveryAt[0].address,
                addressExtra: this_orderDefault.checkShippingCosts.deliveryAt[0].addressExtra,
                subdistrict: this_orderDefault.checkShippingCosts.deliveryAt[0].subdistrict.subdistrictName,
                district: this_orderDefault.checkShippingCosts.deliveryAt[0].district.districtName,
                province: this_orderDefault.checkShippingCosts.deliveryAt[0].province.provinceName,
                zipcode: this_orderDefault.checkShippingCosts.deliveryAt[0].zipcode,
                typeCar: "MOTORCYCLE",
                isBox: this_orderDefault.checkShippingCosts.deliveryAt[0].isBox
            },
            methodPayment: "transfer",
            shopId: this_orderDefault.mainData._id,
            contactId: this_orderDefault.contactShop._id,
            orders: orders
        };
        const { data } = yield call(api_invoicesCtrl.checkShippingCostNoOrder, { ...queryShippingCost });
        yield put({
            type: actions_logistics.Type.GET_LIST_LOGISTIC_SUCCESS,
            payload: data
        });
        yield put({
            type: actions_createOrder.Type.SET_LIST_LOGISTIC_SUCCESS,
            payload: {
                idx: payload.idx,
                listLogistic: data
            }
        })
        yield put({
            type: actions_createOrder.Type.SET_SHIPING_COST,
            payload: { shippingCost: data, idx: parseInt(payload.idx) }
        });
        // }
        // })
        yield put(actions_loading.closeLoading());// close loading.
        // }
        // }


    } catch (error) {
        const errorData = error.response.data;
        if (errorData.error && errorData.error.statusCode === 422) {
            if (errorData.error.name === "ERR_OUT_OF_SERVICE_AREA" || errorData.error.name === "DALIVERY_ERROR") {
                yield put({
                    type: actions_notification.Type.OPEN_NOTIFICATION_ALERT,
                    payload: {
                        message: <div><img src={IconNotification} alt="IconNotification"></img><span className="ml-3">การจัดส่งที่ {payload.idx + 1} ที่อยู่ลูกค้าอยู่นอกพื้นที่บริการ</span></div>,
                        level: 'error',
                        autoDismiss: 3,
                        position: "tr"
                    }
                });
                yield put({
                    type: actions_createOrder.Type.SET_ERROR_ADDRESS_OUT_OF_AREA,
                    payload: { idx: payload.idx, status: true }
                });
                // dispatch(showModalAlert('ไม่สามารถสั่งอาหารได้ เนื่องจากอยู่นอกพื้นที่ให้บริการ', "Warning", "primary", "Close"));
            }
        }
        yield put(actions_loading.closeLoading());// close loading.    }
    }




}

function* setIsIndividually(action) { //set IsBox to defaultContact
    const { payload } = action
    // if (payload.idx == 0) {
    const stateCreateOrder = yield select(getCreateOrder);
    // if (stateCreateOrder.orderShop.length > 1) {
    yield put(actions_loading.openLoading());// open loading.
    // yield* stateCreateOrder.orderShop.map(function* (item, id) {
    // if (item.isDefaultContactCustomer && id != 0) {
    yield put({
        type: actions_createOrder.Type.SET_IS_INDIVIDUALLY_DEFAULT,
        payload: {
            idx: payload.idx,
            isIndividually: stateCreateOrder.orderShop[payload.idx].isIndividually
        }
    })
    // }
    // })
    yield put(actions_loading.closeLoading());// close loading.
    // }
    // }
}

function* selectSearch(action) {
    const { payload } = action

    try {
        const stateCreateOrder = yield select(getCreateOrder);
        const orderShop = stateCreateOrder.orderShop[stateCreateOrder.activeTab]
        const search = orderShop.search.shop

        ///remove order when change search shop
        if (Object.keys(payload)[0] == "shop") {
            if (payload.shop != search) {

                yield put({
                    type: actions_createOrder.Type.REMOVE_DELIVERY_AT_DATE_DELIVERY,
                    payload: {
                        idx: stateCreateOrder.activeTab,
                    }
                });

                yield put({
                    type: actions_createOrder.Type.REMOVE_MENU_FROM_CART_SUCCESS,
                    payload: {
                        idx: stateCreateOrder.activeTab,
                        listMenuSelect: {}
                    }
                })
                ///remove order when change search shop

                ////remove data when no order
                if (stateCreateOrder.orderShop[0].length == 1) {
                    yield put({
                        type: actions_createOrder.Type.REMOVE_DATA_WHEN_NONE_SHOP,
                        payload: {
                            idx: stateCreateOrder.activeTab,
                        }
                    })
                }
            }
            ////remove data when no order


            //////add shop to search redux
            yield put({
                type: actions_createOrder.Type.ADD_SHOP_OF_SEARCH,
                payload: {
                    idx: payload.idx,
                    search: {
                        shop: payload.shop
                    }
                }
            })
            //////add shop to search redux

            ///get List Menu new search shop
            const newStateCreateorder = yield select(getCreateOrder)
            const { data } = yield call(api_menuCtrl.getListMenu, newStateCreateorder.orderShop[stateCreateOrder.activeTab].search);
            yield put({
                type: actions_menus.Type.GET_LIST_MENU_SUCCESS,
                data
            })
        } else if (Object.keys(payload)[0] == "menu") {
            const { data } = yield call(api_menuCtrl.getListMenu, stateCreateOrder.orderShop[stateCreateOrder.activeTab].search);
            yield put({
                type: actions_menus.Type.GET_LIST_MENU_SUCCESS,
                data
            })
        } else {

        }
        ///get List Menu new search shop


    } catch (error) {
        console.log(error);
    }
}

function* setSendLocation(action) {
    const { payload } = action
    const res_sendMessage = yield call(chatCtrl.sendDataToLine_Promise, payload.data);
    if (res_sendMessage.data == "Done") {
        yield put({
            type: actions_notification.Type.OPEN_NOTIFICATION_ALERT,
            payload: {
                message: <div><span className="ml-3">ส่ง location สำเร็จ</span></div>,
                level: 'success',
                autoDismiss: 3,
                position: "tr"
            }
        });
    }
}

export default function* createOrderWatch() {
    yield takeLatest(actions_createOrder.Type.GET_LAT_LNG_BY_ADDRESS_REQUIRE, getLatLngByAddress);
    yield takeLatest(actions_createOrder.Type.SET_LAT_LNG_REQUIRE, setLatLng);
    yield takeLatest(actions_createOrder.Type.ADD_MENU_TO_CART_REQUIRE, addMenuToCart);
    yield takeLatest(actions_createOrder.Type.SET_ACTIVE_TAB_REQUIRE, setActiveTab);
    yield takeLatest(actions_createOrder.Type.CHECK_SPECIAL_CODE_REQUIRE, checkSpecialCode);
    yield takeLatest(actions_createOrder.Type.SET_CONTACT_SHOP_REQUIRE, setContactShop);
    yield takeLatest(actions_createOrder.Type.ADD_TAB_REQUIRE, addTab);
    yield takeLatest(actions_createOrder.Type.ON_SET_QUANTITY_MENU, setStockFromMenuList);
    yield takeLatest(actions_createOrder.Type.SET_DATA_NEW_USER_REQUIRE, setDataNewUser);
    yield takeLatest(actions_createOrder.Type.CONFIRM_SEND_DATA_REQUIRE, mainCreateOrder);
    yield takeLatest(actions_createOrder.Type.SET_TIME_DELIVERY_AT, setDateDelivery);
    yield takeLatest(actions_createOrder.Type.REMOVE_MENU_ON_LIST, addMenuToCart);
    yield takeLatest(actions_createOrder.Type.DELETE_ORDER_MULTI_REQIURE, deleteOrdertMulti);
    yield takeLatest(actions_createOrder.Type.GET_LIST_ADDRESS_USER_REQUES, getListAddressUser);
    yield takeLatest(actions_createOrder.Type.SET_ADDRESS_USER_REQUEST, setAddressUser);
    yield takeLatest(actions_createOrder.Type.DELETE_ADDRESS_USER_REQUEST, deleteAddressUser);
    yield takeLatest(actions_createOrder.Type.SELECT_LIST_OPTION_ADDRESS_USER_REQUEST, selectListOptionAddressUser);
    yield takeLatest(actions_createOrder.Type.SET_DELIVERY_OPTION_REQUEST, setDeliveryOption);
    yield takeLatest(actions_createOrder.Type.SET_IS_BOX, setIsBox);
    yield takeLatest(actions_createOrder.Type.SET_IS_INDIVIDUALLY, setIsIndividually);
    yield takeLatest(actions_createOrder.Type.SELECT_SEARCH_SHOP, selectSearch);
    yield takeLatest(actions_createOrder.Type.SELECT_SEARCH_MENU, selectSearch);
    yield takeLatest(actions_createOrder.Type.SET_SEND_LOCATION_REQUIRE, setSendLocation)


    // yield takeLatest(actions_createOrder.Type.REMOVE_DATA_WHEN_NONE_SHOP, addMenuToCart);
}
